import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Grid } from "@mui/material";
import {
  SearchBar,
  AdvancedSearch,
  EditCard
} from "components/CustomComponents";
import { filterStaticData } from "actions";
import { BB_PRODUCTS } from "constants";

const getSelectedLabel = items =>
  items.map(i => `${_.startCase(i.subsection)}: ${i.value}`);

let isFirstRender = true;

const EditContext = props => {
  const {
    subsections,
    isFilteredData,
    story,
    client,
    dispatchFilterStaticData,
    isFilteringData,
    context,
    setContext,
    productA,
    productB
  } = props;
  const [isContextOpen, setIsContextOpen] = useState(false);

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      return;
    }
    if (context.length === 0) setIsContextOpen(true);
  }, [context]);

  const handleContext = value => {
    setContext(value);
    const filterTerms = [...value, productA, productB];
    const apiTerms = filterTerms.map(i => i.apiValue);
    dispatchFilterStaticData(null, apiTerms, story, client);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <EditCard
          label="Product Filter"
          selected={getSelectedLabel(context)}
          controlled
          isOpen={isContextOpen}
          isError={context.length === 0}
          errorText="Please select a new Product Filter."
          disabled={!productA || !productB}
        >
          <SearchBar
            value={context}
            onChange={(e, value) => handleContext(value)}
            options={subsections.filter(i => !BB_PRODUCTS.includes(i.name))}
            multiple
            disabled={isFilteringData || !isFilteredData}
            styles={{ mb: 2 }}
            isUpdating={isFilteringData}
            infoText="What you select here will become the scope of the report. You can enter multiple attributes."
            story={story}
          />
          <AdvancedSearch
            fields={subsections.filter(i => !BB_PRODUCTS.includes(i.name))}
            isLoading={isFilteringData}
            selected={context}
            onChange={value => handleContext(value)}
            story={story}
          />
        </EditCard>
      </Grid>
    </Grid>
  );
};

EditContext.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  isFilteredData: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  dispatchFilterStaticData: PropTypes.func,
  client: PropTypes.string,
  story: PropTypes.string,
  context: PropTypes.arrayOf(PropTypes.shape()),
  setContext: PropTypes.func,
  productA: PropTypes.shape(),
  productB: PropTypes.shape()
};

EditContext.defaultProps = {
  subsections: [],
  isFilteredData: false,
  isFilteringData: false,
  dispatchFilterStaticData: () => {},
  client: "",
  story: "",
  context: [],
  setContext: () => {},
  productA: null,
  productB: null
};

const mapStateToProps = (state, ownProps) => {
  const { story } = ownProps;
  const {
    search: {
      filteredSubsections = { story: [] },
      isFilteredData = { story: false },
      isFilteringData
    }
  } = state;
  return {
    isFilteringData,
    client: state.user.user.client,
    subsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story]
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditContext);
