import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import SKUPrioritisation from "./SKUPrioritisation";
import Weightings from "./Weightings";
import RankingCustomisation from "./RankingCustomisation";
import SKUEditing, { NewPrioritisation } from "./SKUEditing";

const showComponent = (Component, isSterling) => {
  if (isSterling) return <Component isSterling={isSterling} />;
  return <SKUPrioritisation isSterling={isSterling} />;
};

const SterlingPortfolio = props => {
  const { isSterling } = props;
  const { option, type } = useParams();
  return (
    <Fragment>
      {option === "weightings" && showComponent(Weightings, isSterling)}
      {option === "prioritisation" && (
        <SKUPrioritisation isSterling={isSterling} />
      )}
      {option === "ranking" && showComponent(RankingCustomisation, isSterling)}
      {option === "ranging" &&
        (type
          ? showComponent(NewPrioritisation, isSterling)
          : showComponent(SKUEditing, isSterling))}
    </Fragment>
  );
};

SterlingPortfolio.propTypes = {
  isSterling: PropTypes.bool
};

SterlingPortfolio.defaultProps = {
  isSterling: false
};

export default SterlingPortfolio;
