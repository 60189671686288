import React from "react";
import PropTypes from "prop-types";
import { formatNumber, wrapText } from "utils";
import PlotlyChart from "components/PlotlyChart";

const ClusteredColumn = props => {
  const { data, display, colors } = props;
  const isValence = display.apply?.includes("valence");
  const isSeries = display.apply?.includes("series");
  const numberFormat = display.labels_style.number_format;
  const { minimum_scale: min, maximum_scale: max } = display.values_style;
  return (
    <PlotlyChart
      data={[
        {
          x: data.x.map(i => wrapText(i, 15)),
          y: data.y,
          type: "bar",
          text: data.y.map(i => formatNumber(i, numberFormat)),
          textposition: isValence && "outside",
          textfont: {
            color:
              isValence && data.y.map(i => (i > 0 ? "#01B050" : "#FF0000")),
            size: 14
          },
          insidetextfont: { color: "#FFFFFF" },
          cliponaxis: false,
          marker: {
            color:
              (isValence && data.y.map(i => (i > 0 ? "#01B050" : "#FF0000"))) ||
              (isSeries && data.y.map((i, k) => colors[k % colors.length])) ||
              display.color
          }
        }
      ]}
      layout={{
        xaxis: {
          type: "category",
          showline: false,
          showticklabels: !display.align,
          tickcolor: "transparent",
          ticklen: 2,
          tickfont: { size: 12 },
          textangle: 0,
          automargin: true,
          zeroline: true,
          fixedrange: true
        },
        yaxis: {
          showticklabels: false,
          showgrid: false,
          zeroline: true,
          range: [min, max],
          fixedrange: true
        },
        margin:
          display.align || display.isSmall
            ? { t: 20, b: 40, r: 0, l: 0 }
            : { t: 40, b: 40, r: 20, l: 20 }
      }}
    />
  );
};

ClusteredColumn.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string)
};

ClusteredColumn.defaultProps = {
  data: {},
  display: {},
  colors: []
};

export default ClusteredColumn;
