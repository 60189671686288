import { createTheme } from "@mui/material";

const defaultTheme = createTheme();

const primaryFont = "'Inter Variable', sans-serif";
const secondaryFont = "'Montserrat Variable', sans-serif";

const palette = {
  primary: {
    main: "#300045"
  },
  primaryArray: {
    50: "#EAE6EC",
    100: "#D6CCDA",
    200: "#AC99B5",
    300: "#83668F",
    400: "#471C59",
    500: "#300045",
    600: "#2B003E",
    700: "#220030",
    800: "#180023",
    900: "#0E0015"
  },
  secondary: {
    main: "#FA4A4A"
  },
  secondaryArray: {
    50: "#FFEDED",
    100: "#FDB7B7",
    200: "#FDB7B7",
    300: "#FC9292",
    400: "#FB6E6E",
    500: "#FA4A4A",
    600: "#C83B3B",
    700: "#AF3434",
    800: "#7D2525",
    900: "#4B1616"
  },
  grey: {
    50: "#F9FAFB",
    100: "#F1F2F4",
    200: "#E5E7EB",
    300: "#D1D5DB",
    400: "#9CA3AF",
    500: "#6B7280",
    600: "#4B5563",
    700: "#374151",
    800: "#1F2937",
    900: "#111827"
  },
  positive: {
    main: "#22C55E",
    light: "#F6FEF8"
  },
  positiveArray: {
    50: "#F6FEF8",
    100: "#DCFCE7",
    200: "#BBF7D0",
    300: "#86EFAC",
    400: "#4ADE80",
    500: "#22C55E",
    600: "#16A34A",
    700: "#15803D",
    800: "#166534",
    900: "#14532D"
  },
  negative: {
    main: "#E6482D",
    light: "#FEF3F1",
    contrastText: "#fff"
  },
  negativeArray: {
    50: "#FEF3F1",
    100: "#F9D0C9",
    200: "#F5B2A6",
    300: "#EE8472",
    400: "#EA6650",
    500: "#E6482D",
    600: "#C73D26",
    700: "#A7321F",
    800: "#882817",
    900: "#681D10"
  },
  neutral: {
    main: "#FFB500",
    light: "#FFF8E6",
    contrastText: "#fff"
  },
  neutralArray: {
    50: "#FFF8E6",
    100: "#FFE9B3",
    200: "#FFE199",
    300: "#FFD366",
    400: "#FFC433",
    500: "#FFB500",
    600: "#E6A300",
    700: "#B37F00",
    800: "#805B00",
    900: "#4C3600"
  },
  disabled: {
    main: "#9CA3AF",
    contrastText: "#fff"
  },
  common: {
    black: "#212427"
  },
  text: {
    primary: "#212427",
    secondary: "#6B7280",
    disabled: "#9CA3AF"
  },
  jade: {
    main: "#35B6B6",
    contrastText: "#fff",
    50: "#EBF8F8",
    100: "#D7F0F0",
    200: "#C2E9E9",
    300: "#AEE2E2",
    400: "#72CCCC",
    500: "#35B6B6",
    600: "#2A9292",
    700: "#257F7F",
    800: "#1B5B5B",
    900: "#103737"
  },
  carmine: {
    main: "#C82E46",
    contrastText: "#fff",
    50: "#FAEAED",
    100: "#F4D5DA",
    200: "#E9ABB5",
    300: "#DE8290",
    400: "#D3586B",
    500: "#C82E46",
    600: "#A02538",
    700: "#781C2A",
    800: "#50121C",
    900: "#28090E"
  }
};

export const REPORT_COLORS = {
  performance: palette.secondary.main,
  focus: palette.carmine.main,
  brandBattles: palette.primaryArray[400],
  rangeReview: palette.jade[500],
  trend: palette.carmine[500],
  autoRanging: palette.neutral.main,
  sterling: palette.secondary.main
};

const variantStyles = {
  buttonSmall: {
    fontSize: defaultTheme.typography.pxToRem(13),
    padding: "4px 10px"
  },
  softPrimary: {
    backgroundColor: palette.primaryArray[50],
    color: palette.primary.main,
    "&:hover": { backgroundColor: palette.primaryArray[100] }
  },
  softSecondary: {
    backgroundColor: palette.secondaryArray[50],
    color: palette.secondary.main,
    "&:hover": { backgroundColor: palette.secondaryArray[100] }
  },
  softCarmine: {
    backgroundColor: palette.carmine[50],
    color: palette.carmine.main,
    "&:hover": { backgroundColor: palette.carmine[100] }
  },
  softDisabled: { backgroundColor: palette.grey[100] }
};

const theme = createTheme({
  palette,
  shape: { borderRadius: 5 },
  typography: {
    fontFamily: primaryFont,
    h1: {
      fontSize: 36,
      lineHeight: "52px",
      fontWeight: 600,
      letterSpacing: 0,
      fontFamily: secondaryFont
    },
    h2: {
      fontSize: 32,
      lineHeight: "48px",
      fontWeight: 600,
      letterSpacing: 0,
      fontFamily: secondaryFont
    },
    h3: {
      fontSize: 24,
      lineHeight: "36px",
      fontWeight: 700,
      letterSpacing: 0,
      fontFamily: secondaryFont
    },
    h4: {
      fontSize: 20,
      lineHeight: "32px",
      fontWeight: 700,
      letterSpacing: 0,
      fontFamily: secondaryFont
    },
    h5: {
      fontSize: 18,
      lineHeight: "26px",
      fontWeight: 500,
      letterSpacing: 0,
      fontFamily: secondaryFont
    },
    h6: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 700,
      fontFamily: secondaryFont
    },
    subtitle1: { fontSize: 16, lineHeight: "20px", letterSpacing: 0 },
    subtitle2: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 500,
      letterSpacing: 0
    },
    body1: { fontSize: 14, lineHeight: "20px", letterSpacing: 0 },
    body2: { fontSize: 12, lineHeight: "20px", letterSpacing: 0 },
    button: { textTransform: "none" },
    caption: { fontSize: 12, lineHeight: "16px", letterSpacing: 0 }
  },
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: ({ ownerState }) => ({
          whiteSpace: "nowrap",
          ...(ownerState.variant === "outlined" &&
            !ownerState.className?.includes("MuiButtonGroup") && {
              borderColor: palette.grey[200]
            })
        }),
        sizeLarge: { height: 48 }
      },
      variants: [
        {
          props: { variant: "soft", color: "primary" },
          style: variantStyles.softPrimary
        },
        {
          props: { variant: "soft", color: "primary", size: "small" },
          style: { ...variantStyles.softPrimary, ...variantStyles.buttonSmall }
        },
        {
          props: { variant: "soft", color: "secondary" },
          style: variantStyles.softSecondary
        },
        {
          props: { variant: "soft", color: "secondary", size: "small" },
          style: {
            ...variantStyles.softSecondary,
            ...variantStyles.buttonSmall
          }
        },
        {
          props: { variant: "soft", disabled: true },
          style: variantStyles.softDisabled
        },
        {
          props: { variant: "soft", disabled: true, size: "small" },
          style: { ...variantStyles.softDisabled, ...variantStyles.buttonSmall }
        },
        {
          props: { variant: "soft", color: "carmine" },
          style: variantStyles.softCarmine
        },
        {
          props: { variant: "soft", color: "carmine", size: "small" },
          style: {
            ...variantStyles.softCarmine,
            ...variantStyles.buttonSmall
          }
        }
      ]
    },
    MuiChip: {
      styleOverrides: {
        root: { fontSize: 12, fontWeight: 500 },
        filled: ({ ownerState }) => ({
          ...(ownerState.color === "default" && {
            backgroundColor: palette.grey[200],
            color: palette.grey[500]
          })
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: { paper: { color: palette.grey[600] } }
    },
    MuiMenuItem: { styleOverrides: { root: { color: palette.grey[600] } } },
    MuiInputLabel: { styleOverrides: { root: { fontSize: 16 } } },
    MuiAlert: { styleOverrides: { root: { fontSize: 14 } } }
  }
});

export default theme;
