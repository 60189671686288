import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  Navigate,
  generatePath
} from "react-router-dom";
import _ from "lodash";
import {
  ExpectedOutput,
  NavigateStepButtons
} from "components/CustomComponents";
import {
  NEW_AUTO_RANGING_ROUTE,
  AUTO_RANGING_REPORT_INITIAL_ROUTE,
  NEW_AUTO_RANGING_FINAL_ROUTE
} from "constants/viewRoutes";
import { clearAutoRangingReports, generateAutoRangingReport } from "actions";

const Review = props => {
  const {
    setActiveStep,
    client,
    autoRangingConstraints,
    dispatchGenerateAutoRangingReport,
    dispatchClearAutoRangingReports
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { id: processId, type } = params;

  useEffect(() => {
    setActiveStep(3);
  }, []);

  const {
    basicConstraints: {
      story,
      retailer,
      comparisonRetailer,
      period,
      consumptionType,
      categories,
      nDist
    },
    bayPlanConstraints,
    currentRangeConstraints: { inRange },
    generatedReport: { isLoading }
  } = autoRangingConstraints;

  const onNextClicked = () => {
    const scope = { consumption_type: consumptionType, ...categories };

    const rangeSizesTableData = bayPlanConstraints.map(
      ({ rangeName, skus, newRangeSize, storesAccountedFor }) => ({
        range_name: rangeName,
        number_of_skus: skus,
        new_range_size: newRangeSize,
        percentage_of_stores_accounted_for: storesAccountedFor
      })
    );

    const currentRangeTableData = inRange.map(
      ({
        tag,
        sales,
        distribution,
        bayId,
        bayName,
        sku,
        salesFormatted,
        distributionFormatted
      }) => ({
        tag,
        sales,
        distribution,
        bay_id: bayId,
        bay_name: bayName,
        sku,
        sales_formatted: salesFormatted,
        distribution_formatted: distributionFormatted
      })
    );

    const constraints = {
      retailer,
      comparison_retailer: comparisonRetailer,
      scope,
      range_sizes_table: rangeSizesTableData,
      current_range_table: currentRangeTableData,
      num_bays: bayPlanConstraints.length,
      story: story.toUpperCase(),
      period,
      client,
      ndist_threshold: nDist
    };

    dispatchGenerateAutoRangingReport({
      constraints,
      onSuccess: ({ reportId }) => {
        dispatchClearAutoRangingReports();
        navigate(
          generatePath(AUTO_RANGING_REPORT_INITIAL_ROUTE, {
            reportId,
            story
          }),
          { state: { from: NEW_AUTO_RANGING_FINAL_ROUTE } }
        );
      }
    });
  };

  if (_.isEmpty(inRange)) {
    return (
      <Navigate
        to={generatePath(NEW_AUTO_RANGING_ROUTE, {
          type,
          id: processId,
          step: "basics"
        })}
      />
    );
  }

  return (
    <Fragment>
      <ExpectedOutput story={story} />
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_AUTO_RANGING_ROUTE, {
              type,
              id: processId,
              step: "current-range"
            })
          )
        }
        onNextClick={onNextClicked}
        nextLabel="Run Auto-Ranging"
        nextLoading={isLoading}
      />
    </Fragment>
  );
};

Review.propTypes = {
  client: PropTypes.string,
  autoRangingConstraints: PropTypes.shape(),
  dispatchGenerateAutoRangingReport: PropTypes.func,
  dispatchClearAutoRangingReports: PropTypes.func,
  setActiveStep: PropTypes.func
};

Review.defaultProps = {
  client: "",
  autoRangingConstraints: {},
  dispatchGenerateAutoRangingReport: () => {},
  dispatchClearAutoRangingReports: () => {},
  setActiveStep: () => {}
};

const mapStateToProps = state => ({
  client: state.user.user.client,
  autoRangingConstraints: state.autoRanging
});

const mapDispatchToProps = dispatch => ({
  dispatchGenerateAutoRangingReport: ({ constraints, onSuccess }) =>
    dispatch(generateAutoRangingReport({ constraints, onSuccess })),
  dispatchClearAutoRangingReports: () => dispatch(clearAutoRangingReports())
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
