import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { formatWeekNumber, locale } from "utils";
import { DateInput } from "./Internal";
import "react-datepicker/dist/react-datepicker.css";
import "./Internal/datepicker.css";

const Datepicker = props => {
  const { label, error, isMonth, isClearable, onClear, styles, ...otherProps } =
    props;
  return (
    <DatePicker
      customInput={
        <DateInput
          label={label}
          error={error}
          isClearable={isClearable}
          onClear={onClear}
          styles={styles}
        />
      }
      dateFormat={isMonth ? "MMM yyyy" : "dd/MM/yyyy"}
      locale={locale}
      showWeekNumbers
      formatWeekNumber={date => formatWeekNumber(date)}
      popperPlacement="bottom"
      showMonthYearPicker={isMonth}
      useWeekdaysShort
      {...otherProps}
    />
  );
};

Datepicker.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  isMonth: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClear: PropTypes.func,
  styles: PropTypes.shape()
};

Datepicker.defaultProps = {
  label: "",
  error: "",
  isMonth: false,
  isClearable: false,
  onClear: () => {},
  styles: {}
};

export default Datepicker;
