import React from "react";
import PropTypes from "prop-types";
import { Stepper, Step, StepLabel } from "@mui/material";
import { Paper } from "components/BaseComponents";

const ReportStepper = props => {
  const { activeStep, steps } = props;
  return (
    <Paper>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          "& .MuiStepLabel-label": { fontSize: 14 },
          "& .MuiStepIcon-root": {
            "&.Mui-active, &.Mui-completed": { color: "secondary.main" }
          }
        }}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
};

ReportStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string)
};

ReportStepper.defaultProps = {
  activeStep: 0,
  steps: []
};

export default ReportStepper;
