import React from "react";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { EditReport } from "components/ReportBuilder";
import { getStoryFromQuery } from "utils";
import { getStoryConfig } from "config";
import { PerformanceIcon } from "../Icons";

const EditPerformanceReport = () => {
  const location = useLocation();
  const story = getStoryFromQuery(location.search);
  const { dataSet } = getStoryConfig(story);
  if (!story || !dataSet) {
    return <Skeleton variant="rounded" height={500} />; // TODO:
  }
  return (
    <EditReport
      title="Performance Report"
      icon={<PerformanceIcon />}
      description="Understand how a category or brand is performing over time, in a specific market, channel or retailer. A performance overview that also drills down to sku level."
      story={story}
      dataSet={dataSet}
    />
  );
};

export default EditPerformanceReport;
