import React from "react";
import PropTypes from "prop-types";
import PlotlyChart from "components/PlotlyChart";

const BubbleChart = props => {
  const { display, data } = props;
  const colorMap = display.chart_style.color_map;
  return (
    <PlotlyChart
      data={[
        {
          x: data.y.map(i => i[0]),
          y: data.y.map(i => i[1]),
          mode: "markers+text",
          marker: {
            color: data.y.map((i, k) => `#${colorMap[0][k]}50`),
            size: data.y.map(i => i[2]),
            sizeref: (2.0 * Math.max(...data.y.map(i => i[2]))) / 120 ** 2,
            sizemode: "area"
          },
          type: "scatter",
          text: Object.values(display.labels_style.individual_map[0]),
          textfont: { size: 12 },
          cliponaxis: false
        }
      ]}
      layout={{
        xaxis: {
          rangemode: "tozero",
          automargin: true,
          title: display.xAxisTitle,
          showgrid: false,
          ticklen: 5
        },
        yaxis: {
          rangemode: "tozero",
          automargin: true,
          title: display.yAxisTitle,
          showgrid: false,
          ticklen: 5
        },
        margin: { t: 40, b: 60, r: 60, l: 60 }
      }}
    />
  );
};

BubbleChart.propTypes = {
  display: PropTypes.shape(),
  data: PropTypes.shape()
};

BubbleChart.defaultProps = {
  display: {},
  data: {}
};

export default BubbleChart;
