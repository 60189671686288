import {
  PerformanceOverviewIcon,
  ExecutiveSummaryIcon,
  SoundbitesIcon,
  ComponentsAnalysisIcon,
  NewProductListingsIcon,
  DistributionChangesIcon,
  RateIcon,
  OffShelfIcon,
  AppendixIcon,
  DriversOfChangeIcon,
  ToolsIcon,
  NPDIcon,
  MarketAnomaliesIcon,
  ProductSwitchingIcon,
  TimeComparisonsIcon,
  DistributionOptimisation,
  SideBySideIcon,
  ShareIcon,
  UnderPerformersIcon,
  StarPerformersIcon,
  SalesOveriewIcon,
  ContextIcon,
  ImpactIcon,
  TopInsightsIcon,
  DriversIcon,
  PerformanceSplitIcon,
  MarketSplitIcon,
  DeepDiveSkuIcon
} from "components/Icons";

export const CONTENT_DETAILS = {
  ida: {
    "executive summary": {
      name: "Executive Summary",
      description:
        "An overview of performance: from sales and share change; to market performance; and key drivers of growth or decline.",
      icon: ExecutiveSummaryIcon
    },
    soundbites: {
      name: "Soundbites",
      description:
        "The most important, concise insights that you need to know about.",
      icon: SoundbitesIcon
    },
    performance: {
      name: "Performance",
      description:
        "A look into numerous performance metrics: from competitor performance; to pack migration; and market dynamics.",
      icon: PerformanceOverviewIcon
    },
    "component analysis": {
      name: "Components Analysis",
      description:
        "An overview to the drivers and drainers of performance through a 'causal factor' lens, starting with a Waterfall Chart (which is broken out in the subsequent sections).",
      icon: ComponentsAnalysisIcon
    },
    "new product listings": {
      name: "New Product Listings",
      description:
        "Understand the impact of NPD sales and their contribution to overall performance.",
      icon: NewProductListingsIcon,
      isChild: true
    },
    "distribution changes": {
      name: "Distribution Changes",
      description: "The change and impact of changes in Distribution.",
      note: "Distribution is weighted distribution. Weighted Distribution is the percentage of stores handling the product weighted by product category store sales.",
      icon: DistributionChangesIcon,
      isChild: true
    },
    "rate of sale and pack/price mix": {
      name: "Rate of Sale and Pack/Price Architecture",
      description:
        "The change and impact of changes to Rate of Sale, Price, and Other factors.",
      icon: RateIcon,
      isChild: true
    },
    "off-shelf presence": {
      name: "Off-Shelf Presence",
      description:
        "The change and impact of changes in Off-Shelf Presence (Promoted Distribution).",
      icon: OffShelfIcon,
      isChild: true
    },
    "promo rate of sale and pack/price mix": {
      name: "Promo Rate of Sale and Pack/Price Architecture",
      description:
        "The change and impact of changes to Promoted Rate of Sale, Promoted Price, and Other Promotional factors.",
      icon: RateIcon,
      isChild: true
    },
    "new tools (beta)": {
      name: "New Tools (Beta)",
      description:
        "We have a set of new and exciting tools to help you answer the questions you have, and ultimately deliver actionable insights to you, fast.",
      icon: ToolsIcon,
      isHidden: true
    },
    appendix: {
      name: "Appendix",
      description:
        "Details of any slides not included within the report, where applicable.",
      icon: AppendixIcon
    }
  },
  cga: {
    "executive summary": {
      name: "Executive Summary",
      description:
        "An overview of performance: from sales and share change; to market performance; and key drivers of growth or decline.",
      icon: ExecutiveSummaryIcon
    },
    soundbites: {
      name: "Soundbites",
      description:
        "The most important, concise insights that you need to know about.",
      icon: SoundbitesIcon
    },
    performance: {
      name: "Performance",
      description:
        "A look into numerous performance metrics: from competitor performance; to pack migration; and market dynamics.",
      icon: PerformanceOverviewIcon
    },
    "component analysis": {
      name: "Components Analysis",
      description:
        "An overview to the drivers and drainers of performance through a 'causal factor' lens, starting with a Waterfall Chart (which is broken out in the subsequent sections).",
      icon: ComponentsAnalysisIcon
    },
    "new product listings": {
      name: "New Product Listings",
      description:
        "Understand the impact of NPD sales and their contribution to overall performance.",
      icon: NewProductListingsIcon,
      isChild: true
    },
    "distribution changes": {
      name: "Distribution Changes",
      description: "The change and impact of changes in Distribution.",
      note: "Distribution is weighted distribution. Weighted Distribution is the percentage of stores handling the product weighted by product category store sales.",
      icon: DistributionChangesIcon,
      isChild: true
    },
    "rate of sale and pack/price mix": {
      name: "Rate of Sale and Pack/Price Architecture",
      description:
        "The change and impact of changes to Rate of Sale, Price, and Other factors.",
      icon: RateIcon,
      isChild: true
    },
    appendix: {
      name: "Appendix",
      description:
        "Details of any slides not included within the report, where applicable.",
      icon: AppendixIcon
    }
  },
  bb: {
    "executive summary": {
      name: "Executive Summary",
      description:
        "An overview of performance: from sales and share change; to market performance; and key drivers of change between the 2 products.",
      icon: ExecutiveSummaryIcon
    },
    performance: {
      name: "Performance",
      description:
        "A look into numerous performance metrics: from competitor performance; to performance over time; and market dynamics.",
      icon: PerformanceOverviewIcon
    },
    "component analysis": {
      name: "Components Analysis",
      description:
        "An overview to the drivers and drainers of performance through a 'causal factor' lens).",
      icon: ComponentsAnalysisIcon
    },
    "drivers of change: overview": {
      name: "Drivers of Change: Overview",
      description:
        "Understand the impact of NPD sales and their contribution to overall performance.",
      icon: DriversOfChangeIcon
    },
    "{subset 1}: driver of change 1": {
      name: "{Subset 1}: Driver of Change 1",
      description:
        "A dive into the share performance, mix impact of the shift to / from the driver, and market competitor performance.",
      icon: DriversOfChangeIcon,
      isChild: true
    },
    "{subset 2}: driver of change 2": {
      name: "{Subset 2}: Driver of Change 2",
      description:
        "A dive into the share performance, mix impact of the shift to / from the driver, and market competitor performance.",
      icon: DriversOfChangeIcon,
      isChild: true
    },
    "{subset 3}: driver of change 3": {
      name: "{Subset 3}: Driver of Change 3",
      description:
        "A dive into the share performance, mix impact of the shift to / from the driver, and market competitor performance.",
      icon: DriversOfChangeIcon,
      isChild: true
    },
    appendix: {
      name: "Appendix",
      description:
        "Details of any slides not included within the report, where applicable.",
      icon: AppendixIcon
    }
  },
  prr: {
    "top insights": {
      name: "Top Insights",
      description: "Headline points from within the report.",
      icon: TopInsightsIcon
    },
    "the context": {
      name: "The Context",
      description:
        "A quick look back at the performance context as we went into the Range Review.",
      icon: ContextIcon
    },
    "the impact": {
      name: "The Impact",
      description:
        "A high-level view of the impact of the Range Review on performance.",
      icon: ImpactIcon
    },
    "the drivers": {
      name: "The Drivers",
      description:
        "Dive deeper to identify what has driven the changes seen in the Post Range Review Period.",
      icon: DriversIcon
    },
    "performance by {split 1} and {split 2} within": {
      name: "Performance by {Split 1} and {Split 2} within",
      description:
        "Understand performance split by {Split 1} and then by {Split 2}.",
      icon: PerformanceSplitIcon
    },
    "performance vs {market parent} excl. {selected market}": {
      name: "Performance Vs {Market Parent} excl. {Selected Market}",
      description:
        "Understanding performance relative to the broader market context.",
      icon: MarketSplitIcon
    },
    "deep dive by sku": {
      name: "Deep Dive By Sku",
      description:
        "Sku level changes caused by changes in Distribution and Rate of Sale.",
      icon: DeepDiveSkuIcon
    },
    "new product listings": {
      name: "New Product Listings",
      description:
        "Detail of NPD sales and how they have contributed to the overall impact of the Range Review.",
      icon: NewProductListingsIcon
    },
    appendix: {
      name: "Appendix",
      description:
        "Details of any slides not included within the report, where applicable.",
      icon: AppendixIcon
    }
  },
  focus: {
    "executive summary": {
      name: "Executive Summary",
      description:
        "High-level insights, detailing the most important findings from the report.",
      icon: ExecutiveSummaryIcon
    },
    performance: {
      name: "Performance",
      description:
        "High-level performance insights – value, volume, and price.",
      icon: PerformanceOverviewIcon
    },
    "star-performers": {
      name: "Star-Performers",
      description:
        "Key products that have over-performed and causal drivers behind this.",
      icon: StarPerformersIcon
    },
    "under-performers": {
      name: "Under-Performers",
      description:
        "Key products that have under-performed and causal drivers behind this.",
      icon: UnderPerformersIcon
    },
    "total performance": {
      name: "Total Performance",
      description:
        "High-level performance insights for the total product - value, volume, and price.",
      icon: PerformanceOverviewIcon
    },
    "sales overview": {
      name: "Sales Overview",
      description:
        "High-level performance insights – value, volume, and price.",
      icon: SalesOveriewIcon
    },
    "share performance": {
      name: "Share Performance",
      description:
        "Relative performance compared to the selected product filter; insights into key competitor wins & losses.",
      icon: ShareIcon
    },
    "npd focus": {
      name: "NPD Focus",
      description:
        "NPD Contribution to sales, and relative price compared to existing product sales.",
      icon: NewProductListingsIcon
    },
    "npd categorisation": {
      name: "NPD Categorisation",
      description:
        "Understand the reliance and impact of NPD by type of innovation (brand, product extension, pack extension).",
      icon: NPDIcon
    }
  }
};

export const IMPACT_ITEMS = [
  { name: "Sales Decrease", label: "Sales", color: "#E6482D" },
  { name: "Sales Increase", label: "Sales", color: "#22C55E" },
  { name: "Distribution Change", label: "Distribution", color: "#A1A4E9" },
  { name: "Off Shelf Presence", label: "Off-Shelf Presence", color: "#5C68D1" },
  { name: "Non-Promo RoS", label: "Non-Promo RoS", color: "#6AA66E" },
  { name: "Promo RoS", label: "Promo RoS", color: "#2E7E34" },
  { name: "RoS", label: "RoS", color: "#6AA66E" },
  { name: "Non-Promo Pricing", label: "Non-Promo Pricing", color: "#CF8FC0" },
  { name: "Pricing", label: "Pricing", color: "#CF8FC0" },
  { name: "Promo Pricing", label: "Promo Pricing", color: "#C852AC" },
  { name: "NPD", label: "NPD", color: "#CEA334" },
  { name: "Other Effects", label: "Other Effects", color: "#A8A8A8" }
];

export const IMPACT_COLORS = Object.assign(
  {},
  ...IMPACT_ITEMS.map(i => ({ [i.name]: i.color }))
);

export const KANTAR_COLORS = {
  spend: "#92d400",
  volume: "#9cd4b9",
  average_price: "#bb5899",
  volume_per_buyer: "#95b3b7",
  buyers: "#96bc58",
  frequency: "#00b4ff",
  volume_per_trip: "#1d84c7",
  penetration: "#3aa960",
  total_households: "#bead84"
};

// auto ranging
export const AUTO_RANGING_COLORS = [
  "#FFE9B3",
  "#FEDBDB",
  "#D7F0F0",
  "#D6CCDA",
  "#CAE5FC",
  "#DFF1E0"
];

export const PROTOTYPE_ICONS = {
  "market-anomalies": MarketAnomaliesIcon,
  "product-switching": ProductSwitchingIcon,
  npd: NPDIcon,
  "time-comparisons": TimeComparisonsIcon,
  "distn-opt": DistributionOptimisation,
  "side-by-side": SideBySideIcon
};

export const FOCUS_MAPPINGS = {
  performance: "Share Performance",
  npd: "NPD",
  stars: "Star- & Under-Performers"
};

export default null;
