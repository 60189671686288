import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { DownIcon, RightIcon, UpIcon } from "components/Icons";

const getColor = rank => {
  if (rank > 0) return "positive.main";
  if (rank < 0) return "negative.main";
  return "neutral.main";
};

const RankList = props => {
  const { data, setNext, value, rank, setRank } = props;
  return _.orderBy(data, ["rank"], ["desc"]).map(i => {
    const isSelected = i.name === value;
    return (
      <Stack
        key={i.name}
        direction="row"
        alignItems="stretch"
        spacing={1}
        onClick={() => setNext(i.name)}
        sx={{ cursor: "pointer" }}
      >
        <ButtonGroup
          size="small"
          orientation="vertical"
          color="disabled"
          sx={{ borderColor: "grey.200" }}
        >
          <Button
            onClick={() => {
              if (i.rank < 10) {
                setRank([..._.without(rank, i), { ...i, rank: i.rank + 1 }]);
              }
            }}
            disabled={i.rank === 10}
          >
            <UpIcon sx={{ fontSize: 16, color: "grey.500" }} />
          </Button>
          <Button
            onClick={() => {
              if (i.rank > -10) {
                setRank([..._.without(rank, i), { ...i, rank: i.rank - 1 }]);
              }
            }}
            disabled={i.rank === -10}
          >
            <DownIcon sx={{ fontSize: 16, color: "grey.500" }} />
          </Button>
        </ButtonGroup>
        <Stack
          justifyContent="center"
          sx={{
            bgcolor: isSelected ? "primary.main" : "grey.50",
            borderRadius: 1,
            width: 1,
            px: 1
          }}
        >
          <Typography
            variant="subtitle2"
            color={isSelected ? "white" : "grey.500"}
          >
            {i.name}
          </Typography>
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            border: 1,
            borderRadius: 1,
            bgcolor: isSelected && getColor(i.rank),
            borderColor: getColor(i.rank),
            minWidth: 40
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color={isSelected ? "white" : getColor(i.rank)}
          >
            {i.rank}
          </Typography>
        </Stack>
        <RightIcon
          sx={{ fontSize: 16, color: "grey.500", alignSelf: "center" }}
        />
      </Stack>
    );
  });
};

RankList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  setNext: PropTypes.func,
  value: PropTypes.string,
  rank: PropTypes.arrayOf(PropTypes.shape()),
  setRank: PropTypes.func
};

RankList.defaultProps = {
  data: [],
  setNext: () => {},
  value: "",
  rank: [],
  setRank: () => {}
};

export default RankList;
