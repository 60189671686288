import React from "react";
import PropTypes from "prop-types";
import { Stack, Tooltip as MUITooltip, Typography } from "@mui/material";

const Tooltip = props => {
  const { title, content, children } = props;
  return (
    <MUITooltip
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            bgcolor: "white",
            p: 1.5,
            borderRadius: 2,
            boxShadow:
              "0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)"
          }
        }
      }}
      title={
        <Stack spacing={1}>
          <Typography variant="subtitle2" color="grey.700">
            {title}
          </Typography>
          <Typography variant="body2" color="grey.500">
            {content}
          </Typography>
        </Stack>
      }
      placement="right-end"
    >
      <Stack>{children}</Stack>
    </MUITooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node.isRequired
};

Tooltip.defaultProps = {
  title: "",
  content: ""
};

export default Tooltip;
