import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";
import {
  AutoRangingIcon,
  BrandBattlesIcon,
  PerformanceIcon,
  RangeReviewIcon,
  TrendIcon,
  ExecutiveSummaryIcon,
  PerformanceOverviewIcon,
  ComponentsAnalysisIcon,
  DriversOfChangeIcon,
  RateIcon,
  NewProductListingsIcon,
  DistributionChangesIcon,
  OffShelfIcon,
  SoundbitesIcon,
  HeadwindsAndTailwindsIcon,
  ChangesIcon,
  ChartsIcon,
  CompareIcon,
  GapAnalysisIcon,
  ContextIcon,
  TopInsightsIcon,
  ImpactIcon,
  DriversIcon,
  PerformanceSplitIcon,
  MarketSplitIcon,
  DeepDiveSkuIcon,
  FocusIcon,
  ShareIcon,
  StarPerformersIcon,
  UnderPerformersIcon,
  NPDIcon,
  SalesOveriewIcon
} from "components/Icons";
import { storyEnums } from "constants/enums";

const { AR, BB, IDA, CGA, PRR, TREND, FP } = storyEnums;

const icons = {
  [AR]: <AutoRangingIcon />,
  [BB]: <BrandBattlesIcon />,
  [IDA]: <PerformanceIcon />,
  [CGA]: <PerformanceIcon />,
  [PRR]: <RangeReviewIcon />,
  [TREND]: <TrendIcon />,
  [FP]: <FocusIcon />
};

const estimatedOutput = {
  [AR]: [
    { title: "Summary of Auto-Range", icon: <ExecutiveSummaryIcon /> },
    {
      title: "Compare Auto-Range to Current Range",
      icon: <CompareIcon />
    },
    { title: "A List of Changes by Bay Plan", icon: <ChangesIcon /> },
    { title: "Charts", icon: <ChartsIcon /> },
    { title: "Gap Analysis", icon: <GapAnalysisIcon /> }
  ],
  [BB]: [
    { title: "Executive Summary", icon: <ExecutiveSummaryIcon /> },
    { title: "Performance Overview", icon: <PerformanceOverviewIcon /> },
    { title: "Components Analysis", icon: <ComponentsAnalysisIcon /> },
    { title: "Key Drivers of Change", icon: <DriversOfChangeIcon /> }
  ],
  [IDA]: [
    { title: "Executive Summary", icon: <ExecutiveSummaryIcon /> },
    { title: "Soundbites", icon: <SoundbitesIcon /> },
    { title: "Performance Overview", icon: <PerformanceOverviewIcon /> },
    { title: "Components Analysis", icon: <ComponentsAnalysisIcon /> },
    { title: "New Product Listings", icon: <NewProductListingsIcon /> },
    { title: "Distribution Changes", icon: <DistributionChangesIcon /> },
    {
      title: "Rate of Sale and Pack/Price Architecture",
      icon: <RateIcon />
    },
    { title: "Off-Shelf Presence", icon: <OffShelfIcon /> }
  ],
  [CGA]: [
    { title: "Executive Summary", icon: <ExecutiveSummaryIcon /> },
    { title: "Soundbites", icon: <SoundbitesIcon /> },
    { title: "Performance Overview", icon: <PerformanceOverviewIcon /> },
    { title: "Components Analysis", icon: <ComponentsAnalysisIcon /> },
    { title: "New Product Listings", icon: <NewProductListingsIcon /> },
    { title: "Distribution Changes", icon: <DistributionChangesIcon /> },
    {
      title: "Rate of Sale and Pack/Price Architecture",
      icon: <RateIcon />
    }
  ],
  [PRR]: [
    { title: "Top Insights", icon: <TopInsightsIcon /> },
    { title: "The Context", icon: <ContextIcon /> },
    { title: "The Impact", icon: <ImpactIcon /> },
    { title: "The Drivers", icon: <DriversIcon /> },
    { title: "Analysis by 'Splits'", icon: <PerformanceSplitIcon /> },
    { title: "Performance Vs Wider Market", icon: <MarketSplitIcon /> },
    { title: "Deep Dive by Sku", icon: <DeepDiveSkuIcon /> },
    { title: "New Product Listings", icon: <NewProductListingsIcon /> }
  ],
  [TREND]: [
    { title: "Executive Summary", icon: <ExecutiveSummaryIcon /> },
    { title: "Performance Overview", icon: <PerformanceOverviewIcon /> },
    {
      title: "Headwinds & Tailwinds Identification and Explanation",
      icon: <HeadwindsAndTailwindsIcon />
    }
  ],
  [FP]: [
    {
      title: "Executive Summary",
      icon: <ExecutiveSummaryIcon />,
      focusType: "performance"
    },
    {
      title: "Sales Overview",
      icon: <SalesOveriewIcon />,
      focusType: "performance"
    },
    {
      title: "Share Performance",
      icon: <ShareIcon />,
      focusType: "performance"
    },
    {
      title: "Executive Summary",
      icon: <ExecutiveSummaryIcon />,
      focusType: "stars"
    },
    {
      title: "Performance",
      icon: <PerformanceOverviewIcon />,
      focusType: "stars"
    },
    {
      title: "Star-Performers",
      icon: <StarPerformersIcon />,
      focusType: "stars"
    },
    {
      title: "Under-Performers",
      icon: <UnderPerformersIcon />,
      focusType: "stars"
    },
    {
      title: "Executive Summary",
      icon: <ExecutiveSummaryIcon />,
      focusType: "npd"
    },
    {
      title: "Total Performance",
      icon: <PerformanceOverviewIcon />,
      focusType: "npd"
    },
    { title: "NPD Focus", icon: <NewProductListingsIcon />, focusType: "npd" },
    { title: "NPD Categorisation", icon: <NPDIcon />, focusType: "npd" }
  ]
};

const ExpectedOutput = props => {
  const { story, focusType } = props;
  let expectedOutput = estimatedOutput[story];
  if (story === FP) {
    expectedOutput = estimatedOutput[story].filter(
      i => i.focusType === focusType
    );
  }
  return (
    <Paper>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ color: "primary.main" }}
      >
        {icons[story]}
        <Typography variant="h4" color="primary.main" sx={{ py: 1 }}>
          Expected Output
        </Typography>
      </Stack>
      <Typography variant="body1" color="grey.500">
        In this report you should be able to see insights for the following, and
        more:
      </Typography>
      <Stack spacing={3} sx={{ pt: 3, pb: 2 }}>
        {expectedOutput.map(({ title, icon }) => (
          <Stack key={title} direction="row" alignItems="center" spacing={1}>
            {icon}
            <Typography
              color="grey.800"
              variant="subtitle1"
              fontWeight="medium"
            >
              {title}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
};

ExpectedOutput.propTypes = {
  story: PropTypes.string,
  focusType: PropTypes.string
};

ExpectedOutput.defaultProps = {
  story: "",
  focusType: ""
};

export default ExpectedOutput;
