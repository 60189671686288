import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import ReportTable from "../ReportTable";
import ReportChart from "../ReportChart";

const ChartTable = props => {
  const { chart, table, align, config } = props;
  const tableWidth = (table?.columns?.length || 0) + 1;
  return (
    <Box sx={{ height: 1, width: 1, mx: 2 }}>
      <Box sx={{ position: "relative", width: 1, height: 250 }}>
        <ReportChart
          data={{ x: chart.x, y: chart.y, type: chart.style.chart_style.type }}
          display={{ ...chart.style, title: chart.title, align }}
          layout={{
            height: 100,
            width: align ? 100 - 100 / tableWidth : 100,
            top: 0,
            left: align ? 100 / tableWidth : 0
          }}
          config={config}
        />
      </Box>
      <ReportTable
        data={{
          columns: table.columns,
          index: table.index,
          values: table.data
        }}
        display={{ gradient: table.gradient, highlight: table.tp_highlight }}
        config={config}
      />
    </Box>
  );
};

ChartTable.propTypes = {
  chart: PropTypes.shape(),
  table: PropTypes.shape(),
  align: PropTypes.bool,
  config: PropTypes.shape()
};

ChartTable.defaultProps = {
  chart: {},
  table: {},
  align: false,
  config: {}
};

export default ChartTable;
