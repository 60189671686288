import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import ReportChart from "../ReportChart";
import ReportTypography from "../ReportTypography";

const MultipleChartsCompare = props => {
  const { charts, config } = props;
  const valCharts = charts.filter(i => i.key.includes("value_sales"));
  const volCharts = charts.filter(i => i.key.includes("volume_sales"));
  const avPriceCharts = charts.filter(i => i.key.includes("average_price"));
  const shareCharts = charts.filter(i => i.key.includes("share"));
  const subCharts = [valCharts, volCharts, avPriceCharts, shareCharts].filter(
    i => i.length > 0
  );
  const flatCharts = _.flatten(subCharts);
  const values = flatCharts.map(i => i.style.additional_info?.value);
  return (
    <Box sx={{ height: 1, width: 1 }}>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
      >
        {subCharts.map(chart => (
          <Grid key={_.uniqueId()} item xs={12 / subCharts.length}>
            <ReportTypography
              textAlign="center"
              fontSize={14}
              config={config}
              gutterBottom
            >
              {chart[0].title}
            </ReportTypography>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          container
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          {flatCharts.map((c, i) => {
            const isValue = values[i];
            const isPositive = isValue && values[i].startsWith("+");
            return (
              <Grid key={_.uniqueId()} item xs={12 / flatCharts.length}>
                {isValue && (
                  <ReportTypography
                    textAlign="center"
                    fontWeight="medium"
                    fontSize={12}
                    config={config}
                    gutterBottom
                    color={isPositive ? "positive.main" : "negative.main"}
                    sx={{
                      bgcolor: isPositive ? "positive.light" : "negative.light",
                      border: 1,
                      borderColor: isPositive
                        ? "positive.main"
                        : "negative.main",
                      borderRadius: 1,
                      width: 75,
                      px: 1.5,
                      py: 0.5,
                      mx: "auto"
                    }}
                  >
                    {values[i]}
                  </ReportTypography>
                )}
              </Grid>
            );
          })}
        </Grid>
        {subCharts.map((chart, k) => {
          const isValue =
            chart.filter(i => i.style.additional_info?.value).length > 0;
          return (
            <Grid key={_.uniqueId()} item xs={12 / subCharts.length}>
              <Box
                sx={{
                  position: "relative",
                  height: isValue ? 300 : 350,
                  width: 1
                }}
              >
                <ReportChart
                  data={{
                    type: "subchart",
                    data: chart.map(i => ({
                      name:
                        (i.key.includes("non_promo") && "Non-Promo") ||
                        (i.key.includes("promo") && "Promo") ||
                        (i.key.includes("___") && i.key.split("___")[0]),
                      x: i.x,
                      y: i.y,
                      type: i.style.chart_style.type
                    }))
                  }}
                  display={{
                    display: subCharts[k].map(i => ({ ...i.style })),
                    isLegend: true,
                    title: chart[0].title
                  }}
                  layout={{ height: 100, width: 100 }}
                  config={config}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

MultipleChartsCompare.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape()
};

MultipleChartsCompare.defaultProps = {
  charts: [],
  config: {}
};

export default MultipleChartsCompare;
