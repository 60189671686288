import _ from "lodash";
import { alertLevels } from "constants/enums";
import {
  FILTER_STATIC_DATA_REQUEST,
  FILTER_STATIC_DATA_ERROR,
  FILTER_STATIC_DATA_SUCCESS,
  REMOVE_STATIC_DATA_FILTER,
  CLEAR_ALL_SEARCH_DATA,
  CLEAR_STORY_SEARCH_DATA,
  GET_CONTEXT_REQUEST,
  GET_CONTEXT_SUCCESS,
  GET_CONTEXT_ERROR,
  REMOVE_CONTEXT
} from "../constants/actionConstants";
import { FILTER_STATIC_DATA_URL, CONTEXT_URL } from "../constants";
import fetchInstance from "../utils/fetchInstance";
import { addNotification } from "./notification";

function filterStaticDataRequest() {
  return {
    type: FILTER_STATIC_DATA_REQUEST
  };
}

function filterStaticDataSuccess(data, terms, story) {
  return {
    type: FILTER_STATIC_DATA_SUCCESS,
    data,
    filterTerms: terms,
    story
  };
}

function filterStaticDataError(error) {
  return {
    type: FILTER_STATIC_DATA_ERROR,
    error
  };
}

function removeFilter(story) {
  return {
    type: REMOVE_STATIC_DATA_FILTER,
    story
  };
}

export const filterStaticData =
  (term, filterTerms, story, client) => dispatch => {
    const terms = term ? filterTerms.concat([term]) : filterTerms;
    if (terms.length === 0) {
      return dispatch(removeFilter(story));
    }
    dispatch(filterStaticDataRequest());
    try {
      return fetchInstance(FILTER_STATIC_DATA_URL, {
        method: "POST",
        body: JSON.stringify({ items: terms, story, client }),
        headers: { "Content-Type": "application/json" }
      })
        .then(async res => {
          if (!res.ok) {
            const data = await res.json();
            const err = data?.message || data;
            throw new Error(err);
          }
          return res.json();
        })
        .then(
          data => {
            dispatch(filterStaticDataSuccess(data, terms, story));
          },
          err => {
            dispatch(filterStaticDataError(err.message));
          }
        );
    } catch (err) {
      return dispatch(filterStaticDataError(err.message));
    }
  };

export const removeFilterTerm =
  (term, filterTerms, story, client) => dispatch => {
    const terms = filterTerms.filter(t => !_.isEqual(t, term));
    if (terms.length === 0) {
      return dispatch(removeFilter(story));
    }
    dispatch(filterStaticDataRequest());
    try {
      return fetchInstance(FILTER_STATIC_DATA_URL, {
        method: "POST",
        body: JSON.stringify({ items: terms, story, client }),
        headers: { "Content-Type": "application/json" }
      })
        .then(async res => {
          if (!res.ok) {
            const data = await res.json();
            throw new Error(data?.ErrorCode ?? data);
          }
          return res.json();
        })
        .then(
          data => {
            dispatch(filterStaticDataSuccess(data, terms, story));
          },
          err => {
            dispatch(addNotification(err.message, alertLevels.ERROR));
            dispatch(filterStaticDataError(err.message));
          }
        );
    } catch (err) {
      dispatch(addNotification(err.message, alertLevels.ERROR));
      return dispatch(filterStaticDataError(err.message));
    }
  };

export const clearAllSearchData = () => ({
  type: CLEAR_ALL_SEARCH_DATA
});

export const clearStorySearchData = story => ({
  type: CLEAR_STORY_SEARCH_DATA,
  story
});

function getContextRequest() {
  return {
    type: GET_CONTEXT_REQUEST
  };
}

function getContextSuccess(data, story) {
  return {
    type: GET_CONTEXT_SUCCESS,
    data,
    story
  };
}

function getContextError(error) {
  return {
    type: GET_CONTEXT_ERROR,
    error
  };
}

function removeContext(story) {
  return {
    type: REMOVE_CONTEXT,
    story
  };
}

export const getContext = (terms, context, type, story, client) => dispatch => {
  if (terms.length === 0) {
    return dispatch(removeContext(story));
  }
  dispatch(getContextRequest());
  try {
    return fetchInstance(CONTEXT_URL, {
      method: "POST",
      body: JSON.stringify({ [type]: terms, context, type, story, client }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        data => dispatch(getContextSuccess(data, story)),
        err => {
          dispatch(addNotification(err.message, alertLevels.ERROR));
          dispatch(getContextError(err.message));
        }
      );
  } catch (err) {
    return dispatch(getContextError(err.message));
  }
};
