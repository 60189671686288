import React from "react";
import PropTypes from "prop-types";
import theme from "theme";
import PlotlyChart from "components/PlotlyChart";

const getColor = (isPositive, isNegative) => {
  if (isPositive) return theme.palette.positive.main;
  if (isNegative) return theme.palette.negative.main;
  return theme.palette.neutral.main;
};

const TrendGraph = props => {
  const { data, isPositive, isNegative } = props;
  return (
    <PlotlyChart
      data={[
        {
          x: data.x,
          y: data.y,
          cliponaxis: false,
          marker: { color: getColor(isPositive, isNegative) },
          mode: "lines",
          line: { shape: "spline" }
        }
      ]}
      layout={{
        xaxis: { showgrid: false, zeroline: false },
        yaxis: { showgrid: false, zeroline: false, rangemode: "tozero" },
        margin: { t: 0, b: 0, r: 0, l: 0 }
      }}
      config={{ staticPlot: true }}
    />
  );
};

TrendGraph.propTypes = {
  data: PropTypes.shape(),
  isPositive: PropTypes.bool,
  isNegative: PropTypes.bool
};

TrendGraph.defaultProps = {
  data: {},
  isPositive: false,
  isNegative: false
};

export default TrendGraph;
