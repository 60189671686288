import { uniqueId } from "lodash";
import { alertLevels } from "constants/enums";
import { clientConfig } from "config";
import {
  GET_ENVIRONMENTS_URL,
  GET_PRODUCTS_URL,
  GET_RANKINGS_URL,
  GET_WEIGHTINGS_URL,
  EDIT_STERLING_URL,
  GET_SHEETS_URL
} from "../constants";
import {
  GET_ENVIRONMENTS_REQUEST,
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_ERROR,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_RANKINGS_REQUEST,
  GET_RANKINGS_SUCCESS,
  GET_RANKINGS_ERROR,
  GET_WEIGHTINGS_REQUEST,
  GET_WEIGHTINGS_SUCCESS,
  GET_WEIGHTINGS_ERROR,
  EDIT_STERLING_REQUEST,
  EDIT_STERLING_SUCCESS,
  EDIT_STERLING_ERROR,
  GET_SHEETS_REQUEST,
  GET_SHEETS_SUCCESS,
  GET_SHEETS_ERROR,
  CLEAR_STERLING_DATA
} from "../constants/actionConstants";
import { addNotification } from "./notification";
import fetchInstance from "../utils/fetchInstance";

function getEnvironmentsRequest() {
  return {
    type: GET_ENVIRONMENTS_REQUEST
  };
}

function getEnvironmentsSuccess(retailers) {
  return {
    type: GET_ENVIRONMENTS_SUCCESS,
    retailers
  };
}

function getEnvironmentsError(error) {
  return {
    type: GET_ENVIRONMENTS_ERROR,
    error
  };
}

export const getEnvironments = client => dispatch => {
  dispatch(getEnvironmentsRequest());
  try {
    return fetchInstance(GET_ENVIRONMENTS_URL, {
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        data => {
          const retailers = [];
          const { channels } = clientConfig[client];
          channels.forEach(channel =>
            data[channel].forEach(i => {
              retailers.push({ name: i, channel });
            })
          );
          return dispatch(getEnvironmentsSuccess(retailers));
        },
        err => {
          dispatch(getEnvironmentsError(err.message));
        }
      );
  } catch (err) {
    return dispatch(getEnvironmentsError(err.message));
  }
};

function getProductsRequest() {
  return {
    type: GET_PRODUCTS_REQUEST
  };
}

function getProductsSuccess(products) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    products
  };
}

function getProductsError(error) {
  return {
    type: GET_PRODUCTS_ERROR,
    error
  };
}

export const getProducts =
  (channel, environments, type, rankType, consumptionType) => dispatch => {
    dispatch(getProductsRequest());
    try {
      return fetchInstance(GET_PRODUCTS_URL, {
        method: "POST",
        body: JSON.stringify({
          env_type: channel,
          environments,
          type,
          result_type: rankType,
          consumption_type: consumptionType
        }),
        headers: { "Content-Type": "application/json" }
      })
        .then(async res => {
          if (!res.ok) {
            const data = await res.json();
            const err = data?.message || data;
            throw new Error(err);
          }
          return res.json();
        })
        .then(
          data => {
            if (String(data).startsWith("ERROR")) {
              throw new Error(String(data));
            }
            return dispatch(
              getProductsSuccess(data.map(i => ({ ...i, id: uniqueId() }))) // TODO: remove temp fix
            );
          },
          err => {
            dispatch(getProductsError(err.message));
          }
        );
    } catch (err) {
      dispatch(addNotification(err.message, alertLevels.ERROR));
      return dispatch(getProductsError(err.message));
    }
  };

function getRankingsRequest() {
  return {
    type: GET_RANKINGS_REQUEST
  };
}

function getRankingsSuccess(rankings) {
  return {
    type: GET_RANKINGS_SUCCESS,
    rankings
  };
}

function getRankingsError(error) {
  return {
    type: GET_RANKINGS_ERROR,
    error
  };
}

export const getRankings = (channel, environments) => dispatch => {
  dispatch(getRankingsRequest());
  try {
    return fetchInstance(GET_RANKINGS_URL, {
      method: "POST",
      body: JSON.stringify({ env_type: channel, environments }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        data => {
          if (String(data).startsWith("ERROR")) {
            throw new Error(String(data));
          }
          return dispatch(getRankingsSuccess(data));
        },
        err => {
          dispatch(getRankingsError(err.message));
        }
      );
  } catch (err) {
    return dispatch(getRankingsError(err.message));
  }
};

function getWeightingsRequest() {
  return {
    type: GET_WEIGHTINGS_REQUEST
  };
}

function getWeightingsSuccess(weightings) {
  return {
    type: GET_WEIGHTINGS_SUCCESS,
    weightings
  };
}

function getWeightingsError(error) {
  return {
    type: GET_WEIGHTINGS_ERROR,
    error
  };
}

export const getWeightings = () => dispatch => {
  dispatch(getWeightingsRequest());
  try {
    return fetchInstance(GET_WEIGHTINGS_URL, {
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        data => {
          if (String(data).startsWith("ERROR")) {
            throw new Error(String(data));
          }
          return dispatch(getWeightingsSuccess(data));
        },
        err => {
          dispatch(getWeightingsSuccess(err.message));
        }
      );
  } catch (err) {
    return dispatch(getWeightingsError(err.message));
  }
};

function editSterlingRequest() {
  return {
    type: EDIT_STERLING_REQUEST
  };
}

function editSterlingSuccess(data, option) {
  return {
    type: EDIT_STERLING_SUCCESS,
    data,
    option
  };
}

function editSterlingError(error) {
  return {
    type: EDIT_STERLING_ERROR,
    error
  };
}

const getOptionFromType = type => {
  switch (type) {
    case "sku":
      return "products";
    case "rankings":
      return "rankings";
    case "weightings":
      return "weightings";
    default:
      return type;
  }
};

export const editSterling =
  (
    type,
    data,
    updatedData,
    channel,
    environments,
    onSuccess = () => {},
    name = "", // sku only
    skus = [] // sku only
  ) =>
  dispatch => {
    dispatch(editSterlingRequest());
    try {
      return fetchInstance(EDIT_STERLING_URL, {
        method: "POST",
        body: JSON.stringify({
          env_type: channel,
          environments,
          type,
          data,
          skus,
          name
        }),
        headers: { "Content-Type": "application/json" }
      })
        .then(async res => {
          if (!res.ok) {
            const response = await res.json();
            const err = response?.message || response;
            throw new Error(err);
          }
          return res.json();
        })
        .then(
          res => {
            if (String(res).startsWith("ERROR")) {
              throw new Error(String(res));
            } else if (res === "SUCCESS") {
              onSuccess();
              dispatch(
                addNotification(
                  `Successfully edited ${type}`,
                  alertLevels.SUCCESS
                )
              );
              return dispatch(
                editSterlingSuccess(updatedData, getOptionFromType(type))
              );
            }
            return dispatch(editSterlingError(res));
          },
          err => {
            dispatch(editSterlingError(err.message));
          }
        );
    } catch (err) {
      dispatch(addNotification(err.message, alertLevels.ERROR));
      return dispatch(editSterlingError(err.message));
    }
  };

function getSheetsRequest() {
  return {
    type: GET_SHEETS_REQUEST
  };
}

function getSheetsSuccess(sheets) {
  return {
    type: GET_SHEETS_SUCCESS,
    sheets
  };
}

function getSheetsError(error) {
  return {
    type: GET_SHEETS_ERROR,
    error
  };
}

export const getSheets = () => dispatch => {
  dispatch(getSheetsRequest());
  try {
    return fetchInstance(GET_SHEETS_URL, {
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        data => {
          dispatch(getSheetsSuccess(data));
        },
        err => {
          dispatch(getSheetsError(err.message));
        }
      );
  } catch (err) {
    dispatch(addNotification(err.message, alertLevels.ERROR));
    return dispatch(getSheetsError(err.message));
  }
};

export const clearSterlingData = () => ({
  type: CLEAR_STERLING_DATA
});
