import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { Paper, Table } from "components/BaseComponents";
import { STERLING_RANGING_ROUTE } from "constants/viewRoutes";
import { getSheets } from "actions";
import { clientConfig, getChannelConfig } from "config";
import { report } from "images";
import Header from "../Header";

const SKUEditing = props => {
  const { getSKUSheets, sheets, isLoading, isSterling, client } = props;
  const { name: clientName = "" } = clientConfig[client] || {};

  useEffect(() => {
    getSKUSheets();
  }, []);

  const columns = [
    {
      label: "Sheet Name",
      styles: { textAlign: "left" },
      renderCell: item => item.name
    },
    {
      label: "# of SKUs",
      styles: { textAlign: "left" },
      renderCell: item => item.skus.length
    },
    {
      label: "Channel",
      styles: { textAlign: "left" },
      renderCell: item => getChannelConfig(item.env_type).name
    },
    {
      label: "Environment",
      styles: { textAlign: "left" },
      renderCell: item => item.environments[0]
    },
    {
      label: "Actions",
      renderCell: item => (
        <Button
          variant="soft"
          size="small"
          component={Link}
          to={generatePath(STERLING_RANGING_ROUTE, { type: "result" })}
          state={{
            channel: item.env_type,
            environment: item.environments[0],
            name: item.name,
            skus: item.skus
          }}
        >
          View Sheet
        </Button>
      ),
      isVisible: true
    }
  ];

  return (
    <Paper>
      <Header isSterling={isSterling} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <Stack spacing={1} sx={{ maxWidth: 0.85 }}>
          <Typography variant="h4" color="primary.main">
            Shopper-Based Ranging
          </Typography>
          <Typography variant="body1" color="grey.500">
            Here you can make changes to the importance of SKUs by environment.
            This is here to combine your expertise with the data to produce the
            best output possible, with this output to be used throughout{" "}
            {clientName}.
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={generatePath(STERLING_RANGING_ROUTE, { type: "input" })}
          disabled={isLoading}
        >
          Create New Sheet
        </Button>
      </Stack>
      {sheets.length > 0 ? (
        <Table rows={sheets} columns={columns} isLoading={isLoading} />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ py: 2 }}
        >
          <img
            alt="report"
            src={report}
            style={{ width: 275, marginBottom: 8 }}
          />
          <Typography variant="h4" color="primary">
            You have not added any sheets yet!
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to={generatePath(STERLING_RANGING_ROUTE, { type: "input" })}
            disabled={isLoading}
          >
            Create New Sheet
          </Button>
        </Stack>
      )}
    </Paper>
  );
};

SKUEditing.propTypes = {
  getSKUSheets: PropTypes.func,
  sheets: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  isSterling: PropTypes.bool,
  client: PropTypes.string
};

SKUEditing.defaultProps = {
  getSKUSheets: () => {},
  sheets: [],
  isLoading: false,
  isSterling: false,
  client: ""
};

const mapStateToProps = state => ({
  sheets: state.sterling.sheets,
  isLoading: state.sterling.isLoading,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  getSKUSheets: () => dispatch(getSheets())
});

export default connect(mapStateToProps, mapDispatchToProps)(SKUEditing);
