import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { getUpdateDate } from "../../utils";
import { clientConfig } from "../../config";

const styles = {
  update: {
    bgcolor: "grey.50",
    px: 2,
    py: 1,
    borderRadius: 1,
    width: 400,
    textAlign: "right"
  }
};

const UpdateDate = props => {
  const { dataDate, client } = props;
  const { channels } = clientConfig[client];
  return (
    <Stack justifyContent="center" spacing={0.5}>
      {channels.includes("ih") && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.update}
        >
          <Typography variant="subtitle2" color="grey.800">
            Next Nielsen data update expected
          </Typography>
          <Typography variant="subtitle2" color="grey.800">
            {getUpdateDate(dataDate.nielsen)}
          </Typography>
        </Stack>
      )}
      {channels.includes("ooh") && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.update}
        >
          <Typography variant="subtitle2" color="grey.800">
            Next CGA data update expected
          </Typography>
          <Typography variant="subtitle2" color="grey.800">
            {getUpdateDate(dataDate.cga, true)}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

UpdateDate.propTypes = {
  dataDate: PropTypes.shape(),
  client: PropTypes.string
};

UpdateDate.defaultProps = {
  dataDate: {},
  client: ""
};

export default UpdateDate;
