import React from "react";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { EditReport } from "components/ReportBuilder";
import { getStoryFromQuery } from "utils";
import { getStoryConfig } from "config";
import { TrendIcon } from "../Icons";

const EditTrendReport = () => {
  const location = useLocation();
  const story = getStoryFromQuery(location.search);
  const { dataSet } = getStoryConfig(story);
  if (!story || !dataSet) {
    return <Skeleton variant="rounded" height={500} />; // TODO:
  }
  return (
    <EditReport
      title="Trend Report"
      icon={<TrendIcon />}
      description="Be informed of the market trends that are affecting a category, manufacturer, brand, and more, within a given market and wider channel."
      story={story}
      dataSet={dataSet}
    />
  );
};

export default EditTrendReport;
