import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { EditIcon } from "components/Icons";
import { Paper } from "components/BaseComponents";
import { getRankings } from "actions";
import { getChannelConfig } from "config";
import RankList from "./RankList";

const renderLoading = () => (
  <Grid container spacing={4}>
    <Grid item xs={12} md={4}>
      <Skeleton variant="rounded" height={50} />
    </Grid>
    <Grid item xs={12} md={4}>
      <Skeleton variant="rounded" height={50} />
    </Grid>
    <Grid item xs={12} md={4}>
      <Skeleton variant="rounded" height={50} />
    </Grid>
  </Grid>
);

const Rankings = props => {
  const {
    channel,
    environments,
    handleBack,
    getOptions,
    options,
    isLoading,
    rankList,
    setRankList
  } = props;
  const [level1, setLevel1] = useState("");
  const [level2, setLevel2] = useState("");
  const { name: channelName } = getChannelConfig(channel);

  useEffect(() => {
    getOptions(channel, environments);
  }, []);

  useEffect(() => {
    if (options.length > 0) {
      setRankList(options);
    }
  }, [options]);

  return (
    <Fragment>
      <Paper styles={{ p: 1.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ columnGap: 1.5, rowGap: 1 }}
          >
            <Chip label={channelName} />
            {environments.map(item => (
              <Chip key={item} label={item} />
            ))}
          </Stack>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleBack}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Stack>
      </Paper>
      {options.length === 0 || isLoading ? (
        renderLoading()
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Stack spacing={1}>
              <Typography
                variant="subtitle2"
                color="grey.500"
                sx={{
                  bgcolor: "grey.100",
                  borderRadius: 1,
                  px: 1,
                  py: 2,
                  width: 1
                }}
              >
                Category / Sector
              </Typography>
              <RankList
                data={rankList.filter(i => i.level === 1)}
                setNext={val => {
                  setLevel1(val);
                  // reset brand
                  setLevel2("");
                }}
                value={level1}
                rank={rankList}
                setRank={setRankList}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={1}>
              <Typography
                variant="subtitle2"
                color="grey.500"
                sx={{
                  bgcolor: "grey.100",
                  borderRadius: 1,
                  px: 1,
                  py: 2,
                  width: 1
                }}
              >
                Brand
              </Typography>
              {level1.length > 0 && (
                <RankList
                  data={rankList.filter(
                    i => i.level === 2 && i.parent === level1
                  )}
                  setNext={val => {
                    setLevel2(val);
                  }}
                  value={level2}
                  rank={rankList}
                  setRank={setRankList}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={1}>
              <Typography
                variant="subtitle2"
                color="grey.500"
                sx={{
                  bgcolor: "grey.100",
                  borderRadius: 1,
                  px: 1,
                  py: 2,
                  width: 1
                }}
              >
                Sub Brand / Flavour
              </Typography>
              {level2.length > 0 && (
                <RankList
                  data={rankList.filter(
                    i =>
                      i.level === 3 &&
                      i.parent === level2 &&
                      i.grandparent === level1
                  )}
                  rank={rankList}
                  setRank={setRankList}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

Rankings.propTypes = {
  channel: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.string),
  handleBack: PropTypes.func,
  getOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  rankList: PropTypes.arrayOf(PropTypes.shape()),
  setRankList: PropTypes.func
};

Rankings.defaultProps = {
  channel: "",
  environments: [],
  handleBack: () => {},
  getOptions: () => {},
  options: [],
  isLoading: false,
  rankList: [],
  setRankList: () => {}
};

const mapDispatchToProps = dispatch => ({
  getOptions: (channel, environments) =>
    dispatch(getRankings(channel, environments))
});

export default connect(null, mapDispatchToProps)(Rankings);
