import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { KANTAR_COLORS } from "constants/reportConstants";
import ReportChart from "../ReportChart";
import ReportTypography from "../ReportTypography";

const getColor = percent => {
  const value = parseFloat(percent);
  return (
    (value >= 3 && "positiveArray.600") ||
    (value <= -3 && "negativeArray.500") ||
    "common.black"
  );
};

const getKantarBox = (text, textKey, position, config) => {
  const textObject = text.find(i => i.key === textKey);
  const {
    contribution,
    percentage_change: precentChange,
    value_change: valueChange,
    title
  } = textObject.text;
  const color = KANTAR_COLORS[textKey];
  return (
    <Box
      sx={{
        width: 165,
        height: 80,
        borderRadius: "11px",
        border: 1,
        borderColor: "grey.300",
        position: "absolute",
        top: `${position.top}%`,
        left: `${position.left}%`
      }}
    >
      <Box
        sx={{
          height: 26,
          width: 1,
          bgcolor: color,
          borderRadius: "10px 10px 0 0"
        }}
      >
        <ReportTypography
          textAlign="center"
          fontWeight="medium"
          fontSize={12}
          config={config}
          color="common.black"
          lineHeight="26px"
        >
          {title}
        </ReportTypography>
      </Box>
      <Box
        sx={{
          height: 26,
          width: 1,
          borderTop: 1,
          borderBottom: 1,
          borderColor: "grey.300"
        }}
      >
        <ReportTypography
          textAlign="center"
          fontSize={12}
          config={config}
          lineHeight="26px"
        >
          {valueChange}
        </ReportTypography>
      </Box>
      <Box sx={{ height: 26, width: 1, borderRadius: "0 0 10px 10px" }}>
        <Typography
          textAlign="center"
          lineHeight="22px"
          color={getColor(precentChange)}
        >
          <ReportTypography
            component="span"
            color="common.black"
            fontSize={12}
            config={config}
          >
            {contribution}
          </ReportTypography>
          <ReportTypography
            component="span"
            fontSize={12}
            config={config}
            color="grey.400"
            sx={{ px: 1.5 }}
          >
            |
          </ReportTypography>
          <ReportTypography
            component="span"
            color="common.black"
            fontSize={12}
            config={config}
          >
            {precentChange}
          </ReportTypography>
        </Typography>
      </Box>
    </Box>
  );
};

const KantarMeasureTree = props => {
  const { chart, text, config } = props;
  return (
    <Box sx={{ height: 1, width: 1, position: "relative" }}>
      <Box sx={{ position: "relative", width: 0.5, height: 0.5 }}>
        <ReportChart
          data={{ x: chart.x, y: chart.y, type: chart.style.chart_style.type }}
          display={{ ...chart.style, apply: ["kantar"] }}
          layout={{ height: 100, width: 100, top: 0, left: -5 }}
          config={config}
        />
      </Box>
      {getKantarBox(text, "spend", { top: 0, left: 60 }, config)}
      {getKantarBox(text, "volume", { top: 25, left: 47 }, config)}
      {getKantarBox(text, "average_price", { top: 25, left: 74 }, config)}
      {getKantarBox(text, "volume_per_buyer", { top: 50, left: 40 }, config)}
      {getKantarBox(text, "buyers", { top: 50, left: 70 }, config)}
      {getKantarBox(text, "frequency", { top: 75, left: 26 }, config)}
      {getKantarBox(text, "volume_per_trip", { top: 75, left: 44 }, config)}
      {getKantarBox(text, "penetration", { top: 75, left: 65 }, config)}
      {getKantarBox(text, "total_households", { top: 75, left: 83 }, config)}
      <Box sx={{ position: "absolute", left: 0, top: "68%" }}>
        <ReportTypography
          fontSize={12}
          config={config}
          fontWeight="medium"
          color="common.black"
          sx={{ my: 1 }}
        >
          Key
        </ReportTypography>
        <ReportTypography
          fontSize={12}
          config={config}
          fontWeight="medium"
          color="positiveArray.600"
          sx={{
            border: 1,
            borderColor: "positiveArray.600",
            bgcolor: "positiveArray.50",
            borderRadius: 1,
            px: 1.5,
            py: 0.5,
            my: 1
          }}
        >
          &gt;= 3% Change
        </ReportTypography>
        <ReportTypography
          fontSize={12}
          config={config}
          fontWeight="medium"
          color="negativeArray.500"
          sx={{
            border: 1,
            borderColor: "negativeArray.500",
            bgcolor: "negativeArray.50",
            borderRadius: 1,
            px: 1.5,
            py: 0.5,
            my: 1
          }}
        >
          &lt;= -3% Change
        </ReportTypography>
      </Box>
    </Box>
  );
};

KantarMeasureTree.propTypes = {
  chart: PropTypes.shape(),
  text: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape()
};

KantarMeasureTree.defaultProps = {
  chart: {},
  text: [],
  config: {}
};

export default KantarMeasureTree;
