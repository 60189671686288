import {
  GET_ENVIRONMENTS_REQUEST,
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_ERROR,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_RANKINGS_REQUEST,
  GET_RANKINGS_SUCCESS,
  GET_RANKINGS_ERROR,
  GET_WEIGHTINGS_REQUEST,
  GET_WEIGHTINGS_SUCCESS,
  GET_WEIGHTINGS_ERROR,
  EDIT_STERLING_REQUEST,
  EDIT_STERLING_SUCCESS,
  EDIT_STERLING_ERROR,
  GET_SHEETS_REQUEST,
  GET_SHEETS_SUCCESS,
  GET_SHEETS_ERROR,
  CLEAR_STERLING_DATA
} from "../constants/actionConstants";

// eslint-disable-next-line default-param-last
const sterling = (state = {}, action) => {
  switch (action.type) {
    case GET_ENVIRONMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case GET_ENVIRONMENTS_SUCCESS:
      return {
        ...state,
        retailers: action.retailers,
        isLoading: false,
        error: ""
      };
    case GET_ENVIRONMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        products: [],
        isLoading: true,
        error: ""
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products,
        isLoading: false,
        error: ""
      };
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        products: [],
        isLoading: false,
        error: action.error
      };
    case GET_RANKINGS_REQUEST:
      return {
        ...state,
        rankings: [],
        isLoading: true,
        error: ""
      };
    case GET_RANKINGS_SUCCESS:
      return {
        ...state,
        rankings: action.rankings,
        isLoading: false,
        error: ""
      };
    case GET_RANKINGS_ERROR:
      return {
        ...state,
        rankings: [],
        isLoading: false,
        error: action.error
      };
    case GET_WEIGHTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case GET_WEIGHTINGS_SUCCESS:
      return {
        ...state,
        weightings: action.weightings,
        isLoading: false,
        error: ""
      };
    case GET_WEIGHTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case EDIT_STERLING_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case EDIT_STERLING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        [action.option]: action.data
      };
    case EDIT_STERLING_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case GET_SHEETS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case GET_SHEETS_SUCCESS:
      return {
        ...state,
        sheets: action.sheets,
        isLoading: false,
        error: ""
      };
    case GET_SHEETS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case CLEAR_STERLING_DATA:
      return {
        ...state,
        retailers: [],
        products: [],
        rankings: [],
        weightings: {},
        sheets: [],
        isLoading: false,
        error: ""
      };
    default:
      return state;
  }
};

export default sterling;
