import React from "react";
import PropTypes from "prop-types";
import { Checkbox as MUICheckbox, FormControlLabel } from "@mui/material";

const Checkbox = props => {
  const { checked, label, onChange, styles, ...otherProps } = props;
  return (
    <FormControlLabel
      sx={styles}
      checked={checked}
      onChange={onChange}
      control={<MUICheckbox />}
      label={label}
      componentsProps={{ typography: { color: "grey.500" } }}
      {...otherProps}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  styles: PropTypes.shape()
};

Checkbox.defaultProps = {
  checked: false,
  label: "",
  onChange: () => {},
  styles: {}
};

export default Checkbox;
