import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Divider, InputAdornment, Stack, Typography } from "@mui/material";
import {
  AutoRangingIcon,
  BrandBattlesIcon,
  PerformanceIcon,
  RangeReviewIcon,
  TrendIcon,
  SearchIcon
} from "components/Icons";
import { Paper, TextField } from "components/BaseComponents";
import {
  PREVIOUS_REPORTS_AUTO_RANGING_ROUTE,
  PREVIOUS_REPORTS_BRAND_BATTLES_ROUTE,
  PREVIOUS_REPORTS_PERFORMANCE_ROUTE,
  PREVIOUS_REPORTS_RANGE_REVIEW_ROUTE,
  PREVIOUS_REPORTS_TREND_ROUTE
} from "constants/viewRoutes";
import { clientConfig } from "config";
import { permissionEnums, storyEnums } from "constants/enums";
import NavLink from "components/NavLink";
import theme from "theme";

const Header = props => {
  const { client, searchText, setSearchText } = props;

  const { stories, permissions } = clientConfig[client] || {
    stories: [],
    permissions: []
  };

  const roles =
    (localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).roles) ||
    [];
  const isAutoRanging = roles && roles.includes("auto_ranging");

  const reportTypes = [
    {
      label: "Performance Reports",
      icon: <PerformanceIcon sx={{ fontSize: 16 }} />,
      path: PREVIOUS_REPORTS_PERFORMANCE_ROUTE,
      isVisible:
        stories.includes(storyEnums.IDA) || stories.includes(storyEnums.CGA)
    },
    {
      label: "Brand Battles",
      icon: <BrandBattlesIcon sx={{ fontSize: 16 }} />,
      path: PREVIOUS_REPORTS_BRAND_BATTLES_ROUTE,
      isVisible: stories.includes(storyEnums.BB)
    },
    {
      label: "Trend Reports",
      icon: <TrendIcon sx={{ fontSize: 16 }} />,
      path: PREVIOUS_REPORTS_TREND_ROUTE,
      isVisible: stories.includes(storyEnums.TREND)
    },
    {
      label: "Range Review",
      icon: <RangeReviewIcon sx={{ fontSize: 16 }} />,
      path: PREVIOUS_REPORTS_RANGE_REVIEW_ROUTE,
      isVisible: stories.includes(storyEnums.PRR)
    },
    {
      label: "Auto-Ranging",
      icon: <AutoRangingIcon sx={{ fontSize: 16 }} />,
      path: PREVIOUS_REPORTS_AUTO_RANGING_ROUTE,
      isVisible:
        permissions.includes(permissionEnums.AUTO_RANGING) && isAutoRanging
    }
  ].filter(i => i.isVisible);

  return (
    <Fragment>
      <Paper styles={{ p: 1 }}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
          sx={{ color: "grey.400" }}
        >
          {reportTypes.map(i => (
            <NavLink
              to={i.path}
              key={i.label}
              style={{ flex: 1 }}
              activeStyle={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primaryArray[50],
                borderRadius: 5
              }}
              onClick={() => setSearchText("")}
              isActive={pathname => pathname === i.path}
            >
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ py: 1.25 }}
              >
                {i.icon}
                <Typography variant="subtitle2">{i.label}</Typography>
              </Stack>
            </NavLink>
          ))}
        </Stack>
      </Paper>
      <TextField
        value={searchText}
        onChange={e => setSearchText(e.target.value.toLowerCase())}
        label="Search Reports"
        styles={{ mb: 3 }}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </Fragment>
  );
};

Header.propTypes = {
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  client: PropTypes.string
};

Header.defaultProps = {
  searchText: "",
  setSearchText: () => {},
  client: ""
};

export default Header;
