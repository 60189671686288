import React, { useEffect } from "react";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";

const Support = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "6129b982-3858-40f4-8388-5f1f67a1683f",
          target: "#hubspotSupportForm"
        });
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "f8b0917e-5f0c-44b1-a928-d4d7419ff1f2",
          target: "#hubspotFeedbackForm"
        });
      }
    });
  }, []);
  return (
    <Paper>
      <Box sx={{ bgcolor: "grey.50", borderRadius: 1, px: 1.5, py: 3 }}>
        <Stack alignItems="center" spacing={2}>
          <Typography variant="h4" color="primary">
            Support
          </Typography>
          <Typography variant="body1" color="grey.500">
            We aim to make dijuno as intuitive to use as possible - but if you
            are experiencing any problems or just want to find out more, please
            don&apos;t hesitate to get in touch with our team!
          </Typography>
        </Stack>
      </Box>
      <Grid
        container
        columnSpacing={5}
        justifyContent="space-between"
        sx={{ py: 3 }}
      >
        <Grid item xs={12} md={6}>
          <Stack spacing={1.5}>
            <Typography variant="h4" color="primary">
              Helpdesk
            </Typography>
            <Typography variant="body1" color="grey.500">
              For all support requests, please fill in the form below or email{" "}
              <Link color="secondary" href="mailto:helpdesk@dijuno.ai">
                helpdesk@dijuno.ai
              </Link>{" "}
              letting us know as much information as possible about what you are
              trying to do and what problem you are experiencing.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1.5}>
            <Typography variant="h4" color="primary">
              Feedback
            </Typography>
            <Typography variant="body1" color="grey.500">
              If you&apos;d like to see give us some feedback or would like us
              to add a new feature, please fill in the form below or email{" "}
              <Link color="secondary" href="mailto:features@dijuno.ai">
                features@dijuno.ai
              </Link>{" "}
              and we will get those things prioritised in discussion with your
              leadership team.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={5}
        justifyContent="space-between"
        sx={{ pb: 3 }}
      >
        <Grid item xs={12} md={6}>
          <Paper styles={{ bgcolor: "grey.50" }}>
            <Box id="hubspotSupportForm" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper styles={{ bgcolor: "grey.50" }}>
            <Box id="hubspotFeedbackForm" />
          </Paper>
        </Grid>
      </Grid>
      <Box
        sx={{
          bgcolor: "grey.50",
          borderRadius: 1,
          py: 1.5,
          px: 2.5,
          textAlign: "center"
        }}
      >
        <Typography variant="body1" color="grey.500">
          dijuno needs the contact information you provide to us to contact you
          about our products and services. You may unsubscribe from these
          communications at any time. For information on how to unsubscribe, as
          well as our privacy practices and commitment to protecting your
          privacy, please review our Privacy Policy.
        </Typography>
      </Box>
    </Paper>
  );
};

export default Support;
