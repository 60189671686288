import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Stack } from "@mui/material";
import { ToggleButtonGroup } from "components/BaseComponents";
import { Legend } from "components/CustomComponents";
import {
  formatDownloadPercentage,
  formatDownloadValue,
  getProductAttributes
} from "utils";
import { AUTO_RANGING_COLORS } from "constants/reportConstants";
import { PageBar, DownloadData } from "./Components";
import CompareSales from "./CompareSales";
import CompareSustainability from "./CompareSustainability";

const getBays = data =>
  _.uniq(data.map(i => i.bay_id_after)).map(id => {
    const name = data.find(i => i.bay_id_after === id)?.bay_name_after ?? "";
    return { id, name };
  });

const getSustainabilityBanding = value => {
  switch (value) {
    case 1:
      return "Very Good";
    case 2:
      return "Good";
    case 3:
      return "Poor";
    case 4:
      return "Very Poor";
    default:
      return "No Data";
  }
};

const Compare = props => {
  const { report } = props;
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState("sales");

  const {
    comparison_data: comparisonData = [],
    tag2sku = {},
    tag2attribute = {},
    header
  } = report;

  const bays = getBays(comparisonData);

  const bayLegend = bays.map(i => ({
    label: i.name,
    color: AUTO_RANGING_COLORS[i.id]
  }));

  const sustainabilityLegend = [
    { label: "Very Good", color: "positiveArray.600" },
    { label: "Good", color: "positiveArray.300" },
    { label: "Poor", color: "negativeArray.200" },
    { label: "Very Poor", color: "negativeArray.400" },
    { label: "No Data", color: "grey.300" }
  ];

  const searchData = comparisonData.filter(({ tag }) =>
    tag2sku[tag].toLowerCase().includes(searchText)
  );

  const salesData = searchData.map(i => ({
    ...i,
    sku: tag2sku[i.tag]
  }));

  const sustainabilityData = searchData.map(i => ({
    ...i,
    sku: tag2sku[i.tag]
  }));

  const downloadData = comparisonData.map((i, k) => ({
    Rank: k + 1,
    SKU: tag2sku[i.tag],
    ...getProductAttributes(i.tag, tag2attribute),
    "Sales Before (£)": formatDownloadValue(i.sales_before),
    "Sales After (£)": formatDownloadValue(i.sales_after),
    "Sales Change (£)": formatDownloadValue(i.sales_change),
    "Sales Change Percentage (%)": formatDownloadPercentage(
      i.sales_percentage_change
    ),
    "Rate of Sale Before": formatDownloadValue(i.uros_before),
    "Rate of Sale After": formatDownloadValue(i.uros_after),
    "Rate of Sale Change (%)": formatDownloadPercentage(
      i.uros_relative_change_percentage
    ),
    "Distribution Before (%)": formatDownloadPercentage(i.distribution_before),
    "Distribution After (%)": formatDownloadPercentage(i.distribution_after),
    "Distribution Change (%)": formatDownloadPercentage(
      i.distribution_relative_change_percentage
    ),
    "Sales Flow (£)": formatDownloadValue(i.net_sales_flow),
    "Bay Plan Change": i.bay_plan_changed,
    "Bay Name After": i.bay_name_after,
    "Sugar Banding": getSustainabilityBanding(i.sugar_gr_100ml_banding),
    "Calories Banding": getSustainabilityBanding(""),
    "Weight of Virgin Plastic Banding": getSustainabilityBanding(
      i.virgin_plastic_weight_gr_per_ml_banding
    ),
    "% of rPET Banding": getSustainabilityBanding(i.rPET_percentage_banding),
    "Pieces of Plastic Banding": getSustainabilityBanding(
      i.number_of_pieces_of_plastic_banding
    ),
    "Recyclability Banding": getSustainabilityBanding(i.recyclability_banding),
    "Carbon Emissions Banding": getSustainabilityBanding("")
  }));

  return (
    <Fragment>
      <PageBar
        isSearch
        searchText={searchText}
        setSearchText={setSearchText}
        leftChild={
          <Stack spacing={1}>
            <Legend items={bayLegend} />
            {view === "sustainability" && (
              <Legend items={sustainabilityLegend} />
            )}
          </Stack>
        }
        rightChild={
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <DownloadData
              data={downloadData}
              header={header}
              section="Compare Tab"
            />
            <ToggleButtonGroup
              value={view}
              onChange={e => setView(e.target.value)}
              values={["sales", "sustainability"]}
              labels={["Sales Data", "Sustainability Data"]}
            />
          </Stack>
        }
      />
      {view === "sales" && <CompareSales data={salesData} />}
      {view === "sustainability" && (
        <CompareSustainability data={sustainabilityData} />
      )}
    </Fragment>
  );
};

Compare.propTypes = {
  report: PropTypes.shape()
};

Compare.defaultProps = {
  report: {}
};

export default Compare;
