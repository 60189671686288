import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Stack, Typography } from "@mui/material";
import { Paper, Tabs } from "components/BaseComponents";
import { Expand, WarningDialog } from "components/CustomComponents";
import { DeleteIcon } from "components/Icons";
import { flushCache } from "actions";
import ChangePassword from "./ChangePassword";
import CreateAccount from "./CreateAccount";
import SubscriptionSettings from "./SubscriptionSettings";
import UserManagement from "./UserManagement";

const Settings = props => {
  const { isAdmin, client, isLoading, subscriptions, deleteCache } = props;
  const [option, setOption] = useState("user");
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const settingsTabs = [
    { label: "User Settings", value: "user", isVisible: true },
    { label: "User Management", value: "management", isVisible: isAdmin },
    { label: "Mail Settings", value: "mail", isVisible: true }
  ].filter(i => i.isVisible);

  return (
    <Paper>
      <Typography variant="h4" color="primary.main">
        Settings
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ my: 2 }}
      >
        <Tabs
          activeTab={option}
          onChange={(e, value) => setOption(value)}
          tabs={settingsTabs}
          styles={{ width: 1 }}
        />
        {isAdmin && (
          <Button
            variant="soft"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => setIsAlertOpen(true)}
          >
            Clear Cache
          </Button>
        )}
      </Stack>
      {option === "user" && (
        <Stack spacing={1.5}>
          <Expand label="Change Password">
            <ChangePassword isLoading={isLoading} />
          </Expand>
          {isAdmin && (
            <Expand label="Create Account">
              <CreateAccount isLoading={isLoading} client={client} />
            </Expand>
          )}
        </Stack>
      )}
      {option === "management" && (
        <UserManagement isLoading={isLoading} client={client} />
      )}
      {option === "mail" && (
        <SubscriptionSettings
          isLoading={isLoading}
          client={client}
          subscriptions={subscriptions}
        />
      )}
      <WarningDialog
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onConfirm={() => {
          deleteCache();
          setIsAlertOpen(false);
        }}
        title="Delete Cache"
        content="Are you sure you want to delete the report cache?"
        confirmButton="Delete Cache"
      />
    </Paper>
  );
};

Settings.propTypes = {
  isAdmin: PropTypes.bool,
  client: PropTypes.string,
  isLoading: PropTypes.bool,
  subscriptions: PropTypes.shape(),
  deleteCache: PropTypes.func
};

Settings.defaultProps = {
  isAdmin: false,
  client: "",
  isLoading: false,
  subscriptions: {},
  deleteCache: () => {}
};

const mapStateToProps = state => ({
  client: state.user.user.client,
  isLoading: state.user.isLoading,
  subscriptions: state.user.subscriptions
});

const mapDispatchToProps = dispatch => ({
  deleteCache: () => dispatch(flushCache())
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
