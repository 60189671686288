import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isSunday } from "date-fns";
import { DatePicker, Paper } from "components/BaseComponents";
import { NavigateStepButtons, SectionTitle } from "components/CustomComponents";
import { reportConstraints } from "actions";
import { NEW_REPORT_TYPE_ROUTE } from "constants/viewRoutes";
import { getWeekStart, getWeekEnd, getImplementationPeriod } from "utils";
import CustomDate from "components/CustomDate";

const Period = props => {
  const {
    story,
    setActiveStep,
    dispatchReportConstraints,
    reportType,
    dataDate,
    period,
    implementationDate
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { channel } = params;
  const [selectedDates, setSelectedDates] = useState(null);

  useEffect(() => {
    setActiveStep(1);
  }, []);

  useEffect(() => {
    if (period) {
      setSelectedDates(
        period.name
          .split(",")
          .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)))
      );
    } else {
      setSelectedDates(null);
    }
  }, [period]);

  const handlePeriod = value => {
    dispatchReportConstraints("period", value, story);
    if (implementationDate)
      dispatchReportConstraints("implementationDate", null, story);
  };

  const handleImplementationDate = value => {
    if (value) {
      dispatchReportConstraints(
        "implementationDate",
        getImplementationPeriod(value),
        story
      );
    } else {
      dispatchReportConstraints("implementationDate", null, story);
    }
  };

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="4"
          title="Time Period"
          subtitle="Select your desired time frame for the Post Range Review."
        />
        <CustomDate
          story={story}
          selectedPeriod={period}
          dataDate={dataDate}
          onChange={date => handlePeriod(date)}
        />
      </Paper>
      <Paper>
        <SectionTitle
          order="5"
          title="Implementation Date"
          subtitle="Select the date of implementation."
        />
        <DatePicker
          label="Implementation Date"
          selected={
            implementationDate ? getWeekStart(implementationDate) : null
          }
          onChange={date => handleImplementationDate(date)}
          filterDate={isSunday}
          minDate={selectedDates ? selectedDates[0] : null} // compare start date
          maxDate={selectedDates ? selectedDates[3] : null} // ppr end date
          openToDate={
            implementationDate
              ? getWeekStart(implementationDate)
              : (selectedDates && selectedDates[2]) || null
          } // implementation date or prr start date
          disabled={!period}
          isClearable
          onClear={() => handleImplementationDate(null)}
          styles={{ width: 220 }}
        />
      </Paper>
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "product"
            })
          )
        }
        onNextClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "hierarchy"
            })
          )
        }
        nextDisabled={!period}
      />
    </Fragment>
  );
};

Period.propTypes = {
  story: PropTypes.string,
  setActiveStep: PropTypes.func,
  dispatchReportConstraints: PropTypes.func,
  reportType: PropTypes.string,
  dataDate: PropTypes.string,
  period: PropTypes.shape(),
  implementationDate: PropTypes.string
};

Period.defaultProps = {
  story: "",
  setActiveStep: () => {},
  dispatchReportConstraints: () => {},
  reportType: "",
  dataDate: "",
  period: null,
  implementationDate: null
};

const mapDispatchToProps = dispatch => ({
  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story))
});

export default connect(null, mapDispatchToProps)(Period);
