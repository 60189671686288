import React, { useEffect, useState } from "react";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { Button, Skeleton, Stack } from "@mui/material";
import { TickCircleIcon } from "components/Icons";
import { FormControl } from "./Internal";

const SelectGroup = props => {
  const {
    options,
    onClick,
    isLoading,
    skeletonWidth,
    styles,
    disabled,
    isIcon,
    controlled,
    selected,
    label,
    infoText,
    error,
    required
  } = props;
  const [internalSelected, setInternalSelected] = useState("");

  useEffect(() => {
    if (controlled) return;
    if (options.length > 0) {
      const defaultOption = options.find(i => i.default) || options[0];
      setInternalSelected(defaultOption.id);
    }
  }, [options]);

  return (
    <FormControl
      label={label}
      styles={styles}
      infoText={infoText}
      error={error}
      required={required}
    >
      <Stack direction="row" sx={{ flexWrap: "wrap", gap: 1 }}>
        {isLoading
          ? options.map(() => (
              <Skeleton
                key={uniqueId()}
                sx={{ borderRadius: 50 }}
                variant="rounded"
                width={skeletonWidth}
                height={36}
              />
            ))
          : options.map(item => {
              const { label: textLabel, id, icon } = item;
              const isSelected = controlled
                ? id === selected
                : id === internalSelected;
              return (
                <Button
                  key={id}
                  variant="contained"
                  onClick={() => {
                    if (!controlled) {
                      setInternalSelected(id);
                    }
                    onClick(item);
                  }}
                  sx={{
                    "&, &:hover": {
                      borderRadius: 100,
                      color: isSelected ? "white" : "grey.500",
                      bgcolor: isSelected ? "primary.main" : "grey.100"
                    }
                  }}
                  disabled={disabled}
                  startIcon={
                    (icon && icon) ||
                    (!disabled && isIcon && isSelected ? (
                      <TickCircleIcon />
                    ) : null)
                  }
                >
                  {textLabel}
                </Button>
              );
            })}
      </Stack>
    </FormControl>
  );
};

SelectGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  skeletonWidth: PropTypes.number,
  styles: PropTypes.shape(),
  disabled: PropTypes.bool,
  isIcon: PropTypes.bool,
  controlled: PropTypes.bool,
  selected: PropTypes.string,
  label: PropTypes.string,
  infoText: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool
};

SelectGroup.defaultProps = {
  options: [],
  onClick: () => {},
  isLoading: false,
  skeletonWidth: 110,
  styles: {},
  disabled: false,
  isIcon: false,
  controlled: false,
  selected: "",
  label: "",
  infoText: "",
  error: "",
  required: false
};

export default SelectGroup;
