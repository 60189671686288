import React from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router-dom";
import {
  Avatar,
  Box,
  Grid,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@mui/material";
import { ToolsIcon as DefaultIcon } from "components/Icons";
import { TOOL_SINGLE_ROUTE } from "constants/viewRoutes";
import { PROTOTYPE_ICONS } from "constants/reportConstants";
import ReportTypography from "../ReportTypography";

const showItems = (items, config) =>
  items.map(i => {
    const { query, service_name: type } = i;
    const accessKeyIndex = query.indexOf("&access_key");
    const search = query.substring(0, accessKeyIndex);
    const path = `${generatePath(TOOL_SINGLE_ROUTE, { type })}?${search}`;
    const Icon = PROTOTYPE_ICONS[type] || DefaultIcon;
    return (
      <Link
        underline="none"
        href={path}
        target="_blank"
        rel="noreferrer"
        key={i.service_name}
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "secondaryArray.100" }}>
              <Icon sx={{ color: "common.black", fontSize: 20 }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <ReportTypography
              config={config}
              color="grey.800"
              fontWeight="medium"
              sx={{
                textDecoration: "underline",
                "&:hover": { textDecoration: "none" }
              }}
            >
              {i.content_header}
            </ReportTypography>
            <ReportTypography fontSize={14} config={config}>
              {i.content}
            </ReportTypography>
          </ListItemText>
        </ListItem>
      </Link>
    );
  });

const PrototypeContents = props => {
  const { config, prototypes } = props;
  const middle = Math.ceil(prototypes.length / 2);
  const left = prototypes.slice(0, middle);
  const right = prototypes.slice(middle);
  return (
    <Box sx={{ height: 1, width: 1 }}>
      <Grid item xs={12} sx={{ mt: -3 }} container>
        <Grid item xs={6}>
          {showItems(left, config)}
        </Grid>
        <Grid item xs={6}>
          {showItems(right, config)}
        </Grid>
      </Grid>
    </Box>
  );
};

PrototypeContents.propTypes = {
  config: PropTypes.shape(),
  prototypes: PropTypes.arrayOf(PropTypes.shape())
};

PrototypeContents.defaultProps = {
  config: {},
  prototypes: []
};

export default PrototypeContents;
