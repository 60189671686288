import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack
} from "@mui/material";
import { CloseIcon, TickCircleIcon } from "components/Icons";
import { PasswordField, TextField } from "components/BaseComponents";
import { resetPassword } from "actions";

const ResetPassword = props => {
  const { submitResetPassword, isLoading, user, isOpen, onClose } = props;
  const { email: userEmail = "" } = user;
  const [email, setEmail] = useState(userEmail);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const reset = () => {
    setEmail(userEmail);
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ color: "grey.900", fontSize: 18, fontWeight: "medium" }}
      >
        Edit User
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={2} sx={{ maxWidth: 400 }}>
          <TextField
            label="Email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={isLoading || !!userEmail}
            type="email"
          />
          <PasswordField
            label="New Password"
            placeholder="New Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={isLoading}
          />
          <PasswordField
            label="Confirm New Password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            disabled={isLoading}
          />
        </Stack>
        <Button
          variant="contained"
          endIcon={<TickCircleIcon />}
          disabled={!email || !password || !confirmPassword}
          onClick={() =>
            submitResetPassword(email, password, confirmPassword, reset)
          }
          sx={{ mt: 3 }}
        >
          Change Password
        </Button>
      </DialogContent>
    </Dialog>
  );
};

ResetPassword.propTypes = {
  submitResetPassword: PropTypes.func,
  isLoading: PropTypes.bool,
  user: PropTypes.shape(),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

ResetPassword.defaultProps = {
  submitResetPassword: () => {},
  isLoading: false,
  user: {},
  isOpen: false,
  onClose: () => {}
};

const mapDispatchToProps = dispatch => ({
  submitResetPassword: (email, password, confirmPassword, onSuccess) =>
    dispatch(resetPassword(email, password, confirmPassword, onSuccess))
});

export default connect(null, mapDispatchToProps)(ResetPassword);
