import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Stack } from "@mui/material";
import { uniqueId } from "lodash";
import ReportChart from "../ReportChart";
import ReportTypography from "../ReportTypography";

const getColumnWidth = num => {
  if (num === 3) return 3.5;
  if (num === 2) return 4;
  if (num === 6) return 2;
  return 12 / num;
};

const MultipleCharts = props => {
  const { charts, config } = props;
  const { chartColors } = config;
  const numCharts = charts.length;
  const values = charts.map(i => i.style.additional_info?.value);
  const colWidth = getColumnWidth(numCharts);
  return (
    <Box sx={{ height: 1, width: 1 }}>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-evenly"
        alignItems="center"
      >
        {charts.map((chart, i) => {
          const isValue = values[i];
          const isPositive = isValue && values[i].startsWith("+");
          const chartColor =
            numCharts > 4 ? chartColors[i % 3] : chartColors[i];
          return (
            <Grid key={uniqueId()} item xs={colWidth}>
              <Stack alignItems="center">
                <ReportTypography
                  textAlign="center"
                  fontSize={14}
                  config={config}
                  gutterBottom
                >
                  {chart.title}
                </ReportTypography>
                <Box
                  sx={{
                    position: "relative",
                    height: isValue ? 300 : 350,
                    width: 1
                  }}
                >
                  <ReportChart
                    data={{
                      x: chart.x,
                      y: chart.y,
                      type: chart.style.chart_style.type
                    }}
                    display={{ ...chart.style, color: chartColor }}
                    layout={{ height: 100, width: 100, top: 0 }}
                    config={config}
                  />
                </Box>
                {isValue && (
                  <ReportTypography
                    textAlign="center"
                    fontWeight="medium"
                    fontSize={12}
                    config={config}
                    gutterBottom
                    color={isPositive ? "positive.main" : "negative.main"}
                    sx={{
                      bgcolor: isPositive ? "positive.light" : "negative.light",
                      border: 1,
                      borderColor: isPositive
                        ? "positive.main"
                        : "negative.main",
                      borderRadius: 1,
                      width: 75,
                      px: 1.5,
                      py: 0.5,
                      mx: "auto"
                    }}
                  >
                    {values[i]}
                  </ReportTypography>
                )}
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

MultipleCharts.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape()
};

MultipleCharts.defaultProps = {
  charts: [],
  config: {}
};

export default MultipleCharts;
