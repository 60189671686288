import React from "react";
import PropTypes from "prop-types";
import {
  Pagination as MUIPagination,
  PaginationItem,
  Typography,
  Skeleton,
  Stack
} from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "components/Icons";
import Paper from "./Paper";

const Previous = () => (
  <Stack
    direction="row"
    alignItems="center"
    spacing={2}
    sx={{ color: "grey.500" }}
  >
    <ArrowLeftIcon fontSize="small" />
    <Typography variant="inherit">Previous</Typography>
  </Stack>
);

const Next = () => (
  <Stack
    direction="row"
    alignItems="center"
    spacing={2}
    sx={{ color: "grey.500" }}
  >
    <Typography variant="inherit">Next</Typography>
    <ArrowRightIcon fontSize="small" />
  </Stack>
);

const Pagination = props => {
  const { page, count, onChange, isLoading, styles, ...otherProps } = props;
  return (
    <Paper styles={{ py: 1, ...styles }}>
      <Stack direction="row" justifyContent="center">
        {isLoading ? (
          <Stack direction="row" spacing={1}>
            <Skeleton variant="rounded" width={100} height={40} />
            <Skeleton variant="rounded" width={40} height={40} />
            <Skeleton variant="rounded" width={40} height={40} />
            <Skeleton variant="rounded" width={40} height={40} />
            <Skeleton variant="rounded" width={100} height={40} />
          </Stack>
        ) : (
          <MUIPagination
            color="secondary"
            count={count}
            page={page}
            onChange={onChange}
            shape="rounded"
            variant="outlined"
            size="large"
            sx={{
              "& .MuiPaginationItem-root:not(.Mui-selected)": {
                color: "grey.500"
              },
              "& .MuiPaginationItem-outlined, & .MuiPaginationItem-root.Mui-selected":
                { border: 0, fontSize: 14, fontWeight: "medium" }
            }}
            renderItem={item => (
              <PaginationItem
                slots={{ previous: Previous, next: Next }}
                {...item}
              />
            )}
            {...otherProps}
          />
        )}
      </Stack>
    </Paper>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  styles: PropTypes.shape()
};

Pagination.defaultProps = {
  page: 1,
  count: 1,
  onChange: () => {},
  isLoading: false,
  styles: {}
};

export default Pagination;
