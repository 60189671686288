import React from "react";
import PropTypes from "prop-types";
import { useLocation, generatePath } from "react-router-dom";
import {
  Avatar,
  Box,
  Grid,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Stack
} from "@mui/material";
import {
  BrandBattlesIcon,
  FocusIcon,
  PerformanceIcon,
  RangeReviewIcon,
  TrendIcon,
  ToolsIcon
} from "components/Icons";
import { PROTOTYPE_ICONS } from "constants/reportConstants";
import {
  NEW_IH_BRAND_BATTLES_REPORT_ROUTE,
  NEW_IH_RANGE_REVIEW_REPORT_ROUTE,
  NEW_IH_TREND_REPORT_ROUTE,
  VIEW_REPORT_ROUTE,
  NEW_OOH_PERFORMANCE_REPORT_ROUTE,
  NEW_IH_PERFORMANCE_REPORT_ROUTE,
  TOOL_SINGLE_ROUTE
} from "constants/viewRoutes";
import { storyEnums } from "constants/enums";
import { clientConfig } from "config";
import { getStoryFromQuery, rebuildQueryString } from "utils";
import logger from "logger";
import ReportTypography from "../ReportTypography";

const NEW_TOOLS_SLIDE = { key: "newtool" };

const showItems = (items, config, queryString, story) => {
  const buildPath = item => {
    if (item.type === "prototype") return item.url;
    return `${item.url}?${rebuildQueryString(queryString, item.focusType)}`;
  };

  const handleClick = item => {
    if (item.focusType) {
      logger({
        date: new Date().toISOString(),
        action: "VIEW_FOCUS_REPORT",
        focus_type: item.focusType,
        source: "report",
        query: queryString,
        story
      });
    }
  };

  return items.map(i => {
    const path = buildPath(i);
    return (
      <Link
        underline="none"
        href={path}
        target="_blank"
        rel="noreferrer"
        key={i.title}
        onClick={() => handleClick(i)}
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "secondaryArray.100" }}>
              <i.icon sx={{ color: "common.black", fontSize: 20 }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <ReportTypography
              config={config}
              color="grey.800"
              fontWeight="medium"
              sx={{
                textDecoration: "underline",
                "&:hover": { textDecoration: "none" }
              }}
            >
              {i.title}
            </ReportTypography>
            <ReportTypography fontSize={14} config={config}>
              {i.description}
            </ReportTypography>
          </ListItemText>
        </ListItem>
      </Link>
    );
  });
};

const RelatedContents = props => {
  const { config, client, prototypeArray, navigateToSlide } = props;
  const { stories } = clientConfig[client] || { stories: [] };
  const location = useLocation();
  const queryString = location.search.slice(1);
  const story = getStoryFromQuery(queryString);
  const prototypeObjects = prototypeArray.slice(0, 2);

  const reportTypes = [
    {
      title: "Performance Reports (In-Home)",
      description:
        "Understand how a category or brand is performing over time, in a specific market, channel or retailer. A performance overview that also drills down to sku level.",
      icon: PerformanceIcon,
      url:
        story === storyEnums.IDA
          ? VIEW_REPORT_ROUTE
          : NEW_IH_PERFORMANCE_REPORT_ROUTE,
      isVisible: stories.includes(storyEnums.IDA)
    },
    {
      title: "Performance Reports (Out-of-Home)",
      description:
        "Understand how a category or brand is performing over time, in a specific market, channel or retailer. A performance overview that also drills down to sku level.",
      icon: PerformanceIcon,
      url:
        story === storyEnums.CGA
          ? VIEW_REPORT_ROUTE
          : NEW_OOH_PERFORMANCE_REPORT_ROUTE,
      isVisible: stories.includes(storyEnums.CGA)
    },
    {
      title: "Trend Reports",
      description:
        "Be informed of the market trends that are affecting a category, manufacturer, brand, and more, within a given market and wider channel.",
      icon: TrendIcon,
      url: NEW_IH_TREND_REPORT_ROUTE,
      isVisible: stories.includes(storyEnums.TREND)
    },
    {
      title: "Brand Battles (Product Comparison)",
      description:
        "Compare the performance of two products within a specific market or retailer; understand the key drivers behind the difference of performance.",
      icon: BrandBattlesIcon,
      url: NEW_IH_BRAND_BATTLES_REPORT_ROUTE,
      isVisible: stories.includes(storyEnums.BB)
    },
    {
      title: "Range Review Impact Assessment",
      description:
        "Explore the context and the impact of a range review, as well as the key drivers of performance change. This report is typically utilised by the Category Team.",
      icon: RangeReviewIcon,
      url: NEW_IH_RANGE_REVIEW_REPORT_ROUTE,
      isVisible: stories.includes(storyEnums.PRR)
    },
    {
      title: "Focus On: Share Performance",
      description:
        "Insights for performance relative to the category and share movement amongst products.",
      icon: FocusIcon,
      url: VIEW_REPORT_ROUTE,
      focusType: "performance",
      isVisible:
        stories.includes(storyEnums.FP) &&
        stories.includes(storyEnums.IDA) &&
        story === storyEnums.IDA
    },
    {
      title: "Focus On: Share Performance",
      description:
        "Insights for performance relative to the category and share movement amongst products.",
      icon: FocusIcon,
      url: VIEW_REPORT_ROUTE,
      focusType: "performance",
      isVisible:
        stories.includes(storyEnums.FP) &&
        stories.includes(storyEnums.CGA) &&
        story === storyEnums.CGA
    },
    {
      title: "Focus On: Star-Performers & Under-Performers",
      description:
        "Highlighting products and groups of products that have over- or under-performed and the causal drivers behind it.",
      icon: FocusIcon,
      url: VIEW_REPORT_ROUTE,
      focusType: "stars",
      isVisible:
        stories.includes(storyEnums.FP) &&
        stories.includes(storyEnums.IDA) &&
        story === storyEnums.IDA
    },
    {
      title: "Focus On: Star-Performers & Under-Performers",
      description:
        "Highlighting products and groups of products that have over- or under-performed and the causal drivers behind it.",
      icon: FocusIcon,
      url: VIEW_REPORT_ROUTE,
      focusType: "stars",
      isVisible:
        stories.includes(storyEnums.FP) &&
        stories.includes(storyEnums.CGA) &&
        story === storyEnums.CGA
    },
    {
      title: "Focus On: NPD",
      description: "Identification and performance of new product development.",
      icon: FocusIcon,
      url: VIEW_REPORT_ROUTE,
      focusType: "npd",
      isVisible:
        stories.includes(storyEnums.FP) &&
        stories.includes(storyEnums.IDA) &&
        story === storyEnums.IDA
    },
    {
      title: "Focus On: NPD",
      description: "Identification and performance of new product development.",
      icon: FocusIcon,
      url: VIEW_REPORT_ROUTE,
      focusType: "npd",
      isVisible:
        stories.includes(storyEnums.FP) &&
        stories.includes(storyEnums.CGA) &&
        story === storyEnums.CGA
    }
  ].filter(i => i.isVisible);

  prototypeObjects.forEach(i => {
    const { query, service_name: type } = i;
    const accessKeyIndex = query.indexOf("&access_key");
    const search = query.substring(0, accessKeyIndex);
    const path = `${generatePath(TOOL_SINGLE_ROUTE, { type })}?${search}`;
    const transformedObj = {
      title: `${i.content_header} (Beta Tool)`,
      description: i.content,
      url: path,
      isVisible: stories.includes(storyEnums.IDA),
      icon: PROTOTYPE_ICONS[i.service_name] || ToolsIcon,
      type: "prototype"
    };
    reportTypes.push(transformedObj);
  });

  const middle = Math.ceil(reportTypes.length / 2);
  const left = reportTypes.slice(0, middle);
  const right = reportTypes.slice(middle);
  return (
    <Box sx={{ height: 1, width: 1 }}>
      <Grid item xs={12} sx={{ mt: -3 }} container>
        <Grid item xs={6}>
          {showItems(left, config, queryString, story)}
        </Grid>
        <Grid item xs={6}>
          {showItems(right, config, queryString, story)}
          {prototypeArray.length > 2 && (
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Button
                key="prototype_slide"
                variant="soft"
                startIcon={<ToolsIcon />}
                rel="noreferrer"
                onClick={() => navigateToSlide(NEW_TOOLS_SLIDE)}
              >
                View All New Tools
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

RelatedContents.propTypes = {
  config: PropTypes.shape(),
  client: PropTypes.string,
  prototypeArray: PropTypes.arrayOf(PropTypes.shape()),
  navigateToSlide: PropTypes.func
};

RelatedContents.defaultProps = {
  config: {},
  client: "",
  prototypeArray: [],
  navigateToSlide: () => {}
};

export default RelatedContents;
