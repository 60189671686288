import defaultState from "./defaultState";
import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_ERROR,
  DISABLE_USER_REQUEST,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_ERROR,
  ENABLE_USER_REQUEST,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CLEAR_USER_MESSAGES,
  LOGOUT,
  // GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  // GET_DEPARTMENTS_ERROR,
  // ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  // ADD_DEPARTMENT_ERROR,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  REMOVE_CONTACT_REQUEST,
  REMOVE_CONTACT_SUCCESS,
  REMOVE_CONTACT_ERROR,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_ERROR,
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR
} from "../constants/actionConstants";

// eslint-disable-next-line default-param-last
const user = (state = defaultState.user, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return {
        ...state,
        createUserError: "",
        createUserMessage: "",
        isLoading: true
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserError: "",
        createUserMessage: "User created successfully",
        isLoading: false
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        createUserError: action.error,
        createUserMessage: "",
        isLoading: false
      };
    case SIGN_IN_REQUEST:
      return {
        ...state,
        user: action.user,
        isUser: false,
        error: "",
        message: "",
        isLoading: true
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.user,
        isUser: true,
        error: "",
        message: "",
        isLoading: false
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        error: action.error,
        message: "",
        user: {},
        isUser: false,
        isLoading: false
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordError: "",
        forgotPasswordMessage: "",
        isLoading: true
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordError: "",
        forgotPasswordMessage: "",
        isLoading: false
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordError: action.error,
        forgotPasswordMessage: "",
        isLoading: false
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordError: "",
        changePasswordMessage: "",
        isLoading: true
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordError: "",
        changePasswordMessage: "Password change successful",
        isLoading: false
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordError: action.error,
        changePasswordMessage: "",
        isLoading: false
      };
    case UNBLOCK_USER_REQUEST:
      return {
        ...state,
        unblockUserError: "",
        unblockUserMessage: "",
        isLoading: true
      };
    case UNBLOCK_USER_SUCCESS: {
      const updatedUserList = state.userList.map(item =>
        item.email === action.email ? { ...item, is_blocked: false } : item
      );
      return {
        ...state,
        unblockUserError: "",
        userList: updatedUserList,
        unblockUserMessage: "Unblocking user successful",
        isLoading: false
      };
    }
    case UNBLOCK_USER_ERROR:
      return {
        ...state,
        unblockUserError: action.error,
        unblockUserMessage: "",
        isLoading: false
      };
    case DISABLE_USER_REQUEST:
      return {
        ...state,
        disableUserError: "",
        disableUserMessage: "",
        isLoading: true
      };
    case DISABLE_USER_SUCCESS: {
      const updatedUserList = state.userList.map(item =>
        item.email === action.email ? { ...item, is_enabled: false } : item
      );
      return {
        ...state,
        disableUserError: "",
        disableUserMessage: "User disabled successful",
        userList: updatedUserList,
        isLoading: false
      };
    }
    case DISABLE_USER_ERROR:
      return {
        ...state,
        disableUserError: action.error,
        disableUserMessage: "",
        isLoading: false
      };
    case ENABLE_USER_REQUEST:
      return {
        ...state,
        enableUserError: "",
        enableUserMessage: "",
        isLoading: true
      };
    case ENABLE_USER_SUCCESS: {
      const updatedUserList = state.userList.map(item =>
        item.email === action.email ? { ...item, is_enabled: true } : item
      );
      return {
        ...state,
        enableUserError: "",
        enableUserMessage: "User enabled successful",
        userList: updatedUserList,
        isLoading: false
      };
    }
    case ENABLE_USER_ERROR:
      return {
        ...state,
        enableUserError: action.error,
        enableUserMessage: "",
        isLoading: false
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordError: "",
        resetPasswordMessage: "",
        isLoading: true
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordError: "",
        resetPasswordMessage: "Password reset successful",
        isLoading: false
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.error,
        resetPasswordMessage: "",
        isLoading: false
      };
    case CLEAR_USER_MESSAGES:
      return {
        ...state,
        error: "",
        message: "",
        changePasswordError: "",
        changePasswordMessage: "",
        createUserError: "",
        createUserMessage: "",
        unblockUserError: "",
        unblockUserMessage: "",
        disableUserError: "",
        disableUserMessage: "",
        enableUserError: "",
        enableUserMessage: "",
        resetPasswordError: "",
        resetPasswordMessage: "",
        subscriptionError: "",
        subscriptionMessage: "",
        forgotPasswordError: "",
        forgotPasswordMessage: ""
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        isUser: false,
        error: "",
        message: "",
        isLoading: false
      };
    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.departments
      };
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: [...state.departments, action.department]
      };
    case GET_CLIENTS_REQUEST:
      return {
        ...state,
        clients: [],
        isLoadingClients: true
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.clients,
        isLoadingClients: false
      };
    case GET_CLIENTS_ERROR:
      return {
        ...state,
        clients: [],
        isLoadingClients: false
      };
    case GET_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "",
        isLoading: true,
        subscriptions: {}
      };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "",
        isLoading: false,
        subscriptions: action.subscriptions
      };
    case GET_SUBSCRIPTION_ERROR:
      return {
        ...state,
        subscriptionError: action.error,
        subscriptionMessage: "",
        isLoading: false,
        subscriptions: {}
      };
    case SUBSCRIBE_REQUEST:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "",
        isLoading: true
      };
    case SUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "Subscription settings updated successfully",
        isLoading: false,
        subscriptions: { ...state.subscriptions, [action.topic]: true }
      };
    case SUBSCRIBE_ERROR:
      return {
        ...state,
        subscriptionError: action.error,
        subscriptionMessage: "Subscription settings updated successfully",
        isLoading: false
      };
    case UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "",
        isLoading: true
      };
    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "Subscription settings updated successfully",
        isLoading: false,
        subscriptions: { ...state.subscriptions, [action.topic]: false }
      };
    case UNSUBSCRIBE_ERROR:
      return {
        ...state,
        subscriptionError: action.error,
        subscriptionMessage: "Subscription settings updated successfully",
        isLoading: false
      };
    case ADD_CONTACT_REQUEST:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "",
        isLoading: true
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "Contact added successfully",
        isLoading: false
      };
    case ADD_CONTACT_ERROR:
      return {
        ...state,
        subscriptionError: action.error,
        subscriptionMessage: "",
        isLoading: false
      };
    case REMOVE_CONTACT_REQUEST:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "",
        isLoading: true
      };
    case REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        subscriptionError: "",
        subscriptionMessage: "Contact removed successfully",
        isLoading: false
      };
    case REMOVE_CONTACT_ERROR:
      return {
        ...state,
        subscriptionError: action.error,
        subscriptionMessage: "",
        isLoading: false
      };
    case GET_USER_LIST_REQUEST:
      return {
        ...state,
        getUserListError: "",
        isLoadingUsers: true,
        userList: [],
        userListMeta: {}
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        getUserListError: "",
        isLoadingUsers: false,
        userList: action.userList,
        userListMeta: action.userListMeta
      };
    case GET_USER_LIST_ERROR:
      return {
        ...state,
        getUserListError: action.error,
        isLoadingUsers: false,
        userList: [],
        userListMeta: {}
      };
    case EDIT_USER_REQUEST:
      return {
        ...state,
        editUserError: "",
        isLoading: true
      };
    case EDIT_USER_SUCCESS: {
      const updatedUserList = state.userList.map(item =>
        item.user_id === action.userId
          ? { ...item, name: action.name, department: action.department }
          : item
      );
      return {
        ...state,
        editUserError: "",
        isLoading: false,
        userList: updatedUserList
      };
    }
    case EDIT_USER_ERROR:
      return {
        ...state,
        editUserError: action.error,
        isLoading: false
      };
    default:
      return state;
  }
};

export default user;
