import { clientConfig } from "config";
import {
  ADD_AUTO_RANGING_USER_TRACKING_REQUEST,
  ADD_AUTO_RANGING_USER_TRACKING_SUCCESS,
  ADD_AUTO_RANGING_USER_TRACKING_ERROR,
  AUTO_RANGING_BASIC_CONSTRAINTS,
  CLEAR_AUTO_RANGING_BASIC_CONSTRAINTS,
  CLEAR_AUTO_RANGING_DATA,
  GET_BAY_PLANS_STATIC_DATA_REQUEST,
  GET_BAY_PLANS_STATIC_DATA_SUCCESS,
  GET_BAY_PLANS_STATIC_DATA_ERROR,
  GET_CURRENT_RANGE_STATIC_DATA_REQUEST,
  GET_CURRENT_RANGE_STATIC_DATA_SUCCESS,
  GET_CURRENT_RANGE_STATIC_DATA_ERROR,
  AUTO_RANGING_BAY_PLANS_CONSTRAINTS,
  AUTO_RANGING_CURRENT_RANGE_CONSTRAINTS,
  GENERATE_AUTO_RANGING_REPORT_REQUEST,
  GENERATE_AUTO_RANGING_REPORT_SUCCESS,
  GENERATE_AUTO_RANGING_REPORT_ERROR,
  GET_AUTO_RANGING_REPORT_REQUEST,
  GET_AUTO_RANGING_REPORT_SUCCESS,
  GET_AUTO_RANGING_REPORT_ERROR,
  CLEAR_AUTO_RANGING_CONSTRAINTS,
  GET_AUTO_RANGING_STATIC_DATA_REQUEST,
  GET_AUTO_RANGING_STATIC_DATA_SUCCESS,
  GET_AUTO_RANGING_STATIC_DATA_ERROR,
  GET_AUTO_RANGING_REPORT_CONSTRAINTS_REQUEST,
  GET_AUTO_RANGING_REPORT_CONSTRAINTS_SUCCESS,
  GET_AUTO_RANGING_REPORT_CONSTRAINTS_ERROR,
  CLEAR_AUTO_RANGING_REPORTS
} from "constants/actionConstants";
import { alertLevels } from "constants/enums";
import fetchInstance from "utils/fetchInstance";
import { store } from "configureStore";
import { getFormattedAutoRangingConstraints } from "utils";
import {
  AUTO_RANGING_USER_TRACKING_URL,
  BAY_PLANS_STATIC_DATA_URL,
  CURRENT_RANGE_STATIC_DATA_URL,
  GENERATE_AUTO_RANGING_REPORT_URL,
  GET_AUTO_RANGING_CONSTRAINTS_URL,
  GET_REPORT_URL,
  STATIC_DATA_URL
} from "../constants";
import { addNotification } from "./notification";
import logger from "../logger";

export function addBasicConstraints(constraints) {
  return {
    type: AUTO_RANGING_BASIC_CONSTRAINTS,
    constraints
  };
}

export function clearBasicConstraints() {
  return {
    type: CLEAR_AUTO_RANGING_BASIC_CONSTRAINTS
  };
}

const addUserTrackingRequest = () => ({
  type: ADD_AUTO_RANGING_USER_TRACKING_REQUEST
});

const addUserTrackingSuccess = () => ({
  type: ADD_AUTO_RANGING_USER_TRACKING_SUCCESS
});

const addUserTrackingError = error => ({
  type: ADD_AUTO_RANGING_USER_TRACKING_ERROR,
  error
});

const addUserTracking = ({
  dispatch,
  reportId,
  constraints,
  success,
  reportHeader,
  isPusher
}) => {
  dispatch(addUserTrackingRequest());
  fetchInstance(AUTO_RANGING_USER_TRACKING_URL, {
    method: "POST",
    body: JSON.stringify({
      report_id: reportId,
      constraints,
      success
    }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      () => {
        dispatch(addUserTrackingSuccess());
        if (isPusher)
          dispatch(
            addNotification(
              `The report "${reportHeader}" is ready.`,
              alertLevels.SUCCESS
            )
          );
      },
      err => {
        dispatch(addUserTrackingError(err.message));
      }
    );
};

const getAutoRangingStaticDataRequest = () => ({
  type: GET_AUTO_RANGING_STATIC_DATA_REQUEST
});

const getAutoRangingStaticDataSuccess = data => ({
  type: GET_AUTO_RANGING_STATIC_DATA_SUCCESS,
  payload: data
});

const getAutoRangingStaticDataError = (error = "") => ({
  type: GET_AUTO_RANGING_STATIC_DATA_ERROR,
  error
});

export const getAutoRangingStaticData = client => async dispatch => {
  dispatch(getAutoRangingStaticDataRequest());
  try {
    const allSubsections = {};
    const placeholders = {};
    let dates = {};
    const dataSets = clientConfig[client].autoRangingStaticDataSets;
    await Promise.all(
      dataSets.map(async e => {
        await fetchInstance(
          `${STATIC_DATA_URL}?data_set=${e}&client=${client}`,
          {
            headers: { "Content-Type": "application/json" }
          }
        )
          .then(async res => {
            if (!res.ok) {
              const data = await res.json();
              const err = data?.message || data;
              throw new Error(err);
            }
            return res.json();
          })
          .then(
            res => {
              if (String(res).startsWith("ERROR")) {
                throw new Error("ERROR: static data cache corrupted");
              } else {
                const { subsections, date, placeholder } = res;
                allSubsections[e] = subsections;
                placeholders[e] = placeholder;
                dates = { ...dates, ...date };
              }
            },
            err => {
              dispatch(getAutoRangingStaticDataError(err.message));
            }
          );
      })
    );
    dispatch(
      getAutoRangingStaticDataSuccess({
        subsections: allSubsections,
        date: dates,
        placeholder: placeholders,
        dataSets
      })
    );
  } catch (err) {
    dispatch(getAutoRangingStaticDataError(err.message));
  }
};

export function bayPlansConstraintsAction(constraints) {
  return {
    type: AUTO_RANGING_BAY_PLANS_CONSTRAINTS,
    constraints
  };
}

const getBayPlansStaticDataRequest = () => ({
  type: GET_BAY_PLANS_STATIC_DATA_REQUEST
});

const getBayPlansStaticDataSuccess = data => ({
  type: GET_BAY_PLANS_STATIC_DATA_SUCCESS,
  payload: data
});

const getBayPlansStaticDataError = (error = "") => ({
  type: GET_BAY_PLANS_STATIC_DATA_ERROR,
  error
});

export const getBayPlansStaticData =
  ({
    retailer,
    period,
    scope,
    story,
    client,
    numBays,
    nDist,
    onSuccess = () => {}
  }) =>
  dispatch => {
    dispatch(getBayPlansStaticDataRequest());
    return fetchInstance(BAY_PLANS_STATIC_DATA_URL, {
      method: "POST",
      body: JSON.stringify({
        retailer,
        period,
        scope,
        story,
        client,
        num_bays: numBays,
        ndist_threshold: nDist
      }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          const { status, result = { range_sizes: [] } } = res;
          if (status === "Error") throw new Error(result?.message ?? "Error");

          if (result?.detail?.includes("There are no products"))
            throw new Error(result?.detail || "There are no products");

          dispatch(getBayPlansStaticDataSuccess(result));
          onSuccess();
        },
        err => {
          dispatch(getBayPlansStaticDataError(err.message));
          dispatch(addNotification(err.message, alertLevels.ERROR));
        }
      )
      .catch(err => {
        dispatch(getBayPlansStaticDataError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      });
  };

export function currentRangeConstraintsAction(constraints) {
  return {
    type: AUTO_RANGING_CURRENT_RANGE_CONSTRAINTS,
    constraints
  };
}

const getCurrentRangeStaticDataRequest = () => ({
  type: GET_CURRENT_RANGE_STATIC_DATA_REQUEST
});

const getCurrentRangeStaticDataSuccess = data => ({
  type: GET_CURRENT_RANGE_STATIC_DATA_SUCCESS,
  payload: data
});

const getCurrentRangeStaticDataError = (error = "") => ({
  type: GET_CURRENT_RANGE_STATIC_DATA_ERROR,
  error
});

export const getCurrentRangeStaticData =
  ({
    retailer,
    period,
    scope,
    rangeSizesTable,
    story,
    client,
    nDist,
    onSuccess = () => {}
  }) =>
  dispatch => {
    dispatch(getCurrentRangeStaticDataRequest());
    return fetchInstance(CURRENT_RANGE_STATIC_DATA_URL, {
      method: "POST",
      body: JSON.stringify({
        retailer,
        period,
        scope,
        range_sizes_table: rangeSizesTable,
        story,
        client,
        ndist_threshold: nDist
      }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          const {
            status,
            result = { current_range: [], skus_not_in_current_range: [] }
          } = res;
          if (status === "Error") throw new Error(result?.message ?? "Error");

          if (result?.detail?.includes("There are no products"))
            throw new Error(result?.detail || "There are no products");

          dispatch(getCurrentRangeStaticDataSuccess(result));
          onSuccess();
        },
        err => {
          dispatch(getCurrentRangeStaticDataError(err.message));
          dispatch(addNotification(err.message, alertLevels.ERROR));
        }
      )
      .catch(err => {
        dispatch(getCurrentRangeStaticDataError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      });
  };

const generateAutoRangingReportRequest = () => ({
  type: GENERATE_AUTO_RANGING_REPORT_REQUEST
});

const generateAutoRangingReportSuccess = data => ({
  type: GENERATE_AUTO_RANGING_REPORT_SUCCESS,
  payload: data
});

const generateAutoRangingReportError = (error = "") => ({
  type: GENERATE_AUTO_RANGING_REPORT_ERROR,
  error
});

export const generateAutoRangingReport =
  ({ constraints, onSuccess = () => {} }) =>
  dispatch => {
    dispatch(generateAutoRangingReportRequest());
    return fetchInstance(GENERATE_AUTO_RANGING_REPORT_URL, {
      method: "POST",
      body: JSON.stringify({
        ...constraints
      }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          dispatch(generateAutoRangingReportSuccess(res));
          onSuccess({ reportId: res.result_id });
        },
        err => {
          dispatch(generateAutoRangingReportError(err.message));
          dispatch(addNotification(err.message, alertLevels.ERROR));
        }
      )
      .catch(err => {
        dispatch(generateAutoRangingReportError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      });
  };

const getAutoRangingReportRequest = () => ({
  type: GET_AUTO_RANGING_REPORT_REQUEST
});

const getAutoRangingReportSuccess = data => ({
  type: GET_AUTO_RANGING_REPORT_SUCCESS,
  payload: data
});

const getAutoRangingReportError = (error = "") => ({
  type: GET_AUTO_RANGING_REPORT_ERROR,
  error
});

export const getAutoRangingReport =
  ({
    client,
    story,
    reportId,
    status,
    responseMessage,
    userId,
    redirect = () => {},
    isPusher
  }) =>
  dispatch => {
    if (status === "error") {
      addUserTracking({
        dispatch,
        reportId,
        constraints: getFormattedAutoRangingConstraints({
          autoRangingConstraints: store.getState()?.autoRanging,
          client
        }),
        success: false
      });
      return dispatch(getAutoRangingReportError(responseMessage));
    }
    dispatch(getAutoRangingReportRequest());
    return fetchInstance(
      `${GET_REPORT_URL}?client=${client}&story=${story}&report_id=${reportId}`,
      {
        headers: { "Content-Type": "application/json" }
      }
    )
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          const response = JSON.parse(res);
          if (response === null) {
            redirect();
            throw new Error(
              "The report doesn't exist. Please try generate a new report with these constraints."
            );
          }
          if (!response) {
            throw new Error("No response");
          }
          const { constraints, header } = response;
          logger({
            date: new Date().toISOString(),
            action: "VIEW_REPORT",
            user_id: userId,
            query: JSON.stringify(constraints),
            report_id: reportId,
            success: true,
            story: "ar",
            client
          });
          addUserTracking({
            dispatch,
            reportId,
            constraints: { ...constraints, client },
            success: true,
            reportHeader: header,
            isPusher
          });
          dispatch(getAutoRangingReportSuccess(response));
        },
        err => {
          logger({
            date: new Date().toISOString(),
            action: "VIEW_REPORT",
            user_id: userId,
            // query: reportId, // TODO:
            report_id: reportId,
            success: false,
            story: "ar",
            client
          });

          dispatch(getAutoRangingReportError(err.message));
          dispatch(addNotification(err.message, alertLevels.ERROR));
        }
      )
      .catch(err => {
        logger({
          date: new Date().toISOString(),
          action: "VIEW_REPORT",
          user_id: userId,
          // query: reportId, // TODO:
          report_id: reportId,
          success: false,
          story: "ar",
          client
        });
        dispatch(getAutoRangingReportError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      });
  };

const getAutoRangingReportConstraintsRequest = () => ({
  type: GET_AUTO_RANGING_REPORT_CONSTRAINTS_REQUEST
});

const getAutoRangingReportConstraintsSuccess = data => ({
  type: GET_AUTO_RANGING_REPORT_CONSTRAINTS_SUCCESS,
  payload: data
});

const getAutoRangingReportConstraintsError = (error = "") => ({
  type: GET_AUTO_RANGING_REPORT_CONSTRAINTS_ERROR,
  error
});

export const getAutoRangingReportConstraints = reportId => dispatch => {
  dispatch(getAutoRangingReportConstraintsRequest());
  return fetchInstance(
    `${GET_AUTO_RANGING_CONSTRAINTS_URL}?report_id=${reportId}`,
    {
      headers: { "Content-Type": "application/json" }
    }
  )
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        const { data: { reports: { constraints = {} } = {} } = {} } = res;
        dispatch(getAutoRangingReportConstraintsSuccess(constraints));
      },
      err => {
        dispatch(getAutoRangingReportConstraintsError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      }
    )
    .catch(err => {
      dispatch(getAutoRangingReportConstraintsError(err.message));
      dispatch(addNotification(err.message, alertLevels.ERROR));
    });
};

export const clearAutoRangingConstraints = () => ({
  type: CLEAR_AUTO_RANGING_CONSTRAINTS
});

export const clearAutoRangingReports = () => ({
  type: CLEAR_AUTO_RANGING_REPORTS
});

export const clearAutoRangingData = () => ({
  type: CLEAR_AUTO_RANGING_DATA
});
