import React from "react";
import PropTypes from "prop-types";
import { Paper as MUIPaper } from "@mui/material";

const Paper = props => {
  const { children, styles, variant } = props;
  if (variant === "elevated") {
    return (
      <MUIPaper
        variant="outlined"
        sx={{
          border: 0.25,
          borderColor: "grey.200",
          boxShadow:
            "0px 1px 2px -2px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
          ...styles
        }}
      >
        {children}
      </MUIPaper>
    );
  }
  return (
    <MUIPaper
      variant="outlined"
      sx={{ borderColor: "grey.200", p: 2.5, mb: 1, ...styles }}
    >
      {children}
    </MUIPaper>
  );
};

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape(),
  variant: PropTypes.oneOf(["elevated", "outlined"])
};

Paper.defaultProps = {
  styles: {},
  variant: "outlined"
};

export default Paper;
