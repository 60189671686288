import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";
import { DownloadIcon } from "components/Icons";

const DownloadData = props => {
  const { data, header, section } = props;
  const title = header?.split("Auto-Range ")?.[1] || header;
  const filename = `Auto Ranging ${section} - ${title}.csv`;
  return (
    <CSVLink data={data} filename={filename}>
      <Button
        variant="outlined"
        sx={{ height: 35 }}
        startIcon={<DownloadIcon />}
      >
        Download Data
      </Button>
    </CSVLink>
  );
};

DownloadData.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  header: PropTypes.string,
  section: PropTypes.string
};

DownloadData.defaultProps = {
  data: [],
  header: "Auto-Ranging",
  section: ""
};

export default DownloadData;
