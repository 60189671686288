import React from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import {
  TableContainer,
  Table as MUITable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton
} from "@mui/material";

const defaultStyles = {
  root: {
    borderRadius: 1,
    border: 1,
    borderColor: "grey.200",
    "& .MuiTableCell-root": {
      lineHeight: 1.43,
      "&:nth-of-type(n)": { borderColor: "grey.200" }
    }
  },
  head: {
    "& .MuiTableRow-root.MuiTableRow-head": { bgcolor: "white" },
    "& .MuiTableCell-head": {
      borderBottom: 1,
      "&:not(:last-child)": { borderRight: 1, borderColor: "grey.200" }
    }
  },
  row: { "&:nth-of-type(odd)": { bgcolor: "grey.100" } },
  cell: { border: 0, textAlign: "center", fontSize: 12, fontWeight: 400 }
};

const Table = props => {
  const {
    rows,
    columns,
    isLoading,
    styles,
    multipleHeader,
    header,
    ...otherProps
  } = props;
  return (
    <TableContainer sx={{ ...defaultStyles.root, ...styles.root }}>
      <MUITable {...otherProps}>
        <TableHead sx={{ ...defaultStyles.head, ...styles.head }}>
          {multipleHeader && (
            <TableRow sx={{ ...defaultStyles.row, ...styles.row }}>
              {header.map(column => (
                <TableCell
                  align="center"
                  key={uniqueId()}
                  sx={{
                    ...defaultStyles.cell,
                    ...styles.cell,
                    "&:nth-of-type(n)": { ...column.styles }
                  }}
                  rowSpan={column.rowSpan}
                  colSpan={column.colSpan}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          )}
          <TableRow sx={{ ...defaultStyles.row, ...styles.row }}>
            {columns
              .filter(i => !i.rowSpan)
              .map(column => (
                <TableCell
                  align="center"
                  key={uniqueId()}
                  sx={{
                    ...defaultStyles.cell,
                    ...styles.cell,
                    "&:nth-of-type(n)": column.styles
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody sx={styles.body}>
          {isLoading
            ? [...Array(3)].map(() => (
                <TableRow
                  key={uniqueId()}
                  sx={{ ...defaultStyles.row, ...styles.row }}
                >
                  {columns.map(column => (
                    <TableCell
                      key={uniqueId()}
                      sx={{
                        ...defaultStyles.cell,
                        ...styles.cell,
                        ...column.styles
                      }}
                    >
                      <Skeleton variant="rectangular" height={22} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : rows.map((row, index) => (
                <TableRow
                  key={uniqueId()}
                  sx={{
                    ...defaultStyles.row,
                    "&:nth-of-type(n)": { ...styles.row, ...row.styles }
                  }}
                >
                  {columns
                    .filter(i => !i.colSpan)
                    .map(column => (
                      <TableCell
                        key={uniqueId()}
                        align="center"
                        sx={{
                          ...defaultStyles.cell,
                          ...styles.cell,
                          ...column.styles,
                          ...column.cellStyles
                        }}
                      >
                        {column.renderCell(row, index)}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      styles: PropTypes.shape(),
      cellStyles: PropTypes.shape(),
      renderCell: PropTypes.func
    })
  ),
  isLoading: PropTypes.bool,
  styles: PropTypes.shape({
    root: PropTypes.shape(),
    head: PropTypes.shape(),
    body: PropTypes.shape(),
    row: PropTypes.shape(),
    cell: PropTypes.shape()
  }),
  multipleHeader: PropTypes.bool,
  header: PropTypes.arrayOf(PropTypes.shape())
};

Table.defaultProps = {
  rows: [],
  columns: [],
  isLoading: false,
  styles: {},
  multipleHeader: false,
  header: []
};

export default Table;
