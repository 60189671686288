import {
  CREATE_PUSHER_CONNECTION,
  REMOVE_PUSHER_CONNECTION
} from "constants/actionConstants";

export const createPusher = (userId, client) => ({
  type: CREATE_PUSHER_CONNECTION,
  userId,
  client
});

export const removePusher = () => ({
  type: REMOVE_PUSHER_CONNECTION
});
