import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isSunday } from "date-fns";
import { Grid } from "@mui/material";
import { DatePicker } from "components/BaseComponents";
import { EditCard } from "components/CustomComponents";
import {
  getWeekStart,
  getWeekEnd,
  getImplementationPeriod,
  getDateLabel,
  getImplementationDateLabel
} from "utils";
import CustomDate from "components/CustomDate";

const EditPeriod = props => {
  const {
    story,
    dataDate,
    period,
    implementationDate,
    setPeriod,
    setImplementationDate
  } = props;
  const [selectedDates, setSelectedDates] = useState(null);

  useEffect(() => {
    if (period) {
      setSelectedDates(
        period.name
          .split(",")
          .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)))
      );
    } else {
      setSelectedDates(null);
    }
  }, [period]);

  const handlePeriod = value => {
    setPeriod(value);
    if (implementationDate) setImplementationDate(null);
  };

  const handleImplementationDate = value => {
    if (value) {
      setImplementationDate(getImplementationPeriod(value));
    } else {
      setImplementationDate(null);
    }
  };

  const getSelectedDateLabel = date => {
    const dateString = getDateLabel(date.name, story).split("Vs");
    return [dateString[0], dateString[1]];
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <EditCard
          label="Time Frame"
          selected={period ? getSelectedDateLabel(period) : []}
          isWarning={!!implementationDate}
          warningText="If you change the Time Period, you must also change the Implementation Date."
        >
          <CustomDate
            story={story}
            selectedPeriod={period}
            dataDate={dataDate}
            onChange={date => handlePeriod(date)}
          />
        </EditCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <EditCard
          label="Implementation Date"
          selected={
            implementationDate
              ? [getImplementationDateLabel(implementationDate)]
              : ["Default: Start of Post Review Period"]
          }
        >
          <DatePicker
            selected={
              implementationDate ? getWeekStart(implementationDate) : null
            }
            onChange={date => handleImplementationDate(date)}
            filterDate={isSunday}
            minDate={selectedDates ? selectedDates[0] : null} // compare start date
            maxDate={selectedDates ? selectedDates[3] : null} // ppr end date
            openToDate={
              implementationDate
                ? getWeekStart(implementationDate)
                : (selectedDates && selectedDates[2]) || null
            } // implementation date or prr start date
            disabled={!period}
            isClearable
            onClear={() => handleImplementationDate(null)}
            styles={{ width: 220 }}
          />
        </EditCard>
      </Grid>
    </Grid>
  );
};

EditPeriod.propTypes = {
  story: PropTypes.string,
  dataDate: PropTypes.string,
  period: PropTypes.shape(),
  implementationDate: PropTypes.string,
  setPeriod: PropTypes.func,
  setImplementationDate: PropTypes.func
};

EditPeriod.defaultProps = {
  story: "",
  dataDate: "",
  period: null,
  implementationDate: null,
  setPeriod: () => {},
  setImplementationDate: () => {}
};

export default EditPeriod;
