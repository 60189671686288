import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";
import { Typography } from "@mui/material";
import { Table } from "components/BaseComponents";
import { clearAutoRangingConstraints } from "actions";
import TimePeriod from "./TimePeriod";
import Actions from "./Actions";

const getChannel = () => "In-Home";

const AutoRanging = props => {
  const {
    dataDate,
    recentReports,
    favouriteReports,
    isLoadingReports,
    isLoadingFavourites,
    dispatchClearAutoRangingConstraints,
    searchText,
    isFavourites,
    reportType
  } = props;

  useEffect(() => {
    dispatchClearAutoRangingConstraints();
  }, []);

  const reports = recentReports.map(
    ({
      constraints,
      create_at: date,
      report_id: reportId,
      constraints_hash: constraintsHash
    }) => {
      const {
        retailer = "",
        comparison_retailer: comparisonRetailer = "",
        scope = {},
        story = "",
        period = ""
      } = constraints || {};
      return {
        constraints,
        retailer,
        comparisonRetailer,
        scope,
        story,
        period,
        date,
        reportId,
        constraintsHash,
        isFavourite: favouriteReports
          .map(i => i.constraints_hash)
          .includes(constraintsHash)
      };
    }
  );
  const reportsToShow = isFavourites
    ? reports.filter(i => i.isFavourite)
    : reports;

  const searchResults = reportsToShow.filter(
    ({ retailer, comparisonRetailer, story, period, date, scope }) =>
      [
        retailer,
        comparisonRetailer,
        story,
        period,
        date,
        getChannel(story),
        ...Object.values(scope).flat()
      ]
        .join(",")
        .toLowerCase()
        .includes(searchText)
  );

  const columns = [
    {
      label: "#",
      renderCell: (item, index) => index + 1,
      isVisible: true
    },
    {
      label: "Select Retailer / Environment",
      renderCell: item => item.retailer
    },
    {
      label: "Comparison Retailer / Environment for Gap Analysis",
      renderCell: item => item.comparisonRetailer
    },
    {
      label: "Scope",
      styles: { textAlign: "left" },
      renderCell: item =>
        Object.entries(item.scope).map(([key, value]) =>
          value.map(text => (
            <Typography key={key + text} variant="inherit" sx={{ mb: 0.125 }}>
              {text}
            </Typography>
          ))
        )
    },
    {
      label: "Channel",
      renderCell: () => getChannel()
    },
    {
      label: "Time Period",
      renderCell: item => (
        <TimePeriod
          story={item.story}
          period={item.period}
          dataDate={dataDate}
        />
      )
    },
    {
      label: "Date",
      renderCell: item =>
        item.date
          ? format(parseISO(item.date), "dd/MM/yy")
          : format(new Date(), "dd/MM/yy")
    },
    {
      label: "Actions",
      renderCell: item => (
        <Actions report={item} reportType={reportType} isAutoRanging />
      )
    }
  ];

  return (
    <Table
      rows={searchResults}
      columns={columns}
      isLoading={isLoadingReports || isLoadingFavourites}
    />
  );
};

AutoRanging.propTypes = {
  dataDate: PropTypes.shape(),
  recentReports: PropTypes.arrayOf(PropTypes.shape()),
  favouriteReports: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingReports: PropTypes.bool,
  isLoadingFavourites: PropTypes.bool,
  dispatchClearAutoRangingConstraints: PropTypes.func,
  searchText: PropTypes.string,
  isFavourites: PropTypes.bool,
  reportType: PropTypes.string
};

AutoRanging.defaultProps = {
  dataDate: {},
  recentReports: [],
  favouriteReports: [],
  isLoadingReports: false,
  isLoadingFavourites: false,
  dispatchClearAutoRangingConstraints: () => {},
  searchText: "",
  isFavourites: false,
  reportType: ""
};

const mapStateToProps = state => ({
  dataDate: state.data.date,
  recentReports: state.report.myRecentARReports,
  favouriteReports: state.report.myFavouriteARReports,
  isLoadingReports: state.report.isLoadingMyARReports,
  isLoadingFavourites: state.report.isLoadingMyARFavourites
});

const mapDispatchToProps = dispatch => ({
  dispatchClearAutoRangingConstraints: () =>
    dispatch(clearAutoRangingConstraints())
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoRanging);
