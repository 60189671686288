import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ReportStepper } from "components/CustomComponents";
import { getAutoRangingStaticData } from "actions";
import Header from "./Header";
import Basics from "./Basics";
import RangeSizes from "./RangeSizes";
import CurrentRange from "./CurrentRange";
import Review from "./Review";

const steps = ["Basics", "Range Sizes", "Current Range", "Review and Run"];
const story = "nielsen_auto_ranging";

const NewAutoRanging = props => {
  const {
    basicConstraints,
    bayPlanConstraints,
    subsections,
    filteredSubsections,
    isFilteredData,
    getData,
    client,
    isStaticData
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const params = useParams();
  const { step, type } = params;

  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  const modifiedSubsections = isFilteredData
    ? filteredSubsections
    : subsections ?? [];

  return (
    <Fragment>
      <ReportStepper activeStep={activeStep} steps={steps} />
      <Header
        isLastStep={activeStep === steps.length - 1}
        basicConstraints={basicConstraints}
        bayPlanConstraints={bayPlanConstraints}
      />
      {step === "basics" && (
        <Basics
          setActiveStep={setActiveStep}
          story={story}
          subsections={modifiedSubsections}
          isEdit={type === "edit"}
        />
      )}
      {step === "range-sizes" && <RangeSizes setActiveStep={setActiveStep} />}
      {step === "current-range" && (
        <CurrentRange setActiveStep={setActiveStep} />
      )}
      {step === "review" && <Review setActiveStep={setActiveStep} />}
    </Fragment>
  );
};

NewAutoRanging.propTypes = {
  basicConstraints: PropTypes.shape(),
  bayPlanConstraints: PropTypes.arrayOf(PropTypes.shape()),
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  isFilteredData: PropTypes.bool,
  getData: PropTypes.func,
  client: PropTypes.string,
  isStaticData: PropTypes.bool
};

NewAutoRanging.defaultProps = {
  basicConstraints: {},
  bayPlanConstraints: [],
  subsections: [],
  filteredSubsections: [],
  isFilteredData: false,
  getData: () => {},
  client: "",
  isStaticData: false
};

const mapStateToProps = state => {
  const {
    search: {
      filteredSubsections = { story: [] },
      isFilteredData = { story: false }
    },
    autoRanging: {
      staticData: {
        data: { subsections = { story: [] } } = {},
        isStaticData = false
      } = {},
      bayPlanConstraints = {},
      basicConstraints = {}
    }
  } = state;
  return {
    basicConstraints,
    bayPlanConstraints,
    subsections: subsections[story],
    filteredSubsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story],
    client: state.user.user.client,
    isStaticData
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => dispatch(getAutoRangingStaticData(client))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAutoRanging);
