export const storyEnums = {
  IDA: "ida",
  CGA: "cga",
  TREND: "trend",
  BB: "bb",
  PRR: "prr",
  AR: "nielsen_auto_ranging",
  FP: "focus"
};

export const permissionEnums = {
  LOOKOUT: "lookout",
  STERLING: "sterling",
  AUTO_RANGING: "auto_ranging",
  STREAMLIT: "streamlit"
};

export const alertLevels = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning"
};
