import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, Stack, Typography, Button, Skeleton, Link } from "@mui/material";
import { getLookout, getPreferences } from "actions";
import { getGreeting, getLookoutDate } from "utils";
import { getChannelConfig, clientConfig, dataSetConfig } from "config";
import {
  NEW_REPORT_ROUTE,
  LOOKOUT_PREFERENCES_ROUTE
} from "constants/viewRoutes";
import { lookout, lookoutLogo } from "images";
import { AddIcon, EditIcon, InHomeIcon, OutOfHomeIcon } from "components/Icons";
import { Paper, Tabs } from "components/BaseComponents";
import { Insights } from "components/Lookout";

const greeting = getGreeting();

const tabItems = [
  { label: "In Home", value: "ih", icon: <InHomeIcon /> },
  { label: "Out Of Home", value: "ooh", icon: <OutOfHomeIcon /> }
];

const Home = props => {
  const {
    dataDate,
    preferences,
    lookoutData,
    client,
    dispatchGetPreferences,
    dispatchGetLookout,
    isLookoutLoading,
    isLookoutData,
    isStaticData
  } = props;
  const navigate = useNavigate();
  const {
    channels = [],
    dataSets = [],
    name = ""
  } = clientConfig[client] || {};
  const homeTabs = tabItems.filter(i => channels.includes(i.value));
  const [channel, setChannel] = useState(homeTabs[0].value);

  useEffect(() => {
    dispatchGetPreferences();
  }, []);

  useEffect(() => {
    if (dataDate && !isLookoutData) {
      dispatchGetLookout(dataDate, client);
    }
  }, [dataDate, isLookoutData]);

  const { dataSet, isMonth } = getChannelConfig(channel);
  const dataList = dataSets.map(i => ({
    name: dataSetConfig[i],
    date: dataDate[i]
  }));

  const renderDataUpdate = items => {
    if (!isStaticData)
      return (
        <Stack direction="row" spacing={0.5}>
          {[...Array(3)].map(() => (
            <Skeleton
              key={_.uniqueId()}
              variant="rounded"
              width={125}
              height={48}
            />
          ))}
        </Stack>
      );
    return (
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          "& .MuiStack-root:first-of-type": {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5
          },
          "& .MuiStack-root:last-of-type": {
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5
          }
        }}
      >
        {items.map(i => (
          <Stack
            justifyContent="center"
            alignItems="center"
            key={i.name}
            spacing={0.5}
            sx={{ bgcolor: "grey.100", width: 125, py: 0.5 }}
          >
            <Typography variant="body2" fontWeight="medium" color="grey.700">
              {i.name}
            </Typography>
            <Typography variant="body2" color="grey.400">
              {i.date}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  };

  const renderLookoutDate = () =>
    !isStaticData ? (
      <Skeleton variant="rounded" width="150px" height="30px" />
    ) : (
      <Typography variant="body2" color="grey.700">
        {channel === "ih" ? "L4W" : "L1M"} (
        {getLookoutDate(dataDate[dataSet], isMonth)})
      </Typography>
    );

  const insights = lookoutData[channel];
  const userInsights = insights?.user?.insight || [];
  const clientInsights = insights?.totalClient?.insight || [];

  return (
    <Fragment>
      {/* for load-runner */}
      <p style={{ display: "none" }}>Performance Reports</p>
      <Paper>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              {greeting}
            </Typography>
            <Typography variant="body1" color="grey.500">
              We are here to simplify the understanding of business performance.
            </Typography>
          </Box>
          <Button
            color="secondary"
            endIcon={<AddIcon />}
            size="large"
            variant="contained"
            onClick={() => navigate(NEW_REPORT_ROUTE)}
          >
            New Report
          </Button>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <img alt="lookout" src={lookout} style={{ height: 40 }} />
          {renderDataUpdate(dataList)}
        </Stack>
        <Tabs
          activeTab={channel}
          onChange={(e, value) => setChannel(value)}
          tabs={homeTabs}
          styles={{ my: 2 }}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ mb: 1.5 }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <img alt="lookout" src={lookoutLogo} style={{ width: 40 }} />
            <Typography variant="subtitle2" color="grey.800">
              Your Top Insights
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ mt: -0.5 }}
          >
            {renderLookoutDate()}
            <Button
              size="small"
              variant="soft"
              endIcon={<EditIcon />}
              onClick={() =>
                navigate(generatePath(LOOKOUT_PREFERENCES_ROUTE, { channel }))
              }
            >
              Edit Preferences
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={clientInsights.length === 0}
              component={Link}
              href="#client-insights"
            >
              Top {name} Insights
            </Button>
          </Stack>
        </Stack>
        <Insights
          userInsights={userInsights}
          clientInsights={clientInsights}
          channel={channel}
          isPreference={preferences?.[channel]?.length > 0}
          client={client}
          isLoading={isLookoutLoading || !isStaticData}
          dataDate={dataDate}
          clientName={name}
        />
      </Paper>
    </Fragment>
  );
};

Home.propTypes = {
  dispatchGetPreferences: PropTypes.func,
  dispatchGetLookout: PropTypes.func,
  dataDate: PropTypes.shape(),
  preferences: PropTypes.shape(),
  lookoutData: PropTypes.shape(),
  client: PropTypes.string,
  isLookoutLoading: PropTypes.bool,
  isLookoutData: PropTypes.bool,
  isStaticData: PropTypes.bool
};

Home.defaultProps = {
  dispatchGetPreferences: () => {},
  dispatchGetLookout: () => {},
  dataDate: {},
  preferences: {},
  lookoutData: {},
  client: "",
  isLookoutLoading: false,
  isLookoutData: false,
  isStaticData: false
};

const mapStateToProps = state => ({
  dataDate: state.data.date,
  isStaticData: state.data.isStaticData,
  preferences: state.lookout.preferences,
  lookoutData: state.lookout.lookout,
  client: state.user.user.client,
  isLookoutLoading: state.lookout.isLookoutLoading,
  isLookoutData: state.lookout.isLookout
});

const mapDispatchToProps = dispatch => ({
  dispatchGetPreferences: () => dispatch(getPreferences()),
  dispatchGetLookout: (dataDate, client) =>
    dispatch(getLookout(dataDate, client))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
