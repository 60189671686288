import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ReportStepper } from "components/CustomComponents";
import { fetchStaticData } from "../../actions";
import Header from "./Header";
import Product from "./Product";
import Market from "./Market";
import Review from "./Review";

const steps = [
  "Target Product, Product Filter",
  "Market, Time, Measure",
  "Review and Run"
];

const ReportBuilder = props => {
  const {
    story,
    isStaticData,
    client,
    getData,
    title,
    icon,
    description,
    dataSet,
    product,
    context,
    retailer,
    period,
    metric,
    reportType,
    source
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const params = useParams();
  const { step } = params;

  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  return (
    <Fragment>
      <ReportStepper activeStep={activeStep} steps={steps} />
      <Header
        story={story}
        icon={icon}
        title={title}
        description={description}
        isLastStep={activeStep === steps.length - 1}
        product={product}
        context={context}
        retailer={retailer}
        period={period}
        metric={metric}
      />
      {step === "product" && (
        <Product
          story={story}
          setActiveStep={setActiveStep}
          dataSet={dataSet}
          product={product}
          context={context}
          reportType={reportType}
        />
      )}
      {step === "market" && (
        <Market
          story={story}
          setActiveStep={setActiveStep}
          dataSet={dataSet}
          product={product}
          context={context}
          retailer={retailer}
          period={period}
          metric={metric}
          reportType={reportType}
        />
      )}
      {step === "review" && (
        <Review
          story={story}
          setActiveStep={setActiveStep}
          product={product}
          context={context}
          retailer={retailer}
          period={period}
          metric={metric}
          reportType={reportType}
          source={source}
        />
      )}
    </Fragment>
  );
};

ReportBuilder.propTypes = {
  getData: PropTypes.func,
  isStaticData: PropTypes.bool,
  story: PropTypes.string,
  client: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.shape(),
  description: PropTypes.string,
  dataSet: PropTypes.string,
  product: PropTypes.arrayOf(PropTypes.shape()),
  context: PropTypes.arrayOf(PropTypes.shape()),
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  metric: PropTypes.string,
  reportType: PropTypes.string,
  source: PropTypes.string
};

ReportBuilder.defaultProps = {
  getData: () => {},
  isStaticData: false,
  story: "",
  client: "",
  title: "",
  icon: null,
  description: "",
  dataSet: "",
  product: [],
  context: [],
  retailer: null,
  period: null,
  metric: null,
  reportType: "",
  source: ""
};

const mapStateToProps = (state, ownProps) => {
  const { story } = ownProps;
  const {
    reportConstraints: {
      [story]: {
        product = [],
        context = [],
        retailer = null,
        period = null,
        metric = null
      } = {}
    }
  } = state;
  return {
    isStaticData: state.data.isStaticData,
    client: state.user.user.client,
    product,
    context,
    retailer,
    period,
    metric
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => dispatch(fetchStaticData(client))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportBuilder);
