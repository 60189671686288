import React from "react";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { EditReport } from "components/ReportBuilder";
import { getFocusFromQuery, getStoryFromQuery } from "utils";
import { getStoryConfig } from "config";
import { FOCUS_MAPPINGS } from "constants/reportConstants";
import { FocusIcon } from "../Icons";

const EditFocusedReport = () => {
  const location = useLocation();
  const story = getStoryFromQuery(location.search);
  const { dataSet } = getStoryConfig(story);
  const { focusType } = getFocusFromQuery(location.search);

  const focusTitle = FOCUS_MAPPINGS[focusType] || "";
  const title = `Focused Reports – ${focusTitle}`;

  let description;
  if (focusType === "performance") {
    description =
      "Insights for performance relative to the category and share movement amongst products.";
  } else if (focusType === "npd") {
    description = "Identification and performance of new product development.";
  } else if (focusType === "stars") {
    description =
      "Highlighting products and groups of products that have over or under-performed and the casual drivers behind it.";
  }

  if (!story || !dataSet) {
    return <Skeleton variant="rounded" height={500} />; // TODO:
  }
  return (
    <EditReport
      title={title}
      icon={<FocusIcon />}
      description={description}
      story={story}
      dataSet={dataSet}
      reportType="focus"
      focusType={focusType}
    />
  );
};

export default EditFocusedReport;
