import React from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { ArrowRightIcon } from "components/Icons";
import { Paper } from "components/BaseComponents";
import { Link } from "react-router-dom";
import {
  brandBattles,
  logoTagline,
  pattern,
  performance,
  rangeReview
} from "images";
import { SUPPORT_ROUTE } from "constants/viewRoutes";
import { REPORT_COLORS } from "theme";

const About = () => (
  <Paper styles={{ p: 0 }}>
    <Box
      sx={theme => ({
        height: 325,
        bgcolor: "primary.main",
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        px: 5,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius
      })}
    >
      <Grid container sx={{ height: 1 }}>
        <Grid item xs={12} md={8}>
          <Stack
            spacing={2}
            alignItems="flex-start"
            justifyContent="center"
            sx={{ height: 1 }}
          >
            <Typography variant="h3" color="white">
              Supercharge what you can do with your data
            </Typography>
            <Stack sx={{ pb: 1 }}>
              <Typography variant="body1" color="white">
                dijuno Reports are here to simplify the understanding of
                business performance.
              </Typography>
              <Typography variant="body1" color="white">
                dijuno intelligently analyses standard industry datasets and
                generates meaningful stories about what happened, and why.
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={SUPPORT_ROUTE}
            >
              Talk to our Helpdesk or give Feedback
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <img src={logoTagline} alt="dijuno" style={{ width: 232 }} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
    <Box sx={{ height: 580, px: 5 }}>
      <Grid container justifyContent="space-between" sx={{ height: 1 }}>
        <Grid item xs={12} md={7}>
          <Stack
            spacing={3}
            alignItems="flex-start"
            justifyContent="center"
            sx={{ height: 1 }}
          >
            <Stack spacing={1.5}>
              <Typography variant="h3" color={REPORT_COLORS.performance}>
                Performance Reports
              </Typography>
              <Typography
                variant="h4"
                fontWeight="medium"
                color={REPORT_COLORS.performance}
              >
                Detailed report about drivers of change, causal factors, key
                performing products and more...
              </Typography>
              <Typography variant="body1" color="grey.500">
                You have data about everything in your business - understanding
                all of it is nigh on impossible. Performance reports allow
                anyone to ask a question about a brand, category, sector or pack
                type.
              </Typography>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="h6" color={REPORT_COLORS.performance}>
                Perfect for
              </Typography>
              <Stack direction="row" alignItems="center" spacing={3}>
                <ArrowRightIcon
                  sx={{ color: REPORT_COLORS.performance, fontSize: 18 }}
                />
                <Typography variant="body1" color="grey.500">
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="common.black"
                    component="span"
                  >
                    Marketing teams
                  </Typography>{" "}
                  to understand market trends & those areas that might need a
                  bit more TLC.
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <ArrowRightIcon
                  sx={{ color: REPORT_COLORS.performance, fontSize: 18 }}
                />
                <Typography variant="body1" color="grey.500">
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="common.black"
                    component="span"
                  >
                    Senior executives
                  </Typography>{" "}
                  to get a quick and holistic understanding of performance
                  without distracting the Insights team from high level
                  strategic work.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <img src={performance} alt="performance" style={{ height: 450 }} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
    <Box sx={{ height: 675, px: 5, bgcolor: "grey.50" }}>
      <Grid container justifyContent="space-between" sx={{ height: 1 }}>
        <Grid item xs={12} md={4}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <img
              src={brandBattles}
              alt="brand battles"
              style={{ height: 530 }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack
            spacing={3}
            alignItems="flex-start"
            justifyContent="center"
            sx={{ height: 1 }}
          >
            <Stack spacing={1.5}>
              <Typography variant="h3" color={REPORT_COLORS.brandBattles}>
                Brand Battles&trade;
              </Typography>
              <Typography
                variant="h4"
                fontWeight="medium"
                color={REPORT_COLORS.brandBattles}
              >
                Empower your organization to act both before, and directly
                against your key competitors.
              </Typography>
              <Stack>
                <Typography variant="body1" color="grey.500" gutterBottom>
                  Be first off the line with Brand Battles.
                </Typography>
                <Typography variant="body1" color="grey.500" gutterBottom>
                  Ask IDA any question about the relative performance of your
                  product, brand or even the whole organisation against your
                  closest competition.
                </Typography>
                <Typography variant="body1" color="grey.500">
                  IDA will generate a rich report that outlines the key drivers
                  of share change between the two products. She will even give
                  you recommended actions to leave your rivals thinking
                  &quot;how on earth did they know to do that!&quot;
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="h6" color={REPORT_COLORS.brandBattles}>
                Perfect for
              </Typography>
              <Stack direction="row" alignItems="center" spacing={3}>
                <ArrowRightIcon
                  sx={{ color: REPORT_COLORS.brandBattles, fontSize: 18 }}
                />
                <Typography variant="body1" color="grey.500">
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="common.black"
                    component="span"
                  >
                    Sales teams
                  </Typography>{" "}
                  to use to prepare their top-level strategy, answer customer
                  questions on the fly and show retailers why your product
                  should take pride of place on the shelf.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
    <Box sx={{ height: 480, px: 5 }}>
      <Grid container justifyContent="space-between" sx={{ height: 1 }}>
        <Grid item xs={12} md={7}>
          <Stack
            spacing={3}
            alignItems="flex-start"
            justifyContent="center"
            sx={{ height: 1 }}
          >
            <Stack spacing={1.5}>
              <Typography variant="h3" color={REPORT_COLORS.rangeReview}>
                Range Review Impact Assessments
              </Typography>
              <Typography
                variant="h4"
                fontWeight="medium"
                color={REPORT_COLORS.rangeReview}
              >
                Report on performance of any range review you&apos;ve undertaken
                - in minutes.
              </Typography>
              <Typography variant="body1" color="grey.500">
                Range Reviews are a crucial part of the FMCG business process.
                Yet, clients told us that they barely had time to assess the
                previous Review, yet alone build strategies and recommendations
                for their customers.
              </Typography>
              <Typography variant="body1" color="grey.500">
                This allows you to spend your time developing actionable,
                revenue-driving recommendations to customers rather than
                scrambling to merely explain what&apos;s happened.
              </Typography>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="h6" color={REPORT_COLORS.rangeReview}>
                Perfect for
              </Typography>
              <Stack direction="row" alignItems="center" spacing={3}>
                <ArrowRightIcon
                  sx={{ color: REPORT_COLORS.rangeReview, fontSize: 18 }}
                />
                <Typography variant="body1" color="grey.500">
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="common.black"
                    component="span"
                  >
                    Category teams
                  </Typography>{" "}
                  who are frustrated at consistently looking back, not forward.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <img src={rangeReview} alt="range review" style={{ height: 350 }} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
    <Box
      sx={theme => ({
        height: 150,
        bgcolor: "primary.main",
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        px: 5,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius
      })}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ height: 1 }}
      >
        <Typography variant="body1" color="white">
          And More ...
        </Typography>
        <Typography variant="h3" color="white">
          Solve real world problems that truly matter to your organization.
        </Typography>
      </Stack>
    </Box>
  </Paper>
);

export default About;
