import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { AddCircleIcon, CloseIcon } from "components/Icons";
import { Pagination, Table } from "components/BaseComponents";
import { getUserList } from "actions";
import UserManagementActions from "./UserManagementActions";
import CreateAccount from "./CreateAccount";

const UserManagement = props => {
  const {
    client,
    isLoading,
    dispatchGetUserList,
    userList,
    userListMeta,
    isLoadingUsers
  } = props;
  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    dispatchGetUserList(1);
  }, []);

  return (
    <Fragment>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ width: 1, mb: 2 }}
        >
          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => setIsNewUser(true)}
          >
            New User
          </Button>
        </Stack>
        <Table
          columns={[
            {
              label: "Email",
              renderCell: item => item.email,
              styles: { textAlign: "left", minWidth: 200 }
            },
            {
              label: "Client",
              renderCell: item => item.client,
              styles: { textAlign: "left", minWidth: 150 }
            },
            {
              label: "Department",
              renderCell: item => item.department,
              styles: { textAlign: "left", minWidth: 150 }
            },
            {
              label: "Status",
              renderCell: item => (
                <Typography
                  variant="inherit"
                  fontWeight="medium"
                  color={item.is_enabled ? "negative.main" : "positive.main"}
                >
                  {item.is_enabled ? "Enabled" : "Disabled"}
                </Typography>
              ),
              styles: { textAlign: "left", width: 150 }
            },
            {
              label: "Actions",
              renderCell: item => (
                <UserManagementActions
                  user={item}
                  isLoading={isLoading}
                  client={client}
                />
              ),
              cellStyles: { py: 0, width: 200 }
            }
          ]}
          rows={userList}
          isLoading={isLoadingUsers}
        />
        <Pagination
          page={userListMeta.page}
          count={userListMeta.total_pages}
          onChange={(e, value) => dispatchGetUserList(value)}
          isLoading={isLoadingUsers}
        />
      </Stack>
      <Dialog
        open={isNewUser}
        onClose={() => setIsNewUser(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          sx={{ color: "grey.900", fontSize: 18, fontWeight: "medium" }}
        >
          Create Account
        </DialogTitle>
        <IconButton
          onClick={() => setIsNewUser(false)}
          sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <CreateAccount isLoading={isLoading} client={client} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

UserManagement.propTypes = {
  client: PropTypes.string,
  isLoading: PropTypes.bool,
  dispatchGetUserList: PropTypes.func,
  userList: PropTypes.arrayOf(PropTypes.shape()),
  userListMeta: PropTypes.shape(),
  isLoadingUsers: PropTypes.bool
};

UserManagement.defaultProps = {
  client: "",
  isLoading: false,
  dispatchGetUserList: () => {},
  userList: [],
  userListMeta: {},
  isLoadingUsers: false
};

const mapStateToProps = state => ({
  userList: state.user.userList,
  userListMeta: state.user.userListMeta,
  isLoadingUsers: state.user.isLoadingUsers
});

const mapDispatchToProps = dispatch => ({
  dispatchGetUserList: page => dispatch(getUserList(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
