import {
  AUTO_RANGING_BASIC_CONSTRAINTS,
  CLEAR_AUTO_RANGING_BASIC_CONSTRAINTS,
  CLEAR_AUTO_RANGING_DATA,
  GET_BAY_PLANS_STATIC_DATA_REQUEST,
  GET_BAY_PLANS_STATIC_DATA_SUCCESS,
  GET_BAY_PLANS_STATIC_DATA_ERROR,
  AUTO_RANGING_BAY_PLANS_CONSTRAINTS,
  GET_CURRENT_RANGE_STATIC_DATA_REQUEST,
  GET_CURRENT_RANGE_STATIC_DATA_SUCCESS,
  GET_CURRENT_RANGE_STATIC_DATA_ERROR,
  AUTO_RANGING_CURRENT_RANGE_CONSTRAINTS,
  GENERATE_AUTO_RANGING_REPORT_REQUEST,
  GENERATE_AUTO_RANGING_REPORT_SUCCESS,
  GENERATE_AUTO_RANGING_REPORT_ERROR,
  GET_AUTO_RANGING_REPORT_REQUEST,
  GET_AUTO_RANGING_REPORT_SUCCESS,
  GET_AUTO_RANGING_REPORT_ERROR,
  CLEAR_AUTO_RANGING_CONSTRAINTS,
  GET_AUTO_RANGING_STATIC_DATA_REQUEST,
  GET_AUTO_RANGING_STATIC_DATA_SUCCESS,
  GET_AUTO_RANGING_STATIC_DATA_ERROR,
  GET_AUTO_RANGING_REPORT_CONSTRAINTS_REQUEST,
  GET_AUTO_RANGING_REPORT_CONSTRAINTS_SUCCESS,
  GET_AUTO_RANGING_REPORT_CONSTRAINTS_ERROR,
  ADD_AUTO_RANGING_USER_TRACKING_REQUEST,
  ADD_AUTO_RANGING_USER_TRACKING_SUCCESS,
  ADD_AUTO_RANGING_USER_TRACKING_ERROR,
  CLEAR_AUTO_RANGING_REPORTS
} from "constants/actionConstants";
import defaultState from "./defaultState";

// eslint-disable-next-line default-param-last
const autoRanging = (state = defaultState.autoRanging, action) => {
  switch (action.type) {
    case GET_AUTO_RANGING_STATIC_DATA_REQUEST:
      return {
        ...state,
        staticData: {
          ...state.staticData,
          isLoading: true,
          error: "",
          isStaticData: false
        }
      };
    case GET_AUTO_RANGING_STATIC_DATA_SUCCESS:
      return {
        ...state,
        staticData: {
          ...state.staticData,
          data: action.payload,
          isLoading: false,
          error: "",
          isStaticData: true
        }
      };
    case GET_AUTO_RANGING_STATIC_DATA_ERROR:
      return {
        ...state,
        staticData: {
          ...state.staticData,
          data: [],
          isLoading: false,
          error: action.error,
          isStaticData: false
        }
      };
    case AUTO_RANGING_BASIC_CONSTRAINTS:
      return {
        ...state,
        basicConstraints: { ...state.basicConstraints, ...action.constraints },
        bayPlanStaticData: defaultState.autoRanging.bayPlanStaticData,
        bayPlanConstraints: defaultState.autoRanging.bayPlanConstraints,
        currentRangeStaticData: defaultState.autoRanging.currentRangeStaticData,
        currentRangeConstraints:
          defaultState.autoRanging.currentRangeConstraints
      };
    case CLEAR_AUTO_RANGING_BASIC_CONSTRAINTS:
      return {
        ...state,
        basicConstraints: { ...defaultState.autoRanging.basicConstraints }
      };
    case AUTO_RANGING_BAY_PLANS_CONSTRAINTS:
      return {
        ...state,
        bayPlanConstraints: action.constraints,
        currentRangeConstraints:
          defaultState.autoRanging.currentRangeConstraints
      };
    case GET_BAY_PLANS_STATIC_DATA_REQUEST:
      return {
        ...state,
        bayPlanStaticData: {
          ...state.bayPlanStaticData,
          isLoading: true,
          error: ""
        }
      };
    case GET_BAY_PLANS_STATIC_DATA_SUCCESS:
      return {
        ...state,
        bayPlanStaticData: {
          ...state.bayPlanStaticData,
          data: action.payload,
          isLoading: false,
          error: ""
        }
      };
    case GET_BAY_PLANS_STATIC_DATA_ERROR:
      return {
        ...state,
        bayPlanStaticData: {
          ...state.bayPlanStaticData,
          data: [],
          isLoading: false,
          error: action.error
        }
      };

    case AUTO_RANGING_CURRENT_RANGE_CONSTRAINTS:
      return {
        ...state,
        currentRangeConstraints: {
          ...state.currentRangeConstraints,
          ...action.constraints
        }
      };
    case GET_CURRENT_RANGE_STATIC_DATA_REQUEST:
      return {
        ...state,
        currentRangeStaticData: {
          ...state.currentRangeStaticData,
          isLoading: true,
          error: ""
        }
      };
    case GET_CURRENT_RANGE_STATIC_DATA_SUCCESS:
      return {
        ...state,
        currentRangeStaticData: {
          ...state.currentRangeStaticData,
          data: action.payload,
          isLoading: false,
          error: ""
        }
      };
    case GET_CURRENT_RANGE_STATIC_DATA_ERROR:
      return {
        ...state,
        currentRangeStaticData: {
          ...state.currentRangeStaticData,
          data: [],
          isLoading: false,
          error: action.error
        }
      };

    case GENERATE_AUTO_RANGING_REPORT_REQUEST:
      return {
        ...state,
        generatedReport: {
          ...state.generatedReport,
          isLoading: true,
          error: ""
        }
      };
    case GENERATE_AUTO_RANGING_REPORT_SUCCESS:
      return {
        ...state,
        generatedReport: {
          ...state.generatedReport,
          data: action.payload,
          isLoading: false,
          error: ""
        }
      };
    case GENERATE_AUTO_RANGING_REPORT_ERROR:
      return {
        ...state,
        generatedReport: {
          ...state.generatedReport,
          data: [],
          isLoading: false,
          error: action.error
        }
      };
    case GET_AUTO_RANGING_REPORT_REQUEST:
      return {
        ...state,
        finalReportResult: {
          ...state.finalReportResult,
          isLoading: true,
          error: ""
        }
      };
    case GET_AUTO_RANGING_REPORT_SUCCESS:
      return {
        ...state,
        finalReportResult: {
          ...state.finalReportResult,
          data: action.payload,
          isLoading: false,
          error: ""
        }
      };
    case GET_AUTO_RANGING_REPORT_ERROR:
      return {
        ...state,
        finalReportResult: {
          ...state.finalReportResult,
          data: {},
          isLoading: false,
          error: action.error
        }
      };
    case ADD_AUTO_RANGING_USER_TRACKING_REQUEST:
      return {
        ...state,
        userTracking: {
          isLoading: true,
          error: ""
        }
      };
    case ADD_AUTO_RANGING_USER_TRACKING_SUCCESS:
      return {
        ...state,
        userTracking: {
          isLoading: false,
          error: ""
        }
      };
    case ADD_AUTO_RANGING_USER_TRACKING_ERROR:
      return {
        ...state,
        userTracking: {
          isLoading: false,
          error: action.error
        }
      };
    case GET_AUTO_RANGING_REPORT_CONSTRAINTS_REQUEST:
      return {
        ...state,
        editConstraints: {
          ...state.editConstraints,
          isLoading: true,
          error: ""
        }
      };
    case GET_AUTO_RANGING_REPORT_CONSTRAINTS_SUCCESS:
      return {
        ...state,
        editConstraints: {
          ...state.editConstraints,
          data: action.payload,
          isLoading: false,
          error: ""
        }
      };
    case GET_AUTO_RANGING_REPORT_CONSTRAINTS_ERROR:
      return {
        ...state,
        editConstraints: {
          ...state.editConstraints,
          data: {},
          isLoading: false,
          error: action.error
        }
      };

    case CLEAR_AUTO_RANGING_CONSTRAINTS:
      return {
        ...defaultState.autoRanging,
        staticData: state.staticData,
        finalReportResult: state.finalReportResult
      };

    case CLEAR_AUTO_RANGING_REPORTS:
      return {
        ...state,
        finalReportResult: defaultState.autoRanging.finalReportResult
      };

    case CLEAR_AUTO_RANGING_DATA:
      return { ...defaultState.autoRanging };

    default:
      return state;
  }
};

export default autoRanging;
