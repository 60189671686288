import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, List, ListItem } from "@mui/material";
import { uniqueId } from "lodash";
import ReportTypography from "../ReportTypography";

const showBulletedList = (textList, config) => {
  const { fontSize, fontFamily } = config;
  return (
    <List disablePadding sx={{ pl: 2, listStyleType: "disc" }}>
      {textList.map(i => (
        <ListItem
          disablePadding
          sx={{
            display: "list-item",
            fontSize: 0.875 * fontSize,
            color: "grey.500",
            fontFamily,
            pb: 0.25
          }}
          key={uniqueId()}
        >
          {i}
        </ListItem>
      ))}
    </List>
  );
};

const showSubsetDriver = (subset, config, isSmall) => (
  <List disablePadding sx={{ pl: 2, listStyleType: "disc" }}>
    {subset.text.map(i => {
      const { title, bullets } = i;
      return (
        <Box key={uniqueId()}>
          <ReportTypography
            fontSize={14}
            config={config}
            fontWeight={isSmall ? "normal" : "bold"}
          >
            {title}
          </ReportTypography>
          <Box sx={{ pl: 2 }}>{showBulletedList(bullets, config)}</Box>
        </Box>
      );
    })}
  </List>
);

const showStarUnderPerformers = (
  starsTitle,
  stars,
  underTitle,
  under,
  direction,
  config
) => {
  const starsSection = stars && starsTitle && (
    <Box
      sx={{
        bgcolor: "positive.light",
        border: 1,
        borderColor: "positive.main",
        borderRadius: 1,
        p: 1,
        mb: 1.5
      }}
    >
      <ReportTypography fontSize={14} config={config}>
        {starsTitle}
      </ReportTypography>
      {showBulletedList(stars.text, config)}
    </Box>
  );
  const underSection = under && underTitle && (
    <Box
      sx={{
        bgcolor: "negative.light",
        border: 1,
        borderColor: "negative.main",
        borderRadius: 1,
        p: 1,
        mb: 1.5
      }}
    >
      <ReportTypography fontSize={14} config={config}>
        {underTitle}
      </ReportTypography>
      {showBulletedList(under.text, config)}
    </Box>
  );
  if (direction === "Neg") {
    return (
      <Fragment>
        {underSection}
        {starsSection}
      </Fragment>
    );
  }
  return (
    <Fragment>
      {starsSection}
      {underSection}
    </Fragment>
  );
};

const Summary = props => {
  const { text, config } = props;
  const context = text.find(i => i.key === "context");
  const drivers = text.find(i => i.key === "drivers");
  const restrainers = text.find(i => i.key === "restrainers");
  const subsetTitle = text.find(i => i.key === "dynamic_executive_title");
  const winnersTitle = text.find(i => i.key === "winners_title");
  const winners = text.find(i => i.key === "winners");
  const losersTitle = text.find(i => i.key === "losers_title");
  const losers = text.find(i => i.key === "losers");
  const stars = text.find(i => i.key === "star_items");
  const starsTitle = text.find(i => i.key === "star_title");
  const under = text.find(i => i.key === "under_items");
  const underTitle = text.find(i => i.key === "under_title");
  const performersOrder = text.find(i => i.key === "sales_direction");
  return (
    <Box sx={{ height: 1, width: 1 }}>
      {context && (
        <Box sx={{ mb: 1.5 }}>{showBulletedList(context.text, config)}</Box>
      )}
      {drivers && (
        <Box
          sx={{
            bgcolor: "positive.light",
            border: 1,
            borderColor: "positive.main",
            borderRadius: 1,
            p: 1,
            mb: 1.5
          }}
        >
          <ReportTypography
            fontSize={14}
            fontWeight="bold"
            color="common.black"
            config={config}
            sx={{ px: 2 }}
          >
            {text.find(i => i.key === "drivers_title")?.text}
          </ReportTypography>
          {showBulletedList(drivers.text, config)}
        </Box>
      )}
      {restrainers && (
        <Box
          sx={{
            bgcolor: "negative.light",
            border: 1,
            borderColor: "negative.main",
            borderRadius: 1,
            p: 1,
            mb: 1.5
          }}
        >
          <ReportTypography
            fontSize={14}
            fontWeight="bold"
            color="common.black"
            config={config}
            sx={{ px: 2 }}
          >
            {text.find(i => i.key === "restrainers_title")?.text}
          </ReportTypography>
          {showBulletedList(restrainers.text, config)}
        </Box>
      )}
      {performersOrder &&
        showStarUnderPerformers(
          starsTitle?.text,
          stars,
          underTitle?.text,
          under,
          performersOrder?.text,
          config
        )}
      {winners && (
        <Box
          sx={
            subsetTitle
              ? {
                  bgcolor: "primaryArray.50",
                  border: 1,
                  borderColor: "primary.main",
                  borderRadius: 1,
                  p: 1,
                  mb: 1.5
                }
              : {}
          }
        >
          {subsetTitle && (
            <ReportTypography
              fontSize={14}
              fontWeight="bold"
              color="common.black"
              config={config}
              gutterBottom
            >
              {subsetTitle.text}
            </ReportTypography>
          )}
          <ReportTypography
            fontSize={14}
            config={config}
            fontWeight={subsetTitle ? "normal" : "bold"}
          >
            {winnersTitle.text}
          </ReportTypography>
          {winners.text.length > 0 &&
            showSubsetDriver(winners, config, subsetTitle)}
          <ReportTypography
            fontSize={14}
            config={config}
            sx={{ mt: 1 }}
            fontWeight={subsetTitle ? "normal" : "bold"}
          >
            {losersTitle.text}
          </ReportTypography>
          {losers.text.length > 0 &&
            showSubsetDriver(losers, config, subsetTitle)}
        </Box>
      )}
    </Box>
  );
};

Summary.propTypes = {
  text: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape()
};

Summary.defaultProps = {
  text: [],
  config: {}
};

export default Summary;
