import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { SelectAutocomplete, Table } from "components/BaseComponents";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";
import { getProductAttributes } from "utils";
import { PageBar, DownloadData } from "./Components";

const getBayPlans = (bayPlanChanges, selectedBayPlans) => {
  if (selectedBayPlans.length === 0) return bayPlanChanges;
  const result = {};
  selectedBayPlans.forEach(bay => {
    result[bay] = bayPlanChanges[bay];
  });
  return result;
};

const BayPlanChanges = props => {
  const { report } = props;
  const [searchText, setSearchText] = useState("");
  const [selectedBayPlans, setSelectedBayPlans] = useState([]);

  const {
    bay_plan_changes: bayPlanChanges = {},
    comparison_data: comparisonData = [],
    tag2sku = {},
    tag2attribute = {},
    header
  } = report;

  const allBayPlans = Object.keys(bayPlanChanges);

  const bayPlans = getBayPlans(bayPlanChanges, selectedBayPlans);

  const searchResult = {};
  Object.entries(bayPlans).forEach(([key, value]) => {
    searchResult[key] = {
      skus_in: value.skus_in.filter(i =>
        tag2sku[i].toLowerCase().includes(searchText)
      ),
      skus_out: value.skus_out.filter(i =>
        tag2sku[i].toLowerCase().includes(searchText)
      )
    };
  });

  const downloadData = comparisonData.map(i => ({
    SKU: tag2sku[i.tag],
    ...getProductAttributes(i.tag, tag2attribute),
    "Bay Name Before": i.bay_name_before,
    "Bay Name After": i.bay_name_after
  }));

  return (
    <Fragment>
      <PageBar
        isSearch
        searchText={searchText}
        setSearchText={setSearchText}
        leftChild={
          <SelectAutocomplete
            label="Select Bay Plan"
            styles={{ width: 500 }}
            fullWidth
            value={selectedBayPlans}
            onChange={(e, value) => setSelectedBayPlans(value)}
            options={allBayPlans}
            multiple
            filterSelectedOptions
          />
        }
        rightChild={
          <DownloadData
            data={downloadData}
            header={header}
            section="Bay Plan Changes Tab"
          />
        }
      />
      {Object.entries(searchResult)
        .filter(
          ([key, value]) =>
            key && (value.skus_in.length > 0 || value.skus_out.length > 0)
        )
        .map(([key, value]) => {
          const max = Math.max(value.skus_in.length, value.skus_out.length);
          const rows = [];
          for (let i = 0; i < max; i += 1) {
            if (value.skus_in[i] || value.skus_out[i]) {
              rows.push({
                in: value.skus_in[i] ? tag2sku[value.skus_in[i]] : "",
                out: value.skus_out[i] ? tag2sku[value.skus_out[i]] : ""
              });
            }
          }
          return (
            <Table
              key={key}
              columns={[
                {
                  label: (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={0.5}
                    >
                      <Typography
                        variant="body2"
                        fontWeight="medium"
                        color="positive.main"
                      >
                        SKUs In
                      </Typography>
                      <ArrowUpIcon fontSize="small" color="positive" />
                    </Stack>
                  ),
                  renderCell: item => item.in,
                  styles: { width: 0.5, borderTop: 1 },
                  cellStyles: { bgcolor: "positiveArray.50" }
                },
                {
                  label: (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={0.5}
                    >
                      <Typography
                        variant="body2"
                        fontWeight="medium"
                        color="negative.main"
                      >
                        SKUs Out
                      </Typography>
                      <ArrowDownIcon fontSize="small" color="negative" />
                    </Stack>
                  ),
                  renderCell: item => item.out,
                  styles: { width: 0.5, borderTop: 1 },
                  cellStyles: { bgcolor: "negativeArray.50" }
                }
              ]}
              rows={rows}
              styles={{
                root: { my: 2 },
                row: { "& .MuiTableCell-head": { py: 1.25 } },
                cell: { textAlign: "left" }
              }}
              multipleHeader
              header={[
                {
                  label: `SKUs in and out of ${key}`,
                  colSpan: 2,
                  styles: { textAlign: "center" }
                }
              ]}
            />
          );
        })}
    </Fragment>
  );
};

BayPlanChanges.propTypes = {
  report: PropTypes.shape()
};

BayPlanChanges.defaultProps = {
  report: {}
};

export default BayPlanChanges;
