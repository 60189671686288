import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Skeleton, Stack, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";
import { getStreamlitUrl } from "actions/streamlit";
import { error } from "images";
import Feedback from "./Feedback";

const StreamlitTool = props => {
  const {
    dispatchFetchStreamlitUrl,
    url,
    isLoading,
    userId,
    client,
    prototypes
  } = props;
  const location = useLocation();
  const { type } = useParams();
  const queryString = location.search;
  const isFromReport = !!queryString;
  const iframeUrl = isFromReport
    ? `${url}&embed=true&${queryString.slice(1)}`
    : `${url}&embed=true`;

  useEffect(() => {
    dispatchFetchStreamlitUrl(type, userId, client, isFromReport);
  }, [type]);

  useEffect(() => {
    const matchingPrototype = prototypes.find(
      prototype => prototype.service_name === type
    );
    if (matchingPrototype) {
      const { display_name: prototypeTitle } = matchingPrototype;
      document.title = `dijuno: ${prototypeTitle}`;
    }
    return () => {
      document.title = "dijuno";
    };
  }, [type, prototypes]);

  if (isLoading) {
    return <Skeleton height="calc(100vh - 200px)" variant="rounded" />;
  }

  if (!url) {
    return (
      <Paper styles={{ height: "calc(100vh - 200px)" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ height: 1 }}
        >
          <img style={{ width: 200 }} alt="error" src={error} />
          <Typography variant="h3">Nothing here yet</Typography>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper styles={{ p: 0, height: "calc(100vh - 200px)" }}>
      <iframe
        src={iframeUrl}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          borderRadius: 5
        }}
        title={type}
      />
      <Feedback component="prototype" />
    </Paper>
  );
};

StreamlitTool.propTypes = {
  dispatchFetchStreamlitUrl: PropTypes.func,
  url: PropTypes.string,
  isLoading: PropTypes.bool,
  userId: PropTypes.string,
  client: PropTypes.string,
  prototypes: PropTypes.arrayOf(PropTypes.shape())
};

StreamlitTool.defaultProps = {
  dispatchFetchStreamlitUrl: () => {},
  url: "",
  isLoading: false,
  userId: "",
  client: "",
  prototypes: []
};

const mapStateToProps = state => ({
  url: state.streamlit.url,
  isLoading: state.streamlit.isLoading,
  userId: state.user.user.id,
  client: state.user.user.client,
  prototypes: state.streamlit.prototypes
});

const mapDispatchToProps = dispatch => ({
  dispatchFetchStreamlitUrl: (type, userId, client, isFromReport) =>
    dispatch(getStreamlitUrl(type, userId, client, isFromReport))
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamlitTool);
