import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography, Link, Button } from "@mui/material";
import {
  BrandBattlesIcon,
  NegativeIcon,
  NeutralIcon,
  PerformanceIcon,
  TrendIcon,
  PositiveIcon,
  DownIcon
} from "components/Icons";
import { Menu } from "components/BaseComponents";
import { getLookoutDate } from "utils";
import { clientConfig } from "config";
import { storyEnums } from "constants/enums";
import { REPORT_COLORS } from "theme";
import TrendGraph from "./TrendGraph";

const getColor = (isPositive, isNegative) => {
  if (isPositive) return "positive.main";
  if (isNegative) return "negative.main";
  return "neutral.main";
};

const getTrendColor = (isPositive, isNegative) => {
  if (isPositive) return "positive.light";
  if (isNegative) return "negative.light";
  return "neutral.light";
};

const getArrow = (isPositive, isNegative) => {
  if (isPositive) return <PositiveIcon color="positive" fontSize="small" />;
  if (isNegative) return <NegativeIcon color="negative" fontSize="small" />;
  return <NeutralIcon color="neutral" fontSize="small" />;
};

const styles = (isPositive, isNegative) => ({
  root: {
    flex: 1,
    height: 1,
    bgcolor: "white",
    border: 1,
    borderColor: "grey.200",
    borderTop: 2,
    borderTopColor: getColor(isPositive, isNegative),
    borderRadius: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    p: 1.5,
    borderBottom: "none"
  },
  trendRoot: { bgcolor: getTrendColor(isPositive, isNegative), p: 1, mt: 2 }
});

const InsightCard = props => {
  const { insight, dataDate, isMonth, client } = props;
  const {
    extended_analysis_title: title,
    extended_analysis_title_arrow_positive: isPositive,
    extended_analysis_title_arrow_negative: isNegative,
    top_analysis: topAnalysis,
    child_analysis_text: childAnalysis,
    competitor_analysis_text: competitorText,
    product_key: productName,
    report_link: reportLink,
    competitor_analysis_competitor: competitorName = "Competitor",
    competitor_analysis_link_bb: competitorAnalysisBBLink,
    competitor_analysis_link_performance: competitorAnalysisPerformanceLink,
    trend_analysis_title: trendAnalysisTitle,
    trend_analysis_heading: trendAnalysisHeading,
    trend_analysis_trend_class: trendAnalysisClass,
    trend_analysis_trend_x: xTrend,
    trend_analysis_trend_y: yTrend,
    trend_link: trendLink
  } = insight || {};
  const { stories } = clientConfig[client];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const isTrendPositive = trendAnalysisClass === "up";
  const isTrendNegative = trendAnalysisClass === "down";

  const linkItems = [
    {
      path: reportLink,
      icon: (
        <PerformanceIcon
          sx={{ color: REPORT_COLORS.performance, fontSize: 15 }}
        />
      ),
      title: `${productName} Performance Report`,
      isVisible: reportLink && stories.includes(storyEnums.IDA)
    },
    {
      path: competitorAnalysisPerformanceLink,
      icon: (
        <PerformanceIcon
          sx={{ color: REPORT_COLORS.performance, fontSize: 15 }}
        />
      ),
      title: `${competitorName} Performance Report`,
      isVisible:
        competitorAnalysisPerformanceLink && stories.includes(storyEnums.IDA)
    },
    {
      path: competitorAnalysisBBLink,
      icon: (
        <BrandBattlesIcon
          sx={{ color: REPORT_COLORS.brandBattles, fontSize: 15 }}
        />
      ),
      title: `${productName} vs ${competitorName} Brand Battles`,
      isVisible: competitorAnalysisBBLink && stories.includes(storyEnums.BB)
    },
    {
      path: trendLink,
      icon: <TrendIcon sx={{ color: REPORT_COLORS.trend, fontSize: 15 }} />,
      title: `${productName} Trend Report`,
      isVisible: trendLink && stories.includes(storyEnums.TREND)
    }
  ].filter(i => i.isVisible);

  return (
    <Stack sx={{ height: 1 }}>
      <Stack sx={styles(isPositive, isNegative).root}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1.5}
          sx={{ mb: 0.5 }}
        >
          {getArrow(isPositive, isNegative)}
          <Link
            underline="none"
            target="_blank"
            href={reportLink}
            variant="subtitle2"
            color={getColor(isPositive, isNegative)}
            sx={{ pointerEvents: reportLink ? "" : "none" }}
          >
            {title}
          </Link>
        </Stack>
        <Typography
          variant="body2"
          fontWeight="bold"
          color="grey.400"
          sx={{ mb: 2 }}
        >
          {`${isMonth ? "L1M" : "L4W"} (${getLookoutDate(dataDate, isMonth)})`}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          color="grey.400"
          sx={{ mb: 0.5 }}
        >
          What Happened?
        </Typography>
        <Typography variant="body2" color="grey.600" sx={{ mb: 1.5 }}>
          {topAnalysis} <br /> {childAnalysis}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          color="grey.400"
          sx={{ mb: 0.5 }}
        >
          Competitors
        </Typography>
        <Typography variant="body2" color="grey.600" sx={{ flex: 1, m: 0 }}>
          {competitorText}
        </Typography>
        {trendAnalysisTitle && stories.includes(storyEnums.TREND) && (
          <Box sx={styles(isTrendPositive, isTrendNegative).trendRoot}>
            <Link
              underline="none"
              target="_blank"
              href={trendLink}
              variant="body2"
              fontWeight="bold"
              color="grey.800"
              sx={{
                display: "block",
                mb: 0.5,
                pointerEvents: trendLink ? "" : "none"
              }}
            >
              {trendAnalysisTitle}
            </Link>
            <Typography variant="body2" color="grey.600" sx={{ mb: 1.5 }}>
              {trendAnalysisHeading}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Stack alignItems="center" spacing={1}>
                <Typography
                  variant="body2"
                  fontWeight="medium"
                  color="grey.800"
                >
                  Trends Shape
                </Typography>
                <Box sx={{ height: 1, width: 1 }}>
                  <TrendGraph
                    data={{ x: yTrend, y: xTrend }} // xTrend and yTrend seem to be switched
                    isPositive={isTrendPositive}
                    isNegative={isTrendNegative}
                  />
                </Box>
              </Stack>
              <Stack alignItems="center" spacing={1}>
                <Typography
                  variant="body2"
                  fontWeight="medium"
                  color="grey.800"
                >
                  Impact
                </Typography>
                {getArrow(isTrendPositive, isTrendNegative)}
              </Stack>
            </Stack>
          </Box>
        )}
      </Stack>
      <Button
        variant="outlined"
        endIcon={<DownIcon sx={{ p: 0.25 }} />}
        onClick={handleClick}
        fullWidth
        color="primary"
        sx={{
          fontWeight: "normal",
          bgcolor: "primaryArray.50",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTopColor: open && "primary.main",
          "&:hover": { bgcolor: "primaryArray.50", borderColor: "grey.200" }
        }}
      >
        Generate Related Report
      </Button>
      <Menu
        menuItems={linkItems}
        anchorEl={anchorEl}
        handleClose={handleClose}
        fullWidth
        newTab
      />
    </Stack>
  );
};

InsightCard.propTypes = {
  insight: PropTypes.shape(),
  dataDate: PropTypes.string,
  isMonth: PropTypes.bool,
  client: PropTypes.string
};

InsightCard.defaultProps = {
  insight: {},
  dataDate: "",
  isMonth: false,
  client: ""
};

export default InsightCard;
