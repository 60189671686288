import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  Navigate,
  generatePath
} from "react-router-dom";
import { Collapse } from "@mui/material";
import { reportConstraints } from "actions";
import {
  NEW_REPORT_TYPE_ROUTE,
  NEW_REPORT_FOCUS_TYPE_ROUTE
} from "constants/viewRoutes";
import { NavigateStepButtons, SectionTitle } from "components/CustomComponents";
import { Paper, SelectAutocomplete } from "components/BaseComponents";
import CustomDate from "components/CustomDate";

const Market = props => {
  const {
    story,
    subsections,
    isLoading,
    dispatchReportConstraints,
    dataDate,
    product,
    context,
    setActiveStep,
    retailer,
    period,
    metric,
    reportType
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { channel, focusType } = params;
  const [isCustomDate, setIsCustomDate] = useState(period?.period === "custom");

  useEffect(() => {
    setActiveStep(1);
  }, []);

  const marketOptions = subsections.find(
    item => item.name === "retailer"
  )?.keywords;

  const periodOptions = [
    ...(subsections.find(item => item.table === "when")?.keywords || []).map(
      i => ({
        date: i.date,
        label: `${i.period} ${i.date}`,
        period: i.period,
        value: i.period
      })
    ),
    { period: "custom", label: "Choose range on calendar" }
  ];

  const metricOptions = subsections.find(
    item => item.table === "measure"
  )?.keywords;

  const handleChange = (type, value) =>
    dispatchReportConstraints(type, value, story);

  const handlePeriodChange = value => {
    handleChange("period", value);
    setIsCustomDate(value?.period === "custom");
  };

  const handleCustomPeriod = value =>
    dispatchReportConstraints("period", value, story);

  if (product.length === 0 || context.length === 0) {
    return (
      <Navigate
        to={
          focusType
            ? generatePath(NEW_REPORT_FOCUS_TYPE_ROUTE, {
                reportType,
                focusType,
                channel,
                step: "product"
              })
            : generatePath(NEW_REPORT_TYPE_ROUTE, {
                reportType,
                channel,
                step: "product"
              })
        }
      />
    );
  }

  const handleBackClick = () => {
    const path = focusType
      ? generatePath(NEW_REPORT_FOCUS_TYPE_ROUTE, {
          reportType,
          focusType,
          channel,
          step: "product"
        })
      : generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "product"
        });
    navigate(path);
  };

  const handleNextClick = () => {
    const path = focusType
      ? generatePath(NEW_REPORT_FOCUS_TYPE_ROUTE, {
          reportType,
          focusType,
          channel,
          step: "review"
        })
      : generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "review"
        });
    navigate(path);
  };

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="3"
          title="Analysis Market"
          subtitle="Select your market."
        />
        <SelectAutocomplete
          label="Market"
          placeholder="Market"
          fullWidth
          styles={{ maxWidth: 400 }}
          options={marketOptions}
          value={retailer}
          onChange={(e, value) => handleChange("retailer", value)}
          isLoading={isLoading}
        />
      </Paper>
      <Paper>
        <SectionTitle
          order="4"
          title="Time Period"
          subtitle="Select your desired time frame."
        />
        <SelectAutocomplete
          label="Time Frame"
          placeholder="Time Frame"
          fullWidth
          styles={{ maxWidth: 400 }}
          options={periodOptions}
          value={period}
          onChange={(e, value) => handlePeriodChange(value)}
          isLoading={isLoading}
          isOptionEqualToValue={(option, value) =>
            option.period === value.period
          }
        />
        <Collapse
          in={isCustomDate}
          mountOnEnter
          unmountOnExit
          sx={{ mt: 3, maxWidth: 400 }}
        >
          <CustomDate
            story={story}
            selectedPeriod={
              period && period.period === "custom" && period.name
                ? period
                : undefined
            }
            dataDate={dataDate}
            isMonth={story === "cga"}
            onChange={value =>
              handleCustomPeriod({
                ...value,
                period: "custom",
                label: "Choose range on calendar"
              })
            }
          />
        </Collapse>
      </Paper>
      <Paper>
        <SectionTitle
          order="5"
          title="Analysis Metric"
          subtitle="Select your desired analysis metric."
        />
        <SelectAutocomplete
          label="Metric"
          placeholder="Metric"
          fullWidth
          styles={{ maxWidth: 400 }}
          options={metricOptions}
          value={metric}
          onChange={(e, value) => handleChange("metric", value)}
          isLoading={isLoading}
        />
      </Paper>
      <NavigateStepButtons
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        nextDisabled={!retailer || !metric || !period}
      />
    </Fragment>
  );
};

Market.propTypes = {
  story: PropTypes.string,
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  dispatchReportConstraints: PropTypes.func,
  dataDate: PropTypes.string,
  product: PropTypes.arrayOf(PropTypes.shape()),
  context: PropTypes.arrayOf(PropTypes.shape()),
  setActiveStep: PropTypes.func,
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  metric: PropTypes.string,
  reportType: PropTypes.string
};

Market.defaultProps = {
  story: "",
  subsections: [],
  isLoading: false,
  dispatchReportConstraints: () => {},
  dataDate: "",
  product: [],
  context: [],
  setActiveStep: () => {},
  retailer: null,
  period: null,
  metric: null,
  reportType: ""
};

const mapStateToProps = (state, ownProps) => {
  const { dataSet } = ownProps;
  const {
    data: { subsections = { dataSet: [] }, date = { dataSet: "" } }
  } = state;
  return {
    isLoading: state.data.isFetchingData,
    dataDate: date[dataSet],
    subsections: subsections[dataSet]
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story))
});

export default connect(mapStateToProps, mapDispatchToProps)(Market);
