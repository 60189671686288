import React from "react";
import PropTypes from "prop-types";
import { format, differenceInWeeks, differenceInMonths } from "date-fns";
import { Stack, Typography } from "@mui/material";
import {
  getWeekStart,
  getWeekEnd,
  getMonthStart,
  getMonthEnd,
  getDateFromPeriod
} from "utils";
import { storyEnums } from "constants/enums";
import { getStoryConfig } from "config";

const TimePeriod = props => {
  const { period, dataDate, story } = props;
  const { dataSet } = getStoryConfig(story);
  const isCustomDate = period.split(",").length === 4;
  let mainStart;
  let mainEnd;
  let compareStart;
  let compareEnd;
  let periodLength;
  const isMonth = story === storyEnums.CGA;
  if (isCustomDate) {
    [compareStart, compareEnd, mainStart, mainEnd] = period
      .split(",")
      .map((i, k) => {
        if (isMonth) {
          return k % 2 === 0 ? getMonthStart(i) : getMonthEnd(i);
        }
        return k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i);
      });
    periodLength = isMonth
      ? differenceInMonths(mainEnd, mainStart) + 1
      : differenceInWeeks(mainEnd, mainStart) + 1;
  } else {
    [compareStart, compareEnd, mainStart, mainEnd] = getDateFromPeriod(
      period,
      dataDate[dataSet],
      isMonth
    );
  }
  return (
    <Stack alignItems="center">
      <Typography variant="caption" gutterBottom>
        {isCustomDate
          ? `${periodLength} ${isMonth ? "months" : "weeks"}`
          : period}
      </Typography>
      <Typography variant="caption" fontSize={10} color="grey.500" noWrap>
        Period:&nbsp;&nbsp;{format(mainStart, "dd/MM/yy")}&nbsp;-&nbsp;
        {format(mainEnd, "dd/MM/yy")}
      </Typography>
      <Typography variant="caption" fontSize={10} color="grey.500" noWrap>
        Comp:&nbsp;&nbsp;{format(compareStart, "dd/MM/yy")}&nbsp;-&nbsp;
        {format(compareEnd, "dd/MM/yy")}
      </Typography>
    </Stack>
  );
};

TimePeriod.propTypes = {
  period: PropTypes.string,
  dataDate: PropTypes.shape(),
  story: PropTypes.string
};

TimePeriod.defaultProps = {
  period: "",
  dataDate: {},
  story: ""
};

export default TimePeriod;
