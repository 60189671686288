import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";

const Legend = props => {
  const { items, styles } = props;
  return (
    <Stack direction="row" spacing={1} sx={styles}>
      {items.map(({ label, color, multiple, colors }) => (
        <Stack key={label} direction="row" spacing={0.5} alignItems="center">
          {multiple ? (
            <Stack
              direction="row"
              sx={{
                width: 16,
                height: 16,
                borderRadius: "50%",
                overflow: "hidden"
              }}
            >
              {colors.map(i => (
                <Box
                  key={i}
                  sx={{ width: 16 / colors.length, height: 16, bgcolor: i }}
                />
              ))}
            </Stack>
          ) : (
            <Box
              sx={{
                width: 16,
                height: 16,
                bgcolor: color,
                borderRadius: "50%"
              }}
            />
          )}
          <Typography variant="body2" color="grey.500">
            {label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

Legend.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      multiple: PropTypes.bool,
      colors: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  styles: PropTypes.shape()
};

Legend.defaultProps = {
  items: [],
  styles: {}
};

export default Legend;
