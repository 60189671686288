import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  Navigate,
  generatePath
} from "react-router-dom";
import { Grid } from "@mui/material";
import { NEW_REPORT_TYPE_ROUTE, VIEW_REPORT_ROUTE } from "constants/viewRoutes";
import {
  ExpectedOutput,
  NavigateStepButtons,
  ReviewCard
} from "components/CustomComponents";
import {
  getDateLabel,
  buildQueryString,
  getImplementationDateLabel
} from "utils";
import { clearStoryConstraints, clearStorySearchData } from "actions";

const Review = props => {
  const {
    story,
    setActiveStep,
    temperature,
    consumptionType,
    category,
    sector,
    retailer,
    period,
    implementationDate,
    hier1,
    hier2,
    metric,
    dispachClearStory,
    reportType
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { channel } = params;

  useEffect(() => {
    setActiveStep(3);
  }, []);

  const goToProduct = () =>
    navigate(
      generatePath(NEW_REPORT_TYPE_ROUTE, {
        reportType,
        channel,
        step: "product"
      })
    );

  const goToPeriod = () =>
    navigate(
      generatePath(NEW_REPORT_TYPE_ROUTE, {
        reportType,
        channel,
        step: "period"
      })
    );

  const goToHierarchy = () =>
    navigate(
      generatePath(NEW_REPORT_TYPE_ROUTE, {
        reportType,
        channel,
        step: "hierarchy"
      })
    );

  const getSelectedDateLabel = date => {
    const dateString = getDateLabel(date.name, story).split("Vs");
    return [`${dateString[0]} Vs`, dateString[1]];
  };

  if (!temperature || !consumptionType || !retailer) {
    return (
      <Navigate
        to={generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "product"
        })}
      />
    );
  }

  if (!period) {
    return (
      <Navigate
        to={generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "period"
        })}
      />
    );
  }

  if (!hier1 || !metric) {
    return (
      <Navigate
        to={generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "hierarchy"
        })}
      />
    );
  }

  const onNextClick = () => {
    const constraints = [
      { name: temperature, subsection: "temperature" },
      { name: consumptionType, subsection: "consumption_type" },
      { name: retailer, subsection: "retailer" },
      { name: period.name, subsection: "period" },
      { name: hier1.name, subsection: "hier1" },
      { name: metric, subsection: "metric_name" }
    ];
    if (category) constraints.push({ name: category, subsection: "category" });
    if (sector) constraints.push({ name: sector, subsection: "sector" });
    if (implementationDate)
      constraints.push({
        name: implementationDate,
        subsection: "implementation_date"
      });
    if (hier2) constraints.push({ name: hier2.name, subsection: "hier2" });
    dispachClearStory(story);
    navigate({
      pathname: VIEW_REPORT_ROUTE,
      search: `story=${story.toUpperCase()}&${buildQueryString(constraints)}`
    });
  };

  return (
    <Fragment>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Product Filter"
            selected={[
              temperature.toUpperCase(),
              consumptionType.toUpperCase()
            ]}
            onEdit={goToProduct}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Product"
            selected={[
              category ? `Category: ${category}` : "All Categories",
              sector ? `Sector: ${sector}` : "All Sectors Within Category"
            ]}
            onEdit={goToProduct}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Analysis Market"
            selected={[retailer]}
            onEdit={goToProduct}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReviewCard
            label="Time Frame"
            selected={getSelectedDateLabel(period)}
            onEdit={goToPeriod}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReviewCard
            label="Implementation Date"
            selected={
              implementationDate
                ? [getImplementationDateLabel(implementationDate)]
                : ["Default: Start of Post Review Period"]
            }
            onEdit={goToPeriod}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReviewCard
            label="Result Hierarchy"
            selected={
              hier2
                ? [
                    `First Split: ${hier1.label}`,
                    `Second Split: ${hier2.label}`
                  ]
                : [`First Split: ${hier1.label}`]
            }
            onEdit={goToHierarchy}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReviewCard
            label="Analysis Metric"
            selected={[metric]}
            onEdit={goToHierarchy}
          />
        </Grid>
      </Grid>
      <ExpectedOutput story={story} />
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "hierarchy"
            })
          )
        }
        onNextClick={onNextClick}
        nextLabel="Run Report"
      />
    </Fragment>
  );
};

Review.propTypes = {
  story: PropTypes.string,
  temperature: PropTypes.string,
  consumptionType: PropTypes.string,
  category: PropTypes.string,
  sector: PropTypes.string,
  setActiveStep: PropTypes.func,
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  implementationDate: PropTypes.string,
  hier1: PropTypes.shape(),
  hier2: PropTypes.shape(),
  metric: PropTypes.string,
  dispachClearStory: PropTypes.func,
  reportType: PropTypes.string
};

Review.defaultProps = {
  story: "",
  temperature: null,
  consumptionType: null,
  category: null,
  sector: null,
  setActiveStep: () => {},
  retailer: null,
  period: null,
  implementationDate: null,
  hier1: null,
  hier2: null,
  metric: null,
  dispachClearStory: () => {},
  reportType: ""
};

const mapDispatchToProps = dispatch => ({
  dispachClearStory: story => {
    dispatch(clearStoryConstraints(story));
    dispatch(clearStorySearchData(story));
  }
});

export default connect(null, mapDispatchToProps)(Review);
