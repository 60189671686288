import React from "react";
import PropTypes from "prop-types";
import plotComponentFactory from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";

const Plot = plotComponentFactory(Plotly);

const PlotlyChart = props => {
  const { data, layout, config } = props;
  return (
    <Plot
      data={data}
      layout={{
        autosize: true,
        hovermode: false,
        paper_bgcolor: "transparent",
        plot_bgcolor: "transparent",
        margin: { t: 40, b: 40, r: 40, l: 40 },
        ...layout
      }}
      style={{ height: "100%", width: "100%" }}
      config={{
        responsive: true,
        modeBarButtonsToRemove: [
          "select2d",
          "lasso2d",
          "zoom2d",
          "pan2d",
          "zoomIn2d",
          "zoomOut2d",
          "autoScale2d"
        ],
        displaylogo: false,
        locales: { en: { format: { currency: ["£", ""] } } },
        ...config
      }}
    />
  );
};

PlotlyChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  layout: PropTypes.shape(),
  config: PropTypes.shape()
};

PlotlyChart.defaultProps = {
  data: [],
  layout: {},
  config: {}
};

export default PlotlyChart;
