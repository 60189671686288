import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Collapse,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack
} from "@mui/material";
import { CloseIcon, TickCircleIcon } from "components/Icons";
import { SelectAutocomplete, TextField } from "components/BaseComponents";
import { getDepartments, addDepartment, editUserAccount } from "actions";

const filter = createFilterOptions();

const EditUser = props => {
  const {
    submitEditUser,
    isLoading,
    client,
    departments,
    getDepts,
    addDept,
    isOpen,
    onClose,
    user
  } = props;
  const {
    email: userEmail,
    user_id: userId,
    name: userName,
    department: userDept
  } = user;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");

  const isDijuno =
    email.includes("@interrodata.com") || email.includes("@dijuno.ai");

  useEffect(() => {
    if (isOpen) {
      getDepts(client);
    }
    setEmail(userEmail);
    setName(userName);
    setDepartment(userDept);
  }, []);

  const reset = () => {
    setEmail("");
    setName("");
    setDepartment("");
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ color: "grey.900", fontSize: 18, fontWeight: "medium" }}
      >
        Edit User
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={2} sx={{ maxWidth: 400 }}>
          <TextField
            label="Email"
            placeholder="Email"
            value={email}
            disabled
            type="email"
            fullWidth
          />
          <TextField
            label="Name"
            placeholder="Name"
            value={name}
            onChange={e => setName(e.target.value)}
            disabled={isLoading}
            fullWidth
          />
          <Collapse in={!isDijuno}>
            <SelectAutocomplete
              label="Department"
              placeholder="Department"
              value={department}
              freeSolo
              fullWidth
              onChange={(e, newValue) => {
                if (typeof newValue === "string") {
                  setDepartment(newValue);
                } else if (newValue && newValue.newOption) {
                  // Create a new value from the user input
                  setDepartment(newValue.newOption);
                  addDept(newValue.newOption, client);
                } else {
                  setDepartment(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    newOption: params.inputValue,
                    label: `Add "${params.inputValue}"`
                  });
                }
                return filtered;
              }}
              getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.newOption) {
                  return option.label;
                }
                // Regular option
                return option;
              }}
              options={departments}
              disabled={isLoading}
            />
          </Collapse>
        </Stack>
        <Button
          variant="contained"
          endIcon={<TickCircleIcon />}
          disabled={!userId || !name || !email || (!isDijuno && !department)}
          onClick={() => submitEditUser(userId, name, email, department, reset)}
          sx={{ mt: 3 }}
        >
          Edit Account
        </Button>
      </DialogContent>
    </Dialog>
  );
};

EditUser.propTypes = {
  submitEditUser: PropTypes.func,
  isLoading: PropTypes.bool,
  client: PropTypes.string,
  departments: PropTypes.arrayOf(PropTypes.string),
  getDepts: PropTypes.func,
  addDept: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.shape()
};

EditUser.defaultProps = {
  submitEditUser: () => {},
  isLoading: false,
  client: "",
  departments: [],
  getDepts: () => {},
  addDept: () => {},
  isOpen: false,
  onClose: () => {},
  user: {}
};

const mapStateToProps = state => ({
  departments: state.user.departments
});

const mapDispatchToProps = dispatch => ({
  submitEditUser: (userId, name, email, department, onSuccess) =>
    dispatch(editUserAccount(userId, name, email, department, onSuccess)),
  getDepts: client => dispatch(getDepartments(client)),
  addDept: (dept, client) => dispatch(addDepartment(dept, client))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
