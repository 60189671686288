import React, { useState } from "react";
import { InputAdornment, IconButton } from "@mui/material";
import { HidePasswordIcon, ShowPasswordIcon } from "components/Icons";
import TextField from "./TextField";

const PasswordField = props => {
  const { ...otherProps } = props;
  const [showPassword, setShowPassword] = useState(false);
  const { InputProps, ...others } = otherProps;
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={e => e.preventDefault()}
              edge="end"
            >
              {showPassword ? (
                <HidePasswordIcon fontSize="small" />
              ) : (
                <ShowPasswordIcon fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps
      }}
      {...others}
    />
  );
};

export default PasswordField;
