import React from "react";
import PropTypes from "prop-types";
import { FormControl as MUIFormControl, InputLabel } from "@mui/material";
import InfoText from "../InfoText";

const FormControl = props => {
  const { label, fullWidth, styles, infoText, error, required, children } =
    props;
  return (
    <MUIFormControl fullWidth={fullWidth} variant="standard" sx={styles}>
      <InputLabel
        shrink
        sx={{ position: "static", color: "grey.800" }}
        required={required}
      >
        {label}
      </InputLabel>
      {children}
      {error && <InfoText level="error" text={error} styles={{ my: 0.5 }} />}
      {infoText && (
        <InfoText
          level="info"
          text={infoText}
          variant="outlined"
          styles={{ my: 0.5 }}
        />
      )}
    </MUIFormControl>
  );
};

FormControl.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  styles: PropTypes.shape(),
  required: PropTypes.bool,
  infoText: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node.isRequired
};

FormControl.defaultProps = {
  label: "",
  fullWidth: false,
  styles: {},
  required: false,
  infoText: "",
  error: ""
};

export default FormControl;
