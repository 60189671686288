import _ from "lodash";
import Fuse from "fuse.js";

export * from "./dateUtils";
export * from "./helperFunctions";
export * from "./reportUtils";

/**
 * Initialises search engine with Fuse
 */
export const initSearch = (searchOptions, keys, fuseOptions = {}) =>
  new Fuse(searchOptions, {
    keys,
    useExtendedSearch: true,
    threshold: 0.4,
    ignoreLocation: true,
    ...fuseOptions
  });

/**
 * Get option label for MUI Autocomplete
 */
export const getOptionLabel = option => {
  if (_.isEmpty(option)) return "";
  return option.name
    ? (option.name.value && `${option.name.value} (${option.subsection})`) ||
        `${option.name} (${
          option.subsection.includes("=")
            ? option.subsection.split("=")[1]
            : option.subsection
        })`
    : option.label;
};

/**
 * Get option from individual item (with name and subsection)
 * for search bar and advanced search
 */
export const getSearchOption = (item, table, story) => {
  if (!item || _.isEmpty(item)) return null;
  return {
    label: getOptionLabel(item),
    value: typeof item.name === "string" ? item.name : item.name?.value,
    name: item.name,
    subsection: item.subsection,
    apiValue: {
      name: typeof item.name === "string" ? item.name : item.name?.value,
      subsection: item.subsection,
      table,
      story
    }
  };
};

/**
 * Get options from list of items (with names and subsections)
 * for search bar and advanced search
 */
export const getSearchOptions = (items, table, story) =>
  items.map(i => getSearchOption(i, table, story)).filter(i => i);

/**
 * Create api value for items with prefix in subsection
 * e.g. product a and product b for brand battles
 */
export const updateAPIValue = (item, prefix) => {
  if (!item) return null;
  return {
    ...item,
    apiValue: { ...item.apiValue, subsection: `${prefix}=${item.subsection}` }
  };
};

/**
 * helper function for formatting values
 */
const getSign = (value, showAbsoluteSign = false) =>
  (value < 0 && "-") || (showAbsoluteSign ? "+" : "");

/**
 * Format values by scaling and adding sign and absolute sign as required
 * @param  {String} value                Value to be formatted
 * @param  {Boolean} showSign            If should show sign if negative (generally true)
 * @param  {Boolean} showAbsoluteSign    If should show absolute sign (+ or -)
 * @return {String}                      Formatted value
 */
export const formatValue = (
  value,
  showSign = true,
  showAbsoluteSign = false
) => {
  // change to absolute value and change tiny values to zero
  if (!value && value !== 0) return "";
  // const absValue = Math.abs(value) < 0.01 ? 0 : Math.abs(value);
  const absValue = Math.abs(value);
  const sign = showSign ? getSign(value, showAbsoluteSign) : "";
  switch (true) {
    case absValue >= 1.0e6:
      return `${sign}${(absValue / 1.0e6).toFixed(1)}m`;
    case absValue >= 1.0e3:
      return `${sign}${(absValue / 1.0e3).toFixed(1)}k`;
    case absValue < 0.01:
      return `${sign}0`;
    default:
      return `${sign}${absValue.toFixed(1)}`;
  }
};

/**
 * Format absolute values with scaling and + or - sign
 * @param {String} value    Value to be formatted
 * @returns {String}        Formatted absolute value
 */
export const formatAbsoluteValue = value => {
  if (!value && value !== 0) return "";
  return formatValue(value, true, true);
};

/**
 * Format currency values with scaling and currency symbol
 * @param {String} value        Value to be formatted
 * @param {String} currency     Currency symbol (default £)
 * @returns {String}            Formatted currency value
 */
export const formatCurrency = (value, currency = "£") => {
  if (!value && value !== 0) return "";
  return `${getSign(value, false)}${currency}${formatValue(value, false)}`;
};

/**
 * Format absolute currency values with scaling, currency symbol and + or - sign
 * @param {String} value        Value to be formatted
 * @param {String} currency     Currency symbol (default £)
 * @returns {String}            Formatted currency value
 */
export const formatAbsoluteCurrency = (value, currency = "£") => {
  if (!value && value !== 0) return "";
  return `${getSign(value, true)}${currency}${formatValue(value, false)}`;
};
/**
 * Format volume values with scaling and unit
 * @param {String} value    Value to be formatted
 * @param {String} unit     Unit (default L)
 * @returns {String}        Formatted volume value
 */
export const formatVolume = (value, unit = "L") => {
  if (!value && value !== 0) return "";
  return `${formatValue(value)} ${unit}`;
};

/**
 * Format percentage values
 * @param {String} value    Value to be formatted
 * @returns {String}        Formatted percentage value
 */
export const formatPercentage = value => {
  if (!value && value !== 0) return "";
  return `${formatValue(value)}%`;
};

/**
 * Format absolute percentage values with + or - sign
 * @param {String} value    Value to be formatted
 * @returns {String}        Formatted absolute percentage value
 */
export const formatAbsolutePercentage = value => {
  if (!value && value !== 0) return "";
  return `${formatAbsoluteValue(value)}%`;
};

/**
 * Convert value (generally between -1 and 1) to percentage
 * @param {String} value    Value to be converted
 * @returns {String}        Percentage value
 */
export const convertToPercentage = value => {
  if (!value && value !== 0) return "";
  return `${Math.round(value * 100 * 10) / 10}%`;
};

/**
 * Format download values to 2 decimal places
 * */
export const formatDownloadValue = value => {
  if (!value && value !== 0) return "";
  return value.toFixed(2);
};

/**
 * Format download percentage values up to 3 decimal places
 * (generally between 0 and 1)
 * */
export const formatDownloadPercentage = value => {
  if (!value && value !== 0) return "";
  return Math.round((value / 100) * 1000) / 1000;
};
