import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { SelectAutocomplete, Switch } from "components/BaseComponents";
import { Legend } from "components/CustomComponents";
import {
  formatDownloadPercentage,
  formatDownloadValue,
  formatValue,
  getProductAttributes,
  wrapText
} from "utils";
import theme from "theme";
import {
  DownloadData,
  PageBar,
  BarLineChart,
  StackedBarChart
} from "./Components";

const styles = {
  root: { bgcolor: "grey.50", p: 2, width: 1, height: 1, borderRadius: 1 }
};

const compareFn = (a, b) => {
  if (a < b) return 1;
  if (a > b) return -1;
  return 0;
};

const getSalesData = (chartData, salesMetric, salesRange) => {
  const result = {};
  const keys = Object.keys(chartData);
  keys.forEach(key => {
    result[key] =
      chartData[key][salesRange][salesMetric].sales_and_sales_change;
  });
  const values = Object.values(result);
  const x = values.map(i => i.x).flat();
  const y = values.map(i => i.y).flat();
  const dy = values.map(i => i.dy).flat();
  const initialData = x
    .map((i, k) => ({ x: i, y: y[k], dy: dy[k] }))
    .sort((a, b) => compareFn(a.y, b.y));
  const sortedX = initialData.map(i => i.x);
  const sortedY = initialData.map(i => i.y);
  const sortedDy = initialData.map(i => i.dy);
  const modifiedY = sortedY.map((i, k) => {
    if (salesRange === "current_range") return i;
    if (sortedDy[k] < 0) return i;
    return i - sortedDy[k];
  });
  return sortedX.map((i, k) => ({
    x: i,
    y: modifiedY[k],
    dy: sortedDy[k]
  }));
};

const getParetoData = (chartData, paretoRange, paretoMetric) => {
  const result = {};
  const keys = Object.keys(chartData);
  keys.forEach(key => {
    result[key] = chartData[key][paretoRange][paretoMetric].pareto_curve;
  });
  const values = Object.values(result);
  const x = values.map(i => i.x).flat();
  const y = values.map(i => i.y).flat();
  const initialData = x
    .map((i, k) => ({ x: i, y: y[k] }))
    .sort((a, b) => compareFn(a.y, b.y));
  const sortedX = initialData.map(i => i.x);
  const sortedY = initialData.map(i => i.y);
  const sum = sortedY.reduce((a, b) => +a + +b, 0);
  const unit = sortedY.map(i => (i * 100) / sum);
  const cumulative = unit.reduce((r, a) => {
    r.push(((r.length && r[r.length - 1]) || 0) + a);
    return r;
  }, []);
  return sortedX.map((i, k) => ({
    x: i,
    y: sortedY[k],
    cumulative: cumulative[k]
  }));
};

const getDistRosData = (chartData, distRosRange) => {
  const result = {};
  const keys = Object.keys(chartData);
  keys.forEach(key => {
    result[key] = chartData[key][distRosRange].distribution_vs_rate_of_sales;
  });
  const values = Object.values(result);
  const x = values.map(i => i.x).flat();
  const uros = values.map(i => i.uros).flat();
  const ndist = values.map(i => i.ndist).flat();
  return x
    .map((i, k) => ({
      x: i,
      uros: uros[k],
      ndist: ndist[k]
    }))
    .sort((a, b) => compareFn(a.ndist, b.ndist));
};

const renderChartHeader = (title, subtitle, data, header, section) => (
  <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
    <Stack>
      <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" color="grey.600">
        {subtitle}
      </Typography>
    </Stack>
    <DownloadData data={data} header={header} section={section} />
  </Stack>
);

const Charts = props => {
  const { report } = props;
  const [salesMetric, setSalesMetric] = useState("value_sales");
  const [salesRange, setSalesRange] = useState("auto_range");
  const [paretoMetric, setParetoMetric] = useState("value_sales");
  const [paretoRange, setParetoRange] = useState("auto_range");
  const [distRosRange, setDistRosRange] = useState("auto_range");
  const [selectedBays, setSelectedBays] = useState([]);
  const [chartData, setChartData] = useState({});

  const {
    charts_data: chartsData = {},
    tag2sku = {},
    tag2attribute = {},
    header
  } = report;
  const bayPlanOptions = Object.keys(chartsData);

  useEffect(() => {
    const bays = selectedBays.length === 0 ? bayPlanOptions : selectedBays;
    const result = {};
    bays.forEach(bay => {
      result[bay] = chartsData[bay];
    });
    setChartData(result);
  }, [chartsData, selectedBays]);

  const salesData = getSalesData(chartData, salesMetric, salesRange);

  const paretoData = getParetoData(chartData, paretoRange, paretoMetric);

  const distRosData = getDistRosData(chartData, distRosRange);

  const salesDownload = salesData.map(i => ({
    [`SKU (${
      salesRange === "current_range" ? "Current Range" : "Auto-Range"
    })`]: tag2sku[i.x],
    ...getProductAttributes(i.x, tag2attribute),
    [`Sales Today (${salesMetric === "value_sales" ? "£" : "L"})`]:
      formatDownloadValue(i.y),
    ...(salesRange === "auto_range" && {
      [`Sales Change (${salesMetric === "value_sales" ? "£" : "L"})`]:
        formatDownloadValue(i.dy)
    })
  }));

  const paretoDownload = paretoData.map(i => ({
    [`SKU (${
      paretoRange === "current_range" ? "Current Range" : "Auto-Range"
    })`]: tag2sku[i.x],
    ...getProductAttributes(i.x, tag2attribute),
    [`Sku Sales (${paretoMetric === "value_sales" ? "£" : "L"})`]:
      formatDownloadValue(i.y),
    "Cumulative Sales (%)": formatDownloadPercentage(i.cumulative)
  }));

  const distRosDownload = distRosData.map(i => ({
    [`SKU (${
      distRosRange === "current_range" ? "Current Range" : "Auto-Range"
    })`]: tag2sku[i.x],
    ...getProductAttributes(i.x, tag2attribute),
    "Rate of Sale": formatDownloadValue(i.uros),
    "Numeric Distribution (%)": formatDownloadPercentage(i.ndist)
  }));

  return (
    <Fragment>
      <PageBar
        leftChild={
          <SelectAutocomplete
            label="Select Bay Plan"
            styles={{ width: 500 }}
            fullWidth
            value={selectedBays}
            onChange={(e, value) => setSelectedBays(value)}
            options={bayPlanOptions}
            multiple
            filterSelectedOptions
          />
        }
      />
      <Stack spacing={2}>
        <Box sx={styles.root}>
          {renderChartHeader(
            "Sales & Sales Change",
            "This chart is used to understand which products Distribution might want to be changed.",
            salesDownload,
            header,
            "Sales and Sales Change Chart"
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Legend
              items={[
                { label: "Sales Today", color: "primaryArray.300" },
                {
                  label: "Sales Change",
                  multiple: true,
                  colors: ["negativeArray.400", "positiveArray.400"]
                }
              ]}
            />
            <Stack direction="row" alignItems="center" spacing={3}>
              <Switch
                checked={salesMetric === "value_sales"}
                onChange={e =>
                  setSalesMetric(
                    e.target.checked ? "value_sales" : "volume_sales"
                  )
                }
                offLabel="Volume"
                onLabel="Value"
              />
              <Switch
                checked={salesRange === "auto_range"}
                onChange={e =>
                  setSalesRange(
                    e.target.checked ? "auto_range" : "current_range"
                  )
                }
                offLabel="Current Range"
                onLabel="Auto-Range"
              />
            </Stack>
          </Stack>
          <StackedBarChart
            data={[
              {
                x: salesData
                  .map(i => tag2sku[i.x])
                  .map(i =>
                    salesData.length < 50 && i.length > 50 ? wrapText(i, 50) : i
                  ),
                y: salesData.map(i => i.y),
                type: "bar",
                marker: { color: theme.palette.primaryArray[300] },
                hovertemplate: "Sales Today: %{y}<extra></extra>"
              },
              ...(salesData.map(i => i.dy).length > 0
                ? [
                    {
                      x: salesData
                        .map(i => tag2sku[i.x])
                        .map(i =>
                          salesData.length < 50 && i.length > 50
                            ? wrapText(i, 50)
                            : i
                        ),
                      y: salesData.map(i => Math.abs(i.dy)),
                      type: "bar",
                      marker: {
                        color: salesData
                          .map(i => i.dy)
                          .map(i =>
                            i > 0
                              ? theme.palette.positiveArray[400]
                              : theme.palette.negativeArray[400]
                          )
                      },
                      hovertemplate: "Sales Change: %{y}<extra></extra>"
                    }
                  ]
                : [])
            ]}
            tickprefix={(salesMetric === "value_sales" && "£") || ""}
            ticksuffix={(salesMetric === "volume_sales" && " L") || ""}
          />
        </Box>
        <Box sx={styles.root}>
          {renderChartHeader(
            "Pareto Curve",
            "Sales by Sku & Cumulative Sales.",
            paretoDownload,
            header,
            "Pareto Chart"
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Legend
              items={[
                { label: "Sku Sales", color: "primaryArray.300" },
                { label: "Cumulative Sales %", color: "negativeArray.400" }
              ]}
            />
            <Stack direction="row" alignItems="center" spacing={3}>
              <Switch
                checked={paretoMetric === "value_sales"}
                onChange={e =>
                  setParetoMetric(
                    e.target.checked ? "value_sales" : "volume_sales"
                  )
                }
                offLabel="Volume"
                onLabel="Value"
              />
              <Switch
                checked={paretoRange === "auto_range"}
                onChange={e =>
                  setParetoRange(
                    e.target.checked ? "auto_range" : "current_range"
                  )
                }
                offLabel="Current Range"
                onLabel="Auto-Range"
              />
            </Stack>
          </Stack>
          <BarLineChart
            data={[
              {
                x: paretoData
                  .map(i => tag2sku[i.x])
                  .map(i =>
                    paretoData.length < 50 && i.length > 50
                      ? wrapText(i, 50)
                      : i
                  ),
                y: paretoData.map(i => i.y),
                type: "bar",
                marker: { color: theme.palette.primaryArray[300] },
                hovertemplate: "Sku Sales: %{y}<extra></extra>"
              },
              {
                x: paretoData
                  .map(i => tag2sku[i.x])
                  .map(i =>
                    paretoData.length < 50 && i.length > 50
                      ? wrapText(i, 50)
                      : i
                  ),
                y: paretoData.map(i => formatValue(i.cumulative)),
                type: "line",
                yaxis: "y2",
                marker: { color: theme.palette.negativeArray[400] },
                line: { width: 3 },
                hovertemplate: "Cumulative Sales: %{y}<extra></extra>"
              }
            ]}
            yaxis1tickprefix={(paretoMetric === "value_sales" && "£") || ""}
            yaxis1ticksuffix={(paretoMetric === "volume_sales" && " L") || ""}
            yaxis2ticksuffix="%"
          />
        </Box>
        <Box sx={styles.root}>
          {renderChartHeader(
            "Distribution vs Rate of Sale",
            "This chart is used to understand which products Distribution might want to be changed.",
            distRosDownload,
            header,
            "Distribution and Rate of Sale Chart"
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Legend
              items={[
                { label: "Numeric Distribution", color: "primaryArray.300" },
                { label: "Rate of Sale", color: "negativeArray.400" }
              ]}
            />
            <Switch
              checked={distRosRange === "auto_range"}
              onChange={e =>
                setDistRosRange(
                  e.target.checked ? "auto_range" : "current_range"
                )
              }
              offLabel="Current Range"
              onLabel="Auto-Range"
            />
          </Stack>
          <BarLineChart
            data={[
              {
                x: distRosData
                  .map(i => tag2sku[i.x])
                  .map(i => (distRosData.length > 50 ? i : wrapText(i, 50))),
                y: distRosData.map(i => formatValue(i.uros)),
                type: "bar",
                marker: { color: theme.palette.negativeArray[400] },
                hovertemplate: "Rate of Sale: %{y}<extra></extra>"
              },
              {
                x: distRosData
                  .map(i => tag2sku[i.x])
                  .map(i => (distRosData.length > 50 ? i : wrapText(i, 50))),
                y: distRosData.map(i => formatValue(i.ndist)),
                type: "scatter",
                mode: "lines",
                yaxis: "y2",
                marker: { color: theme.palette.primaryArray[300] },
                line: { width: 3 },
                hovertemplate: "Numeric Distribution: %{y}<extra></extra>"
              }
            ]}
            yaxis2ticksuffix="%"
          />
        </Box>
      </Stack>
    </Fragment>
  );
};

Charts.propTypes = {
  report: PropTypes.shape()
};

Charts.defaultProps = {
  report: {}
};

export default Charts;
