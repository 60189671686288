import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { Paper } from "components/BaseComponents";
import Feedback from "components/Feedback";
import ReportTemplate from "./ReportTemplate";
import Layout from "./Layout";

const styles = {
  presentation: { pb: "56.25%", overflow: "auto", position: "relative" }, // 9:16 ratio
  large: { display: { xs: "none", md: "block" } },
  small: { display: { md: "none", sm: "block" } }
};

const extractPrototypeContent = report => {
  const obj = report.find(item => item.formatkey === "prototype_content");
  if (obj && obj.content && Array.isArray(obj.content.prototype)) {
    return obj.content.prototype;
  }
  return [];
};

const Presentation = props => {
  const { activeSlideIndex, report, client, story, contents, navigateToSlide } =
    props;
  const slideData = report[activeSlideIndex];
  const prototypeArray = extractPrototypeContent(report);
  if (!slideData) {
    return (
      <div className="Presentation">
        <Paper variant="elevated" styles={styles.presentation}>
          <Box textAlign="center">No slide data.</Box>
        </Paper>
      </div>
    );
  }
  return (
    <div className="Presentation">
      <Paper
        variant="elevated"
        styles={{ ...styles.presentation, ...styles.small }}
      >
        <Box sx={{ p: 2, textAlign: "center" }}>
          Please view on a device with a larger screen.
        </Box>
      </Paper>
      <Paper
        variant="elevated"
        styles={{ ...styles.presentation, ...styles.large }}
      >
        <ReportTemplate
          activeSlideIndex={activeSlideIndex}
          client={client}
          formatKey={slideData.formatkey}
        />
        <Layout
          client={client}
          formatKey={slideData.formatkey}
          slideData={slideData}
          contents={contents}
          story={story}
          prototypeArray={prototypeArray}
          navigateToSlide={navigateToSlide}
        />
      </Paper>
      <Feedback component="report" />
    </div>
  );
};

Presentation.propTypes = {
  activeSlideIndex: PropTypes.number,
  report: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string,
  story: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape()),
  navigateToSlide: PropTypes.func
};

Presentation.defaultProps = {
  activeSlideIndex: 0,
  report: [],
  client: "",
  story: "",
  contents: [],
  navigateToSlide: () => {}
};

export default Presentation;
