import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        fallback ?? (
          <Typography variant="body1" textAlign="center" color="negative.main">
            Oops, something went wrong :(
          </Typography>
        )
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.shape(),
  fallback: PropTypes.node
};

ErrorBoundary.defaultProps = {
  children: {},
  fallback: undefined
};

export default ErrorBoundary;
