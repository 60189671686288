import jwt_decode from "jwt-decode"; // eslint-disable-line camelcase
import { alertLevels } from "constants/enums";
import {
  CREATE_USER_URL,
  SIGN_IN_URL,
  SIGN_OUT_URL,
  FORGOT_PASSWORD_URL,
  FORGOT_PASSWORD_RESET_URL,
  CHANGE_PASSWORD_URL,
  CHANGE_USER_STATUS_URL,
  RESET_PASSWORD_URL,
  ADD_CONTACT_URL,
  REMOVE_CONTACT_URL,
  CHECK_SUBSCRIPTION_URL,
  SUBSCRIBE_URL,
  UNSUBSCRIBE_URL,
  GET_CLIENTS_URL,
  GET_DEPARTMENTS_URL,
  ADD_DEPARTMENT_URL,
  GET_USER_LIST_URL,
  EDIT_USER_URL,
  USER_LIST_LIMIT
} from "../constants";
import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_ERROR,
  DISABLE_USER_REQUEST,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_ERROR,
  ENABLE_USER_REQUEST,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CLEAR_USER_MESSAGES,
  LOGOUT,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_ERROR,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  REMOVE_CONTACT_REQUEST,
  REMOVE_CONTACT_SUCCESS,
  REMOVE_CONTACT_ERROR,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_ERROR,
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR
} from "../constants/actionConstants";
import logger, { initLogger, resetLogger } from "../logger";
import fetchInstance from "../utils/fetchInstance";
import { addNotification } from "./notification";

/**
 * Ensure all user input emails are lowercase
 */
const lowerCaseEmail = email => email.toLowerCase();

/**
 * Validate email to ensure it is at least 8 characters and contains all of
 * lowercase letters, uppercase letters, numbers and special characters as defined by
 * https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
 */
export const checkPasswordErrors = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return "Passwords do not match";
  }
  if (password.length < 8) {
    return "Password must be at least 8 characters";
  }
  if (password.length >= 64) {
    return "Password must be less than 64 characters";
  }
  if (!password.match(/(\S*[a-z]\S*)/)) {
    return "Password must contain a lowercase character";
  }
  if (!password.match(/(\S*[A-Z]\S*)/)) {
    return "Password must contain an uppercase character";
  }
  if (!password.match(/(\S*\d\S*)/)) {
    return "Password must contain a number";
  }
  if (!password.match(/(\S*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]\S*)/)) {
    return "Password must contain a special character";
  }
  return false;
};

function addContactRequest() {
  return {
    type: ADD_CONTACT_REQUEST
  };
}

function addContactSuccess() {
  return {
    type: ADD_CONTACT_SUCCESS
  };
}

function addContactError(error) {
  return {
    type: ADD_CONTACT_ERROR,
    error
  };
}

function addContact(dispatch, email) {
  dispatch(addContactRequest());
  return fetchInstance(ADD_CONTACT_URL, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(addContactSuccess());
        } else {
          dispatch(
            addNotification("Error creating email contact", alertLevels.ERROR)
          );
          dispatch(addContactError(res));
        }
      },
      err => {
        dispatch(addNotification(err.message, alertLevels.ERROR));
        dispatch(addContactError(err.message));
      }
    );
}

function removeContactRequest() {
  return {
    type: REMOVE_CONTACT_REQUEST
  };
}

function removeContactSuccess() {
  return {
    type: REMOVE_CONTACT_SUCCESS
  };
}

function removeContactError(error) {
  return {
    type: REMOVE_CONTACT_ERROR,
    error
  };
}

function removeContact(dispatch, email) {
  dispatch(removeContactRequest());
  return fetchInstance(REMOVE_CONTACT_URL, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(removeContactSuccess());
        } else {
          dispatch(removeContactError(res));
        }
      },
      err => {
        dispatch(removeContactError(err.message));
      }
    );
}

function createUserRequest() {
  return {
    type: CREATE_USER_REQUEST
  };
}

function createUserSuccess() {
  return {
    type: CREATE_USER_SUCCESS
  };
}

function createUserError(error) {
  return {
    type: CREATE_USER_ERROR,
    error
  };
}

export const createUser =
  (
    name,
    inputEmail,
    password,
    confirmPassword,
    department,
    client,
    onSuccess
  ) =>
  dispatch => {
    const email = lowerCaseEmail(inputEmail);
    dispatch(createUserRequest());
    if (name.length === 0) {
      dispatch(addNotification("Please enter a name", alertLevels.ERROR));
      return dispatch(createUserError("Name is not valid"));
    }
    if (email.length === 0) {
      dispatch(addNotification("Please enter an email", alertLevels.ERROR));
      return dispatch(createUserError("Email is not valid"));
    }
    if (!email.match(/\S+@\S+\.\S+/)) {
      dispatch(
        addNotification("Please enter a valid email", alertLevels.ERROR)
      );
      return dispatch(createUserError("Email is not valid"));
    }
    const passwordErrors = checkPasswordErrors(password, confirmPassword);
    if (passwordErrors) {
      dispatch(addNotification(passwordErrors, alertLevels.ERROR));
      return dispatch(createUserError(passwordErrors));
    }
    const isDijuno =
      email.includes("@interrodata.com") || email.includes("@dijuno.ai");
    if (!isDijuno && department.length === 0) {
      dispatch(addNotification("Please enter a department", alertLevels.ERROR));
      return dispatch(createUserError("Department not valid"));
    }
    const body = isDijuno
      ? { name, email, password }
      : {
          name,
          email,
          password,
          department,
          client,
          product: "ida"
        };
    return fetchInstance(CREATE_USER_URL, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            onSuccess();
            addContact(dispatch, email);
            dispatch(createUserSuccess());
            logger({
              date: new Date().toISOString(),
              action: "CREATE_USER",
              email,
              client
            });
          } else {
            dispatch(addNotification("Error creating user", alertLevels.ERROR));
            dispatch(createUserError(res));
          }
        },
        err => {
          dispatch(addNotification(err.message, alertLevels.ERROR));
          dispatch(createUserError(err.message));
        }
      );
  };

function signInRequest(user) {
  return {
    type: SIGN_IN_REQUEST,
    user
  };
}

function signInSuccess(user) {
  return {
    type: SIGN_IN_SUCCESS,
    user
  };
}

function signInError(error) {
  return {
    type: SIGN_IN_ERROR,
    error
  };
}

export const signIn = (inputEmail, password, pseudoClient) => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  let appType = "regular";
  dispatch(signInRequest({ email }));
  if (email.length === 0 || password.length === 0) {
    return dispatch(signInError("Please enter a valid email and password"));
  }
  const isDijuno =
    email.includes("@interrodata.com") || email.includes("@dijuno.ai");
  if (isDijuno && pseudoClient.length === 0) {
    return dispatch(signInError("Please select a valid client"));
  }
  if (process.env.REACT_APP_DEV_ENV === "poc") {
    appType = "poc";
  }
  return fetch(SIGN_IN_URL, {
    method: "POST",
    body: JSON.stringify({
      email,
      password,
      client: pseudoClient,
      app_type: appType
    }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res.status === "ACCEPTED") {
          const { user } = res;
          const { access, refresh, id } = user;
          const decoded = jwt_decode(access);
          const { admin, client, product, roles } = decoded;
          initLogger(email, client);
          if (admin || isDijuno) {
            // i.e. dijuno user
            localStorage.setItem("accessToken", access);
            localStorage.setItem("refreshToken", refresh);
            localStorage.setItem(
              "user",
              JSON.stringify({ admin, roles, client: pseudoClient, id, email })
            );
            dispatch(signInSuccess({ id, client: pseudoClient, email }));
            logger({
              date: new Date().toISOString(),
              action: "SIGN_IN",
              user_id: id,
              client
            });
          } else if (product !== "ida") {
            // not admin
            dispatch(signInError("Permission denied"));
          } else {
            // not admin
            localStorage.setItem("accessToken", access);
            localStorage.setItem("refreshToken", refresh);
            localStorage.setItem(
              "user",
              JSON.stringify({ admin, roles, client, id, email })
            );
            dispatch(signInSuccess({ id, client, email }));
            logger({
              date: new Date().toISOString(),
              action: "SIGN_IN",
              user_id: id,
              client
            });
          }
        } else {
          dispatch(signInError(res));
        }
      },
      err => {
        if (err.message === "Failed to fetch") {
          dispatch(
            signInError(
              "Please check your network connection and firewall settings. Our support team are here to help if you continue to have issues."
            )
          );
        } else {
          dispatch(signInError(err.message));
        }
      }
    );
};

function forgotPasswordRequest() {
  return {
    type: FORGOT_PASSWORD_REQUEST
  };
}

function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS
  };
}

function forgotPasswordError(error) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    error
  };
}

export const forgotPassword = (inputEmail, onComplete) => dispatch => {
  dispatch(forgotPasswordRequest());
  const email = lowerCaseEmail(inputEmail);
  if (email.length === 0) {
    return dispatch(forgotPasswordError("Please enter a valid email"));
  }
  return fetch(FORGOT_PASSWORD_URL, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(forgotPasswordSuccess());
        } else {
          dispatch(forgotPasswordError(res));
        }
      },
      err => {
        dispatch(forgotPasswordError(err.message));
      }
    )
    .finally(() => {
      // for security reason, always display success message
      onComplete();
    });
};

export const forgotPasswordReset =
  (inputEmail, token, password, confirmPassword, onComplete) => dispatch => {
    dispatch(forgotPasswordRequest());
    const email = lowerCaseEmail(inputEmail);
    if (email.length === 0) {
      return dispatch(forgotPasswordError("Please enter a valid email"));
    }
    if (token.length === 0) {
      return dispatch(forgotPasswordError("Please enter a valid token"));
    }
    const passwordErrors = checkPasswordErrors(password, confirmPassword);
    if (passwordErrors) {
      return dispatch(forgotPasswordError(passwordErrors));
    }
    return fetch(FORGOT_PASSWORD_RESET_URL, {
      method: "POST",
      body: JSON.stringify({ email, token, password }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            onComplete(true);
            logger({
              date: new Date().toISOString(),
              action: "FORGOT_PASSWORD",
              email,
              client: process.env.REACT_APP_DEV_ENV // user not logged in
            });
            dispatch(forgotPasswordSuccess());
            dispatch(
              addNotification(
                "Password reset successful. Please login here",
                alertLevels.SUCCESS
              )
            );
          } else {
            dispatch(forgotPasswordError(res));
            dispatch(addNotification(res, alertLevels.ERROR));
          }
        },
        err => {
          dispatch(forgotPasswordError(err.message));
          dispatch(addNotification(err.message, alertLevels.ERROR));
        }
      );
  };

function changePasswordRequest() {
  return {
    type: CHANGE_PASSWORD_REQUEST
  };
}

function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS
  };
}

function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error
  };
}

export const changePassword =
  (password, newPassword, confirmNewPassword, onSuccess) => dispatch => {
    dispatch(changePasswordRequest());
    const passwordErrors = checkPasswordErrors(newPassword, confirmNewPassword);
    if (passwordErrors) {
      dispatch(addNotification(passwordErrors, alertLevels.ERROR));
      return dispatch(changePasswordError(passwordErrors));
    }
    return fetchInstance(CHANGE_PASSWORD_URL, {
      method: "POST",
      body: JSON.stringify({ password, new_password: newPassword }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            onSuccess();
            dispatch(
              addNotification(
                "Password changed successfully",
                alertLevels.SUCCESS
              )
            );
            dispatch(changePasswordSuccess());
          } else {
            dispatch(
              addNotification("Error changing password", alertLevels.ERROR)
            );
            dispatch(changePasswordError(res));
          }
        },
        err => {
          dispatch(addNotification(err.message, alertLevels.ERROR));
          dispatch(changePasswordError(err.message));
        }
      );
  };

function unblockUserRequest() {
  return {
    type: UNBLOCK_USER_REQUEST
  };
}

function unblockUserSuccess(email) {
  return {
    type: UNBLOCK_USER_SUCCESS,
    email
  };
}

function unblockUserError(error) {
  return {
    type: UNBLOCK_USER_ERROR,
    error
  };
}

export const unblockUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(unblockUserRequest());
  if (email.length === 0) {
    return dispatch(unblockUserError("Email is not valid"));
  }
  return fetchInstance(CHANGE_USER_STATUS_URL, {
    method: "POST",
    body: JSON.stringify({ email, change_user_status: "unblock" }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(
            addNotification("User unblocked successfully", alertLevels.SUCCESS)
          );
          dispatch(unblockUserSuccess(email));
        } else {
          dispatch(addNotification("Error unblocking user", alertLevels.ERROR));
          dispatch(unblockUserError(res));
        }
      },
      err => {
        dispatch(addNotification(err.message, alertLevels.ERROR));
        dispatch(unblockUserError(err.message));
      }
    );
};

function disableUserRequest() {
  return {
    type: DISABLE_USER_REQUEST
  };
}

function disableUserSuccess(email) {
  return {
    type: DISABLE_USER_SUCCESS,
    email
  };
}

function disableUserError(error) {
  return {
    type: DISABLE_USER_ERROR,
    error
  };
}

export const disableUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(disableUserRequest());
  if (email.length === 0) {
    return dispatch(disableUserError("Email is not valid"));
  }
  return fetchInstance(CHANGE_USER_STATUS_URL, {
    method: "POST",
    body: JSON.stringify({ email, change_user_status: "disable" }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          removeContact(dispatch, email);
          dispatch(
            addNotification("User disabled successfully", alertLevels.SUCCESS)
          );
          dispatch(disableUserSuccess(email));
        } else {
          dispatch(addNotification("Error disabling user", alertLevels.ERROR));
          dispatch(disableUserError(res));
        }
      },
      err => {
        dispatch(addNotification(err.message, alertLevels.ERROR));
        dispatch(disableUserError(err.message));
      }
    );
};

function enableUserRequest() {
  return {
    type: ENABLE_USER_REQUEST
  };
}

function enableUserSuccess(email) {
  return {
    type: ENABLE_USER_SUCCESS,
    email
  };
}

function enableUserError(error) {
  return {
    type: ENABLE_USER_ERROR,
    error
  };
}

export const enableUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(enableUserRequest());
  if (email.length === 0) {
    return dispatch(enableUserError("Email is not valid"));
  }
  return fetchInstance(CHANGE_USER_STATUS_URL, {
    method: "POST",
    body: JSON.stringify({ email, change_user_status: "enable" }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          addContact(dispatch, email);
          dispatch(
            addNotification("User enabled successfully", alertLevels.SUCCESS)
          );
          dispatch(enableUserSuccess(email));
        } else {
          dispatch(addNotification("Error enabling user", alertLevels.ERROR));
          dispatch(enableUserError(res));
        }
      },
      err => {
        dispatch(addNotification(err.message, alertLevels.ERROR));
        dispatch(enableUserError(err.message));
      }
    );
};

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_REQUEST
  };
}
function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS
  };
}
function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error
  };
}
export const resetPassword =
  (inputEmail, password, confirmPassword, onSuccess) => dispatch => {
    const email = lowerCaseEmail(inputEmail);
    dispatch(resetPasswordRequest());
    if (email.length === 0 || password.length === 0) {
      return dispatch(
        resetPasswordError("Please enter a valid email and password")
      );
    }
    const passwordErrors = checkPasswordErrors(password, confirmPassword);
    if (passwordErrors) {
      dispatch(addNotification(passwordErrors, alertLevels.ERROR));
      return dispatch(resetPasswordError(passwordErrors));
    }
    return fetchInstance(RESET_PASSWORD_URL, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            onSuccess();
            dispatch(
              addNotification(
                "Password reset successfully",
                alertLevels.SUCCESS
              )
            );
            dispatch(resetPasswordSuccess());
          } else {
            dispatch(
              addNotification("Error resetting password", alertLevels.ERROR)
            );
            dispatch(resetPasswordError(res));
          }
        },
        err => {
          dispatch(addNotification(err.message, alertLevels.ERROR));
          dispatch(resetPasswordError(err.message));
        }
      );
  };

export function clearSettingsMessages() {
  return {
    type: CLEAR_USER_MESSAGES
  };
}

// for automatic sign out, when jwt expired -> update redux store
export function logout() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  resetLogger();
  return { type: LOGOUT };
}

// for when a user clicks the logout button -> call api to blacklist tokens
export const signOut = () => dispatch => {
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");
  try {
    return fetch(SIGN_OUT_URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`
      }
    }).then(
      fetch(SIGN_OUT_URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        }
      }).then(dispatch(logout()))
    );
  } catch (err) {
    // logout anyway
    return dispatch(logout());
  }
};

function getDepartmentsRequest() {
  return {
    type: GET_DEPARTMENTS_REQUEST
  };
}

function getDepartmentsSuccess(departments) {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    departments
  };
}

function getDepartmentsError(error) {
  return {
    type: GET_DEPARTMENTS_ERROR,
    error
  };
}

export const getDepartments = client => dispatch => {
  dispatch(getDepartmentsRequest());
  return fetchInstance(`${GET_DEPARTMENTS_URL}?client=${client}`, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          dispatch(getDepartmentsError(res));
        } else {
          const departments = res.sort();
          dispatch(getDepartmentsSuccess(departments));
        }
      },
      err => {
        dispatch(getDepartmentsError(err.message));
      }
    );
};

function addDepartmentRequest() {
  return {
    type: ADD_DEPARTMENT_REQUEST
  };
}

function addDepartmentSuccess(department) {
  return {
    type: ADD_DEPARTMENT_SUCCESS,
    department
  };
}

function addDepartmentError(error) {
  return {
    type: ADD_DEPARTMENT_ERROR,
    error
  };
}

export const addDepartment = (department, client) => dispatch => {
  dispatch(addDepartmentRequest());
  return fetchInstance(ADD_DEPARTMENT_URL, {
    method: "POST",
    body: JSON.stringify({ department, client }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(addDepartmentSuccess(department));
        } else {
          dispatch(addDepartmentError(res));
        }
      },
      err => {
        dispatch(addDepartmentError(err.message));
      }
    );
};

function getClientsRequest() {
  return {
    type: GET_CLIENTS_REQUEST
  };
}

function getClientsSuccess(clients) {
  return {
    type: GET_CLIENTS_SUCCESS,
    clients
  };
}

function getClientsError(error) {
  return {
    type: GET_CLIENTS_ERROR,
    error
  };
}

export const getClients = () => dispatch => {
  dispatch(getClientsRequest());
  return fetch(`${GET_CLIENTS_URL}?product=ida`, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          dispatch(getClientsError(res));
          dispatch(addNotification(res, alertLevels.ERROR));
        } else {
          const clients = res.sort();
          dispatch(getClientsSuccess(clients));
        }
      },
      err => {
        dispatch(addNotification(err.message, alertLevels.ERROR));
        dispatch(getClientsError(err.message));
      }
    );
};

function getSubscriptionRequest() {
  return {
    type: GET_SUBSCRIPTION_REQUEST
  };
}

function getSubscriptionSuccess(subscriptions) {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
    subscriptions
  };
}

function getSubscriptionError(error) {
  return {
    type: GET_SUBSCRIPTION_ERROR,
    error
  };
}

export const getSubscription = () => dispatch => {
  dispatch(getSubscriptionRequest());
  return fetchInstance(CHECK_SUBSCRIPTION_URL, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          dispatch(getDepartmentsError(res));
        } else {
          const subscriptions = res.reduce(
            (o, i) =>
              Object.assign(o, {
                [i.TopicName]: i.SubscriptionStatus
              }),
            {}
          );
          dispatch(getSubscriptionSuccess(subscriptions));
        }
      },
      err => {
        dispatch(getSubscriptionError(err.message));
      }
    );
};

function subscribeRequest() {
  return {
    type: SUBSCRIBE_REQUEST
  };
}

function subscribeSuccess(topic) {
  return {
    type: SUBSCRIBE_SUCCESS,
    topic
  };
}

function subscribeError(error) {
  return {
    type: SUBSCRIBE_ERROR,
    error
  };
}

export const subscribe = topic => dispatch => {
  dispatch(subscribeRequest());
  return fetchInstance(SUBSCRIBE_URL, {
    method: "POST",
    body: JSON.stringify({ topic }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(subscribeSuccess(topic));
        } else {
          dispatch(subscribeError(res));
        }
      },
      err => {
        dispatch(subscribeError(err.message));
      }
    );
};

function unsubscribeRequest() {
  return {
    type: UNSUBSCRIBE_REQUEST
  };
}

function unsubscribeSuccess(topic) {
  return {
    type: UNSUBSCRIBE_SUCCESS,
    topic
  };
}

function unsubscribeError(error) {
  return {
    type: UNSUBSCRIBE_ERROR,
    error
  };
}

export const unsubscribe = topic => dispatch => {
  dispatch(unsubscribeRequest());
  return fetchInstance(UNSUBSCRIBE_URL, {
    method: "POST",
    body: JSON.stringify({ topic }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(unsubscribeSuccess(topic));
        } else {
          dispatch(unsubscribeError(res));
        }
      },
      err => {
        dispatch(unsubscribeError(err.message));
      }
    );
};

function getUserListRequest() {
  return {
    type: GET_USER_LIST_REQUEST
  };
}

function getUserListSuccess({ data, meta }) {
  return {
    type: GET_USER_LIST_SUCCESS,
    userList: data,
    userListMeta: meta
  };
}

function getUserListError(error) {
  return {
    type: GET_USER_LIST_ERROR,
    error
  };
}

export const getUserList = page => dispatch => {
  dispatch(getUserListRequest());
  return fetchInstance(
    `${GET_USER_LIST_URL}?limit=${USER_LIST_LIMIT}&page=${page}`,
    {
      headers: { "Content-Type": "application/json" }
    }
  )
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        dispatch(getUserListSuccess(res));
      },
      err => {
        dispatch(getUserListError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      }
    );
};

function editUserAccountRequest() {
  return {
    type: EDIT_USER_REQUEST
  };
}
function editUserAccountSuccess({ userId, name, department }) {
  return {
    type: EDIT_USER_SUCCESS,
    userId,
    name,
    department
  };
}
function editUserAccountError(error) {
  return {
    type: EDIT_USER_ERROR,
    error
  };
}
export const editUserAccount =
  (userId, name, inputEmail, department, onSuccess) => dispatch => {
    const email = lowerCaseEmail(inputEmail);
    dispatch(editUserAccountRequest());
    if (name.length === 0) {
      dispatch(addNotification("Please enter a name", alertLevels.ERROR));
      return dispatch(editUserAccountError("Name is not valid"));
    }
    if (email.length === 0) {
      dispatch(addNotification("Please enter an email", alertLevels.ERROR));
      return dispatch(editUserAccountError("Email is not valid"));
    }
    if (!email.match(/\S+@\S+\.\S+/)) {
      dispatch(
        addNotification("Please enter a valid email", alertLevels.ERROR)
      );
      return dispatch(editUserAccountError("Email is not valid"));
    }
    const isDijuno =
      email.includes("@interrodata.com") || email.includes("@dijuno.ai");
    if (!isDijuno && department.length === 0) {
      dispatch(addNotification("Please enter a department", alertLevels.ERROR));
      return dispatch(editUserAccountError("Department not valid"));
    }
    const body = isDijuno
      ? { user_id: userId, name }
      : { user_id: userId, name, department };
    return fetchInstance(EDIT_USER_URL, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            onSuccess();
            dispatch(
              addNotification(
                "User account updated successfully",
                alertLevels.SUCCESS
              )
            );
            dispatch(editUserAccountSuccess({ userId, name, department }));
          } else {
            dispatch(addNotification("Error updating user", alertLevels.ERROR));
            dispatch(editUserAccountError(res));
          }
        },
        err => {
          dispatch(addNotification(err.message, alertLevels.ERROR));
          dispatch(editUserAccountError(err.message));
        }
      );
  };
