import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { Tabs } from "components/BaseComponents";
import { STERLING_OPTION_ROUTE } from "constants/viewRoutes";

const Header = props => {
  const { isSterling } = props;
  const params = useParams();
  const { option } = params;
  const navigate = useNavigate();

  const sterlingTabs = [
    { label: "SKU Prioritisation", value: "prioritisation", isVisible: true },
    { label: "Weightings", value: "weightings", isVisible: isSterling },
    { label: "Ranking Customisation", value: "ranking", isVisible: isSterling },
    { label: "Shopper-Based Ranging", value: "ranging", isVisible: isSterling }
  ].filter(i => i.isVisible);

  return (
    <Fragment>
      <Typography variant="h4" color="primary.main">
        Sterling Portfolio Prioritisation
      </Typography>
      {isSterling && (
        <Tabs
          activeTab={option}
          onChange={(e, value) =>
            navigate(generatePath(STERLING_OPTION_ROUTE, { option: value }))
          }
          tabs={sterlingTabs}
          styles={{ my: 2 }}
        />
      )}
    </Fragment>
  );
};

Header.propTypes = {
  isSterling: PropTypes.bool
};

Header.defaultProps = {
  isSterling: false
};

export default Header;
