import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouterNavLink, useLocation } from "react-router-dom";

const NavLink = props => {
  const { isActive, activeStyle, style, children, to } = props;
  const { pathname } = useLocation();
  const active = isActive(pathname);
  return (
    <RouterNavLink
      to={to}
      style={active ? { ...style, ...activeStyle } : style}
      end
    >
      {children}
    </RouterNavLink>
  );
};

NavLink.propTypes = {
  isActive: PropTypes.func,
  activeStyle: PropTypes.shape(),
  style: PropTypes.shape(),
  to: PropTypes.string,
  children: PropTypes.node.isRequired
};

NavLink.defaultProps = {
  isActive: () => {},
  activeStyle: {},
  style: {},
  to: ""
};

export default NavLink;
