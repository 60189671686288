import _ from "lodash";
import defaultState from "./defaultState";
import {
  ADD_PREFERENCE_REQUEST,
  ADD_PREFERENCE_SUCCESS,
  ADD_PREFERENCE_ERROR,
  REMOVE_PREFERENCE_REQUEST,
  REMOVE_PREFERENCE_SUCCESS,
  REMOVE_PREFERENCE_ERROR,
  EDIT_PREFERENCE_REQUEST,
  EDIT_PREFERENCE_SUCCESS,
  EDIT_PREFERENCE_ERROR,
  GET_PREFERENCES_REQUEST,
  GET_PREFERENCES_SUCCESS,
  GET_PREFERENCES_ERROR,
  GET_LOOKOUT_REQUEST,
  GET_LOOKOUT_SUCCESS,
  GET_LOOKOUT_ERROR,
  CLEAR_LOOKOUT_DATA
} from "../constants/actionConstants";

// eslint-disable-next-line default-param-last
const lookout = (state = defaultState.lookout, action) => {
  switch (action.type) {
    case ADD_PREFERENCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case ADD_PREFERENCE_SUCCESS:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.channel]: [
            ...state.preferences[action.channel],
            { ...action.preference, id: action.id }
          ]
        },
        isLoading: false,
        error: ""
      };
    case ADD_PREFERENCE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case REMOVE_PREFERENCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case REMOVE_PREFERENCE_SUCCESS:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.channel]: [
            ...state.preferences[action.channel].filter(
              i => !_.isEqual(i.id, action.id)
            )
          ]
        },
        isLoading: false,
        error: ""
      };
    case REMOVE_PREFERENCE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case EDIT_PREFERENCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case EDIT_PREFERENCE_SUCCESS:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.channel]: [
            ...state.preferences[action.channel].filter(
              i => !_.isEqual(i.id, action.removeId)
            ),
            { ...action.preference, id: action.addId }
          ]
        },
        isLoading: false,
        error: ""
      };
    case EDIT_PREFERENCE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case GET_PREFERENCES_REQUEST:
      return {
        ...state,
        error: "",
        isPreferenceLoading: true
      };
    case GET_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: action.preferences,
        error: "",
        isPreferenceLoading: false
      };
    case GET_PREFERENCES_ERROR:
      return {
        ...state,
        error: action.error,
        isPreferenceLoading: false
      };
    case GET_LOOKOUT_REQUEST:
      return {
        ...state,
        lookout: {},
        isLookout: false,
        error: "",
        isLookoutLoading: true
      };
    case GET_LOOKOUT_SUCCESS:
      return {
        ...state,
        lookout: action.lookout,
        isLookout: true,
        error: "",
        isLookoutLoading: false
      };
    case GET_LOOKOUT_ERROR:
      return {
        ...state,
        lookout: {},
        isLookout: false,
        error: action.error,
        isLookoutLoading: false
      };
    case CLEAR_LOOKOUT_DATA:
      return {
        ...state,
        preferences: {},
        lookout: {},
        isLoading: false,
        isLookoutLoading: false,
        isPreferenceLoading: false,
        isLookout: false,
        error: ""
      };
    default:
      return state;
  }
};

export default lookout;
