import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowRightIcon } from "components/Icons";
import { PasswordField } from "components/BaseComponents";
import { checkPasswordErrors, forgotPasswordReset } from "actions";
import { HOME_ROUTE, LOGIN_ROUTE } from "constants/viewRoutes";
import Layout from "./Layout";

const ResetPassword = props => {
  const { isUser, dispatchForgotPasswordReset, isLoading } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmitClicked = () => {
    const string = location.search.slice(1);
    const urlParams = new URLSearchParams(string);
    const email = urlParams.get("email");
    const token = urlParams.get("token");
    const onComplete = () => navigate(LOGIN_ROUTE);
    dispatchForgotPasswordReset(
      email,
      token,
      password,
      confirmPassword,
      onComplete
    );
  };

  const checkPasswordItems = [
    { label: "be at least 8 characters", isValid: password.length > 8 },
    {
      label: "contain uppercase and lowercase characters",
      isValid: password.match(/(?=.*[a-z])(?=.*[A-Z])/)
    },
    {
      label: "contain a number",
      isValid: password.match(/(?=.*\d)/)
    },
    {
      label: "contain a special character",
      isValid: password.match(/(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])/)
    }
  ];

  const isPasswordMatch =
    confirmPassword.length === 0 || password === confirmPassword;

  if (isUser) {
    return <Navigate to={HOME_ROUTE} />;
  }
  return (
    <Layout>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h2">Reset your password</Typography>
          <Typography variant="subtitle1" color="grey.500">
            Please enter your new password.
          </Typography>
        </Stack>
        <PasswordField
          required
          value={password}
          onChange={e => setPassword(e.target.value)}
          label="New Password"
          name="password"
          placeholder="Password"
          InputProps={{ autoComplete: "off" }}
          inputStyles={{ "& .MuiInputBase-input": { height: 28 } }}
        />
        <PasswordField
          required
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          label="Confirm New Password"
          name="password"
          placeholder="Confirm Password"
          InputProps={{ autoComplete: "off" }}
          inputStyles={{ "& .MuiInputBase-input": { height: 28 }, mb: 1 }}
          error={isPasswordMatch ? "" : "Passwords do not match"}
        />
        <LoadingButton
          color="secondary"
          loadingPosition="end"
          fullWidth
          endIcon={<ArrowRightIcon />}
          variant="contained"
          size="large"
          loading={isLoading}
          disabled={!!checkPasswordErrors(password, confirmPassword)}
          onClick={onSubmitClicked}
        >
          Reset Password
        </LoadingButton>
        <List>
          <ListItem disablePadding disableGutters>
            <ListItemText
              primaryTypographyProps={{ variant: "body2", color: "grey.800" }}
              primary="Your password must:"
            />
          </ListItem>
          {checkPasswordItems.map(({ label, isValid }) => (
            <ListItem key={label} disablePadding>
              <ListItemIcon
                sx={{
                  minWidth: 20,
                  color: isValid ? "grey.800" : "negative.main"
                }}
              >
                {isValid ? "✓" : "◦"}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: "body2",
                  color: isValid ? "grey.800" : "negative.main"
                }}
                primary={label}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
    </Layout>
  );
};

ResetPassword.propTypes = {
  isUser: PropTypes.bool,
  dispatchForgotPasswordReset: PropTypes.func,
  isLoading: PropTypes.bool
};

ResetPassword.defaultProps = {
  isUser: false,
  dispatchForgotPasswordReset: () => {},
  isLoading: false
};

const mapStateToProps = state => ({
  isUser: state.user.isUser,
  isLoading: state.user.isLoading
});

const mapDispatchToProps = dispatch => ({
  dispatchForgotPasswordReset: (
    email,
    token,
    password,
    confirmPassword,
    onComplete
  ) =>
    dispatch(
      forgotPasswordReset(email, token, password, confirmPassword, onComplete)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
