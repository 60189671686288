import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Stack, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";
import { NavigateStepButtons } from "components/CustomComponents";
import { editSterling } from "actions";
import ChannelEnvironment from "../ChannelEnvironment";
import Header from "../Header";
import Rankings from "./Rankings";

const RankingCustomisation = props => {
  const { options, isLoading, isSterling, edit } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [channel, setChannel] = useState("ih");
  const [environment, setEnvironment] = useState(null);
  const [environments, setEnvironments] = useState([]);
  const [rankList, setRankList] = useState([]);

  useEffect(() => {
    if (environment) {
      setEnvironments([environment]);
    } else {
      setEnvironments([]);
    }
  }, [environment]);

  const handleNext = () => setActiveStep(activeStep + 1);

  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <Fragment>
      <Paper>
        <Header isSterling={isSterling} />
        <Stack spacing={1} sx={{ mb: 2 }}>
          <Typography variant="h4" color="primary.main">
            Ranking Customisation
          </Typography>
          <Typography variant="body1" color="grey.500">
            This sheet allows you to edit the prioiritisation to be given to
            each category or sector, brand and sub brand. This will impact the
            overall ranking of skus, with changes here affecting all skus that
            match the attribute you edit. By default all options have equal
            weighting, so not editing them mean that the model will not be
            affected and only show inputs from other sources.
          </Typography>
        </Stack>
        {activeStep === 0 && (
          <ChannelEnvironment
            channel={channel}
            setChannel={setChannel}
            environment={environment}
            setEnvironment={setEnvironment}
          />
        )}
        {activeStep === 1 && (
          <Rankings
            channel={channel}
            environments={environments}
            handleBack={handleBack}
            options={options}
            isLoading={isLoading}
            rankList={rankList}
            setRankList={setRankList}
          />
        )}
      </Paper>
      {activeStep === 0 && (
        <NavigateStepButtons
          onBackClick={() => navigate(-1)}
          onNextClick={handleNext}
          nextDisabled={!environment}
        />
      )}
      {activeStep === 1 && (
        <NavigateStepButtons
          onBackClick={handleBack}
          onNextClick={() =>
            edit(
              _.differenceWith(rankList, options, _.isEqual),
              channel,
              environments,
              handleBack
            )
          }
          nextLabel="Apply Changes"
          nextDisabled={
            isLoading || options.length === 0 || rankList.length === 0
          }
        />
      )}
    </Fragment>
  );
};

RankingCustomisation.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  isSterling: PropTypes.bool,
  edit: PropTypes.func
};

RankingCustomisation.defaultProps = {
  options: [],
  isLoading: false,
  isSterling: false,
  edit: () => {}
};

const mapStateToProps = state => ({
  options: state.sterling.rankings,
  isLoading: state.sterling.isLoading
});

const mapDispatchToProps = dispatch => ({
  edit: (data, channel, environments, onSuccess) =>
    dispatch(
      editSterling("rankings", data, [], channel, environments, onSuccess)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankingCustomisation);
