import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputLabel,
  Stack,
  Typography
} from "@mui/material";
import { Paper, TextField } from "components/BaseComponents";
import {
  CloseIcon,
  DislikeIcon,
  FeedbackIcon,
  LikeIcon
} from "components/Icons";
import { sendFeedback } from "logger";

const Feedback = props => {
  const { component } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isPositive, setIsPositive] = useState(false);
  const [isNegative, setIsNegative] = useState(false);
  const [comment, setComment] = useState("");
  const { pathname, search } = useLocation();
  const url = `${pathname}${search}`;

  const reset = () => {
    setIsPositive(false);
    setIsNegative(false);
    setComment("");
  };

  const onThumbsUp = () => {
    setIsPositive(true);
    setIsNegative(false);
  };

  const onThumbsDown = () => {
    setIsNegative(true);
    setIsPositive(false);
  };

  const onSubmit = () => {
    setIsOpen(false);
    sendFeedback(component, url, isPositive, isNegative, comment);
    reset();
  };

  return (
    <Box sx={{ zIndex: 200, position: "relative" }}>
      <Button
        variant="soft"
        color="carmine"
        sx={{
          position: "absolute",
          right: 0,
          bottom: -48,
          boxShadow:
            "0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)"
        }}
        startIcon={<FeedbackIcon />}
        onClick={() => setIsOpen(!isOpen)}
      >
        Feedback
      </Button>
      <Collapse in={isOpen}>
        <Paper
          variant="elevated"
          styles={{
            width: 400,
            p: 2,
            position: "absolute",
            right: 0,
            bottom: 0,
            boxShadow:
              "0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)"
          }}
        >
          <IconButton
            onClick={() => setIsOpen(false)}
            sx={{ position: "absolute", right: 4, top: 4, color: "grey.500" }}
          >
            <CloseIcon />
          </IconButton>
          <Stack alignItems="flex-start" spacing={2}>
            <Typography variant="h6" color="grey.700">
              Please provide feedback
            </Typography>
            <Box sx={{ width: 1 }}>
              <InputLabel shrink sx={{ position: "static", color: "grey.800" }}>
                How do you feel about these insights?
              </InputLabel>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <IconButton
                  onClick={onThumbsDown}
                  color="negative"
                  sx={{
                    "&, &:hover": {
                      bgcolor: isNegative ? "negative.main" : "inherit"
                    },
                    border: 1,
                    borderColor: "negative.main"
                  }}
                >
                  <DislikeIcon
                    sx={{
                      color: isNegative ? "white" : "negative.main"
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={onThumbsUp}
                  color="positive"
                  sx={{
                    "&, &:hover": {
                      bgcolor: isPositive ? "positive.main" : "inherit"
                    },
                    border: 1,
                    borderColor: "positive.main"
                  }}
                >
                  <LikeIcon
                    sx={{ color: isPositive ? "white" : "positive.main" }}
                  />
                </IconButton>
              </Stack>
            </Box>
            <TextField
              label="Any comments?"
              placeholder="Write your feedback here"
              type="text"
              value={comment}
              onChange={e => setComment(e.target.value)}
              fullWidth
              multiline
              minRows={2}
            />
            <Button
              variant="soft"
              color="carmine"
              disabled={!isPositive && !isNegative}
              onClick={onSubmit}
            >
              Send
            </Button>
          </Stack>
        </Paper>
      </Collapse>
    </Box>
  );
};

Feedback.propTypes = {
  component: PropTypes.string
};

Feedback.defaultProps = {
  component: ""
};

export default Feedback;
