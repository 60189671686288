import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import {
  SearchBar,
  AdvancedSearch,
  Suggestions,
  EditCard
} from "components/CustomComponents";
import { getContext, filterStaticData } from "actions";

const getSelectedLabel = items =>
  items.map(i => `${_.startCase(i.subsection)}: ${i.value}`);

const EditProduct = props => {
  const {
    initialSubsections,
    filteredSubsections,
    isFilteredData,
    contextSubsections,
    dispatchGetContext,
    story,
    client,
    isFetchingData,
    isLoadingContext,
    suggestedContext,
    dispatchFilterStaticData,
    isFilteringData,
    error,
    product,
    context,
    setProduct,
    setContext
  } = props;
  const [subsections, setSubsections] = useState(initialSubsections);
  const [isContextOpen, setIsContextOpen] = useState(false);

  useEffect(() => {
    if (isFilteredData) {
      setSubsections(filteredSubsections);
    } else {
      setSubsections(initialSubsections);
    }
  }, [initialSubsections, filteredSubsections, isFilteredData]);

  useEffect(() => {
    const apiProduct = product.map(i => i.apiValue);
    dispatchFilterStaticData(null, apiProduct, story, client);
  }, [product]);

  useEffect(() => {
    if (product.length === 0) return;
    const apiProduct = product.map(i => i.apiValue);
    const apiContext = context.map(i => i.apiValue);
    dispatchGetContext(apiProduct, apiContext, story, client);
  }, [product, context]);

  const handleProduct = value => {
    setContext([]);
    setProduct(value);
    setIsContextOpen(true);
  };

  const handleContext = value => setContext(value);

  const handleSuggestion = suggestion => {
    const allContext = context.concat(suggestion);
    setContext(allContext);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <EditCard
          label="Target Product"
          selected={getSelectedLabel(product)}
          isWarning
          warningText="If you change the Target Product, you must also change the Product Filter."
        >
          <SearchBar
            value={product}
            onChange={(e, value) => handleProduct(value)}
            options={subsections}
            multiple
            disabled={isFilteringData}
            styles={{ mb: 2 }}
            isLoading={isFetchingData}
            isUpdating={isFilteringData}
            story={story}
          />
          <AdvancedSearch
            fields={subsections}
            isLoading={isFetchingData || isFilteringData}
            selected={product}
            onChange={value => handleProduct(value)}
            story={story}
          />
        </EditCard>
      </Grid>
      <Grid item xs={12}>
        <EditCard
          label="Product Filter"
          selected={getSelectedLabel(context)}
          isError={context.length === 0}
          errorText="Please select a new Product Filter."
          controlled
          isOpen={isContextOpen}
          disabled={product.length === 0 || isFilteringData || !!error}
        >
          <SearchBar
            value={context}
            onChange={(e, value) => handleContext(value)}
            options={contextSubsections}
            multiple
            disabled={isLoadingContext}
            styles={{ mb: 2 }}
            story={story}
          />
          {suggestedContext.length > 0 && (
            <Fragment>
              <Typography variant="subtitle2">
                Suggested Product Filter (based on your target product)
              </Typography>
              <Suggestions
                suggestions={suggestedContext}
                isLoading={isLoadingContext}
                onClick={value => handleSuggestion(value)}
                styles={{ mb: 3 }}
                story={story}
              />
            </Fragment>
          )}
          <AdvancedSearch
            fields={contextSubsections}
            isLoading={isLoadingContext}
            selected={context}
            onChange={value => handleContext(value)}
            story={story}
          />
        </EditCard>
      </Grid>
    </Grid>
  );
};

EditProduct.propTypes = {
  initialSubsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  isFilteredData: PropTypes.bool,
  contextSubsections: PropTypes.arrayOf(PropTypes.shape()),
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  isFetchingData: PropTypes.bool,
  isLoadingContext: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  error: PropTypes.string,
  dispatchGetContext: PropTypes.func,
  dispatchFilterStaticData: PropTypes.func,
  client: PropTypes.string,
  story: PropTypes.string,
  product: PropTypes.arrayOf(PropTypes.shape()),
  context: PropTypes.arrayOf(PropTypes.shape()),
  setProduct: PropTypes.func,
  setContext: PropTypes.func
};

EditProduct.defaultProps = {
  initialSubsections: [],
  filteredSubsections: [],
  isFilteredData: false,
  contextSubsections: [],
  suggestedContext: [],
  isFetchingData: false,
  isLoadingContext: false,
  isFilteringData: false,
  error: "",
  dispatchGetContext: () => {},
  dispatchFilterStaticData: () => {},
  client: "",
  story: "",
  product: [],
  context: [],
  setProduct: () => {},
  setContext: () => {}
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      error,
      isFilteringData,
      filteredSubsections = { story: [] },
      isFilteredData = { story: false },
      isLoadingContext = false,
      contextSubsections = { story: [] },
      suggestedContext = { story: [] }
    },
    data: { subsections = { dataSet: [] } }
  } = state;
  return {
    error,
    isFilteringData,
    client: state.user.user.client,
    isFetchingData: state.data.isFetchingData,
    isLoadingContext,
    contextSubsections: contextSubsections[story],
    suggestedContext: suggestedContext[story],
    initialSubsections: subsections[dataSet],
    filteredSubsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story]
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchGetContext: (products, contexts, story, client) =>
    dispatch(getContext(products, contexts, "product", story, client)),

  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
