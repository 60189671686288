import React from "react";
import PropTypes from "prop-types";
import { Link, generatePath } from "react-router-dom";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  EditIcon,
  RefreshIcon,
  StarIcon,
  StarFilledIcon
} from "components/Icons";
import { EDIT_AUTO_RANGING_INITIAL_ROUTE } from "constants/viewRoutes";
import { Paper } from "components/BaseComponents";

const Header = props => {
  const {
    report,
    reportId,
    onRefreshData,
    userTracking,
    handleFavourite,
    isLoadingFavourite,
    isFavorite
  } = props;
  const { header = "" } = report || {};
  const { isLoading = false, error = "" } = userTracking;
  return (
    <Paper>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 0.5 }}>
        <Typography variant="h4" color="primary.main">
          Auto-Ranging
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Tooltip
            title={isFavorite ? "Remove from Favourites" : "Add to Favourites"}
          >
            <Box>
              <LoadingButton
                size="small"
                variant="outlined"
                color="disabled"
                loadingPosition="center"
                loading={isLoadingFavourite}
                onClick={handleFavourite}
                disabled={isLoading || !!error}
                sx={{ color: "grey.500", minWidth: "auto" }}
              >
                {isFavorite ? (
                  <StarFilledIcon color="neutral" sx={{ p: 0.375 }} />
                ) : (
                  <StarIcon sx={{ p: 0.375 }} />
                )}
              </LoadingButton>
            </Box>
          </Tooltip>
          <Button
            size="small"
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={onRefreshData}
          >
            Refresh with latest data
          </Button>
          <Button
            size="small"
            variant="outlined"
            disabled={isLoading || !!error}
            startIcon={<EditIcon />}
            component={Link}
            to={generatePath(EDIT_AUTO_RANGING_INITIAL_ROUTE, { id: reportId })}
            rel="noreferrer"
            target="_blank"
          >
            Edit Constraints
          </Button>
        </Stack>
      </Stack>
      <Typography variant="subtitle1" fontWeight="medium" color="grey.700">
        {header}
      </Typography>
    </Paper>
  );
};

Header.propTypes = {
  report: PropTypes.shape(),
  reportId: PropTypes.string,
  onRefreshData: PropTypes.func,
  handleFavourite: PropTypes.func,
  userTracking: PropTypes.shape(),
  isLoadingFavourite: PropTypes.bool,
  isFavorite: PropTypes.bool
};

Header.defaultProps = {
  report: {},
  reportId: "",
  onRefreshData: () => {},
  handleFavourite: () => {},
  userTracking: {},
  isLoadingFavourite: false,
  isFavorite: false
};

export default Header;
