import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import { WarningDialog } from "components/CustomComponents";

const InRangeAction = props => {
  const { item, onRemove, disabled } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <Fragment>
      <Button
        color="negative"
        onClick={() => setIsDialogOpen(true)}
        disabled={disabled}
      >
        &#8212;
      </Button>
      <WarningDialog
        isOpen={isDialogOpen}
        title="Remove Sku"
        content={
          <Fragment>
            <Typography variant="inherit" gutterBottom>
              Are you sure that this SKU was not included in the bay plan at the
              last review? If so, click Remove.
            </Typography>
            <Typography variant="inherit">
              The first sku in &quot;SKUs not in Current Range&quot; will be
              added to the end of the list.
            </Typography>
          </Fragment>
        }
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => {
          onRemove(item);
          setIsDialogOpen(false);
        }}
        confirmButton="Remove"
      />
    </Fragment>
  );
};

InRangeAction.propTypes = {
  item: PropTypes.shape(),
  onRemove: PropTypes.func,
  disabled: PropTypes.bool
};

InRangeAction.defaultProps = {
  item: {},
  onRemove: () => {},
  disabled: false
};

export default InRangeAction;
