import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { CalendarIcon, CloseIcon } from "components/Icons";
import FormControl from "./FormControl";

const DateInput = forwardRef((props, ref) => {
  const {
    label,
    fullWidth,
    styles,
    required,
    infoText,
    error,
    disabled,
    isClearable,
    onClear,
    ...otherProps
  } = props;
  return (
    <FormControl
      label={label}
      fullWidth={fullWidth}
      styles={styles}
      required={required}
      infoText={infoText}
      error={error}
    >
      <TextField
        variant="outlined"
        error={!!error}
        disabled={disabled}
        ref={ref}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarIcon
                fontSize="small"
                color={disabled ? "disabled" : "inherit"}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {isClearable && otherProps.value && (
                <IconButton
                  onClick={e => {
                    onClear();
                    // stop calendar opening
                    e.stopPropagation();
                  }}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </InputAdornment>
          ),
          autoComplete: "off"
        }}
        {...otherProps}
      />
    </FormControl>
  );
});

DateInput.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  styles: PropTypes.shape(),
  required: PropTypes.bool,
  infoText: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClear: PropTypes.func
};

DateInput.defaultProps = {
  label: "",
  fullWidth: false,
  styles: {},
  required: false,
  infoText: "",
  error: "",
  disabled: false,
  isClearable: false,
  onClear: () => {}
};

export default DateInput;
