import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { FCIcon, ICIcon } from "components/Icons";
import { SelectGroup, TextField } from "components/BaseComponents";
import ChannelEnvironment from "../ChannelEnvironment";

const rankingOptions = [
  { label: "SKU", id: "sku" },
  { label: "Liquid", id: "liquid" }
];

const filterOptions = [
  { label: "All", id: "all" },
  { label: "IC (Immediate Consumption)", id: "ic", icon: <ICIcon /> },
  { label: "FC (Future Consumption)", id: "fc", icon: <FCIcon /> }
];

const Input = props => {
  const {
    channel,
    setChannel,
    environment,
    setEnvironment,
    isLoading,
    rankType,
    setRankType,
    consumptionType,
    setConsumptionType,
    number,
    setNumber
  } = props;

  const handleRanking = item => {
    const { id } = item;
    setRankType(id);
  };

  const handleConsumptionType = item => {
    const { id } = item;
    setConsumptionType(id);
  };

  return (
    <ChannelEnvironment
      channel={channel}
      setChannel={setChannel}
      environment={environment}
      setEnvironment={setEnvironment}
      additionalInput={
        <Stack spacing={3}>
          <SelectGroup
            options={rankingOptions}
            onClick={handleRanking}
            isLoading={isLoading}
            controlled
            selected={rankType}
            label="View Rankings by"
          />
          {channel === "ih" && (
            <SelectGroup
              options={filterOptions}
              onClick={handleConsumptionType}
              isLoading={isLoading}
              controlled
              selected={consumptionType}
              label="Products filtered by"
            />
          )}
          <TextField
            label="Number of Products"
            value={number > 0 ? number : ""}
            onChange={e => setNumber(Number(e.target.value))}
            fullWidth
            styles={{
              "& .MuiTextField-root, .MuiSkeleton-root": { maxWidth: 400 }
            }}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            isLoading={isLoading}
            infoText="Please enter the number of products that can be listed within the given environment."
            placeholder="0"
          />
        </Stack>
      }
    />
  );
};

Input.propTypes = {
  channel: PropTypes.string,
  setChannel: PropTypes.func,
  environment: PropTypes.string,
  setEnvironment: PropTypes.func,
  isLoading: PropTypes.bool,
  rankType: PropTypes.string,
  setRankType: PropTypes.func,
  consumptionType: PropTypes.string,
  setConsumptionType: PropTypes.func,
  number: PropTypes.number,
  setNumber: PropTypes.func
};

Input.defaultProps = {
  channel: "",
  setChannel: () => {},
  environment: null,
  setEnvironment: () => {},
  isLoading: false,
  rankType: "",
  setRankType: () => {},
  consumptionType: "",
  setConsumptionType: () => {},
  number: 0,
  setNumber: () => {}
};

export default Input;
