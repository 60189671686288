import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { InfoIcon } from "components/Icons";
import { Table } from "components/BaseComponents";
import {
  formatCurrency,
  formatDownloadPercentage,
  formatDownloadValue,
  formatPercentage,
  formatValue,
  getProductAttributes
} from "utils";
import { PageBar, DownloadData } from "./Components";

const columns = [
  { label: "Rank", renderCell: (item, index) => index + 1 },
  {
    label: "Product Name",
    styles: { textAlign: "left" },
    renderCell: item => item.product_name
  },
  {
    label: "Sales Today (£)",
    styles: { width: 130 },
    renderCell: item => formatCurrency(item.sales_today)
  },
  {
    label: "Share of Sales %",
    styles: { width: 130 },
    renderCell: item => formatPercentage(item.share_of_sales)
  },
  {
    label: "Rate of Sale",
    styles: { width: 130 },
    renderCell: item => formatValue(item.ros)
  },
  {
    name: "distribution",
    label: "Distribution %",
    styles: { width: 130 },
    renderCell: item => formatPercentage(item.distribution)
  }
];

const GapAnalysis = props => {
  const { report } = props;
  const {
    gap_analysis: gapAnalysis = [],
    constraints = {},
    tag2attribute = {},
    header
  } = report;

  const { retailer = "", comparison_retailer: comparisonRetailer = "" } =
    constraints;

  const downloadData = gapAnalysis.map((i, k) => ({
    Rank: k + 1,
    SKU: i.product_name,
    ...getProductAttributes(i.tag, tag2attribute),
    "Sales Today (£)": formatDownloadValue(i.sales_today),
    "Share of Sales %": formatDownloadPercentage(i.share_of_sales),
    "Rate of Sale": formatDownloadValue(i.ros, true),
    "Distribution %": formatDownloadPercentage(i.distribution)
  }));

  return (
    <Fragment>
      <PageBar
        leftChild={
          <Stack direction="row" spacing={1} alignItems="center">
            <InfoIcon sx={{ fontSize: 16 }} />
            <Typography variant="subtitle1" fontWeight="medium">
              Gap Analysis: Products that are not listed in {retailer} but are
              in {comparisonRetailer}. Products are ranked by Value Sales.
            </Typography>
          </Stack>
        }
        rightChild={
          <DownloadData
            data={downloadData}
            header={header}
            section="Gap Analysis Tab"
          />
        }
      />
      <Table rows={gapAnalysis} columns={columns} />
    </Fragment>
  );
};

GapAnalysis.propTypes = {
  report: PropTypes.shape()
};

GapAnalysis.defaultProps = {
  report: {}
};

export default GapAnalysis;
