import React from "react";
import PropTypes from "prop-types";
import { Routes, Route } from "react-router-dom";
import { storyEnums } from "constants/enums";
import {
  NEW_PERFORMANCE_REPORT_ROUTE,
  NEW_BRAND_BATTLES_REPORT_ROUTE,
  NEW_RANGE_REVIEW_REPORT_ROUTE,
  NEW_TREND_REPORT_ROUTE,
  NEW_FOCUSED_REPORT_ROUTE,
  EDIT_PERFORMANCE_REPORT_ROUTE,
  EDIT_BRAND_BATTLES_REPORT_ROUTE,
  EDIT_RANGE_REVIEW_REPORT_ROUTE,
  EDIT_TREND_REPORT_ROUTE,
  EDIT_FOCUS_REPORT_ROUTE
} from "constants/viewRoutes";
import NewPerformanceReport, {
  EditPerformanceReport
} from "components/NewPerformanceReport";
import NewBrandBattlesReport, {
  EditBrandBattlesReport
} from "components/NewBrandBattlesReport";
import NewRangeReview, { EditRangeReview } from "components/NewRangeReview";
import NewTrendReport, { EditTrendReport } from "components/NewTrendReport";
import { clientConfig } from "config";
import PrivateRoute from "components/PrivateRoute";
import NewFocusedReport, {
  EditFocusedReport
} from "components/NewFocusedReport";

const ReportsReviews = props => {
  const { client } = props;
  const { stories } = client ? clientConfig[client] : { stories: [] };

  const reportRoutes = [
    {
      path: NEW_PERFORMANCE_REPORT_ROUTE,
      component: <NewPerformanceReport />,
      isVisible:
        stories.includes(storyEnums.IDA) || stories.includes(storyEnums.CGA)
    },
    {
      path: EDIT_PERFORMANCE_REPORT_ROUTE,
      component: <EditPerformanceReport />,
      isVisible:
        stories.includes(storyEnums.IDA) || stories.includes(storyEnums.CGA)
    },
    {
      path: NEW_BRAND_BATTLES_REPORT_ROUTE,
      component: <NewBrandBattlesReport />,
      isVisible: stories.includes(storyEnums.BB)
    },
    {
      path: EDIT_BRAND_BATTLES_REPORT_ROUTE,
      component: <EditBrandBattlesReport />,
      isVisible: stories.includes(storyEnums.BB)
    },
    {
      path: NEW_RANGE_REVIEW_REPORT_ROUTE,
      component: <NewRangeReview story="prr" dataSet="nielsen" />,
      isVisible: stories.includes(storyEnums.BB)
    },
    {
      path: EDIT_RANGE_REVIEW_REPORT_ROUTE,
      component: <EditRangeReview story="prr" dataSet="nielsen" />,
      isVisible: stories.includes(storyEnums.BB)
    },
    {
      path: NEW_TREND_REPORT_ROUTE,
      component: <NewTrendReport />,
      isVisible: stories.includes(storyEnums.TREND)
    },
    {
      path: EDIT_TREND_REPORT_ROUTE,
      component: <EditTrendReport />,
      isVisible: stories.includes(storyEnums.TREND)
    },
    {
      path: NEW_FOCUSED_REPORT_ROUTE,
      component: <NewFocusedReport />,
      isVisible: stories.includes(storyEnums.FP)
    },
    {
      path: EDIT_FOCUS_REPORT_ROUTE,
      component: <EditFocusedReport />,
      isVisible: stories.includes(storyEnums.FP)
    }
  ].filter(i => i.isVisible);

  return (
    <Routes>
      {reportRoutes.map(i => (
        <Route
          path={i.path}
          key={i.path}
          element={<PrivateRoute>{i.component}</PrivateRoute>}
        />
      ))}
    </Routes>
  );
};

ReportsReviews.propTypes = {
  client: PropTypes.string
};

ReportsReviews.defaultProps = {
  client: ""
};

export default ReportsReviews;
