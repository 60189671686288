import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";

const ReportHeader = props => {
  const { isLastStep, title, icon, description, isEdit, review } = props;
  return (
    <Paper>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ color: "primary.main", mb: 1 }}
      >
        {icon}
        <Typography variant="h4" color="primary.main">
          {isEdit && "Edit"} {title}
        </Typography>
      </Stack>
      <Typography variant="body1" color="grey.500" gutterBottom>
        {description}
      </Typography>
      {isLastStep && <Box sx={{ pt: 2 }}>{review}</Box>}
    </Paper>
  );
};

ReportHeader.propTypes = {
  isLastStep: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  isEdit: PropTypes.bool,
  review: PropTypes.node
};

ReportHeader.defaultProps = {
  isLastStep: false,
  icon: null,
  title: "",
  description: "",
  isEdit: false,
  review: null
};

export default ReportHeader;
