import React from "react";
import { useParams } from "react-router-dom";
import ReportBuilder from "components/ReportBuilder";
import { getStoryConfig } from "config";
import { storyEnums } from "constants/enums";
import { FOCUS_MAPPINGS } from "constants/reportConstants";
import { FocusIcon } from "../Icons";

const NewFocusedReport = () => {
  const params = useParams();
  const { channel, focusType } = params;
  const story = channel === "ih" ? storyEnums.IDA : storyEnums.CGA;
  const { dataSet } = getStoryConfig(story);

  const channelTitle = channel === "ih" ? "In-Home" : "Out-of-Home";
  const focusTitle = FOCUS_MAPPINGS[focusType] || "";
  const title = `Focused Reports (${channelTitle} – ${focusTitle})`;

  let description;
  if (focusType === "performance") {
    description =
      "Insights for performance relative to the category and share movement amongst products.";
  } else if (focusType === "npd") {
    description = "Identification and performance of new product development.";
  } else if (focusType === "stars") {
    description =
      "Highlighting products and groups of products that have over or under-performed and the casual drivers behind it.";
  }

  return (
    <ReportBuilder
      title={title}
      icon={<FocusIcon />}
      description={description}
      story={story}
      dataSet={dataSet}
      reportType="focus"
      source="menu"
    />
  );
};

export default NewFocusedReport;
