import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { getConstraintsFromQuery, getSearchOptions } from "utils";
import { reportConstraints } from "actions";
import ReportBuilder from "components/ReportBuilder";
import { getStoryConfig } from "config";
import { storyEnums } from "constants/enums";
import { PerformanceIcon } from "../Icons";

const NewPerformanceReport = props => {
  const { dispatchReportConstraints, allSubsections } = props;
  const location = useLocation();
  const params = useParams();
  const { channel } = params;
  const story = channel === "ih" ? storyEnums.IDA : storyEnums.CGA;
  const { dataSet, type } = getStoryConfig(story);

  useEffect(() => {
    if (location.search) {
      const {
        market: marketValue,
        period: periodValue,
        metric: metricValue,
        product: productValue,
        context: contextValue
      } = getConstraintsFromQuery(location.search);
      const subsections = allSubsections[dataSet];

      const productExists = productValue.some(p => {
        const matchingSubsection = subsections.find(
          subsection => subsection.name === p.subsection
        );
        return (
          matchingSubsection &&
          matchingSubsection.keywords.some(keyword => {
            if (typeof keyword === "string") {
              return keyword.includes(p.name);
            }
            if (typeof keyword === "object" && keyword.value) {
              return keyword.value.includes(p.name);
            }
            return false;
          })
        );
      });

      if (productExists) {
        const updatedProduct = getSearchOptions(productValue, "what", story);
        dispatchReportConstraints("product", updatedProduct, story);
      } else {
        dispatchReportConstraints("product", [], story);
      }

      const contextExists = contextValue.some(c => {
        const matchingContextSubsection = subsections.find(
          subsection => subsection.name === c.subsection
        );
        return (
          matchingContextSubsection &&
          matchingContextSubsection.keywords.some(keyword => {
            if (typeof keyword === "string") {
              return keyword.includes(c.name);
            }
            if (typeof keyword === "object" && keyword.value) {
              return keyword.value.includes(c.name);
            }
            return false;
          })
        );
      });

      if (contextExists) {
        const updatedContext = getSearchOptions(contextValue, "what", story);
        dispatchReportConstraints("context", updatedContext, story);
      } else {
        dispatchReportConstraints("context", [], story);
      }

      const marketOptions = subsections.find(
        item => item.name === "retailer"
      )?.keywords;
      const retailerExists = marketOptions.includes(marketValue);
      if (retailerExists) {
        dispatchReportConstraints("retailer", marketValue, story);
      }

      const periodOptions = [
        ...(
          subsections.find(item => item.table === "when")?.keywords || []
        ).map(i => ({
          date: i.date,
          label: `${i.period} ${i.date}`,
          period: i.period,
          value: i.period
        })),
        { period: "custom", label: "Choose range on calendar" }
      ];
      const selectedPeriod = periodOptions.find(i => i.period === periodValue);
      dispatchReportConstraints("period", selectedPeriod, story);

      dispatchReportConstraints("metric", metricValue, story);
    }
  }, []);

  return (
    <ReportBuilder
      title={`Performance Reports ${
        story === storyEnums.IDA
          ? "(In-Home – Including Impulse)"
          : "(Out-of-Home – Excluding Impulse)"
      }`}
      icon={<PerformanceIcon />}
      description="Understand how a category or brand is performing over time, in a specific market, channel or retailer. A performance overview that also drills down to sku level."
      story={story}
      dataSet={dataSet}
      reportType={type}
    />
  );
};

NewPerformanceReport.propTypes = {
  dispatchReportConstraints: PropTypes.func,
  allSubsections: PropTypes.shape()
};

NewPerformanceReport.defaultProps = {
  dispatchReportConstraints: () => {},
  allSubsections: {}
};

const mapStateToProps = state => {
  const {
    data: { subsections = { dataSet: [] } }
  } = state;
  return { allSubsections: subsections };
};

const mapDispatchToProps = dispatch => ({
  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPerformanceReport);
