import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  getAutoRangingFavoriteReports,
  getAutoRangingRecentReports,
  getMyFavouriteReports,
  getMyRecentReports
} from "actions";
import { Paper } from "components/BaseComponents";
import { clientConfig } from "config";
import { permissionEnums, storyEnums } from "constants/enums";
import { getConstraintsFromQuery } from "utils";
import Header from "./Header";
import ReportsTable from "./ReportsTable";
import ReportInfo from "./ReportInfo";
import AutoRanging from "./AutoRanging";

const PreviousReports = props => {
  const {
    favouriteReports,
    recentReports,
    getRecentReports,
    getFavourites,
    getARRecentReports,
    getARFavourites,
    client,
    isLoadingReports,
    isLoadingFavourites,
    dataDate
  } = props;
  const { reportType } = useParams();
  const [searchText, setSearchText] = useState("");
  const [isFavourites, setIsFavourites] = useState(false);
  const { permissions, stories } = clientConfig[client] || {
    permissions: [],
    stories: []
  };
  const roles =
    (localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).roles) ||
    [];
  const isAutoRanging = roles && roles.includes("auto_ranging");

  useEffect(() => {
    getRecentReports();
    getFavourites();
    if (permissions.includes(permissionEnums.AUTO_RANGING) && isAutoRanging) {
      getARRecentReports();
      getARFavourites();
    }
  }, []);

  useEffect(() => {
    setIsFavourites(false);
  }, [reportType]);

  const getStory = () => {
    switch (reportType) {
      case "performance":
        if (
          stories.includes(storyEnums.IDA) ||
          stories.includes(storyEnums.CGA)
        )
          return [storyEnums.IDA, storyEnums.CGA];
        return [];
      case "brand-battles":
        if (stories.includes(storyEnums.BB)) return [storyEnums.BB];
        return [];
      case "trend":
        if (stories.includes(storyEnums.TREND)) return [storyEnums.TREND];
        return [];
      case "range-review":
        if (stories.includes(storyEnums.PRR)) return [storyEnums.PRR];
        return [];
      case "auto-ranging":
        if (permissions.includes(permissionEnums.AUTO_RANGING) && isAutoRanging)
          return [storyEnums.AR];
        return [];
      default:
        return [];
    }
  };

  const story = getStory(reportType, stories);

  const reports = _.uniqWith(
    [...recentReports, ...favouriteReports],
    (arrVal, othVal) => arrVal.query === othVal.query
  ).map(i => ({
    ...i,
    ...getConstraintsFromQuery(i.query),
    isFavourite: favouriteReports.map(item => item.query).includes(i.query)
  }));

  const reportsToShow = isFavourites
    ? reports.filter(i => i.isFavourite)
    : reports;

  if (story.length === 0)
    return (
      <Paper>
        <Header
          client={client}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </Paper>
    );
  if (reportType === "auto-ranging") {
    return (
      <Paper>
        <Header
          client={client}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <ReportInfo
          client={client}
          setIsFavourites={setIsFavourites}
          isFavourites={isFavourites}
          reportType={reportType}
        />
        <AutoRanging
          searchText={searchText}
          dataDate={dataDate}
          isFavourites={isFavourites}
          story={story}
          reportType={reportType}
        />
      </Paper>
    );
  }
  return (
    <Paper>
      <Header
        client={client}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <ReportInfo
        client={client}
        setIsFavourites={setIsFavourites}
        isFavourites={isFavourites}
        reportType={reportType}
      />
      <ReportsTable
        reports={reportsToShow}
        searchText={searchText}
        isLoading={isLoadingReports || isLoadingFavourites}
        dataDate={dataDate}
        reportType={reportType}
        story={story}
      />
    </Paper>
  );
};

PreviousReports.propTypes = {
  recentReports: PropTypes.arrayOf(PropTypes.shape()),
  favouriteReports: PropTypes.arrayOf(PropTypes.shape()),
  getRecentReports: PropTypes.func,
  getARRecentReports: PropTypes.func,
  getFavourites: PropTypes.func,
  getARFavourites: PropTypes.func,
  client: PropTypes.string,
  isLoadingReports: PropTypes.bool,
  isLoadingFavourites: PropTypes.bool,
  dataDate: PropTypes.shape()
};

PreviousReports.defaultProps = {
  recentReports: [],
  favouriteReports: [],
  getRecentReports: () => {},
  getARRecentReports: () => {},
  getFavourites: () => {},
  getARFavourites: () => {},
  client: "",
  isLoadingReports: false,
  isLoadingFavourites: false,
  dataDate: {}
};

const mapStateToProps = state => ({
  recentReports: state.report.myRecentReports,
  favouriteReports: state.report.myFavouriteReports,
  isLoadingReports: state.report.isLoadingMyReports,
  isLoadingFavourites: state.report.isLoadingFavourites,
  dataDate: state.data.date,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  getRecentReports: () => dispatch(getMyRecentReports()),
  getARRecentReports: () => dispatch(getAutoRangingRecentReports()),
  getFavourites: () => dispatch(getMyFavouriteReports()),
  getARFavourites: () => dispatch(getAutoRangingFavoriteReports())
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviousReports);
