import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { ReportHeader } from "components/CustomComponents";
import { getDateLabel } from "utils";

const Highlight = ({ children }) => (
  <Typography variant="h4" component="span" color="primary">
    {children}
  </Typography>
);

Highlight.propTypes = { children: PropTypes.node };

Highlight.defaultProps = { children: null };

const Header = props => {
  const {
    isLastStep,
    icon,
    title,
    description,
    story,
    product,
    context,
    retailer,
    period,
    metric,
    isEdit
  } = props;

  const getSelectedDateLabel = date => {
    if (!date) return "";
    if (date.period !== "custom") return date.label;
    if (date.name) return getDateLabel(date.name, story);
    return "";
  };

  return (
    <ReportHeader
      icon={icon}
      title={title}
      description={description}
      isLastStep={isLastStep}
      isEdit={isEdit}
      review={
        <Typography variant="h4">
          Preview: Analysis of{" "}
          <Highlight>{product.map(i => i.value).join(" ")}</Highlight>{" "}
          <Highlight>{metric}</Highlight> performance within{" "}
          <Highlight>{context.map(i => i.value).join(" ")}</Highlight> in{" "}
          <Highlight>{retailer}</Highlight> in{" "}
          <Highlight>{getSelectedDateLabel(period)}</Highlight>
        </Typography>
      }
    />
  );
};

Header.propTypes = {
  isLastStep: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  story: PropTypes.string,
  product: PropTypes.arrayOf(PropTypes.shape()),
  context: PropTypes.arrayOf(PropTypes.shape()),
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  metric: PropTypes.string,
  isEdit: PropTypes.bool
};

Header.defaultProps = {
  isLastStep: false,
  icon: null,
  title: "",
  description: "",
  story: "",
  product: [],
  context: [],
  retailer: null,
  period: null,
  metric: null,
  isEdit: false
};

export default Header;
