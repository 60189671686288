import React from "react";
import PropTypes from "prop-types";
import { Switch as MUISwitch, Stack, Typography } from "@mui/material";

const defaultStyles = color => ({
  width: 36,
  height: 20,
  p: 0,
  "& .MuiSwitch-switchBase": {
    p: 0,
    m: 0.25,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "white",
      "& + .MuiSwitch-track": { opacity: 1 },
      "&.Mui-disabled + .MuiSwitch-track": { opacity: 0.5 }
    },
    "&.Mui-disabled + .MuiSwitch-thumb": { color: "grey.200" },
    "&.Mui-disabled + .MuiSwitch-track": { opacity: 0.5 }
  },
  "& .MuiSwitch-thumb": { width: 16, height: 16 },
  "& .MuiSwitch-track": {
    borderRadius: 20,
    bgcolor: color === "secondary" ? "secondaryArray.200" : "primaryArray.200",
    opacity: 1
  }
});

const Switch = props => {
  const { styles, checked, onChange, offLabel, onLabel, color, ...otherProps } =
    props;
  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={styles}>
      <Typography variant="body1">{offLabel}</Typography>
      <MUISwitch
        checked={checked}
        onChange={onChange}
        sx={defaultStyles(color)}
        color={color}
        {...otherProps}
      />
      <Typography variant="body1">{onLabel}</Typography>
    </Stack>
  );
};

Switch.propTypes = {
  styles: PropTypes.shape(),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  offLabel: PropTypes.string,
  onLabel: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary"])
};

Switch.defaultProps = {
  styles: {},
  checked: false,
  onChange: () => {},
  offLabel: "",
  onLabel: "",
  color: "primary"
};

export default Switch;
