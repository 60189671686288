import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { AutoRangingIcon } from "components/Icons";
import { ReportHeader } from "components/CustomComponents";

const Header = props => {
  const { isLastStep, basicConstraints, bayPlanConstraints } = props;
  const { retailer, categories } = basicConstraints;
  return (
    <ReportHeader
      isLastStep={isLastStep}
      title="Auto-Ranging"
      icon={<AutoRangingIcon />}
      description="This Auto-Ranging tool will assist you on the journey to produce a set of Bay Plans for a retailer or environment. It will compare performance to the current ranging, so the more information you provide in the setup will help us return better outputs!"
      review={
        <Stack>
          <Typography variant="h4" gutterBottom>
            You have selected to Auto-Range{" "}
            {Object.values(categories).flat().join(", ")} in{" "}
            {bayPlanConstraints.length} Bay Plans within {retailer}
          </Typography>
          <Typography variant="body1" color="grey.500">
            You can edit these sections by clicking &apos;Go Back&apos;.
          </Typography>
        </Stack>
      }
    />
  );
};

Header.propTypes = {
  isLastStep: PropTypes.bool,
  basicConstraints: PropTypes.shape(),
  bayPlanConstraints: PropTypes.arrayOf(PropTypes.shape())
};

Header.defaultProps = {
  isLastStep: false,
  basicConstraints: {},
  bayPlanConstraints: []
};

export default Header;
