import React from "react";
import PropTypes from "prop-types";
import { InputAdornment, Stack } from "@mui/material";
import { TextField } from "components/BaseComponents";
import { SearchIcon } from "components/Icons";

const PageBar = props => {
  const { leftChild, rightChild, isSearch, searchText, setSearchText, styles } =
    props;

  return (
    <Stack
      direction="row"
      spacing={1.5}
      alignItems="flex-end"
      justifyContent="space-between"
      sx={{ mb: 1.5, ...styles }}
    >
      {leftChild}
      <Stack direction="row" alignItems="center" spacing={1.5}>
        {isSearch && (
          <TextField
            value={searchText}
            onChange={e => setSearchText(e.target.value.toLowerCase())}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            sx={{ "& .MuiOutlinedInput-input": { p: 0.75 } }}
          />
        )}
        {rightChild}
      </Stack>
    </Stack>
  );
};

PageBar.propTypes = {
  leftChild: PropTypes.node,
  rightChild: PropTypes.node,
  isSearch: PropTypes.bool,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  styles: PropTypes.shape()
};

PageBar.defaultProps = {
  leftChild: undefined,
  rightChild: undefined,
  isSearch: false,
  searchText: "",
  setSearchText: () => {},
  styles: {}
};

export default PageBar;
