import React from "react";
import PropTypes from "prop-types";
import { Typography, Stack } from "@mui/material";
import { Tooltip } from "components/BaseComponents";
import { MoreInfoIcon } from "components/Icons";

const SectionTitle = props => {
  const {
    order,
    title,
    subtitle,
    styles,
    isTooltip,
    tooltipTitle,
    tooltipContent
  } = props;
  return (
    <Stack spacing={1} sx={{ mb: 3, ...styles }}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ bgcolor: "grey.200", px: 1, py: 0.125, borderRadius: "50%" }}
        >
          {order}
        </Typography>
        <Typography variant="h6">{title}</Typography>
        {isTooltip && (
          <Tooltip title={tooltipTitle} content={tooltipContent}>
            <MoreInfoIcon />
          </Tooltip>
        )}
      </Stack>
      <Typography variant="body1" color="grey.500">
        {subtitle}
      </Typography>
    </Stack>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  order: PropTypes.string,
  styles: PropTypes.shape(),
  isTooltip: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  tooltipContent: PropTypes.string
};

SectionTitle.defaultProps = {
  title: "",
  subtitle: "",
  order: "",
  styles: {},
  isTooltip: false,
  tooltipTitle: "",
  tooltipContent: ""
};

export default SectionTitle;
