import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Stack } from "@mui/material";
import { WarningDialog } from "components/CustomComponents";
import { unblockUser, disableUser, enableUser } from "actions";
import ResetPassword from "./ResetPassword";
import EditUser from "./EditUser";

const UserManagementActions = props => {
  const {
    isLoading,
    dispatchUnblockUser,
    dispatchDisableUser,
    dispatchEnableUser,
    user,
    client
  } = props;
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [isUnblockOpen, setIsUnblockOpen] = useState(false);
  const [isEnableOpen, setIsEnableOpen] = useState(false);
  const [isDisableOpen, setIsDisableOpen] = useState(false);

  return (
    <Fragment>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <Button
          variant="outlined"
          size="small"
          disabled={isLoading}
          onClick={() => setIsEditOpen(true)}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          size="small"
          disabled={isLoading}
          onClick={() => setIsResetOpen(true)}
        >
          Reset Password
        </Button>
        <Button
          variant="outlined"
          size="small"
          disabled={isLoading || !user.is_blocked}
          onClick={() => setIsUnblockOpen(true)}
        >
          Unblock
        </Button>
        {user.is_enabled ? (
          <Button
            variant="outlined"
            size="small"
            disabled={isLoading}
            onClick={() => setIsDisableOpen(true)}
          >
            Disable
          </Button>
        ) : (
          <Button
            variant="outlined"
            size="small"
            disabled={isLoading}
            onClick={() => setIsEnableOpen(true)}
          >
            Enable
          </Button>
        )}
      </Stack>
      <WarningDialog
        isOpen={isUnblockOpen}
        title="Unblock User"
        content="Are you sure you want to unblock this user?"
        onClose={() => setIsUnblockOpen(false)}
        onConfirm={() => {
          dispatchUnblockUser(user.email);
          setIsUnblockOpen(false);
        }}
        confirmButton="Unblock"
      />
      <WarningDialog
        isOpen={isEnableOpen}
        title="Enable User"
        content="Are you sure you want to enable this user?"
        onClose={() => setIsEnableOpen(false)}
        onConfirm={() => {
          dispatchEnableUser(user.email);
          setIsEnableOpen(false);
        }}
        confirmButton="Enable"
      />
      <WarningDialog
        isOpen={isDisableOpen}
        title="Disable User"
        content="Are you sure you want to disable this user?"
        onClose={() => setIsDisableOpen(false)}
        onConfirm={() => {
          dispatchDisableUser(user.email);
          setIsDisableOpen(false);
        }}
        confirmButton="Disable"
      />
      <EditUser
        isLoading={isLoading}
        client={client}
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        user={user}
      />
      <ResetPassword
        isLoading={isLoading}
        user={user}
        isOpen={isResetOpen}
        onClose={() => setIsResetOpen(false)}
      />
    </Fragment>
  );
};

UserManagementActions.propTypes = {
  isLoading: PropTypes.bool,
  dispatchDisableUser: PropTypes.func,
  dispatchUnblockUser: PropTypes.func,
  dispatchEnableUser: PropTypes.func,
  user: PropTypes.shape(),
  client: PropTypes.string
};

UserManagementActions.defaultProps = {
  isLoading: false,
  dispatchDisableUser: () => {},
  dispatchUnblockUser: () => {},
  dispatchEnableUser: () => {},
  user: {},
  client: ""
};

const mapDispatchToProps = dispatch => ({
  dispatchDisableUser: email => dispatch(disableUser(email)),
  dispatchEnableUser: email => dispatch(enableUser(email)),
  dispatchUnblockUser: email => dispatch(unblockUser(email))
});

export default connect(null, mapDispatchToProps)(UserManagementActions);
