import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Box, Grid, Stack } from "@mui/material";
import { Legend } from "components/CustomComponents";
import { IMPACT_ITEMS } from "constants/reportConstants";
import ReportTable from "../ReportTable";
import ReportChart from "../ReportChart";
import ReportTypography from "../ReportTypography";

const TripleTableChart = props => {
  const { config, tables, charts, texts } = props;
  const legendItems = _.uniq(_.flatten(charts.map(i => i.y.map(j => j[0]))));
  const legend = IMPACT_ITEMS.filter(i => legendItems.includes(i.name));
  return (
    <Box sx={{ height: 1, width: 1, mx: 2 }}>
      <Grid container spacing={1.5}>
        {tables.map((i, k) => {
          const chart = charts[k];
          const text = texts[k]?.text;
          return (
            <Grid item xs={4} key={_.uniqueId()}>
              <Stack spacing={1}>
                <Box sx={{ height: 30 }}>
                  <ReportTypography
                    textAlign="center"
                    fontSize={12}
                    fontWeight="medium"
                    color="common.black"
                    config={config}
                  >
                    {i.title}
                  </ReportTypography>
                </Box>
                <ReportTable
                  data={{ columns: i.columns, index: i.index, values: i.data }}
                  display={{
                    gradient: i.gradient,
                    highlight: i.tp_highlight,
                    isSmall: true
                  }}
                  config={config}
                />
                <Box
                  sx={{
                    height: 80,
                    border: 1,
                    borderRadius: 1,
                    borderColor: "grey.200",
                    py: 0.5,
                    px: 1
                  }}
                >
                  <ReportTypography
                    fontSize={12}
                    color="common.black"
                    config={config}
                    gutterBottom
                  >
                    {text}
                  </ReportTypography>
                </Box>
                {chart.x.length > 0 && chart.y.length > 0 && (
                  <Box sx={{ position: "relative", width: 1, height: 100 }}>
                    <ReportChart
                      data={{
                        x: chart.x,
                        y: chart.y,
                        type: chart.style.chart_style.type
                      }}
                      display={{ ...chart.style, isTiny: true }}
                      layout={{ height: 100, width: 100, top: 0, left: 0 }}
                      config={config}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Legend items={legend} styles={{ justifyContent: "center" }} />
        </Grid>
      </Grid>
    </Box>
  );
};

TripleTableChart.propTypes = {
  config: PropTypes.shape(),
  tables: PropTypes.arrayOf(PropTypes.shape()),
  charts: PropTypes.arrayOf(PropTypes.shape()),
  texts: PropTypes.arrayOf(PropTypes.shape())
};

TripleTableChart.defaultProps = {
  config: {},
  tables: [],
  charts: [],
  texts: []
};

export default TripleTableChart;
