import defaultState from "./defaultState";
import {
  FILTER_STATIC_DATA_REQUEST,
  FILTER_STATIC_DATA_SUCCESS,
  FILTER_STATIC_DATA_ERROR,
  REMOVE_STATIC_DATA_FILTER,
  CLEAR_ALL_SEARCH_DATA,
  CLEAR_STORY_SEARCH_DATA,
  GET_CONTEXT_REQUEST,
  GET_CONTEXT_SUCCESS,
  GET_CONTEXT_ERROR,
  REMOVE_CONTEXT
} from "../constants/actionConstants";

// eslint-disable-next-line default-param-last
export default (state = defaultState.search, action) => {
  const { type } = action;

  switch (type) {
    case FILTER_STATIC_DATA_REQUEST: {
      return {
        ...state,
        isFilteringData: true,
        error: ""
      };
    }

    case FILTER_STATIC_DATA_SUCCESS: {
      return {
        ...state,
        isFilteringData: false,
        filteredSubsections: {
          ...state.filteredSubsections,
          [action.story]: action.data.subsections
        },
        error: "",
        isFilteredData: { ...state.isFilteredData, [action.story]: true }
      };
    }

    case FILTER_STATIC_DATA_ERROR: {
      return {
        ...state,
        isFilteringData: false,
        error: action.error
      };
    }

    case REMOVE_STATIC_DATA_FILTER: {
      return {
        ...state,
        filteredSubsections: {
          ...state.filteredSubsections,
          [action.story]: []
        },
        error: "",
        isFilteredData: { ...state.isFilteredData, [action.story]: false }
      };
    }

    case CLEAR_ALL_SEARCH_DATA: {
      return defaultState.search;
    }

    case CLEAR_STORY_SEARCH_DATA: {
      return {
        ...state,
        filteredSubsections: {
          ...state.filteredSubsections,
          [action.story]: []
        },
        isFilteredData: { ...state.isFilteredData, [action.story]: false },
        contextSubsections: { ...state.contextSubsections, [action.story]: [] },
        suggestedContext: { ...state.suggestedContext, [action.story]: [] }
      };
    }

    case GET_CONTEXT_REQUEST: {
      return {
        ...state,
        isLoadingContext: true
      };
    }

    case GET_CONTEXT_SUCCESS: {
      return {
        ...state,
        contextSubsections: {
          ...state.contextSubsections,
          [action.story]: action.data.subsections
        },
        isLoadingContext: false,
        suggestedContext: {
          ...state.suggestedContext,
          [action.story]: action.data.suggestedContext
        }
      };
    }

    case GET_CONTEXT_ERROR: {
      return {
        ...state,
        error: action.error,
        isLoadingContext: false
      };
    }

    case REMOVE_CONTEXT: {
      return {
        ...state,
        contextSubsections: { ...state.contextSubsections, [action.story]: [] },
        isLoadingContext: false,
        suggestedContext: { ...state.suggestedContext, [action.story]: [] }
      };
    }

    default: {
      return state;
    }
  }
};
