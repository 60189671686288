import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Stack } from "@mui/material";
import { TickCircleIcon } from "components/Icons";
import { PasswordField } from "components/BaseComponents";

import { changePassword } from "actions";

const ChangePassword = props => {
  const { submitChangePassword, isLoading } = props;
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const reset = () => {
    setPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  return (
    <Fragment>
      <Stack spacing={2} sx={{ maxWidth: 400 }}>
        <PasswordField
          label="Current Password"
          placeholder="Current Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          disabled={isLoading}
        />
        <PasswordField
          label="New Password"
          placeholder="New Password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          disabled={isLoading}
        />
        <PasswordField
          label="Confirm New Password"
          placeholder="Confirm New Password"
          value={confirmNewPassword}
          onChange={e => setConfirmNewPassword(e.target.value)}
          disabled={isLoading}
        />
      </Stack>
      <Button
        variant="contained"
        endIcon={<TickCircleIcon />}
        disabled={!password || !newPassword || !confirmNewPassword}
        onClick={() =>
          submitChangePassword(password, newPassword, confirmNewPassword, reset)
        }
        sx={{ mt: 3 }}
      >
        Change Password
      </Button>
    </Fragment>
  );
};

ChangePassword.propTypes = {
  submitChangePassword: PropTypes.func,
  isLoading: PropTypes.bool
};

ChangePassword.defaultProps = {
  submitChangePassword: () => {},
  isLoading: false
};

const mapDispatchToProps = dispatch => ({
  submitChangePassword: (
    password,
    newPassword,
    confirmNewPassword,
    onSuccess
  ) =>
    dispatch(
      changePassword(password, newPassword, confirmNewPassword, onSuccess)
    )
});

export default connect(null, mapDispatchToProps)(ChangePassword);
