import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Stack, Typography, Grid, Skeleton } from "@mui/material";
import { lookoutLogo } from "images";
import { getChannelConfig } from "config";
import InsightCard from "./InsightCard";
import NoInsights from "./NoInsights";

const Insights = props => {
  const {
    dataDate,
    client,
    isLoading,
    channel,
    isPreference,
    userInsights,
    clientInsights,
    clientName
  } = props;
  const { dataSet, isMonth } = getChannelConfig(channel);
  const isUserInsight = userInsights.length > 0;
  const isClientInsight = clientInsights.length > 0;

  if (isLoading) {
    return (
      <Grid container spacing={3}>
        {[...Array(3)].map(() => (
          <Grid item xs={12} md={6} lg={4} key={_.uniqueId()}>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width="100%" height="30px" />
              <Skeleton variant="rounded" width="30%" height="20px" />
              <Skeleton variant="rounded" width="100%" height="100px" />
              <Skeleton variant="rounded" width="100%" height="80px" />
              <Skeleton variant="rounded" width="100%" height="40px" />
            </Stack>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Fragment>
      {!isUserInsight && (
        <NoInsights
          dataDate={dataDate}
          isPreference={isPreference}
          channel={channel}
          client={client}
        />
      )}
      {isUserInsight && (
        <Grid container spacing={3}>
          {userInsights.map(item => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              key={item.extended_analysis_title + item.top_analysis}
            >
              <InsightCard
                insight={item}
                dataDate={dataDate[dataSet]}
                client={client}
                isMonth={isMonth}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {isClientInsight && (
        <Fragment>
          <Stack
            id="client-insights"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ pb: 1.5, mt: -8, pt: 11 }} // top margin and padding needed to offset sticky menu bar
          >
            <img alt="lookout" src={lookoutLogo} style={{ width: 40 }} />
            <Typography variant="subtitle2" color="grey.800">
              Top {clientName} Insights
            </Typography>
          </Stack>
          <Grid container spacing={3}>
            {clientInsights.map(item => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={item.extended_analysis_title + item.top_analysis}
              >
                <InsightCard
                  insight={item}
                  dataDate={dataDate[dataSet]}
                  client={client}
                  isMonth={isMonth}
                />
              </Grid>
            ))}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

Insights.propTypes = {
  dataDate: PropTypes.shape(),
  client: PropTypes.string,
  isLoading: PropTypes.bool,
  channel: PropTypes.string,
  isPreference: PropTypes.bool,
  userInsights: PropTypes.arrayOf(PropTypes.shape()),
  clientInsights: PropTypes.arrayOf(PropTypes.shape()),
  clientName: PropTypes.string
};

Insights.defaultProps = {
  dataDate: {},
  client: "",
  isLoading: false,
  channel: "",
  isPreference: false,
  userInsights: [],
  clientInsights: [],
  clientName: ""
};

export default Insights;
