import {
  REPORT_CONSTRAINTS,
  CLEAR_STORY_CONSTRAINTS,
  CLEAR_ALL_CONSTRAINTS
} from "constants/actionConstants";

export const reportConstraints = (constraintType, constraints, story) => ({
  type: REPORT_CONSTRAINTS,
  constraintType,
  constraints,
  story
});

export const clearStoryConstraints = story => ({
  type: CLEAR_STORY_CONSTRAINTS,
  story
});

export const clearAllConstraints = () => ({ type: CLEAR_ALL_CONSTRAINTS });
