import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import ReportTypography from "../ReportTypography";

const Title = props => {
  const { text, config, color } = props;
  const date = text.find(i => i.key === "date");
  const scope = text.find(i => i.key === "scope");
  return (
    <Fragment>
      <ReportTypography fontSize={14} color={color} config={config}>
        {date?.text}
      </ReportTypography>
      {scope && (
        <Stack spacing={0.25} sx={{ my: 1, py: 1 }}>
          {scope.text?.map(i => (
            <ReportTypography
              fontSize={12}
              color={color}
              config={config}
              key={i}
            >
              {i}
            </ReportTypography>
          ))}
        </Stack>
      )}
    </Fragment>
  );
};

Title.propTypes = {
  text: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape(),
  color: PropTypes.string
};

Title.defaultProps = {
  text: [],
  config: {},
  color: ""
};

export default Title;
