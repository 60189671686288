import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import _ from "lodash";
import { Tooltip, Stack, IconButton } from "@mui/material";
import { Menu } from "components/BaseComponents";
import {
  StarIcon,
  StarFilledIcon,
  ViewIcon,
  EditIcon,
  PerformanceIcon,
  BrandBattlesIcon,
  MoreIcon,
  FocusIcon,
  ToolsIcon
} from "components/Icons";
import { PROTOTYPE_ICONS } from "constants/reportConstants";
import { permissionEnums, storyEnums } from "constants/enums";
import { clientConfig } from "config";
import {
  addToFavourites,
  changeAutoRangingFavoriteStatus,
  removeFromFavourites
} from "actions";
import {
  AUTO_RANGING_REPORT_INITIAL_ROUTE,
  PREVIOUS_REPORTS_AUTO_RANGING_ROUTE,
  EDIT_REPORT_TYPE_ROUTE,
  EDIT_AUTO_RANGING_INITIAL_ROUTE,
  VIEW_REPORT_ROUTE,
  NEW_IH_PERFORMANCE_REPORT_ROUTE,
  NEW_OOH_PERFORMANCE_REPORT_ROUTE,
  NEW_IH_BRAND_BATTLES_REPORT_ROUTE,
  EDIT_REPORT_FOCUS_TYPE_ROUTE,
  TOOL_SINGLE_ROUTE
} from "constants/viewRoutes";
import logger from "logger";
import { getFocusFromQuery, rebuildQueryString } from "utils";

const Actions = props => {
  const {
    isAutoRanging,
    report,
    addFavourite,
    removeFavourite,
    changeARFavourite,
    isLoading,
    isARLoading,
    reportType,
    client,
    prototypes
  } = props;
  const { permissions, stories } = clientConfig[client] || {
    permissions: [],
    stories: []
  };
  const { isFavourite, query, reportId, story, constraints, constraintsHash } =
    report;
  const { isFocus, focusType } = getFocusFromQuery(query);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFavoriteReport = () => {
    if (isAutoRanging) {
      return changeARFavourite({
        constraints,
        operation: isFavourite ? "remove" : "add",
        constraintsHash
      });
    }
    return isFavourite
      ? removeFavourite({ query, source: "previous_reports" })
      : addFavourite({ query, source: "previous_reports" });
  };

  const handleViewReport = () => {
    if (isAutoRanging)
      return generatePath(AUTO_RANGING_REPORT_INITIAL_ROUTE, {
        reportId,
        story
      });
    return { pathname: VIEW_REPORT_ROUTE, search: query };
  };

  const handleEditReport = () => {
    if (isAutoRanging)
      return {
        pathname: generatePath(EDIT_AUTO_RANGING_INITIAL_ROUTE, {
          id: reportId
        }),
        state: { from: PREVIOUS_REPORTS_AUTO_RANGING_ROUTE }
      };
    if (isFocus)
      return {
        pathname: generatePath(EDIT_REPORT_FOCUS_TYPE_ROUTE, {
          focusType
        }),
        search: query
      };
    return {
      pathname: generatePath(EDIT_REPORT_TYPE_ROUTE, {
        reportType
      }),
      search: query
    };
  };

  const relatedReports = [
    {
      title: "IH Performance Report",
      path:
        story === storyEnums.IDA
          ? `${VIEW_REPORT_ROUTE}?${rebuildQueryString(query)}`
          : `${NEW_IH_PERFORMANCE_REPORT_ROUTE}?${rebuildQueryString(query)}`,
      isVisible: stories.includes(storyEnums.IDA),
      icon: <PerformanceIcon sx={{ fontSize: 15, color: "primary.main" }} />
    },
    {
      title: "OOH Performance Report",
      path:
        story === storyEnums.CGA
          ? `${VIEW_REPORT_ROUTE}?${rebuildQueryString(query)}`
          : `${NEW_OOH_PERFORMANCE_REPORT_ROUTE}?${rebuildQueryString(query)}`,
      isVisible: stories.includes(storyEnums.CGA),
      icon: <PerformanceIcon sx={{ fontSize: 15, color: "primary.main" }} />
    },
    {
      title: "Focus On: Share Performance",
      path: `${VIEW_REPORT_ROUTE}?${rebuildQueryString(query, "performance")}`,
      isVisible:
        stories.includes(storyEnums.FP) &&
        (stories.includes(storyEnums.IDA) || stories.includes(storyEnums.CGA)),
      icon: <FocusIcon sx={{ fontSize: 15, color: "primary.main" }} />,
      action: () =>
        logger({
          date: new Date().toISOString(),
          action: "VIEW_FOCUS_REPORT",
          focus_type: "performance",
          source: "previous_reports",
          query: rebuildQueryString(query, "performance"),
          story
        })
    },
    {
      title: "Focus On: NPD",
      path: `${VIEW_REPORT_ROUTE}?${rebuildQueryString(query, "npd")}`,
      isVisible:
        stories.includes(storyEnums.FP) &&
        (stories.includes(storyEnums.IDA) || stories.includes(storyEnums.CGA)),
      icon: <FocusIcon sx={{ fontSize: 15, color: "primary.main" }} />,
      action: () =>
        logger({
          date: new Date().toISOString(),
          action: "VIEW_FOCUS_REPORT",
          focus_type: "npd",
          source: "previous_reports",
          query: rebuildQueryString(query, "npd"),
          story
        })
    },
    {
      title: "Focus On: Star- & Under-Performers",
      path: `${VIEW_REPORT_ROUTE}?${rebuildQueryString(query, "stars")}`,
      isVisible:
        stories.includes(storyEnums.FP) &&
        (stories.includes(storyEnums.IDA) || stories.includes(storyEnums.CGA)),
      icon: <FocusIcon sx={{ fontSize: 15, color: "primary.main" }} />,
      action: () =>
        logger({
          date: new Date().toISOString(),
          action: "VIEW_FOCUS_REPORT",
          focus_type: "stars",
          source: "previous_reports",
          query: rebuildQueryString(query, "stars"),
          story
        })
    },
    {
      title: "Brand Battles",
      path: `${NEW_IH_BRAND_BATTLES_REPORT_ROUTE}?${query}`,
      isVisible: stories.includes(storyEnums.BB),
      icon: <BrandBattlesIcon sx={{ fontSize: 15, color: "primary.main" }} />
    }
  ].filter(i => i.isVisible);

  const prototypeList = prototypes
    ?.map(i => {
      const cons = {
        period: report.period,
        retailer: report.market,
        context: _.zipObject(
          _.map(report.context, "subsection"),
          _.map(report.context, "name")
        ),
        product: _.zipObject(
          _.map(report.product, "subsection"),
          _.map(report.product, "name")
        ),
        metric: report.metric
      };
      const Icon = PROTOTYPE_ICONS[i.service_name] || ToolsIcon;
      const path = generatePath(TOOL_SINGLE_ROUTE, { type: i.service_name });
      const search = `constraints=${encodeURIComponent(JSON.stringify(cons))}`;
      return {
        title: `Tools (Beta): ${i.display_name}`,
        path: `${path}?${search}`,
        icon: <Icon sx={{ color: "primary.main", fontSize: 18 }} />,
        isVisible:
          permissions.includes(permissionEnums.STREAMLIT) &&
          stories.includes(storyEnums.IDA)
      };
    })
    .filter(i => i.isVisible);

  return (
    <Stack direction="row" justifyContent="center">
      <Tooltip
        title={isFavourite ? "Remove from Favourites" : "Add to Favourites"}
      >
        <IconButton
          color="grey.500"
          size="small"
          onClick={() => handleFavoriteReport()}
          disabled={
            (!isAutoRanging && isLoading) || (isAutoRanging && isARLoading)
          }
        >
          {isFavourite ? (
            <StarFilledIcon color="neutral" sx={{ fontSize: 18, m: 0.5 }} />
          ) : (
            <StarIcon sx={{ fontSize: 18, m: 0.5 }} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit Report">
        <IconButton
          color="grey.500"
          size="small"
          component={Link}
          to={handleEditReport()}
        >
          <EditIcon sx={{ fontSize: 18, m: 0.5 }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Report">
        <IconButton
          color="grey.500"
          size="small"
          component={Link}
          to={handleViewReport()}
        >
          <ViewIcon sx={{ fontSize: 18, m: 0.5 }} />
        </IconButton>
      </Tooltip>
      {reportType === "performance" && (
        <Fragment>
          <Tooltip title="Other Reports" placement="top">
            <IconButton
              color="grey.500"
              size="small"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <MoreIcon sx={{ fontSize: 18, m: 0.5 }} />
            </IconButton>
          </Tooltip>
          <Menu
            menuItems={
              story === storyEnums.IDA
                ? [...relatedReports, ...prototypeList]
                : relatedReports
            }
            handleClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            newTab
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          />
        </Fragment>
      )}
    </Stack>
  );
};

Actions.propTypes = {
  report: PropTypes.shape(),
  isLoading: PropTypes.bool,
  isAutoRanging: PropTypes.bool,
  addFavourite: PropTypes.func,
  removeFavourite: PropTypes.func,
  changeARFavourite: PropTypes.func,
  isARLoading: PropTypes.bool,
  reportType: PropTypes.string,
  client: PropTypes.string,
  prototypes: PropTypes.arrayOf(PropTypes.shape())
};

Actions.defaultProps = {
  report: {},
  isLoading: false,
  isAutoRanging: false,
  addFavourite: () => {},
  removeFavourite: () => {},
  changeARFavourite: () => {},
  isARLoading: false,
  reportType: "",
  client: "",
  prototypes: []
};

const mapStateToProps = state => ({
  isLoading: state.report.isLoadingAddToFavourite,
  isARLoading: state.report.isLoadingChangeARFavourite,
  client: state.user.user.client,
  prototypes: state.streamlit.prototypes
});

const mapDispatchToProps = dispatch => ({
  addFavourite: report => dispatch(addToFavourites(report)),
  removeFavourite: report => dispatch(removeFromFavourites(report)),
  changeARFavourite: ({ constraints, operation, constraintsHash }) =>
    dispatch(
      changeAutoRangingFavoriteStatus({
        constraints,
        operation,
        constraintsHash
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
