import { alertLevels } from "constants/enums";
import {
  REPORT_URL,
  USER_TRACKING_URL,
  LIST_RECENT_REPORTS_URL,
  CHANGE_FAVOURITES_URL,
  LIST_FAVOURITES_URL,
  GET_REPORT_URL,
  AUTO_RANGING_RECENT_REPORTS_URL,
  AUTO_RANGING_FAVORITE_REPORTS_URL,
  AUTO_RANGING_CHANGE_FAVORITE_STATUS_URL,
  FLUSH_CACHE_URL
} from "../constants";
import {
  LOAD_REPORT_REQUEST,
  LOAD_REPORT_SUCCESS,
  LOAD_REPORT_ERROR,
  ADD_USER_TRACKING_REQUEST,
  ADD_USER_TRACKING_SUCCESS,
  ADD_USER_TRACKING_ERROR,
  GET_MY_RECENT_REPORTS_REQUEST,
  GET_MY_RECENT_REPORTS_SUCCESS,
  GET_MY_RECENT_REPORTS_ERROR,
  GET_MY_FAVOURITE_REPORTS_REQUEST,
  GET_MY_FAVOURITE_REPORTS_SUCCESS,
  GET_MY_FAVOURITE_REPORTS_ERROR,
  ADD_TO_FAVOURITES_REQUEST,
  ADD_TO_FAVOURITES_SUCCESS,
  ADD_TO_FAVOURITES_ERROR,
  REMOVE_FROM_FAVOURITES_REQUEST,
  REMOVE_FROM_FAVOURITES_SUCCESS,
  REMOVE_FROM_FAVOURITES_ERROR,
  GET_REPORT_ID,
  GET_AUTO_RANGING_RECENT_REPORTS_REQUEST,
  GET_AUTO_RANGING_RECENT_REPORTS_SUCCESS,
  GET_AUTO_RANGING_RECENT_REPORTS_ERROR,
  AUTO_RANGING_CHANGE_FAVORITE_STATUS_REQUEST,
  AUTO_RANGING_CHANGE_FAVORITE_STATUS_SUCCESS,
  AUTO_RANGING_CHANGE_FAVORITE_STATUS_ERROR,
  GET_AUTO_RANGING_FAVORITE_REPORTS_REQUEST,
  GET_AUTO_RANGING_FAVORITE_REPORTS_SUCCESS,
  GET_AUTO_RANGING_FAVORITE_REPORTS_ERROR,
  FLUSH_CACHE_REQUEST,
  FLUSH_CACHE_SUCCESS,
  FLUSH_CACHE_ERROR
} from "../constants/actionConstants";
import { addNotification } from "./notification";
import { getConstraintsFromQuery } from "../utils";
import fetchInstance from "../utils/fetchInstance";
import logger from "../logger";

function loadReportRequest(report) {
  return {
    type: LOAD_REPORT_REQUEST,
    report
  };
}

function loadReportSuccess(report) {
  return {
    type: LOAD_REPORT_SUCCESS,
    report
  };
}

function loadReportError(error) {
  return {
    type: LOAD_REPORT_ERROR,
    error
  };
}

function addUserTrackingRequest() {
  return {
    type: ADD_USER_TRACKING_REQUEST
  };
}

function addUserTrackingSuccess() {
  return {
    type: ADD_USER_TRACKING_SUCCESS
  };
}

function addUserTrackingError(error) {
  return {
    type: ADD_USER_TRACKING_ERROR,
    error
  };
}

function addUserTracking(dispatch, userId, reportId, queryString, success) {
  return fetchInstance(USER_TRACKING_URL, {
    method: "POST",
    body: JSON.stringify({
      user_id: userId,
      report_id: reportId,
      query: queryString,
      success
    }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(addUserTrackingSuccess());
        } else {
          dispatch(addUserTrackingError(res));
        }
      },
      err => {
        dispatch(addUserTrackingError(err.message));
      }
    );
}

export const fetchReport = (queryString, date, client) => dispatch => {
  dispatch(loadReportRequest(queryString));
  try {
    if (queryString.length === 0) {
      throw new Error(
        "You did not provide a search query. This may be because this link is corrupted. Try making a new report."
      );
    }
    // // currently set to 4 search terms, but there seem to be reports that run with only 3 search terms.
    if (queryString.match(/=/g).length < 4) {
      throw new Error(
        "You did not provide enough search terms. Try adding more search terms."
      );
    }
    const params = `${queryString}&datadate=${date}&client=${client}&response_type=slide_deck`;
    return fetchInstance(`${REPORT_URL}?${params}`, {
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        data => {
          if (data.status === "error") {
            return dispatch(loadReportError(data.text));
          }
          return dispatch({ type: GET_REPORT_ID, id: data.id });
        },
        err => dispatch(loadReportError(err.message))
      );
  } catch (err) {
    return dispatch(loadReportError(err.message));
  }
};

export const getReport =
  (client, story, reportId, queryString, userId, status, error) => dispatch => {
    const constraints = getConstraintsFromQuery(queryString);
    if (status === "error") {
      // add to user analytics table
      dispatch(addUserTrackingRequest());
      addUserTracking(dispatch, userId, reportId, queryString, false);
      logger({
        date: new Date().toISOString(),
        action: "VIEW_REPORT",
        user_id: userId,
        query: queryString,
        report_id: reportId,
        success: false,
        story,
        client,
        error_message: error,
        constraints
      });
      return dispatch(loadReportError(error));
    }
    const params = `client=${client}&story=${story}&report_id=${reportId}&response_type=slide_deck`;
    return fetchInstance(`${GET_REPORT_URL}?${params}`, {
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        data => {
          const { report, title, navbar_order: navigation } = JSON.parse(data);
          // add to user analytics table
          dispatch(addUserTrackingRequest());
          addUserTracking(dispatch, userId, reportId, queryString, true);
          logger({
            date: new Date().toISOString(),
            action: "VIEW_REPORT",
            user_id: userId,
            query: queryString,
            report_id: reportId,
            success: true,
            story,
            client,
            constraints
          });
          return dispatch(
            loadReportSuccess({
              data: report,
              title,
              navigation,
              client,
              id: reportId,
              story
            })
          );
        },
        err => {
          // add to user analytics table
          dispatch(addUserTrackingRequest());
          addUserTracking(dispatch, userId, reportId, queryString, false);
          logger({
            date: new Date().toISOString(),
            action: "VIEW_REPORT",
            user_id: userId,
            query: queryString,
            report_id: reportId,
            success: false,
            story,
            client,
            error_message: err.message,
            constraints
          });
          return dispatch(loadReportError(err.message));
        }
      );
  };

function getMyRecentReportsRequest() {
  return {
    type: GET_MY_RECENT_REPORTS_REQUEST
  };
}

function getMyRecentReportsSuccess(myRecentReports) {
  return {
    type: GET_MY_RECENT_REPORTS_SUCCESS,
    myRecentReports
  };
}

function getMyRecentReportsError(error) {
  return {
    type: GET_MY_RECENT_REPORTS_ERROR,
    error
  };
}

export const getMyRecentReports = () => dispatch => {
  dispatch(getMyRecentReportsRequest());
  return fetchInstance(LIST_RECENT_REPORTS_URL, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          dispatch(getMyRecentReportsError(res));
          dispatch(addNotification(res, alertLevels.ERROR));
        } else {
          dispatch(getMyRecentReportsSuccess(res));
        }
      },
      err => {
        dispatch(getMyRecentReportsError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      }
    );
};

function getFavouriteMyReportsRequest() {
  return {
    type: GET_MY_FAVOURITE_REPORTS_REQUEST
  };
}

function getMyFavouriteReportsSuccess(myFavouriteReports) {
  return {
    type: GET_MY_FAVOURITE_REPORTS_SUCCESS,
    myFavouriteReports
  };
}

function getMyFavouriteReportsError(error) {
  return {
    type: GET_MY_FAVOURITE_REPORTS_ERROR,
    error
  };
}

export const getMyFavouriteReports = () => dispatch => {
  dispatch(getFavouriteMyReportsRequest());
  return fetchInstance(LIST_FAVOURITES_URL, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          dispatch(getMyFavouriteReportsError(res));
          dispatch(addNotification(res, alertLevels.ERROR));
        } else {
          dispatch(getMyFavouriteReportsSuccess(res));
        }
      },
      err => {
        dispatch(getMyFavouriteReportsError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      }
    );
};

function addToFavouritesRequest() {
  return {
    type: ADD_TO_FAVOURITES_REQUEST
  };
}

function addToFavouritesSuccess(report) {
  return {
    type: ADD_TO_FAVOURITES_SUCCESS,
    report
  };
}

function addToFavouritesError(error) {
  return {
    type: ADD_TO_FAVOURITES_ERROR,
    error
  };
}

export const addToFavourites = report => dispatch => {
  const { query, source } = report;
  const constraints = getConstraintsFromQuery(query);
  dispatch(addToFavouritesRequest());
  logger({
    date: new Date().toISOString(),
    action: "ADD_FAVORITE_REPORT",
    query,
    source,
    constraints
  });
  return fetchInstance(CHANGE_FAVOURITES_URL, {
    method: "POST",
    body: JSON.stringify({ query, param: "add" }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res?.success) {
          dispatch(addToFavouritesSuccess(report));
          dispatch(
            addNotification("Report added to favourites", alertLevels.SUCCESS)
          );
        } else {
          dispatch(addToFavouritesError(res?.message));
          dispatch(addNotification(res?.message, alertLevels.ERROR));
        }
      },
      err => {
        dispatch(addToFavouritesError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      }
    );
};

function removeFromFavouritesRequest() {
  return {
    type: REMOVE_FROM_FAVOURITES_REQUEST
  };
}

function removeFromFavouritesSuccess(query) {
  return {
    type: REMOVE_FROM_FAVOURITES_SUCCESS,
    query
  };
}

function removeFromFavouritesError(error) {
  return {
    type: REMOVE_FROM_FAVOURITES_ERROR,
    error
  };
}

export const removeFromFavourites = report => dispatch => {
  const { query, source } = report;
  const constraints = getConstraintsFromQuery(query);
  dispatch(removeFromFavouritesRequest());
  logger({
    date: new Date().toISOString(),
    action: "REMOVE_FAVORITE_REPORT",
    query,
    source,
    constraints
  });
  return fetchInstance(CHANGE_FAVOURITES_URL, {
    method: "POST",
    body: JSON.stringify({ query, param: "remove" }),
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (res?.success) {
          dispatch(removeFromFavouritesSuccess(query));
          dispatch(
            addNotification("Report removed from favourites", alertLevels.INFO)
          );
        } else {
          dispatch(addNotification(res?.message, alertLevels.WARNING));
          dispatch(removeFromFavouritesError(res?.message));
        }
      },
      err => {
        dispatch(addNotification(err.message, alertLevels.WARNING));
        dispatch(removeFromFavouritesError(err.message));
      }
    );
};

function getAutoRangingRecentReportsRequest() {
  return {
    type: GET_AUTO_RANGING_RECENT_REPORTS_REQUEST
  };
}

function getAutoRangingRecentReportsSuccess(reports) {
  return {
    type: GET_AUTO_RANGING_RECENT_REPORTS_SUCCESS,
    reports
  };
}

function getAutoRangingRecentReportsError(error) {
  return {
    type: GET_AUTO_RANGING_RECENT_REPORTS_ERROR,
    error
  };
}

export const getAutoRangingRecentReports = () => dispatch => {
  dispatch(getAutoRangingRecentReportsRequest());
  return fetchInstance(AUTO_RANGING_RECENT_REPORTS_URL, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          throw new Error(res);
        } else {
          const { data: { reports = {} } = {} } = res || {};
          dispatch(getAutoRangingRecentReportsSuccess(reports));
        }
      },
      err => {
        dispatch(getAutoRangingRecentReportsError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      }
    );
};

function getAutoRangingFavoriteReportsRequest() {
  return {
    type: GET_AUTO_RANGING_FAVORITE_REPORTS_REQUEST
  };
}

function getAutoRangingFavoriteReportsSuccess(reports) {
  return {
    type: GET_AUTO_RANGING_FAVORITE_REPORTS_SUCCESS,
    reports
  };
}

function getAutoRangingFavoriteReportsError(error) {
  return {
    type: GET_AUTO_RANGING_FAVORITE_REPORTS_ERROR,
    error
  };
}

export const getAutoRangingFavoriteReports = () => dispatch => {
  dispatch(getAutoRangingFavoriteReportsRequest());
  return fetchInstance(AUTO_RANGING_FAVORITE_REPORTS_URL, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          throw new Error(res);
        } else {
          const { data: { reports = {} } = {} } = res || {};
          dispatch(getAutoRangingFavoriteReportsSuccess(reports));
        }
      },
      err => {
        dispatch(getAutoRangingFavoriteReportsError(err.message));
        dispatch(addNotification(err.message, alertLevels.ERROR));
      }
    );
};

function changeAutoRangingFavoriteStatusRequest() {
  return {
    type: AUTO_RANGING_CHANGE_FAVORITE_STATUS_REQUEST
  };
}

function changeAutoRangingFavoriteStatusSuccess({
  constraintsHash,
  operation
}) {
  return {
    type: AUTO_RANGING_CHANGE_FAVORITE_STATUS_SUCCESS,
    constraintsHash,
    operation
  };
}

function changeAutoRangingFavoriteStatusError(error) {
  return {
    type: AUTO_RANGING_CHANGE_FAVORITE_STATUS_ERROR,
    error
  };
}

export const changeAutoRangingFavoriteStatus =
  ({ constraints, operation, constraintsHash }) =>
  dispatch => {
    dispatch(changeAutoRangingFavoriteStatusRequest());
    return fetchInstance(AUTO_RANGING_CHANGE_FAVORITE_STATUS_URL, {
      method: "POST",
      body: JSON.stringify({ constraints, operation }),
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          if (String(res).startsWith("ERROR")) {
            throw new Error(res);
          } else {
            dispatch(
              changeAutoRangingFavoriteStatusSuccess({
                constraintsHash,
                operation
              })
            );
            const successMessage = `The report was successfully ${
              operation === "add" ? "added to" : "removed from"
            } favourites.`;
            dispatch(addNotification(successMessage, alertLevels.INFO));
          }
        },
        err => {
          dispatch(changeAutoRangingFavoriteStatusError(err.message));
          dispatch(addNotification(err.message, alertLevels.ERROR));
        }
      );
  };

function flushCacheRequest() {
  return {
    type: FLUSH_CACHE_REQUEST
  };
}

function flushCacheSuccess() {
  return {
    type: FLUSH_CACHE_SUCCESS
  };
}

function flushCacheError(error) {
  return {
    type: FLUSH_CACHE_ERROR,
    error
  };
}

export const flushCache = () => dispatch => {
  dispatch(flushCacheRequest());
  return fetchInstance(FLUSH_CACHE_URL, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(
      res => {
        dispatch(addNotification(res.message, alertLevels.SUCCESS));
        dispatch(flushCacheSuccess());
      },
      err => {
        dispatch(addNotification(err.message, alertLevels.ERROR));
        dispatch(flushCacheError(err.message));
      }
    );
};
