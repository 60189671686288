import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, generatePath, useParams } from "react-router-dom";
import _ from "lodash";
import { Box, Stack } from "@mui/material";
import { InfoText, Paper, SelectAutocomplete } from "components/BaseComponents";
import {
  NavigateStepButtons,
  SectionTitle,
  SearchBar,
  Expand
} from "components/CustomComponents";
import { filterStaticData, reportConstraints } from "actions";
import { NEW_REPORT_TYPE_ROUTE } from "constants/viewRoutes";
import { BB_PRODUCTS, BB_COMPARISON_PRODUCTS } from "constants";
import { getSearchOptions, updateAPIValue } from "utils";

const Products = props => {
  const {
    subsections,
    filteredSubsections,
    isFilteredData,
    story,
    client,
    isFetchingData,
    dispatchFilterStaticData,
    isFilteringData,
    error,
    dispatchReportConstraints,
    setActiveStep,
    productA,
    productB,
    reportType,
    context
  } = props;
  const [subsectionsA, setSubsectionsA] = useState([]);
  const [subsectionsB, setSubsectionsB] = useState([]);
  const [comparisonOptions, setComparisonOptions] = useState([]);

  const navigate = useNavigate();
  const params = useParams();
  const { channel } = params;

  useEffect(() => {
    setActiveStep(0);
  }, []);

  useEffect(() => {
    // get initial filter terms
    if (!productA && !productB) return;
    const filterTerms = [];
    if (productA) filterTerms.push(productA);
    if (productB) filterTerms.push(productB);
    const apiTerms = filterTerms.map(i => i.apiValue);
    dispatchFilterStaticData(null, apiTerms, story, client);
  }, []);

  useEffect(() => {
    // set comparison options
    if (productA) {
      setComparisonOptions(BB_COMPARISON_PRODUCTS[productA.subsection]);
    } else {
      setComparisonOptions([]);
    }
  }, [productA]);

  useEffect(() => {
    const options = subsections.filter(i => BB_PRODUCTS.includes(i.name));
    setSubsectionsA(options);
  }, [subsections]);

  useEffect(() => {
    if (comparisonOptions.length === 0) return;
    const options = filteredSubsections.filter(i =>
      comparisonOptions.includes(i.name)
    );
    setSubsectionsB(options);
  }, [filteredSubsections, comparisonOptions]);

  const handleProductAChange = value => {
    const updatedValue = updateAPIValue(value, "product_a");
    dispatchReportConstraints("productA", updatedValue, story);
    // remove productB
    if (productB) dispatchReportConstraints("productB", null, story);
    // remove context
    if (context.length > 0) {
      dispatchReportConstraints("context", [], story);
    }
    // filter static data
    const apiProduct = updatedValue ? updatedValue.apiValue : null;
    dispatchFilterStaticData(apiProduct, [], story, client);
  };

  const handleProductBChange = value => {
    const updatedValue = updateAPIValue(value, "product_b");
    dispatchReportConstraints("productB", updatedValue, story);
    // remove context
    if (context.length > 0) {
      dispatchReportConstraints("context", [], story);
    }
    // filter static data
    const apiProduct = updatedValue ? updatedValue.apiValue : null;
    const filterTerms = [productA.apiValue];
    dispatchFilterStaticData(apiProduct, filterTerms, story, client);
  };

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="1"
          title="Primary Product"
          subtitle="Describe what you would like to see as the primary product."
        />
        <SearchBar
          label="Primary Product"
          value={productA}
          onChange={(e, value) => handleProductAChange(value)}
          options={subsectionsA}
          disabled={isFilteringData}
          styles={{ mb: 2 }}
          isLoading={isFetchingData}
          infoText="You can select a Distributor, a Major Brand or a Sub Brand."
          story={story}
        />
        <Expand label="Advanced Search">
          <Stack spacing={2} sx={{ py: 1 }}>
            {subsectionsA.map(i => (
              <SelectAutocomplete
                key={i.name}
                label={_.startCase(i.name)}
                fullWidth
                styles={{ maxWidth: 400 }}
                options={getSearchOptions(
                  i.keywords
                    .sort()
                    .map(kw => ({ name: kw, subsection: i.name })),
                  i.table,
                  story
                )}
                value={productA?.subsection === i.name ? productA : null}
                onChange={(e, value) => handleProductAChange(value)}
                getOptionLabel={option => option.value}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                disabled={isFilteringData}
              />
            ))}
          </Stack>
        </Expand>
      </Paper>
      <Paper>
        {!productA && (
          <InfoText
            level="error"
            text="Please select your Primary Product first."
            styles={{ mb: 2 }}
          />
        )}
        <Box sx={{ position: "relative" }}>
          {(!productA || !isFilteredData || error) && (
            <Box
              sx={{
                bgcolor: "white",
                filter: "blur(0px)",
                opacity: 0.6,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100
              }}
            />
          )}
          <SectionTitle
            order="2"
            title="Comparison Product"
            subtitle="Describe what you would like to see as the comparison product."
          />
          <SearchBar
            label="Comparison Product"
            value={productB}
            onChange={(e, value) => handleProductBChange(value)}
            options={subsectionsB}
            disabled={isFilteringData}
            styles={{ mb: 2 }}
            infoText={
              productA &&
              `You have chosen a ${_.startCase(productA.subsection).replace(
                " Id",
                ""
              )} as the Primary Product. Please select a different ${comparisonOptions
                .join(" or a ")
                .replaceAll("_", " ")
                .replace(" id", "")} as your Comparison Product.`
            }
            story={story}
          />
          <Expand label="Advanced Search">
            <Stack spacing={2} sx={{ py: 1 }}>
              {subsectionsB.map(i => (
                <SelectAutocomplete
                  key={i.name}
                  label={_.startCase(i.name)}
                  fullWidth
                  styles={{ maxWidth: 400 }}
                  options={getSearchOptions(
                    i.keywords
                      .sort()
                      .map(kw => ({ name: kw, subsection: i.name })),
                    i.table,
                    story
                  )}
                  value={productB?.subsection === i.name ? productB : null}
                  onChange={(e, value) => handleProductBChange(value)}
                  getOptionLabel={option => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  disabled={isFilteringData}
                />
              ))}
            </Stack>
          </Expand>
        </Box>
      </Paper>
      <NavigateStepButtons
        onBackClick={() => navigate(-1)}
        onNextClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "context"
            })
          )
        }
        nextDisabled={!productA || !productB}
      />
    </Fragment>
  );
};

Products.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  isFilteredData: PropTypes.bool,
  isFetchingData: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  error: PropTypes.string,
  dispatchFilterStaticData: PropTypes.func,
  client: PropTypes.string,
  story: PropTypes.string,
  dispatchReportConstraints: PropTypes.func,
  setActiveStep: PropTypes.func,
  reportType: PropTypes.string,
  productA: PropTypes.shape(),
  productB: PropTypes.shape(),
  context: PropTypes.arrayOf(PropTypes.shape())
};

Products.defaultProps = {
  subsections: [],
  filteredSubsections: [],
  isFilteredData: false,
  isFetchingData: false,
  isFilteringData: false,
  error: "",
  dispatchFilterStaticData: () => {},
  client: "",
  story: "",
  dispatchReportConstraints: () => {},
  setActiveStep: () => {},
  reportType: "",
  productA: null,
  productB: null,
  context: []
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      filteredSubsections = { story: [] },
      isFilteredData = { story: false }
    },
    data: { subsections = { dataSet: [] } }
  } = state;
  return {
    error: state.search.error,
    isFilteringData: state.search.isFilteringData,
    client: state.user.user.client,
    isFetchingData: state.data.isFetchingData,
    subsections: subsections[dataSet],
    filteredSubsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story]
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client)),

  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story))
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
