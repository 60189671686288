import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, generatePath, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { AddIcon } from "components/Icons";
import { NEW_REPORT_TYPE_ROUTE, VIEW_REPORT_ROUTE } from "constants/viewRoutes";
import { NavigateStepButtons } from "components/CustomComponents";
import { buildQueryString, getConstraintsFromQuery } from "utils";
import { HIERARCHY_LIST } from "constants";
import { getStoryConfig } from "config";
import {
  fetchStaticData,
  filterStaticData,
  clearStoryConstraints,
  clearStorySearchData
} from "actions";
import { storyEnums } from "constants/enums";
import Header from "./Header";
import EditProduct from "./EditProduct";
import EditHierarchy from "./EditHierarchy";
import EditPeriod from "./EditPeriod";

const story = storyEnums.PRR;
const { dataSet, type, channel } = getStoryConfig(story);

const EditRangeReview = props => {
  const {
    subsections,
    filteredSubsections,
    isFilteredData,
    isFilteringData,
    getData,
    client,
    isStaticData,
    dataDate,
    dispatchFilterStaticData,
    dispachClearStory
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [temperature, setTemperature] = useState(null);
  const [consumptionType, setConsumptionType] = useState(null);
  const [category, setCategory] = useState(null);
  const [sector, setSector] = useState(null);
  const [retailer, setRetailer] = useState(null);
  const [period, setPeriod] = useState(null);
  const [implementationDate, setImplementationDate] = useState(null);
  const [hier1, setHier1] = useState(null);
  const [hier2, setHier2] = useState(null);
  const [metric, setMetric] = useState(null);

  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  const categoryList = isFilteredData
    ? filteredSubsections.find(i => i.name === "category")
    : subsections.find(i => i.name === "category");
  const sectorList = isFilteredData
    ? filteredSubsections.find(i => i.name === "sector")
    : subsections.find(i => i.name === "sector");
  const retailerList = subsections.find(i => i.name === "prr_retailer");

  useEffect(() => {
    if (!isStaticData) return;
    if (subsections.length === 0) return;

    const {
      market: editRetailer,
      metric: editMetric,
      period: editPeriod,
      implementationDate: editImplementationDate,
      temperature: editTemperature,
      consumptionType: editConsumptionType,
      category: editCategory,
      sector: editSector,
      hierarchy1,
      hierarchy2
    } = getConstraintsFromQuery(location.search);

    if (
      !editRetailer ||
      !editPeriod ||
      !editMetric ||
      !editTemperature ||
      !editConsumptionType ||
      !hierarchy1
    ) {
      dispachClearStory();
      navigate(
        generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType: type,
          channel,
          step: "product"
        })
      );
      return;
    }

    setTemperature(editTemperature);
    setConsumptionType(editConsumptionType);
    setCategory(editCategory);
    setSector(editSector);
    setRetailer(editRetailer);

    const selectedPeriod = {
      name: editPeriod,
      story,
      subsection: "period",
      table: "when"
    };
    setPeriod(selectedPeriod);
    setImplementationDate(editImplementationDate);

    const editHier1 = HIERARCHY_LIST.find(i => i.name === hierarchy1);
    const editHier2 = hierarchy2
      ? HIERARCHY_LIST.find(i => i.name === hierarchy2)
      : null;
    setHier1(editHier1);
    setHier2(editHier2);

    setMetric(editMetric);

    // filter static data
    const filterTerms = [];
    if (editTemperature !== "all")
      filterTerms.push({
        name: editTemperature,
        subsection: "temperature",
        table: "what",
        story
      });
    if (editCategory)
      filterTerms.push({
        name: editCategory,
        subsection: "category",
        table: "what",
        story
      });
    dispatchFilterStaticData(null, filterTerms, story, client);
  }, [isStaticData]);

  const onNextClick = () => {
    const constraints = [
      { name: temperature, subsection: "temperature" },
      { name: consumptionType, subsection: "consumption_type" },
      { name: retailer, subsection: "retailer" },
      { name: period.name, subsection: "period" },
      { name: hier1.name, subsection: "hier1" },
      { name: metric, subsection: "metric_name" }
    ];
    if (category) constraints.push({ name: category, subsection: "category" });
    if (sector) constraints.push({ name: sector, subsection: "sector" });
    if (implementationDate)
      constraints.push({
        name: implementationDate,
        subsection: "implementation_date"
      });
    if (hier2) constraints.push({ name: hier2.name, subsection: "hier2" });
    dispachClearStory(story);
    navigate({
      pathname: VIEW_REPORT_ROUTE,
      search: `story=${story.toUpperCase()}&${buildQueryString(constraints)}`
    });
  };

  return (
    <Fragment>
      <Header
        story={story}
        isLastStep
        category={category}
        sector={sector}
        retailer={retailer}
        isEdit
      />
      <Grid container rowSpacing={1} alignItems="stretch">
        <Grid item xs={12}>
          <EditProduct
            categoryOptions={
              categoryList?.keywords.map(i => (i.value ? i.value : i)) || []
            }
            sectorOptions={
              sectorList?.keywords.map(i => (i.value ? i.value : i)) || []
            }
            retailerOptions={retailerList?.keywords || []}
            story={story}
            client={client}
            isFilteringData={isFilteringData}
            isStaticData={isStaticData}
            category={category}
            sector={sector}
            retailer={retailer}
            temperature={temperature}
            consumptionType={consumptionType}
            hier1={hier1}
            hier2={hier2}
            setTemperature={setTemperature}
            setConsumptionType={setConsumptionType}
            setCategory={setCategory}
            setSector={setSector}
            setRetailer={setRetailer}
            setHier1={setHier1}
            setHier2={setHier2}
          />
        </Grid>
        <Grid item xs={12}>
          <EditPeriod
            dataDate={dataDate}
            period={period}
            implementationDate={implementationDate}
            story={story}
            setPeriod={setPeriod}
            setImplementationDate={setImplementationDate}
          />
        </Grid>
        <Grid item xs={12}>
          <EditHierarchy
            hierarchyList={
              (sector &&
                HIERARCHY_LIST.filter(
                  i => i.name !== "sector" && i.name !== "category"
                )) ||
              (category && HIERARCHY_LIST.filter(i => i.name !== "category")) ||
              HIERARCHY_LIST
            }
            hier1={hier1}
            hier2={hier2}
            metric={metric}
            setHier1={setHier1}
            setHier2={setHier2}
            setMetric={setMetric}
            isLoading={isFilteringData}
          />
        </Grid>
        <Grid item xs={12}>
          <NavigateStepButtons
            onBackClick={() =>
              navigate(
                generatePath(NEW_REPORT_TYPE_ROUTE, {
                  reportType: type,
                  channel,
                  step: "product"
                })
              )
            }
            backLabel="New Range Review Report"
            backIcon={<AddIcon />}
            onNextClick={onNextClick}
            nextLabel="Run Report"
            nextDisabled={
              !temperature ||
              !consumptionType ||
              !retailer ||
              !period ||
              !hier1 ||
              !metric
            }
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

EditRangeReview.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  isFilteredData: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  getData: PropTypes.func,
  client: PropTypes.string,
  isStaticData: PropTypes.bool,
  dataDate: PropTypes.string,
  dispatchFilterStaticData: PropTypes.func,
  dispachClearStory: PropTypes.func
};

EditRangeReview.defaultProps = {
  subsections: [],
  filteredSubsections: [],
  isFilteredData: false,
  isFilteringData: false,
  getData: () => {},
  client: "",
  isStaticData: false,
  dataDate: "",
  dispatchFilterStaticData: () => {},
  dispachClearStory: () => {}
};

const mapStateToProps = state => {
  const {
    data: { subsections = { dataSet: [] }, date = { dataSet: "" } },
    search: {
      filteredSubsections = { story: [] },
      isFilteredData = { story: false },
      isFilteringData = false
    }
  } = state;
  return {
    subsections: subsections[dataSet],
    filteredSubsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story],
    isFilteringData,
    isLoading: state.data.isFetchingData,
    isStaticData: state.data.isStaticData,
    client: state.user.user.client,
    dataDate: date[dataSet]
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => dispatch(fetchStaticData(client)),
  dispatchFilterStaticData: (term, filterTerms, filterStory, client) =>
    dispatch(filterStaticData(term, filterTerms, filterStory, client)),
  dispachClearStory: () => {
    dispatch(clearStoryConstraints(story));
    dispatch(clearStorySearchData(story));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRangeReview);
