import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  Typography,
  Collapse,
  Box,
  Button,
  Stack,
  Tooltip
} from "@mui/material";
import {
  EditIcon,
  DeleteIcon,
  InHomeIcon,
  OutOfHomeIcon,
  AddIcon,
  ArrowLeftIcon
} from "components/Icons";
import { Paper, Table, Tabs } from "components/BaseComponents";
import { WarningDialog } from "components/CustomComponents";
import {
  clearStorySearchData,
  getPreferences,
  removePreference
} from "actions";
import { getChannelConfig, clientConfig } from "config";
import { NUMBER_PREFERENCES } from "constants";
import { LOOKOUT_PREFERENCES_ROUTE } from "constants/viewRoutes";
import { getSearchOption, getSearchOptions } from "utils";
import AddPreference from "./AddPreference";

const tabItems = [
  { label: "In Home", value: "ih", icon: <InHomeIcon fontSize="small" /> },
  {
    label: "Out Of Home",
    value: "ooh",
    icon: <OutOfHomeIcon fontSize="small" />
  }
];

const LookoutPreferences = props => {
  const { totalPreferences, client, remove, userId, getPreferenceList, reset } =
    props;
  const { channel } = useParams();
  const navigate = useNavigate();
  const channelData = getChannelConfig(channel);
  const [dataSet, setDataSet] = useState(channelData.dataSet);
  const [story, setStory] = useState(`lookout-${channel}`);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState({});
  const preferences = totalPreferences[channel] || [];
  const isPreference = preferences.length !== 0;
  const { channels = [] } = clientConfig[client] || {};

  useEffect(() => {
    if (!isPreference) {
      getPreferenceList();
    }
  }, []);

  return (
    <Fragment>
      <Paper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                onClick={() => {
                  if (isEditOpen && preferences.length > 0) {
                    setIsEditOpen(false);
                  } else {
                    navigate(-1);
                  }
                  reset(story);
                }}
                color="primary"
              >
                <ArrowLeftIcon />
              </IconButton>
              <Typography variant="h4" color="primary.main">
                Lookout Preferences
              </Typography>
            </Stack>
            <Typography variant="body1" color="grey.500" gutterBottom>
              Here are your Lookout Preferences! Here you can add up to 3
              preferences from each channel to be informed of the top insights
              as soon as new data is released.
            </Typography>
          </Box>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            disabled={preferences.length >= NUMBER_PREFERENCES}
            onClick={() => {
              setIsEditOpen(true);
              setSelectedPreference({});
            }}
          >
            Add More Preferences
          </Button>
        </Stack>
      </Paper>
      <Collapse in={!isEditOpen}>
        <Paper>
          <Tabs
            activeTab={channel}
            onChange={(e, value) => {
              setDataSet(getChannelConfig(value).dataSet);
              setStory(`lookout-${value}`);
              navigate(
                generatePath(LOOKOUT_PREFERENCES_ROUTE, { channel: value }),
                { replace: true }
              );
            }}
            tabs={tabItems.filter(i => channels.includes(i.value))}
            styles={{ mt: -1 }}
          />
          <Table
            styles={{ root: { mt: 2 } }}
            rows={preferences}
            columns={[
              {
                label: "#",
                styles: { width: 40, px: 0 },
                renderCell: (i, index) => index + 1
              },
              {
                label: "Target Product",
                styles: { textAlign: "left" },
                renderCell: i =>
                  i.product &&
                  (i.product.name.value ? i.product.name.value : i.product.name)
              },
              {
                label: "Product Filter",
                styles: { textAlign: "left" },
                renderCell: i =>
                  i.context
                    .map(c => (c.name.value ? c.name.value : c.name))
                    .join(", ")
              },
              {
                label: "Market",
                styles: { textAlign: "left" },
                renderCell: i => i.market && i.market.label
              },
              {
                label: "Actions",
                styles: { width: 80 },
                renderCell: i => (
                  <Stack direction="row" spacing={0.5} justifyContent="center">
                    <Tooltip title="Edit Preference">
                      <IconButton
                        size="small"
                        color="grey.500"
                        onClick={() => {
                          setIsEditOpen(true);
                          setSelectedPreference({
                            id: i.id,
                            product: i.product
                              ? getSearchOption(i.product, "what", story)
                              : null,
                            context: getSearchOptions(
                              i.context,
                              "context",
                              story
                            ),
                            market: i.market ? i.market.label : null
                          });
                        }}
                      >
                        <EditIcon sx={{ fontSize: 16, m: 0.5 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Preference">
                      <IconButton
                        size="small"
                        color="negative"
                        onClick={() => {
                          setIsDeleteOpen(true);
                          setSelectedPreference(i);
                        }}
                      >
                        <DeleteIcon sx={{ fontSize: 16, m: 0.5 }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )
              }
            ]}
          />
        </Paper>
      </Collapse>
      <Collapse in={isEditOpen} mountOnEnter unmountOnExit>
        <AddPreference
          onClose={() => {
            setIsEditOpen(false);
            reset(story);
            setSelectedPreference({});
          }}
          preference={selectedPreference}
          userId={userId}
          client={client}
          story={story}
          dataSet={dataSet}
          channel={channel}
        />
      </Collapse>
      <WarningDialog
        isOpen={isDeleteOpen}
        title="Removing Lookout™ Preference"
        content="Are you sure you want to remove this preference? This action can not be undone."
        onClose={() => setIsDeleteOpen(false)}
        onConfirm={() => {
          remove(selectedPreference, userId, story, channel, client);
          setIsDeleteOpen(false);
          setSelectedPreference({});
        }}
        confirmButton="Remove Preference"
      />
    </Fragment>
  );
};

LookoutPreferences.propTypes = {
  totalPreferences: PropTypes.shape(),
  client: PropTypes.string,
  remove: PropTypes.func,
  userId: PropTypes.string,
  getPreferenceList: PropTypes.func,
  reset: PropTypes.func
};

LookoutPreferences.defaultProps = {
  totalPreferences: {},
  client: "",
  remove: () => {},
  userId: "",
  getPreferenceList: () => {},
  reset: () => {}
};

const mapStateToProps = state => ({
  totalPreferences: state.lookout.preferences,
  userId: state.user.user.id,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  remove: (preference, userId, story, channel, client) => {
    dispatch(removePreference(preference, userId, channel, client));
    dispatch(clearStorySearchData(story));
  },
  getPreferenceList: () => {
    dispatch(getPreferences());
  },
  reset: story => {
    dispatch(clearStorySearchData(story));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LookoutPreferences);
