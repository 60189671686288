import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { InfoIcon, InfoOutlineIcon } from "components/Icons";

const getIconColor = level => {
  switch (level) {
    case "info":
      return "grey.500";
    case "warning":
      return "neutral.main";
    case "error":
      return "negative.main";
    default:
      return "grey.500";
  }
};

const getTextColor = level => {
  switch (level) {
    case "info":
      return "grey.500";
    case "warning":
      return "grey.800";
    case "error":
      return "grey.800";
    default:
      return "grey.500";
  }
};

const InfoText = props => {
  const { level, text, styles, variant } = props;
  const iconColor = getIconColor(level);
  const textColor = getTextColor(level);
  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={styles}>
      {variant === "outlined" ? (
        <InfoOutlineIcon sx={{ color: iconColor, fontSize: 16 }} />
      ) : (
        <InfoIcon sx={{ color: iconColor, fontSize: 16 }} />
      )}
      <Typography variant="body2" color={textColor}>
        {text}
      </Typography>
    </Stack>
  );
};

InfoText.propTypes = {
  level: PropTypes.oneOf(["info", "warning", "error"]),
  text: PropTypes.string,
  styles: PropTypes.shape(),
  variant: PropTypes.oneOf(["contained", "outlined"])
};

InfoText.defaultProps = {
  level: "info",
  text: "",
  styles: {},
  variant: "contained"
};

export default InfoText;
