import _ from "lodash";
import { scaleFormatter, getStackedMaxMin, getUniqueColors } from "utils";
import theme from "theme";
import { clientTemplates } from "config";

export const SIDES_BOTTOM_BORDER = [
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") }
];

export const SIDES_TOP_BORDER = [
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: "ffffff" },
  { color: theme.palette.grey[200].replace("#", "") }
];

export const RIGHT_BOTTOM_BORDER = [
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { type: "none" }
];

export const SIDES_BORDER = [
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: "ffffff" },
  { color: theme.palette.grey[200].replace("#", "") }
];

export const FULL_BORDER = [
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") }
];

export const BOTTOM_BORDER = [
  { type: "none" },
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { type: "none" }
];

const TOP_BOTTOM_BORDER = [
  { color: theme.palette.grey[200].replace("#", "") },
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { type: "none" }
];

const RIGHT_TOP_BOTTOM_BORDER = [
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { type: "none" }
];

export const TEMPLATE_LAYOUT = { x: 0, y: 0, w: "100%", h: "100%" };

/**
 * Add slide templates to ppt
 */
export const addTemplates = (ppt, client) => {
  const path = clientTemplates.includes(client)
    ? `/templates/${client}`
    : "/templates/default";
  ppt.defineSlideMaster({
    title: "TITLE_SLIDE",
    objects: [
      { image: { ...TEMPLATE_LAYOUT, path: `${path}/title_template.png` } }
    ]
  });
  ppt.defineSlideMaster({
    title: "SECTION_SLIDE",
    objects: [
      { image: { ...TEMPLATE_LAYOUT, path: `${path}/section_template.png` } }
    ]
  });
  ppt.defineSlideMaster({
    title: "MASTER_SLIDE",
    objects: [
      { image: { ...TEMPLATE_LAYOUT, path: `${path}/main_template.png` } }
    ]
  });
};

/**
 * Get position and size of chart based on layout
 */
export const getChartLayout = layout => {
  const { left, top, width, height } = layout;
  return {
    x: (left / 100) * 13.3,
    y: (top / 100) * 7.5,
    w: `${width}%`,
    h: `${height}%`
  };
};

// table header
export const getHeaderRow = (header, highlight, boldCells, fontSize) =>
  header.map((i, key) => ({
    text: i,
    options: {
      bold:
        highlight && boldCells.find(o => o.x === 0 && o.y === key + 1) && true,
      border: FULL_BORDER,
      fontSize: 0.8125 * fontSize
    }
  }));

// first column if used
export const getIndexOptions = (index, isHeader, fontSize) =>
  index.map((i, k) => ({
    text: i,
    options: {
      fill: theme.palette.grey[100].replace("#", ""),
      border:
        k === index.length - 1
          ? (isHeader && SIDES_BOTTOM_BORDER) || FULL_BORDER
          : (isHeader && SIDES_BORDER) || SIDES_TOP_BORDER,
      fontSize:
        (i.length > 50 &&
          (index.length > 4 ? 0.46875 * fontSize : 0.5625 * fontSize)) ||
        0.625 * fontSize
    }
  }));

// main table rows
export const getCellOptions = (data, isHeader, gradient, colours, fontSize) =>
  data.map((i, x) =>
    i.map((j, y) => ({
      text: j,
      options: {
        color:
          gradient && colours.find(o => o.x === x + 1 && o.y === y + 1)?.colour,
        fontSize: 0.75 * fontSize,
        border:
          y === i.length - 1
            ? (isHeader && RIGHT_BOTTOM_BORDER) || RIGHT_TOP_BOTTOM_BORDER
            : (isHeader && BOTTOM_BORDER) || TOP_BOTTOM_BORDER
      }
    }))
  );

/**
 * Get the table rows from the backend data
 */
export const getTableRows = (tableData, highlight, gradient, config) => {
  const { columns, values, index } = tableData;
  const { fontSize } = config;
  const isGradient = gradient.length > 0;
  const colours =
    isGradient &&
    gradient.map(i => ({
      x: i[0][0],
      y: i[0][1],
      colour: i[1]
    }));
  const isHighlight = highlight.length > 0;
  const boldCells =
    isHighlight &&
    (highlight[0].length > 1
      ? highlight?.map(i => ({ x: i[0], y: i[1] }))
      : [{ x: highlight[0], y: highlight[1] }]);
  const isHeader = columns.length > 0;
  const headerOptions = getHeaderRow(columns, isHighlight, boldCells, fontSize);
  const indexOptions = getIndexOptions(index, isHeader, fontSize);
  const cellOptions = getCellOptions(
    values,
    isHeader,
    isGradient,
    colours,
    fontSize
  );
  const rows = (isHeader && [
    index
      ? [
          {
            text: "",
            options: { border: FULL_BORDER }
          },
          ...headerOptions
        ]
      : headerOptions,
    ...(index
      ? cellOptions.map((row, k) => [indexOptions[k], ...row])
      : cellOptions)
  ]) || [
    ...(index
      ? cellOptions.map((row, k) => [indexOptions[k], ...row])
      : cellOptions)
  ];
  return rows;
};

/**
 * Get chart details of individual charts making up a multi chart
 */
export const getChartDetails = (ppt, type, colorMap, yData, index, colors) => {
  const isSecondary = index === 1;
  let chartColors = colors;
  const uniqueColors = getUniqueColors(colors, colorMap, true);
  if (!_.isEmpty(colorMap)) {
    chartColors = yData.map((i, k) => colorMap[k] || uniqueColors[k]);
  } else if (isSecondary) {
    chartColors = yData.map(
      (i, k) => colors[(k + yData.length) % colors.length]
    );
  }
  const options = {
    chartColors,
    invertedColors: chartColors,
    secondaryValAxis: isSecondary,
    secondaryCatAxis: isSecondary
  };
  switch (type) {
    case "clustered_column":
      return {
        type: ppt.ChartType.bar,
        options: { barGapWidthPct: 50, ...options }
      };
    case "stacked_column":
      return {
        type: ppt.ChartType.bar,
        barGapWidthPct: 50,
        options: { barGrouping: "stacked", barGapWidthPct: 50, ...options }
      };
    case "line":
      return {
        type: ppt.ChartType.line,
        options: { lineDataSymbol: "none", ...options }
      };
    default:
      return {};
  }
};

/**
 * Align zero lines for multiple y axes on multi chart using scale formatter
 * to set min and max values for secondary y axis
 */
export const getMinMax = (chart, type) => {
  const yData = type.includes("stacked")
    ? _.flattenDeep(getStackedMaxMin(chart))
    : _.flattenDeep(chart.y.map(i => i[1]));
  const { minScale, maxScale } = scaleFormatter(yData);
  return { valAxisMinVal: minScale, valAxisMaxVal: maxScale };
};

/**
 * Return table info in correct format
 */
export const getTableInfo = table => {
  const data = {
    columns: table.columns,
    index: table.index,
    values: table.data
  };
  const { gradient, tp_highlight: highlight } = table;
  return { data, gradient, highlight, title: table.title };
};

/**
 * Create a bulleted list from text
 */
export const getBulletArray = text =>
  text.map(i => ({ text: i, options: { bullet: true, breakLine: true } }));

/**
 * Add "subchart" to chart apply array
 */
export const addApplySubchart = chart => ({
  ...chart,
  style: { ...chart.style, apply: [...chart.style.apply, "subchart"] }
});

/**
 * Add maximum value based on a set of charts
 */
export const addMaximumValue = (chart, chartList) => {
  const max = _.max(chartList.map(i => _.max(i.y)));
  return {
    ...chart,
    style: {
      ...chart.style,
      values_style: { ...chart.style.values_style, maximum_scale: max }
    }
  };
};

/**
 * Get colour for kantar measure tree based on percent change
 */
export const getColor = percent => {
  const value = parseFloat(percent);
  return (
    (value >= 3 && theme.palette.positiveArray[600]) ||
    (value <= -3 && theme.palette.negativeArray[500]) ||
    theme.palette.common.black
  );
};

/**
 * Create text array for contents page
 */
export const getContentArray = (contents, config) => {
  const { fontSize } = config;
  const contentsArray = [];
  for (let i = 0; i < contents.length; i += 1) {
    const item = contents[i];
    const isNote = !!item.note;
    contentsArray.push({
      text: item.name,
      options: {
        fontSize,
        color: theme.palette.grey[800],
        breakLine: true,
        paraSpaceAfter: 6
      }
    });
    contentsArray.push({
      text: item.description,
      options: {
        fontSize: 0.875 * fontSize,
        breakLine: true,
        paraSpaceAfter: !isNote && 18
      }
    });
    if (isNote) {
      contentsArray.push({
        text: item.note,
        options: {
          fontSize: 0.75 * fontSize,
          breakLine: true,
          paraSpaceAfter: 18,
          italic: true
        }
      });
    }
  }
  return contentsArray;
};

export default null;
