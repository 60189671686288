import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from "@mui/material";
import _ from "lodash";

const getColors = colors =>
  Object.assign(
    colors && colors.length > 0
      ? colors.map(d => ({ x: d[0][0], y: d[0][1], colour: d[1] }))
      : []
  );

const getHighlight = highlight =>
  Object.assign(
    highlight && highlight.length > 0
      ? (highlight[0].length > 1 &&
          highlight.map(d => ({ x: d[0], y: d[1] }))) || [
          { x: highlight[0], y: highlight[1] }
        ]
      : []
  );

const ReportTable = props => {
  const { data, display, config } = props;
  const tableData = data;
  const colours = getColors(display.gradient);
  const highlight = getHighlight(display.highlight);
  const { fontSize, fontFamily } = config;
  const { isSmall } = display;
  const isCompact =
    _.max(tableData.columns.map(i => i.length)) > 150 ||
    _.max(tableData.index.map(i => i.length)) > 75;
  return (
    <TableContainer
      className="ReportTable"
      sx={{ border: 1, borderColor: "grey.200", borderRadius: 1 }}
    >
      <Table
        size="small"
        sx={{
          tableLayout: "fixed",
          "& .MuiTableCell-root": { lineHeight: 1.43 }
        }}
      >
        {tableData.columns.length > 0 && (
          <TableHead
            sx={theme => ({
              "& th:first-of-type": {
                borderTopLeftRadius: theme.shape.borderRadius
              },
              "& th:last-of-type": {
                borderTopRightRadius: theme.shape.borderRadius
              }
            })}
          >
            <TableRow>
              {tableData.index && (
                // index header -> blank
                <TableCell sx={{ px: 0.5 }} />
              )}
              {tableData.columns.map((i, key) => (
                // header
                <TableCell
                  align="center"
                  key={_.uniqueId()}
                  sx={{
                    p: isCompact ? 0.375 : 0.5,
                    lineHeight: 1.4,
                    fontWeight: highlight.find(
                      o => o.x === 0 && o.y === key + 1
                    )
                      ? "bold"
                      : "normal",
                    fontSize:
                      (i.length > 180 && 0.5625 * fontSize) ||
                      (isSmall && i.length > 100 && 0.5625 * fontSize) ||
                      (isSmall && 0.75 * fontSize) ||
                      0.875 * fontSize,
                    fontFamily
                  }}
                >
                  {i}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody
          sx={{ "& tr:last-child .MuiTableCell-root": { borderBottom: 0 } }}
        >
          {tableData.values.map((i, x) => (
            <TableRow key={_.uniqueId()}>
              {tableData.index && (
                // index
                <TableCell
                  align="center"
                  sx={{
                    p: isCompact ? 0.375 : 0.5,
                    borderColor: "white",
                    fontSize:
                      (isCompact && tableData.index[x].length > 50) ||
                      tableData.index[x].length > 75
                        ? 0.5625 * fontSize
                        : 0.625 * fontSize,
                    fontFamily,
                    bgcolor: "grey.100"
                  }}
                >
                  {tableData.index[x]}
                </TableCell>
              )}
              {i.map((j, y) => (
                // table cells
                <TableCell
                  align="center"
                  key={_.uniqueId()}
                  sx={{
                    p: isCompact ? 0.375 : 0.5,
                    fontSize: 0.75 * fontSize,
                    fontFamily,
                    color: `#${
                      colours.find(o => o.x === x + 1 && o.y === y + 1)?.colour
                    }`,
                    bgcolor: `#${
                      colours.find(o => o.x === x + 1 && o.y === y + 1)?.colour
                    }10`,
                    fontWeight:
                      highlight.find(o => o.x === x + 1 && o.y === 0) && "bold"
                  }}
                >
                  {j}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportTable.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  config: PropTypes.shape()
};

ReportTable.defaultProps = {
  data: {},
  display: {},
  config: {}
};

export default ReportTable;
