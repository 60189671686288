import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import {
  Paper,
  SelectAutocomplete,
  SelectGroup
} from "components/BaseComponents";
import { NavigateStepButtons, SectionTitle } from "components/CustomComponents";
import { reportConstraints } from "actions";
import { NEW_REPORT_TYPE_ROUTE } from "constants/viewRoutes";

const metricOptions = [
  { label: "Unit Sales", id: "Unit Sales" },
  { label: "Value Sales", id: "Value Sales" }
];

const filterHierarchy = (hierarchyList, selected) => {
  const option = hierarchyList.find(i => i.name === selected);
  return option.isFilter
    ? hierarchyList
        .filter(i => i.name !== option.name)
        .filter(i => i.ranking <= option.ranking)
    : hierarchyList.filter(i => i.name !== option.name);
};

const Hierarchy = props => {
  const {
    story,
    setActiveStep,
    dispatchReportConstraints,
    hier1,
    hier2,
    hierarchyList,
    isLoading,
    reportType,
    metric
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { channel } = params;
  const [hierarchyTwoList, setHierarchyTwoList] = useState([]);

  useEffect(() => {
    setActiveStep(2);
  }, []);

  useEffect(() => {
    if (!hier1) return;
    const filteredList = filterHierarchy(hierarchyList, hier1.name);
    setHierarchyTwoList(filteredList);
  }, [hier1]);

  const handleHier1 = value => {
    if (hier2) dispatchReportConstraints("hier2", null, story);
    dispatchReportConstraints("hier1", value, story);
  };

  const handleHier2 = value => dispatchReportConstraints("hier2", value, story);

  const handleMetric = item => {
    const { id } = item;
    dispatchReportConstraints("metric", id, story);
  };

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="6"
          title="Result Hierarchy"
          subtitle="Select how you would like to see your results."
        />
        <Stack spacing={2} sx={{ maxWidth: 400 }}>
          <SelectAutocomplete
            label="First split by"
            placeholder="Hierarchy"
            fullWidth
            options={hierarchyList}
            value={hier1}
            onChange={(e, value) => handleHier1(value)}
            isLoading={isLoading}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            required
          />
          <SelectAutocomplete
            label="Then split by"
            placeholder="Second Hierarchy"
            fullWidth
            options={hierarchyTwoList}
            value={hier2}
            onChange={(e, value) => handleHier2(value)}
            isLoading={isLoading}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            disabled={!hier1}
          />
        </Stack>
      </Paper>
      <Paper>
        <SectionTitle
          order="7"
          title="Analysis Metric"
          subtitle="Select your desired analysis metric."
        />
        <SelectGroup
          label="Metric"
          options={metricOptions}
          selected={metric}
          onClick={handleMetric}
          required
          controlled
          isIcon
        />
      </Paper>
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "period"
            })
          )
        }
        onNextClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "review"
            })
          )
        }
        nextDisabled={!hier1 || !metric}
      />
    </Fragment>
  );
};

Hierarchy.propTypes = {
  story: PropTypes.string,
  setActiveStep: PropTypes.func,
  dispatchReportConstraints: PropTypes.func,
  hier1: PropTypes.shape(),
  hier2: PropTypes.shape(),
  hierarchyList: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  reportType: PropTypes.string,
  metric: PropTypes.string
};

Hierarchy.defaultProps = {
  story: "",
  setActiveStep: () => {},
  dispatchReportConstraints: () => {},
  hier1: null,
  hier2: null,
  hierarchyList: [],
  isLoading: false,
  reportType: "",
  metric: null
};

const mapDispatchToProps = dispatch => ({
  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story))
});

export default connect(null, mapDispatchToProps)(Hierarchy);
