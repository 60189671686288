import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import { Typography } from "@mui/material";
import { Table } from "components/BaseComponents";
import { storyEnums } from "constants/enums";
import { FOCUS_MAPPINGS } from "constants/reportConstants";
import { getChannelConfig } from "config";
import { getFocusFromQuery } from "utils";
import TimePeriod from "./TimePeriod";
import Actions from "./Actions";

const ReportsTable = props => {
  const { dataDate, reports, isLoading, searchText, reportType, story } = props;

  const storyReports = reports.filter(i => story.includes(i.story));

  const reportsToShow = storyReports.filter(report => {
    const searchWords = searchText.toLowerCase().split(" ");
    return searchWords.every(word => report.query.toLowerCase().includes(word));
  });

  const columns = [
    {
      label: "#",
      renderCell: (item, index) => index + 1,
      isVisible: true
    },
    {
      label: "Target Product",
      styles: { textAlign: "left" },
      renderCell: item =>
        item.product?.map(i => (
          <Typography key={i.name} variant="inherit" sx={{ mb: 0.125 }}>
            {i.name}
          </Typography>
        )),
      isVisible: story.some(i =>
        [storyEnums.IDA, storyEnums.CGA, storyEnums.TREND].includes(i)
      )
    },
    {
      label: "Primary Product",
      styles: { textAlign: "left" },
      renderCell: item => item.productA?.name,
      isVisible: story.some(i => [storyEnums.BB].includes(i))
    },
    {
      label: "Comparison Product",
      styles: { textAlign: "left" },
      renderCell: item => item.productB?.name,
      isVisible: story.some(i => [storyEnums.BB].includes(i))
    },
    {
      label: "Product Filter",
      styles: { textAlign: "left" },
      renderCell: item =>
        item.context.map(i => (
          <Typography key={i.name} variant="inherit" sx={{ mb: 0.125 }}>
            {i.name}
          </Typography>
        )),
      isVisible: story.some(i =>
        [
          storyEnums.IDA,
          storyEnums.CGA,
          storyEnums.BB,
          storyEnums.TREND
        ].includes(i)
      )
    },
    {
      label: "Type",
      renderCell: item => getChannelConfig(item.channel).name,
      isVisible: story.some(i => [storyEnums.IDA, storyEnums.CGA].includes(i))
    },
    {
      label: "Focus Type",
      renderCell: item => {
        const { isFocus, focusType } = getFocusFromQuery(item.query);
        return (isFocus && FOCUS_MAPPINGS[focusType]) || "";
      },
      isVisible: story.some(i => [storyEnums.IDA, storyEnums.CGA].includes(i))
    },
    {
      label: "Temperature",
      renderCell: item => item.temperature,
      isVisible: story.some(i => [storyEnums.PRR].includes(i))
    },
    {
      label: "Consumption Type",
      renderCell: item => item.consumptionType,
      isVisible: story.some(i => [storyEnums.PRR].includes(i))
    },
    {
      label: "Category",
      renderCell: item => item.category,
      isVisible: story.some(i => [storyEnums.PRR].includes(i))
    },
    {
      label: "Sector",
      renderCell: item => item.sector,
      isVisible: story.some(i => [storyEnums.PRR].includes(i))
    },
    {
      label: "Market",
      renderCell: item => item.market,
      isVisible: story.some(i =>
        [
          storyEnums.IDA,
          storyEnums.CGA,
          storyEnums.BB,
          storyEnums.TREND,
          storyEnums.PRR
        ].includes(i)
      )
    },
    {
      label: "Time Period",
      renderCell: item => (
        <TimePeriod
          story={item.story}
          period={item.period}
          dataDate={dataDate}
        />
      ),
      isVisible: true
    },
    {
      label: "Hierarchy",
      renderCell: item => (
        <Fragment>
          <Typography variant="inherit" sx={{ mb: 0.125 }}>
            {_.startCase(item.hierarchy1)}
          </Typography>
          <Typography variant="inherit">
            {_.startCase(item.hierarchy2)}
          </Typography>
        </Fragment>
      ),
      isVisible: story.some(i => [storyEnums.PRR].includes(i))
    },
    {
      label: "Metric",
      renderCell: item => item.metric,
      isVisible: story.some(i =>
        [
          storyEnums.IDA,
          storyEnums.CGA,
          storyEnums.BB,
          storyEnums.TREND,
          storyEnums.PRR
        ].includes(i)
      )
    },
    {
      label: "Date",
      renderCell: item =>
        item.date
          ? format(parseISO(item.date), "dd/MM/yy")
          : format(new Date(), "dd/MM/yy"),
      isVisible: true
    },
    {
      label: "Actions",
      renderCell: item => <Actions report={item} reportType={reportType} />,
      isVisible: true
    }
  ].filter(i => i.isVisible);

  return <Table rows={reportsToShow} columns={columns} isLoading={isLoading} />;
};

ReportsTable.propTypes = {
  searchText: PropTypes.string,
  dataDate: PropTypes.shape(),
  reports: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  reportType: PropTypes.string,
  story: PropTypes.arrayOf(PropTypes.string)
};

ReportsTable.defaultProps = {
  searchText: "",
  dataDate: {},
  reports: [],
  isLoading: false,
  reportType: "",
  story: []
};

export default ReportsTable;
