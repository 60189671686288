import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Dialog as MUIDialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack
} from "@mui/material";
import { InfoOutlineIcon } from "components/Icons";

const Dialog = props => {
  const {
    isOpen,
    title,
    content,
    onClose,
    onConfirm,
    cancelButton,
    confirmButton,
    Icon,
    color,
    level
  } = props;

  const getActionButtons = () => {
    if (level === "warning") {
      return (
        <Fragment>
          <Button
            variant="outlined"
            onClick={onClose}
            color="disabled"
            sx={{ width: 1, color: "common.black" }}
          >
            {cancelButton}
          </Button>
          <Button
            variant="contained"
            color="negative"
            sx={{ width: 1 }}
            onClick={onConfirm}
          >
            {confirmButton}
          </Button>
        </Fragment>
      );
    }
    return (
      <Button variant="contained" color="primary" fullWidth onClick={onConfirm}>
        {confirmButton}
      </Button>
    );
  };

  return (
    <MUIDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ color: "grey.900", fontSize: 18, fontWeight: "medium" }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            color: `${color}.500`,
            bgcolor: `${color}.100`,
            borderRadius: "50%",
            border: 8,
            borderColor: `${color}.50`,
            width: 56,
            height: 56,
            ml: -1
          }}
        >
          <Icon />
        </Stack>
        {title}
      </DialogTitle>
      <DialogContent sx={{ color: "grey.500" }}>{content}</DialogContent>
      <DialogActions sx={{ p: 3, pt: 1, justifyContent: "space-between" }}>
        {getActionButtons()}
      </DialogActions>
    </MUIDialog>
  );
};

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  color: PropTypes.string,
  level: PropTypes.oneOf(["info", "warning"])
};

Dialog.defaultProps = {
  isOpen: false,
  title: "",
  cancelButton: "Cancel",
  confirmButton: "Confirm",
  content: "",
  onClose: () => {},
  onConfirm: () => {},
  Icon: InfoOutlineIcon,
  color: "primaryArray",
  level: "info"
};

export default Dialog;
