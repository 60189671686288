import config from "../config";

// ------ api constants
export const API_HOST = config.serverURL.api;

// main backend
export const BACKEND_HOST = `${API_HOST}/backend_controller`;
// report
export const REPORT_URL = `${BACKEND_HOST}/generate_report`;
export const GET_REPORT_URL = `${BACKEND_HOST}/get/report`;
export const GENERATE_AUTO_RANGING_REPORT_URL = `${BACKEND_HOST}/auto_ranging/generate_report`;

// data refresh
export const CHECK_DATA_REFRESH_URL = `${BACKEND_HOST}/get_dataset_flag`;

// static data
export const STATIC_DATA_HOST = `${API_HOST}/static_data_service`;
// static data api
export const STATIC_DATA_URL = `${STATIC_DATA_HOST}/get_static_data`;
export const FILTER_STATIC_DATA_URL = `${STATIC_DATA_HOST}/get_filtered_static_data`;
export const CONTEXT_URL = `${STATIC_DATA_HOST}/get_context`;
// auto ranging
export const BAY_PLANS_STATIC_DATA_URL = `${STATIC_DATA_HOST}/auto_ranging/get_bay_plans`;
export const CURRENT_RANGE_STATIC_DATA_URL = `${STATIC_DATA_HOST}/auto_ranging/get_current_range`;

// user
export const USER_HOST = `${API_HOST}/user`;
// user api
export const CREATE_USER_URL = `${USER_HOST}/create`;
export const EDIT_USER_URL = `${USER_HOST}/edit`;
export const CHANGE_PASSWORD_URL = `${USER_HOST}/change_password`;
export const SIGN_IN_URL = `${USER_HOST}/login`;
export const SIGN_OUT_URL = `${USER_HOST}/logout`;
export const CHANGE_USER_STATUS_URL = `${USER_HOST}/change_user_status`;
export const RESET_PASSWORD_URL = `${USER_HOST}/reset_password`;
export const FORGOT_PASSWORD_URL = `${USER_HOST}/forgot_password`;
export const FORGOT_PASSWORD_RESET_URL = `${USER_HOST}/forgot_password_reset`;
export const GET_CLIENTS_URL = `${USER_HOST}/clients`;
export const GET_DEPARTMENTS_URL = `${USER_HOST}/departments`;
export const ADD_DEPARTMENT_URL = `${USER_HOST}/create/department`;
export const REFRESH_URL = `${USER_HOST}/token/refresh`;
export const GET_USER_LIST_URL = `${USER_HOST}/list_users`;
// recent reports, favourites, user tracking
export const USER_TRACKING_URL = `${USER_HOST}/add_user_tracking`;
export const LIST_RECENT_REPORTS_URL = `${USER_HOST}/recent_reports`;
export const LIST_FAVOURITES_URL = `${USER_HOST}/favorites`;
export const CHANGE_FAVOURITES_URL = `${USER_HOST}/change_favourite_status`;
export const AUTO_RANGING_RECENT_REPORTS_URL = `${USER_HOST}/auto_ranging/query_user_reports`;
export const AUTO_RANGING_FAVORITE_REPORTS_URL = `${USER_HOST}/auto_ranging/get_favorites`;
export const AUTO_RANGING_CHANGE_FAVORITE_STATUS_URL = `${USER_HOST}/auto_ranging/change_favourite_status`;
export const GET_AUTO_RANGING_CONSTRAINTS_URL = `${USER_HOST}/auto_ranging/get_report`;
export const AUTO_RANGING_USER_TRACKING_URL = `${USER_HOST}/auto_ranging/log_user_report`;

// user feedback
export const USER_FEEDBACK_URL = `${API_HOST}/user_feedback/feedback`;

// subscription
export const SUBSCRIPTION_HOST = `${USER_HOST}/subscription`;
// subscription api
export const ADD_CONTACT_URL = `${SUBSCRIPTION_HOST}/add_contact`;
export const REMOVE_CONTACT_URL = `${SUBSCRIPTION_HOST}/remove_contact`;
export const SUBSCRIBE_URL = `${SUBSCRIPTION_HOST}/subscribe`;
export const UNSUBSCRIBE_URL = `${SUBSCRIPTION_HOST}/unsubscribe`;
export const CHECK_SUBSCRIPTION_URL = `${SUBSCRIPTION_HOST}/check_subscription`;

// lookout
export const LOOKOUT_HOST = `${API_HOST}/lookout`;
// lookout api
export const LIST_PREFERENCES_URL = `${LOOKOUT_HOST}/list_preferences`;
export const ADD_PREFERENCE_URL = `${LOOKOUT_HOST}/add_preference`;
export const REMOVE_PREFERENCE_URL = `${LOOKOUT_HOST}/remove_preference`;
export const EDIT_PREFERENCE_URL = `${LOOKOUT_HOST}/edit_preference`;
export const LOOKOUT_URL = `${LOOKOUT_HOST}/get_lookout_result`;

// sterling
export const STERLING_HOST = `${API_HOST}/sterling`;
// sterling api
export const GET_ENVIRONMENTS_URL = `${STERLING_HOST}/get_environments`;
export const GET_PRODUCTS_URL = `${STERLING_HOST}/get_products`;
export const GET_RANKINGS_URL = `${STERLING_HOST}/get_rankings`;
export const GET_WEIGHTINGS_URL = `${STERLING_HOST}/get_weightings`;
export const EDIT_STERLING_URL = `${STERLING_HOST}/edit_sterling`;
export const GET_SHEETS_URL = `${STERLING_HOST}/get_sheets`;

// pusher
export const PUSHER_URL = `${API_HOST}/pusher/authentication`;

// cache
export const FLUSH_CACHE_URL = `${API_HOST}/flush_cache`;

// streamlit api
export const STREAMLIT_API = `${API_HOST}/prototypes`;
export const ALL_PROTOTYPE_API = `${API_HOST}/prototypes/all`;

// ------ app constants

const getPusherConfig = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "local":
      return { key: "534e59a82ef6761e698f", cluster: "eu" };
    case "dev":
      return { key: "534e59a82ef6761e698f", cluster: "eu" };
    case "haleon-dev":
      return { key: "534e59a82ef6761e698f", cluster: "eu" };
    case "drinksinc":
      return { key: "534e59a82ef6761e698f", cluster: "eu" };
    case "poc":
      return { key: "534e59a82ef6761e698f", cluster: "eu" };
    case "qa":
      return { key: "e09421dfa74f38ef9fb9", cluster: "eu" };
    case "stg":
      return { key: "6fa4bb718342ff005dea", cluster: "eu" };
    case "prod":
      return { key: "b9082abebbce0a500ea4", cluster: "eu" };
    case "suntory":
      return { key: "b9082abebbce0a500ea4", cluster: "eu" };
    case "upfield":
      return { key: "b9082abebbce0a500ea4", cluster: "eu" };
    case "haleon-prod":
      return { key: "b9082abebbce0a500ea4", cluster: "eu" };
    default:
      return { key: "534e59a82ef6761e698f", cluster: "eu" };
  }
};

const getAPIKey = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "local":
      return "ad289abdf8e6a98a3f7e528abc072a2f";
    case "dev":
      return "ad289abdf8e6a98a3f7e528abc072a2f";
    case "haleon-dev":
      return "ad289abdf8e6a98a3f7e528abc072a2f";
    case "drinksinc":
      return "ad289abdf8e6a98a3f7e528abc072a2f";
    case "poc":
      return "ad289abdf8e6a98a3f7e528abc072a2f";
    case "qa":
      return "ad289abdf8e6a98a3f7e528abc072a2f";
    case "stg":
      return "ad289abdf8e6a98a3f7e528abc072a2f";
    case "prod":
      return "9a1ad4c5cc624e4d1000f1c0574e0575";
    case "suntory":
      return "9a1ad4c5cc624e4d1000f1c0574e0575";
    case "upfield":
      return "9a1ad4c5cc624e4d1000f1c0574e0575";
    case "haleon-prod":
      return "9a1ad4c5cc624e4d1000f1c0574e0575";
    default:
      return "ad289abdf8e6a98a3f7e528abc072a2f";
  }
};

// pusher
export const PUSHER_KEY = getPusherConfig().key;
export const PUSHER_CLUSTER = getPusherConfig().cluster;

// amplitude
export const AMPLITUDE_API_KEY = getAPIKey();

// data constants
export const HIERARCHY_LIST = config.hierarchyList;
export const GROUPINGS = config.groupings;
export const BB_PRODUCTS = config.bbProducts;
export const BB_COMPARISON_PRODUCTS = config.bbComparisonProducts;
export const STERLING_HIGHLIGHT_COLUMNS = config.sterlingHighlightColumns;

// number of search suggestions
export const NUMBER_SUGGESTIONS = config.app.numberSuggestions;

// number of preferences
export const NUMBER_PREFERENCES = config.app.numberPreferences;

// settings constants
export const USER_LIST_LIMIT = config.app.userListLimit;

// auto ranging
export const DEFAULT_NUM_BAYS = config.autoRangingDefaults.numBay;
export const DEFAULT_DIST_THRESHOLD = config.autoRangingDefaults.distThreshold;
