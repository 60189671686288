import React from "react";
import PropTypes from "prop-types";
import {
  GridFooterContainer,
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";
import Pagination from "../Pagination";

const DataGridPagination = props => {
  const { page, onPageChange } = props;
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => onPageChange(event, newPage - 1)}
      styles={{ border: 0, mb: 0 }}
    />
  );
};

const DataGridFooter = () => (
  <GridFooterContainer
    sx={{ mt: 1, border: 1, borderRadius: 1, justifyContent: "center" }}
  >
    <GridPagination
      ActionsComponent={DataGridPagination}
      rowsPerPageOptions={[]}
      labelDisplayedRows={() => null}
    />
  </GridFooterContainer>
);

DataGridPagination.propTypes = {
  page: PropTypes.number,
  onPageChange: PropTypes.func
};

DataGridPagination.defaultProps = {
  page: 1,
  onPageChange: () => {}
};

export default DataGridFooter;
