import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography
} from "@mui/material";
import {
  AddIcon,
  CloseIcon,
  SearchIcon,
  SelectSkuIcon
} from "components/Icons";
import { Table, TextField } from "components/BaseComponents";
import { initSearch } from "utils";

const AddSKU = props => {
  const { open, onClose, options, rows, setRows, isLoading } = props;
  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState(options);
  const [searchEngine, setSearchEngine] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSelected(rows);
  }, [options, rows]);

  useEffect(() => {
    setSearchEngine(
      initSearch(options, ["name", "category", "sector"], { threshold: 0.2 })
    );
  }, [options]);

  const handleSearch = e => {
    setSearch(e.target.value);
    const results = searchEngine.search(e.target.value);
    setProducts(results.map(i => i.item));
  };

  const handleSelect = item => {
    const updateSelected = [...selected, item];
    setSelected(updateSelected);
  };

  const handleUnselect = item => {
    const updateSelected = selected.filter(i => i.sku !== item.sku);
    setSelected(updateSelected);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{ color: "grey.900", fontSize: 18, fontWeight: "medium", pb: 0 }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            color: "jade.500",
            bgcolor: "jade.100",
            borderRadius: "50%",
            border: 8,
            borderColor: "jade.50",
            width: 56,
            height: 56,
            ml: -1
          }}
        >
          <SelectSkuIcon />
        </Stack>
        Select SKU
        <Typography variant="body1" color="grey.500">
          Please select SKUs to add to your sheet
        </Typography>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            endIcon={<AddIcon />}
            disabled={selected.length === 0}
            onClick={() => {
              setRows(selected);
              onClose();
            }}
          >
            Add Selected SKUs
          </Button>
        </Stack>
        <TextField
          value={search}
          onChange={handleSearch}
          placeholder="Search"
          styles={{ my: 1 }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          disabled={isLoading}
        />
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", right: 16, top: 16, color: "grey.500" }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <Table
          rows={search.length > 0 ? products : options}
          columns={[
            {
              label: "SKU",
              renderCell: item => item.sku
            },
            {
              label: "Product Name",
              styles: { textAlign: "left", width: 1 },
              renderCell: item => item.name
            },
            {
              label: "Select to Add",
              styles: { minWidth: 100, p: 0.5 },
              renderCell: item =>
                selected.includes(item) ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleUnselect(item)}
                  >
                    Selected
                  </Button>
                ) : (
                  <Button
                    variant="soft"
                    size="small"
                    onClick={() => handleSelect(item)}
                  >
                    Select
                  </Button>
                )
            }
          ]}
          isLoading={isLoading || options.length === 0}
          stickyHeader
          styles={{ root: { maxHeight: 500, height: 1 } }}
        />
      </DialogContent>
    </Dialog>
  );
};

AddSKU.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  setRows: PropTypes.func,
  isLoading: PropTypes.bool
};

AddSKU.defaultProps = {
  open: false,
  onClose: () => {},
  options: [],
  rows: [],
  setRows: () => {},
  isLoading: false
};

export default AddSKU;
