import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, generatePath, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { AddIcon } from "components/Icons";
import {
  NEW_REPORT_FOCUS_TYPE_ROUTE,
  NEW_REPORT_TYPE_ROUTE,
  VIEW_REPORT_ROUTE
} from "constants/viewRoutes";
import { NavigateStepButtons } from "components/CustomComponents";
import {
  buildQueryString,
  getConstraintsFromQuery,
  getSearchOptions
} from "utils";
import { getStoryConfig } from "config";
import {
  fetchStaticData,
  clearStoryConstraints,
  clearStorySearchData
} from "actions";
import logger from "logger";
import Header from "./Header";
import EditMarket from "./EditMarket";
import EditProduct from "./EditProduct";

const EditReport = props => {
  const {
    subsections,
    isLoading,
    getData,
    client,
    isStaticData,
    story,
    title,
    icon,
    description,
    dataDate,
    dataSet,
    dispachClearStory,
    focusType
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState([]);
  const [context, setContext] = useState([]);
  const [retailer, setRetailer] = useState(null);
  const [period, setPeriod] = useState(null);
  const [metric, setMetric] = useState(null);
  const { type, channel } = getStoryConfig(story);

  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  useEffect(() => {
    if (!isStaticData) return;
    if (subsections.length === 0) return;

    const {
      market: editRetailer,
      period: editPeriod,
      metric: editMetric,
      product: editProduct,
      context: editContext
    } = getConstraintsFromQuery(location.search);

    if (
      !editRetailer ||
      !editPeriod ||
      !editMetric ||
      editProduct.length === 0 ||
      editContext.length === 0
    ) {
      dispachClearStory(story);
      navigate(
        generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType: type,
          channel,
          step: "product"
        })
      );
      return;
    }

    setRetailer(editRetailer);
    setMetric(editMetric);

    const periodOptions = [
      ...(subsections.find(item => item.table === "when")?.keywords || []).map(
        i => ({
          date: i.date,
          label: `${i.period} ${i.date}`,
          period: i.period,
          value: i.period
        })
      ),
      { period: "custom", label: "Choose range on calendar" }
    ];

    const selectedPeriod = periodOptions.find(i => i.period === editPeriod);

    if (selectedPeriod) {
      setPeriod(selectedPeriod);
    } else if (editPeriod.split(",").length === 4) {
      const customPeriod = {
        label: "Choose range on calendar",
        name: editPeriod,
        period: "custom",
        story,
        subsections: "period",
        table: "when"
      };
      setPeriod(customPeriod);
    }

    const updatedProduct = getSearchOptions(editProduct, "what", story);
    const updatedContext = getSearchOptions(editContext, "context", story);

    setProduct(updatedProduct);
    setContext(updatedContext);
  }, [isStaticData]);

  const onNextClick = () => {
    const constraints = [
      ...product.map(i => ({
        name: i.value,
        subsection: i.subsection
      })),
      ...context.map((i, k) => ({
        name: i.value,
        subsection: i.subsection,
        prefix: `context${k}`
      })),
      { name: retailer, subsection: "retailer" },
      {
        name: period.period !== "custom" ? period.value : period.name,
        subsection: "period"
      },
      { name: metric, subsection: "metric_name" }
    ];
    if (focusType) {
      constraints.push({ name: focusType, subsection: "focus_report" });
      logger({
        date: new Date().toISOString(),
        action: "VIEW_FOCUS_REPORT",
        focus_type: focusType,
        source: "edit",
        query: `story=${story.toUpperCase()}&${buildQueryString(constraints)}`,
        story
      });
    }
    navigate({
      pathname: VIEW_REPORT_ROUTE,
      search: `story=${story.toUpperCase()}&${buildQueryString(constraints)}`
    });
  };

  return (
    <Fragment>
      <Header
        story={story}
        icon={icon}
        title={title}
        description={description}
        isLastStep
        product={product}
        context={context}
        retailer={retailer}
        period={period}
        metric={metric}
        isEdit
      />
      <Grid container rowSpacing={1} alignItems="stretch">
        <Grid item xs={12}>
          <EditProduct
            product={product}
            context={context}
            story={story}
            setProduct={setProduct}
            setContext={setContext}
            dataSet={dataSet}
          />
        </Grid>
        <Grid item xs={12}>
          <EditMarket
            isLoading={isLoading}
            subsections={subsections}
            retailer={retailer}
            period={period}
            metric={metric}
            setRetailer={setRetailer}
            setPeriod={setPeriod}
            setMetric={setMetric}
            dataDate={dataDate}
            story={story}
          />
        </Grid>
      </Grid>
      <NavigateStepButtons
        onBackClick={() =>
          focusType
            ? generatePath(NEW_REPORT_FOCUS_TYPE_ROUTE, {
                reportType: type,
                focusType,
                channel,
                step: "product"
              })
            : navigate(
                generatePath(NEW_REPORT_TYPE_ROUTE, {
                  reportType: type,
                  channel,
                  step: "product"
                })
              )
        }
        backLabel="New Report"
        backIcon={<AddIcon />}
        onNextClick={onNextClick}
        nextLabel="Run Report"
        nextDisabled={
          product.length === 0 ||
          context.length === 0 ||
          !retailer ||
          !period ||
          !metric
        }
      />
    </Fragment>
  );
};

EditReport.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  getData: PropTypes.func,
  client: PropTypes.string,
  isStaticData: PropTypes.bool,
  dataSet: PropTypes.string,
  story: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  description: PropTypes.string,
  dataDate: PropTypes.string,
  dispachClearStory: PropTypes.func,
  focusType: PropTypes.string
};

EditReport.defaultProps = {
  subsections: [],
  isLoading: false,
  getData: () => {},
  client: "",
  isStaticData: false,
  dataSet: "",
  story: "",
  title: "",
  icon: null,
  description: "",
  dataDate: "",
  dispachClearStory: () => {},
  focusType: ""
};

const mapStateToProps = (state, ownProps) => {
  const { dataSet } = ownProps;
  const {
    data: { subsections = { dataSet: [] }, date = { dataSet: "" } }
  } = state;
  return {
    subsections: subsections[dataSet],
    isLoading: state.data.isFetchingData,
    isStaticData: state.data.isStaticData,
    client: state.user.user.client,
    dataDate: date[dataSet]
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => dispatch(fetchStaticData(client)),
  dispachClearStory: story => {
    dispatch(clearStoryConstraints(story));
    dispatch(clearStorySearchData(story));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReport);
