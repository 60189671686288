import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  Navigate,
  generatePath
} from "react-router-dom";
import _ from "lodash";
import { Grid } from "@mui/material";
import {
  NEW_REPORT_TYPE_ROUTE,
  VIEW_REPORT_ROUTE,
  NEW_REPORT_FOCUS_TYPE_ROUTE
} from "constants/viewRoutes";
import {
  ExpectedOutput,
  NavigateStepButtons,
  ReviewCard
} from "components/CustomComponents";
import { getDateLabel, buildQueryString } from "utils";
import { clearStoryConstraints, clearStorySearchData } from "actions";
import logger from "logger";

const Review = props => {
  const {
    story,
    setActiveStep,
    product,
    context,
    retailer,
    period,
    metric,
    dispachClearStory,
    reportType,
    source
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { channel, focusType } = params;

  useEffect(() => {
    setActiveStep(2);
  }, []);

  const getSelectedLabel = items =>
    items.map(i => `${_.startCase(i.subsection)}: ${i.value}`);

  const getSelectedDateLabel = date => {
    if (date.period !== "custom") return [date.label];
    const dateString = getDateLabel(date.name, story).split("Vs");
    return [`${dateString[0]} Vs`, dateString[1]];
  };

  const handleNavigation = navigateStep =>
    focusType
      ? generatePath(NEW_REPORT_FOCUS_TYPE_ROUTE, {
          reportType,
          focusType,
          channel,
          step: navigateStep
        })
      : generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: navigateStep
        });

  if (product.length === 0 || context.length === 0) {
    return <Navigate to={handleNavigation("product")} />;
  }

  if (!retailer || !period || !metric) {
    return <Navigate to={handleNavigation("market")} />;
  }

  const onNextClick = () => {
    const constraints = [
      ...product.map(i => ({
        name: i.value,
        subsection: i.subsection
      })),
      ...context.map((i, k) => ({
        name: i.value,
        subsection: i.subsection,
        prefix: `context${k}`
      })),
      { name: retailer, subsection: "retailer" },
      {
        name: period.period !== "custom" ? period.value : period.name,
        subsection: "period"
      },
      { name: metric, subsection: "metric_name" }
    ];
    if (focusType) {
      constraints.push({ name: focusType, subsection: "focus_report" });
      logger({
        date: new Date().toISOString(),
        action: "VIEW_FOCUS_REPORT",
        focus_type: focusType,
        source,
        query: `story=${story.toUpperCase()}&${buildQueryString(constraints)}`,
        story
      });
    }
    dispachClearStory(story);
    navigate({
      pathname: VIEW_REPORT_ROUTE,
      search: `story=${story.toUpperCase()}&${buildQueryString(constraints)}`
    });
  };

  return (
    <Fragment>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        <Grid item xs={12} md={6}>
          <ReviewCard
            label="Target Product"
            selected={getSelectedLabel(product)}
            onEdit={() => navigate(handleNavigation("product"))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReviewCard
            label="Product Filter"
            selected={getSelectedLabel(context)}
            onEdit={() => navigate(handleNavigation("product"))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Analysis Market"
            selected={[retailer]}
            onEdit={() => navigate(handleNavigation("market"))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Time Frame"
            selected={getSelectedDateLabel(period)}
            onEdit={() => navigate(handleNavigation("market"))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Analysis Metric"
            selected={[metric]}
            onEdit={() => navigate(handleNavigation("market"))}
          />
        </Grid>
      </Grid>
      <ExpectedOutput
        story={focusType ? "focus" : story}
        focusType={focusType}
      />
      <NavigateStepButtons
        onBackClick={() => navigate(handleNavigation("market"))}
        onNextClick={onNextClick}
        nextLabel="Run Report"
      />
    </Fragment>
  );
};

Review.propTypes = {
  story: PropTypes.string,
  product: PropTypes.arrayOf(PropTypes.shape()),
  context: PropTypes.arrayOf(PropTypes.shape()),
  setActiveStep: PropTypes.func,
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  metric: PropTypes.string,
  dispachClearStory: PropTypes.func,
  reportType: PropTypes.string,
  source: PropTypes.string
};

Review.defaultProps = {
  story: "",
  product: [],
  context: [],
  setActiveStep: () => {},
  retailer: null,
  period: null,
  metric: null,
  dispachClearStory: () => {},
  reportType: "",
  source: ""
};

const mapDispatchToProps = dispatch => ({
  dispachClearStory: story => {
    dispatch(clearStoryConstraints(story));
    dispatch(clearStorySearchData(story));
  }
});

export default connect(null, mapDispatchToProps)(Review);
