import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Chip, Collapse, Stack, Typography } from "@mui/material";
import { InfoText, Paper } from "components/BaseComponents";
import { EditIcon } from "components/Icons";

const EditCard = props => {
  const {
    label,
    selected,
    children,
    isWarning,
    warningText,
    isError,
    errorText,
    controlled,
    isOpen,
    disabled
  } = props;
  const [isOpenInternal, setIsOpenInternal] = useState(false);

  useEffect(() => {
    if (controlled) {
      setIsOpenInternal(isOpen);
    }
  }, [isOpen]);

  return (
    <Paper styles={{ height: 1, mb: 0 }}>
      {isError && (
        <InfoText level="error" text={errorText} styles={{ mb: 2 }} />
      )}
      <Box sx={{ position: "relative" }}>
        {disabled && (
          <Box
            sx={{
              bgcolor: "white",
              filter: "blur(0px)",
              opacity: 0.6,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 100
            }}
          />
        )}
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{label}</Typography>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => setIsOpenInternal(!isOpenInternal)}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Stack>
        <Collapse in={!isOpenInternal} mountOnEnter unmountOnExit>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ columnGap: 1.5, rowGap: 1, mt: 1 }}
          >
            {selected.map(item => (
              <Chip key={item} label={item} />
            ))}
          </Stack>
          {isWarning && (
            <InfoText level="warning" text={warningText} styles={{ mt: 2 }} />
          )}
        </Collapse>
        <Collapse in={isOpenInternal} mountOnEnter unmountOnExit sx={{ mt: 2 }}>
          {children}
        </Collapse>
      </Box>
    </Paper>
  );
};

EditCard.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  isWarning: PropTypes.bool,
  warningText: PropTypes.string,
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  controlled: PropTypes.bool,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool
};

EditCard.defaultProps = {
  selected: [],
  label: "",
  isWarning: false,
  warningText: "",
  isError: false,
  errorText: "",
  controlled: false,
  isOpen: false,
  disabled: false
};

export default EditCard;
