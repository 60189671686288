import React from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router-dom";
import { Stack, Button } from "@mui/material";
import { ArrowRightIcon } from "components/Icons";
import { TOOL_SINGLE_ROUTE } from "constants/viewRoutes";

const POSITIONED = ["section", "prototype_slide"];
const HORIZONTAL = ["single_chart", "chart_table"];

const getPosition = (formatKey, alignment) => {
  if (formatKey === "section") {
    if (alignment === "center") {
      return { top: "55%", left: "50%", transform: "translate(-50%, 0)" };
    }
    if (alignment === "right") {
      return { top: "55%", right: 0 };
    }
    return { top: "55%", left: 0 };
  }
  if (formatKey === "prototype_slide") return { top: "25%", left: 0 };
  return { bottom: 0, right: 0 };
};

const ReportPrototype = props => {
  const { prototype, formatKey, alignment } = props;
  const items = Array.isArray(prototype) ? prototype : [prototype];
  const isPositioned = POSITIONED.includes(formatKey);
  const isHorizontal = HORIZONTAL.includes(formatKey) && items.length > 1;
  return (
    <Stack
      id={isHorizontal ? "report-prototype-horizontal" : "report-prototype"}
      spacing={1}
      direction={isHorizontal ? "row" : "column"}
      sx={isPositioned ? {} : { position: "absolute", bottom: 0, right: 0 }}
    >
      {items.map(i => {
        const { description, query, service_name: type, URL: url } = i;
        let appSubstring = "";
        const accessKeyIndex = query.indexOf("&access_key");
        const search = query.substring(0, accessKeyIndex);
        const appStartIndex = url.indexOf("&app=");
        if (appStartIndex !== -1) {
          appSubstring = url.substring(appStartIndex);
        }
        const path = `${generatePath(TOOL_SINGLE_ROUTE, {
          type
        })}?${search}${appSubstring}`;

        return (
          <Button
            key={type}
            variant="soft"
            href={path}
            endIcon={<ArrowRightIcon />}
            target="_blank"
            rel="noreferrer"
            sx={
              isPositioned
                ? { position: "absolute", ...getPosition(formatKey, alignment) }
                : {}
            }
          >
            {description}
          </Button>
        );
      })}
    </Stack>
  );
};

ReportPrototype.propTypes = {
  formatKey: PropTypes.string,
  prototype: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape()
  ]),
  alignment: PropTypes.string
};

ReportPrototype.defaultProps = {
  formatKey: "",
  prototype: [],
  alignment: ""
};

export default ReportPrototype;
