import defaultState from "./defaultState";
import {
  GOTO_SLIDE,
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR
} from "../constants/actionConstants";

// eslint-disable-next-line default-param-last
const presentation = (state = defaultState.presentation, action) => {
  switch (action.type) {
    case GOTO_SLIDE:
      return {
        ...state,
        activeSlideIndex: action.slideIndex
      };
    case DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        downloadReady: false,
        isLoading: true
      };
    case DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        error: "",
        downloadReady: true,
        isLoading: false
      };
    case DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        error: action.error,
        downloadReady: false,
        isLoading: false
      };
    default:
      return state;
  }
};

export default presentation;
