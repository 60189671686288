import React from "react";
import PropTypes from "prop-types";
import PlotlyChart from "components/PlotlyChart";
import theme from "theme";

const BarLineChart = props => {
  const {
    data,
    yaxis1tickprefix,
    yaxis1ticksuffix,
    yaxis2tickprefix,
    yaxis2ticksuffix,
    layout
  } = props;
  const length = Math.max(...data.map(i => i.x.length));
  return (
    <PlotlyChart
      data={data}
      layout={{
        xaxis: {
          tickangle: 90,
          ticklen: 2,
          tickcolor: "transparent",
          automargin: true,
          tickfont: { size: length > 30 ? 8 : 12 }
        },
        yaxis: {
          tickprefix: yaxis1tickprefix,
          ticksuffix: yaxis1ticksuffix,
          tickfont: { size: 11, color: theme.palette.grey[500] }
        },
        showlegend: false,
        yaxis2: {
          overlaying: "y",
          side: "right",
          tickprefix: yaxis2tickprefix,
          ticksuffix: yaxis2ticksuffix,
          showgrid: false,
          rangemode: "tozero",
          tickfont: { size: 11, color: theme.palette.grey[500] }
        },
        hovermode: "x unified",
        hoverlabel: {
          bgcolor: theme.palette.grey[700],
          bordercolor: theme.palette.grey[600],
          font: { color: theme.palette.grey[300], size: 12 }
        },
        height: 750,
        ...layout
      }}
    />
  );
};

BarLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  yaxis1tickprefix: PropTypes.string,
  yaxis1ticksuffix: PropTypes.string,
  yaxis2tickprefix: PropTypes.string,
  yaxis2ticksuffix: PropTypes.string,
  layout: PropTypes.shape()
};

BarLineChart.defaultProps = {
  data: [],
  yaxis1tickprefix: "",
  yaxis1ticksuffix: "",
  yaxis2tickprefix: "",
  yaxis2ticksuffix: "",
  layout: {}
};

export default BarLineChart;
