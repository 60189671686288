import React from "react";
import PropTypes from "prop-types";
import { InfoOutlineIcon } from "components/Icons";
import { Dialog } from "components/BaseComponents";

const WarningDialog = props => {
  const {
    isOpen,
    title,
    content,
    onClose,
    onConfirm,
    cancelButton,
    confirmButton
  } = props;
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      content={content}
      onClose={onClose}
      onConfirm={onConfirm}
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      Icon={InfoOutlineIcon}
      color="negativeArray"
      level="warning"
    />
  );
};

WarningDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

WarningDialog.defaultProps = {
  isOpen: false,
  title: "",
  cancelButton: "Cancel",
  confirmButton: "Confirm",
  content: "",
  onClose: () => {},
  onConfirm: () => {}
};

export default WarningDialog;
