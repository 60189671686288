import React from "react";
import PropTypes from "prop-types";
import { InfoOutlineIcon } from "components/Icons";
import { Dialog } from "components/BaseComponents";

const InfoDialog = props => {
  const { isOpen, title, content, onClose, onConfirm, confirmButton, Icon } =
    props;
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      content={content}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmButton={confirmButton}
      Icon={Icon}
    />
  );
};

InfoDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  confirmButton: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

InfoDialog.defaultProps = {
  isOpen: false,
  title: "",
  confirmButton: "Confirm",
  content: "",
  onClose: () => {},
  onConfirm: () => {},
  Icon: InfoOutlineIcon
};

export default InfoDialog;
