import _ from "lodash";
import { storyEnums } from "constants/enums";
import { DEFAULT_DIST_THRESHOLD } from "constants";

// get initial user info from local storage
const isUser = !!localStorage.getItem("user");
const userInfo = JSON.parse(localStorage.getItem("user")) || {};
const user = _.pick(userInfo, ["client", "id", "email"]);

const defaultState = {
  search: {
    filteredSubsections: {},
    isFilteredData: {},
    contextSubsections: {},
    suggestedContext: {}
  },
  data: {
    subsections: {},
    isStaticData: false,
    date: {},
    placeholder: {},
    isUpdatingData: false,
    updatingDataSets: [],
    updatingStories: []
  },
  user: {
    isUser,
    user,
    userList: []
  },
  reportConstraints: {
    ida: {
      product: [],
      context: [],
      retailer: null,
      period: null,
      metric: null
    },
    cga: {
      product: [],
      context: [],
      retailer: null,
      period: null,
      metric: null
    },
    bb: {
      productA: null,
      productB: null,
      context: [],
      retailer: null,
      period: null,
      metric: null
    },
    trend: {
      product: [],
      context: [],
      retailer: null,
      period: null,
      metric: null
    },
    prr: {
      temperature: null,
      consumptionType: null,
      category: null,
      sector: null,
      retailer: null,
      period: null,
      implementationDate: null,
      hier1: null,
      hier2: null,
      metric: null
    }
  },
  report: {
    myRecentReports: [],
    myFavouriteReports: [],
    myRecentARReports: [],
    myFavouriteARReports: []
  },
  presentation: {
    activeSlideIndex: 0
  },
  lookout: {
    preferences: {},
    lookout: {}
  },
  autoRanging: {
    staticData: {
      isLoading: false,
      data: { subsections: {}, placeholder: {} },
      error: "",
      isStaticData: false
    },
    basicConstraints: {
      story: storyEnums.AR,
      retailer: null,
      comparisonRetailer: null,
      period: null,
      consumptionType: [],
      categories: {},
      nDist: DEFAULT_DIST_THRESHOLD
    },
    bayPlanStaticData: { isLoading: false, data: {}, error: "" },
    bayPlanConstraints: [],
    currentRangeStaticData: { isLoading: false, data: {}, error: "" },
    currentRangeConstraints: { inRange: [], notInRange: [] },
    generatedReport: { isLoading: false, data: [], error: "" },
    finalReportResult: { isLoading: true, data: {}, error: "" },
    userTracking: { isLoading: false, error: "" },
    editConstraints: { isLoading: false, data: {}, error: "" }
  },
  streamlit: { prototypes: [] }
};

export default defaultState;
