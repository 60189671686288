import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { store, persistor } from "./configureStore";
// fonts
import "@fontsource-variable/inter";
import "@fontsource-variable/montserrat";

// =============================================================
// TO FORCE REFRESH THE STATIC DATA -> uncomment the line below:
// persistor.purge();
// -------------------------------------------------------------

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
