import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import { AddIcon } from "components/Icons";
import { Paper, SelectAutocomplete } from "components/BaseComponents";
import {
  SectionTitle,
  SearchBar,
  AdvancedSearch,
  NavigateStepButtons,
  Suggestions
} from "components/CustomComponents";
import {
  getContext,
  addPreference,
  editPreference,
  fetchStaticData
} from "actions";
import { lookoutExamples } from "config";
import ExamplesBox from "./ExamplesBox";

const AddPreference = props => {
  const {
    isStaticData,
    subsections,
    client,
    getProductContext,
    isLoadingContext,
    contextSubsections,
    addToPreferences,
    onClose,
    preference,
    userId,
    story,
    getData,
    channel,
    edit,
    suggestedContext
  } = props;
  const [product, setProduct] = useState(null);
  const [context, setContext] = useState([]);
  const [market, setMarket] = useState(null);
  const examples = lookoutExamples[client];

  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(preference)) {
      const {
        product: selectedProduct = null,
        context: selectedContext = [],
        market: selectedMarket = null
      } = preference;
      setProduct(selectedProduct);
      setContext(selectedContext);
      setMarket(selectedMarket);
    }
  }, [preference]);

  useEffect(() => {
    if (!product) return;
    const apiProduct = product.apiValue;
    const apiContext = context.map(i => i.apiValue);
    getProductContext([apiProduct], apiContext, story, client);
  }, [product, context]);

  const marketOptions = subsections.find(
    item => item.name === "retailer"
  )?.keywords;

  const handleProduct = value => {
    setProduct(value);
    if (context.length > 0) {
      setContext([]);
    }
  };

  const handleContext = value => setContext(value);

  const handleSuggestion = suggestion => {
    const allContext = context.concat(suggestion);
    setContext(allContext);
  };

  const onNextClick = () => {
    const oldPreference = _.omit(preference, ["id"]);
    const newPeference = { product, context, market };
    const toAdd = {
      product: product.apiValue,
      context: context.map(i => i.apiValue),
      market: { label: market, value: `retailer--${market}` }
    };
    if (!_.isEmpty(preference) && !_.isEqual(oldPreference, newPeference)) {
      edit(toAdd, userId, channel, preference.id, client, onClose);
    } else {
      addToPreferences(toAdd, userId, channel, client, onClose);
    }
  };

  return (
    <Fragment>
      {examples && _.isEmpty(preference) && (
        <ExamplesBox examples={examples[channel]} />
      )}
      <Paper>
        <SectionTitle
          order="1"
          title="Target Product"
          subtitle="Select your target product, you can filter your desired product with following tabs."
          isTooltip
          tooltipTitle="What is target product?"
          tooltipContent="In most instances you should only choose a distributor or brand as your target product. Other attributes such as category, sector, pack type, or sugar content are likely part of the competitive set you are interested in, so we recommend adding them at the next step."
        />
        <SearchBar
          label="Target Product"
          value={product}
          onChange={(e, value) => handleProduct(value)}
          options={subsections}
          disabled={isLoadingContext}
          styles={{ mb: 2 }}
          isLoading={!isStaticData}
          story={story}
        />
        <AdvancedSearch
          fields={subsections}
          isLoading={!isStaticData || isLoadingContext}
          selected={product}
          onChange={value => handleProduct(value)}
          single
          story={story}
        />
      </Paper>
      <Paper>
        <Box sx={{ position: "relative" }}>
          {(!product || !isStaticData) && (
            <Box
              sx={{
                bgcolor: "white",
                filter: "blur(0px)",
                opacity: 0.6,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100
              }}
            />
          )}
          <SectionTitle
            order="2"
            title="Product Filter"
            subtitle="Select your desired competitive set in which you want to get the analysis."
          />
          <SearchBar
            label="Product Filter"
            value={context}
            onChange={(e, value) => handleContext(value)}
            options={contextSubsections}
            multiple
            disabled={isLoadingContext}
            loading={isLoadingContext}
            styles={{ mb: 2 }}
            story={story}
          />
          {suggestedContext.length > 0 && (
            <Fragment>
              <Typography variant="subtitle2">
                Suggested Product Filter (based on your target product)
              </Typography>
              <Suggestions
                suggestions={suggestedContext}
                isLoading={isLoadingContext}
                onClick={value => handleSuggestion(value)}
                styles={{ mb: 3 }}
                story={story}
              />
            </Fragment>
          )}
          <AdvancedSearch
            fields={contextSubsections}
            isLoading={isLoadingContext}
            selected={context}
            onChange={value => handleContext(value)}
            story={story}
          />
        </Box>
      </Paper>
      <Paper>
        <SectionTitle
          order="3"
          title="Analysis Market"
          subtitle="Select your market."
        />
        <SelectAutocomplete
          label="Market"
          fullWidth
          styles={{ maxWidth: 400 }}
          options={marketOptions}
          value={market}
          onChange={(e, value) => setMarket(value)}
          isLoading={!isStaticData}
        />
      </Paper>
      <NavigateStepButtons
        onBackClick={onClose}
        onNextClick={onNextClick}
        nextDisabled={!product || context.length === 0 || !market}
        nextLabel="Create Preference"
        nextIcon={<AddIcon />}
      />
    </Fragment>
  );
};

AddPreference.propTypes = {
  isStaticData: PropTypes.bool,
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string,
  getProductContext: PropTypes.func,
  isLoadingContext: PropTypes.bool,
  contextSubsections: PropTypes.arrayOf(PropTypes.shape()),
  addToPreferences: PropTypes.func,
  onClose: PropTypes.func,
  preference: PropTypes.shape(),
  userId: PropTypes.string,
  story: PropTypes.string,
  getData: PropTypes.func,
  channel: PropTypes.string,
  edit: PropTypes.func,
  suggestedContext: PropTypes.arrayOf(PropTypes.shape())
};

AddPreference.defaultProps = {
  isStaticData: false,
  subsections: [],
  client: "",
  getProductContext: () => {},
  isLoadingContext: false,
  contextSubsections: [],
  addToPreferences: () => {},
  onClose: () => {},
  preference: {},
  userId: "",
  story: "",
  getData: () => {},
  channel: "",
  edit: () => {},
  suggestedContext: []
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      isLoadingContext = false,
      contextSubsections = { story: [] },
      suggestedContext = { story: [] }
    },
    data: { subsections = { dataSet: [] }, isStaticData = false }
  } = state;
  return {
    subsections: subsections[dataSet],
    isStaticData,
    isLoadingContext,
    contextSubsections: contextSubsections[story],
    suggestedContext: suggestedContext[story]
  };
};

const mapDispatchToProps = dispatch => ({
  getProductContext: (terms, context, story, client) =>
    dispatch(getContext(terms, context, "product", story, client)),
  addToPreferences: (preference, userId, channel, client, onSuccess) =>
    dispatch(addPreference(preference, userId, channel, client, onSuccess)),
  edit: (newPeference, userId, channel, deleteId, client, onSuccess) =>
    dispatch(
      editPreference(newPeference, userId, channel, deleteId, client, onSuccess)
    ),
  getData: client => fetchStaticData(client)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPreference);
