import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  useNavigate,
  Navigate,
  generatePath,
  useParams
} from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  Paper,
  Slider,
  TextField,
  Tooltip,
  DataGrid,
  InfoText
} from "components/BaseComponents";
import {
  SectionTitle,
  WarningDialog,
  NavigateStepButtons
} from "components/CustomComponents";
import { NEW_AUTO_RANGING_ROUTE } from "constants/viewRoutes";
import { alertLevels } from "constants/enums";
import { MoreInfoOutlineIcon, RefreshIcon } from "components/Icons";
import {
  bayPlansConstraintsAction,
  getBayPlansStaticData,
  getCurrentRangeStaticData,
  addNotification,
  addBasicConstraints
} from "actions";
import { formatNumber, formatPercentage } from "utils";
import { DEFAULT_NUM_BAYS } from "constants";

const defaultErrors = {
  isDuplicateName: false,
  isSkuNotIncremental: false,
  isPercentageWrong: false,
  isSkuMaxTooHigh: false
};

const renderHeader = params => {
  const { colDef } = params;
  const { headerName, description } = colDef;
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2">{headerName}</Typography>
      <Tooltip title={headerName} content={description}>
        <MoreInfoOutlineIcon sx={{ fontSize: 14 }} />
      </Tooltip>
    </Stack>
  );
};

const columns = [
  {
    field: "rowNumber",
    headerName: "#",
    width: 32,
    sortable: false
  },
  {
    field: "rangeName",
    headerName: "Range Name",
    renderHeader: params => renderHeader(params),
    width: 140,
    editable: true,
    sortable: false,
    description:
      'We recommend renaming these so it is easier for you to review the outputs later on. An example of a good name would be "2 bay plan".'
  },
  {
    field: "skus",
    headerName: "# of Skus",
    renderHeader: params => renderHeader(params),
    width: 100,
    type: "number",
    editable: true,
    sortable: false,
    description:
      "Please enter the number of unique skus that are present on each bay plan. This should reflect what was agreed with the customer at the previous range review.",
    valueParser: value => Math.round(value)
  },
  {
    field: "storesAccountedFor",
    headerName: "% of Stores Accounted for",
    renderHeader: params => renderHeader(params),
    width: 200,
    type: "number",
    editable: true,
    sortable: false,
    description:
      "We have estimated this based on the distribution of the number of skus selected, but we recommend editing it if you can. This impacts the overall sales impact of Auto-Ranging."
  }
];

const RangeSizes = props => {
  const {
    dispatchGetCurrentRangeStaticData,
    dispatchBayPlansConstraints,
    client,
    basicConstraints,
    bayPlanConstraints,
    dispatchGetBayPlansStaticData,
    currentRangeConstraints,
    dispatchAddNotification,
    dispatchBasicConstraints,
    setActiveStep,
    isLoading,
    rangeSizes,
    error,
    isLoadingCurrentRange
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { id: processId, type } = params;

  const { story, retailer, period, consumptionType, categories, nDist } =
    basicConstraints;
  const scope = { consumption_type: consumptionType, ...categories };

  const [isShowingErrors, setIsShowingErrors] = useState(false);
  const [isEditFinished, setIsEditFinished] = useState(true);
  const [distribution, setDistribution] = useState(nDist);
  const [isDistDialogOpen, setIsDistDialogOpen] = useState(false);
  const [isBayPlanDialogOpen, setIsBayPlanDialogOpen] = useState(false);
  const [bayPlansQuantity, setBayPlansQuantity] = useState(
    bayPlanConstraints.length
  );
  const [tempQuantity, setTempQuantity] = useState(bayPlanConstraints.length);
  const [errors, setErrors] = useState(defaultErrors);
  const [hasError, setHasError] = useState(false);

  const initialRows = rangeSizes.map((i, k) => ({
    id: _.uniqueId(),
    rowNumber: k + 1,
    rangeName: i.range_name,
    skus: i.number_of_skus,
    storesAccountedFor: i.percentage_of_stores_accounted_for
  }));

  useEffect(() => {
    setActiveStep(1);
  }, []);

  useEffect(() => {
    if (!!error || _.isEmpty(rangeSizes) || !_.isEmpty(bayPlanConstraints))
      return;
    setBayPlansQuantity(initialRows.length);
    dispatchBayPlansConstraints(initialRows);
  }, [rangeSizes]);

  //
  // error checking
  //
  const isNotAllFields = !!bayPlanConstraints.find(
    ({ rangeName, skus, storesAccountedFor }) =>
      !rangeName || !skus || !storesAccountedFor
  );

  const maxNumberOfSkus = rangeSizes.slice(-1)?.[0]?.number_of_skus;

  const totalPercentage = bayPlanConstraints
    .map(({ storesAccountedFor }) => storesAccountedFor)
    .reduce((a, b) => +a + +b, 0);

  useEffect(() => {
    const rangeNames = bayPlanConstraints.map(i => i.rangeName);
    const isDuplicateName = rangeNames.some(
      (item, index) => rangeNames.indexOf(item) !== index
    );

    let isSkusIncremental = true;
    bayPlanConstraints.forEach(({ skus }, index) => {
      if (
        bayPlanConstraints[index + 1] &&
        +skus >= +bayPlanConstraints[index + 1].skus
      )
        isSkusIncremental = false;
    });
    const isSkuNotIncremental = !isSkusIncremental;

    const isPercentageWrong =
      totalPercentage < 99.999 || totalPercentage > 100.001;

    let isSkuMaxTooHigh = false;
    if (
      bayPlanConstraints.slice(-1)?.[0]?.skus &&
      +bayPlanConstraints.slice(-1)[0].skus > +maxNumberOfSkus
    ) {
      isSkuMaxTooHigh = true;
    }

    setErrors({
      isDuplicateName,
      isSkuNotIncremental,
      isPercentageWrong,
      isSkuMaxTooHigh
    });
    setHasError(
      isDuplicateName ||
        isSkuNotIncremental ||
        isPercentageWrong ||
        isSkuMaxTooHigh
    );
  }, [bayPlanConstraints]);
  //
  // end error checking
  //

  const onNextClicked = () => {
    // if next stage has data -> navigate directly
    if (!_.isEmpty(currentRangeConstraints.inRange)) {
      navigate(
        generatePath(NEW_AUTO_RANGING_ROUTE, {
          type,
          id: processId,
          step: "current-range"
        })
      );
      return;
    }
    setIsShowingErrors(true);
    if (hasError) return;
    const rangeSizesTable = bayPlanConstraints.map(
      ({ rangeName, skus, storesAccountedFor }) => ({
        range_name: rangeName,
        number_of_skus: skus,
        percentage_of_stores_accounted_for: storesAccountedFor
      })
    );
    dispatchGetCurrentRangeStaticData({
      retailer,
      period,
      scope,
      rangeSizesTable,
      story,
      client,
      nDist,
      onSuccess: () =>
        navigate(
          generatePath(NEW_AUTO_RANGING_ROUTE, {
            type,
            id: processId,
            step: "current-range"
          })
        )
    });
  };

  if (_.isEmpty(categories)) {
    return (
      <Navigate
        to={generatePath(NEW_AUTO_RANGING_ROUTE, {
          type,
          id: processId,
          step: "basics"
        })}
      />
    );
  }

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="4"
          title="Distribution Threshold"
          subtitle="Auto-Ranging excludes SKUs below a certain distribution threshold from range optimisation. The default threshold is 10%, but you can modify it to suit your needs."
        />
        <Slider
          value={distribution}
          onChange={(e, value) => setDistribution(value)}
          valueLabelDisplay="auto"
          valueLabelFormat={val => `${val * 100}%`}
          marks={[
            { value: 0.05, label: "5%" },
            { value: 0.1, label: "10%" },
            { value: 0.15, label: "15%" },
            { value: 0.2, label: "20%" },
            { value: 0.25, label: "25%" }
          ]}
          min={0.05}
          max={0.25}
          step={0.05}
          styles={{ mx: 1, width: 300 }}
        />
        <InfoText
          level="info"
          text="Adjusting the threshold will update the Range Sizes table accordingly."
          variant="outlined"
          styles={{ mb: 2.5 }}
        />
        <Button
          variant="soft"
          size="small"
          onClick={() => setIsDistDialogOpen(true)}
          disabled={distribution === nDist}
        >
          Update Threshold
        </Button>
      </Paper>
      <Paper>
        <SectionTitle
          order="5"
          title="Range Sizes"
          subtitle="Select your desired Range Sizes."
        />
        <TextField
          label="How many bay plans will you be presenting"
          value={bayPlansQuantity}
          onChange={e => {
            let val = Number(e.target.value);
            if (!val || val < 0) val = 1;
            if (val > 6) val = 6;
            setTempQuantity(val);
            setIsBayPlanDialogOpen(true);
          }}
          styles={{ maxWidth: 400 }}
          type="number"
          InputProps={{ inputProps: { min: 1, max: 6 } }}
          disabled={isLoading}
          infoText="You can Auto-Range up to 6 Bay Plans at once."
        />
        <Box>
          <Button
            variant="soft"
            color="secondary"
            size="small"
            startIcon={<RefreshIcon />}
            sx={{ my: 1 }}
            onClick={() => {
              setErrors(defaultErrors);
              dispatchBayPlansConstraints(initialRows);
            }}
          >
            Reset table data
          </Button>
          <DataGrid
            styles={{
              maxWidth: 500,
              mb: 2,
              "& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-cell": {
                justifyContent: "center"
              }
            }}
            rows={bayPlanConstraints}
            columns={columns}
            isLoading={isLoading}
            hideFooter
            processRowUpdate={item => {
              setErrors(defaultErrors);
              const { id, rowNumber, rangeName, skus, storesAccountedFor } =
                item || {};
              const modifiedRow = {
                id,
                rowNumber,
                rangeName,
                skus: Math.round(skus) > 2 ? Math.round(skus) : 2,
                storesAccountedFor: formatNumber(storesAccountedFor)
              };
              const updatedBayPlans = bayPlanConstraints.map(row =>
                row.id === id ? modifiedRow : row
              );
              dispatchBayPlansConstraints(updatedBayPlans);
              return modifiedRow;
            }}
            onProcessRowUpdateError={() =>
              dispatchAddNotification("Error updating table")
            }
            onCellEditStart={() => setIsEditFinished(false)}
            onCellEditStop={() => setIsEditFinished(true)}
          />
        </Box>
        {isShowingErrors && isNotAllFields && (
          <Typography variant="body1" color="negative.main">
            * All fields must be filled.
          </Typography>
        )}
        {isShowingErrors && errors.isDuplicateName && (
          <Typography variant="body1" color="negative.main">
            * Range Names must be unique.
          </Typography>
        )}
        {isShowingErrors && errors.isSkuNotIncremental && (
          <Typography variant="body1" color="negative.main">
            * # of Skus in Current Range must increase.
          </Typography>
        )}
        {isShowingErrors && errors.isPercentageWrong && (
          <Typography variant="body1" color="negative.main">
            * Sum of % of Stores Accounted for must equal 100%.
            {totalPercentage
              ? ` It is currently ${formatPercentage(totalPercentage)}.`
              : ""}
          </Typography>
        )}
        {isShowingErrors && errors.isSkuMaxTooHigh && (
          <Typography variant="body1" color="negative.main">
            * The maximum # of Skus is {maxNumberOfSkus}.
          </Typography>
        )}
      </Paper>
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_AUTO_RANGING_ROUTE, {
              type,
              id: processId,
              step: "basics"
            })
          )
        }
        onNextClick={onNextClicked}
        nextDisabled={
          !isEditFinished ||
          bayPlanConstraints.length === 0 ||
          isNotAllFields ||
          (isShowingErrors && hasError)
        }
        nextLoading={isLoadingCurrentRange}
      />
      <WarningDialog
        isOpen={isDistDialogOpen}
        title="Confirm Threshold Update"
        content={
          <Fragment>
            <Typography variant="inherit" gutterBottom>
              Updating the Distribution Threshold will recalculate the Range
              Sizes table with new values.
            </Typography>
            <Typography variant="inherit">
              Do you want to proceed with this change?
            </Typography>
          </Fragment>
        }
        onClose={() => setIsDistDialogOpen(false)}
        onConfirm={() => {
          dispatchBasicConstraints({ nDist: distribution });
          dispatchGetBayPlansStaticData({
            retailer,
            period,
            scope,
            story,
            client,
            numBays: DEFAULT_NUM_BAYS,
            nDist: distribution
          });
          setBayPlansQuantity(DEFAULT_NUM_BAYS);
          setIsDistDialogOpen(false);
        }}
        confirmButton="Update Threshold"
      />
      <WarningDialog
        isOpen={isBayPlanDialogOpen}
        title="Change Number of Bay Plans"
        content="Please confirm you would like to change the number of Bay Plans. Doing so will reset the table to default values."
        onClose={() => setIsBayPlanDialogOpen(false)}
        onConfirm={() => {
          setBayPlansQuantity(tempQuantity);
          dispatchBayPlansConstraints([]);
          dispatchGetBayPlansStaticData({
            retailer,
            period,
            scope,
            story,
            client,
            numBays: tempQuantity,
            nDist
          });
          setErrors(defaultErrors);
          setIsBayPlanDialogOpen(false);
        }}
        confirmButton="Change"
      />
    </Fragment>
  );
};

RangeSizes.propTypes = {
  dispatchGetCurrentRangeStaticData: PropTypes.func,
  dispatchGetBayPlansStaticData: PropTypes.func,
  dispatchBayPlansConstraints: PropTypes.func,
  client: PropTypes.string,
  basicConstraints: PropTypes.shape(),
  bayPlanConstraints: PropTypes.arrayOf(PropTypes.shape()),
  currentRangeConstraints: PropTypes.shape(),
  dispatchAddNotification: PropTypes.func,
  dispatchBasicConstraints: PropTypes.func,
  setActiveStep: PropTypes.func,
  isLoading: PropTypes.bool,
  rangeSizes: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.string,
  isLoadingCurrentRange: PropTypes.bool
};

RangeSizes.defaultProps = {
  dispatchGetCurrentRangeStaticData: () => {},
  dispatchGetBayPlansStaticData: () => {},
  dispatchBayPlansConstraints: () => {},
  client: "",
  basicConstraints: {},
  bayPlanConstraints: [],
  currentRangeConstraints: {},
  dispatchAddNotification: () => {},
  dispatchBasicConstraints: () => {},
  setActiveStep: () => {},
  isLoading: false,
  rangeSizes: [],
  error: "",
  isLoadingCurrentRange: false
};

const mapStateToProps = state => {
  const {
    autoRanging: {
      bayPlanStaticData: {
        data: { range_sizes: rangeSizes } = {},
        isLoading = false,
        error = ""
      } = {},
      basicConstraints = {},
      bayPlanConstraints = [],
      currentRangeConstraints = {},
      currentRangeStaticData: { isLoading: isLoadingCurrentRange } = {}
    }
  } = state;
  return {
    client: state.user.user.client,
    basicConstraints,
    rangeSizes,
    isLoading,
    isLoadingCurrentRange,
    error,
    bayPlanConstraints,
    currentRangeConstraints
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchBayPlansConstraints: constraints =>
    dispatch(bayPlansConstraintsAction(constraints)),
  dispatchGetCurrentRangeStaticData: ({
    retailer,
    period,
    scope,
    rangeSizesTable,
    story,
    client,
    nDist,
    onSuccess
  }) =>
    dispatch(
      getCurrentRangeStaticData({
        retailer,
        period,
        scope,
        rangeSizesTable,
        story,
        client,
        nDist,
        onSuccess
      })
    ),
  dispatchGetBayPlansStaticData: ({
    retailer,
    period,
    scope,
    story,
    client,
    nDist,
    numBays
  }) =>
    dispatch(
      getBayPlansStaticData({
        retailer,
        period,
        scope,
        story,
        client,
        nDist,
        numBays
      })
    ),
  dispatchAddNotification: error =>
    dispatch(addNotification(error, alertLevels.ERROR)),
  dispatchBasicConstraints: constraints =>
    dispatch(addBasicConstraints(constraints))
});

export default connect(mapStateToProps, mapDispatchToProps)(RangeSizes);
