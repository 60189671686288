import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import PlotlyChart from "components/PlotlyChart";
import {
  formatNumber,
  getUniqueColors,
  wrapText,
  getIndividualMap
} from "utils";
import { IMPACT_COLORS } from "constants/reportConstants";

const StackedBar = props => {
  const { data, display, colors } = props;
  const { isSmall, isTiny } = display;
  const isIndividualMap = !_.isEmpty(display.labels_style.individual_map);
  const isColorMap = !_.isEmpty(display.chart_style.color_map);
  const colorMap = display.chart_style.color_map;
  const uniqueColors = getUniqueColors(colors, colorMap);
  const { minimum_scale: min, maximum_scale: max } = display.values_style;
  const numberFormat = display.labels_style.number_format;
  const isKantar = display.apply.includes("kantar");
  const isImpact = display.apply.includes("impact");
  return (
    <PlotlyChart
      data={data.y.map((e, key) => {
        let colour = uniqueColors[key % uniqueColors.length];
        if (isColorMap) {
          colour =
            colorMap[key] === undefined
              ? uniqueColors[key % uniqueColors.length]
              : `#${colorMap[key]}`;
        }
        if (isImpact) {
          colour = IMPACT_COLORS[e[0]];
        }
        return {
          x: e[1],
          y: isTiny ? data.x.map(i => wrapText(i, 15)) : data.x,
          type: "bar",
          orientation: "h",
          text:
            (isImpact && e[1].map(i => formatNumber(i, numberFormat))) ||
            (isIndividualMap &&
              getIndividualMap(
                display.labels_style.individual_map[key],
                e[1].length
              )) ||
            (!isKantar && e[1].map(i => formatNumber(i, numberFormat))),
          textfont: { color: "#FFFFFF", size: isTiny ? 10 : 14 },
          textangle: 0,
          insidetextanchor: "middle",
          cliponaxis: false,
          name: isSmall || isTiny ? wrapText(e[0], 15) : e[0],
          marker: { color: colour, width: 1 }
        };
      })}
      layout={{
        yaxis: {
          type: "category",
          tickfont: { size: 12 },
          ticklen: 2,
          tickcolor: "transparent",
          automargin: true,
          showgrid: false,
          fixedrange: true
        },
        xaxis: {
          showticklabels: false,
          automargin: true,
          showgrid: false,
          zeroline: true,
          range: [min, max],
          fixedrange: true
        },
        barmode: "relative",
        showlegend: !isImpact,
        legend:
          isSmall && !isKantar
            ? { font: { size: 10 } }
            : {
                orientation: "h",
                xanchor: "center",
                yanchor: !isKantar && "bottom",
                y: !isKantar && 1,
                x: 0.5,
                font: { size: 10 }
              },
        margin: (isTiny && { t: 0, b: 0, r: 0, l: 0 }) ||
          (isSmall && { t: 20, b: 40, r: 0, l: 0 }) || {
            t: 40,
            b: 40,
            r: 20,
            l: 20
          }
      }}
    />
  );
};

StackedBar.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string)
};

StackedBar.defaultProps = {
  data: {},
  display: {},
  colors: []
};

export default StackedBar;
