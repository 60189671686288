import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const FONT_SIZE = 16;
const COLOR = "grey.500";

const ReportTypography = props => {
  const { children, config, fontSize, ...otherProps } = props;
  const { fontFamily, fontSize: size } = config;
  return (
    <Typography
      fontSize={(fontSize / FONT_SIZE) * size}
      fontFamily={fontFamily}
      lineHeight={1.43}
      color={COLOR}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

ReportTypography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  config: PropTypes.shape(),
  fontSize: PropTypes.number
};

ReportTypography.defaultProps = {
  children: "",
  config: {},
  fontSize: 16
};

export default ReportTypography;
