import React from "react";
import PropTypes from "prop-types";
import { isSaturday, isSunday } from "date-fns";
import { Box, Stack } from "@mui/material";
import { DatePicker } from "components/BaseComponents";

const CustomDatepicker = props => {
  const {
    dateError,
    onChangeStart,
    onChangeEnd,
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate,
    selectedStart,
    selectedEnd,
    disabledStart,
    disabledEnd,
    openToDate,
    startDate,
    endDate,
    isMonth
  } = props;
  return (
    <Stack direction="row" spacing={1}>
      <Box>
        <DatePicker
          label="From"
          error={dateError}
          selected={selectedStart}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeStart}
          filterDate={!isMonth && isSunday}
          minDate={minStartDate}
          maxDate={maxStartDate}
          placeholderText="Start date"
          disabled={disabledStart}
          isMonth={isMonth}
        />
      </Box>
      <Box>
        <DatePicker
          label="To"
          error={dateError}
          selected={selectedEnd}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeEnd}
          filterDate={!isMonth && isSaturday}
          openToDate={openToDate}
          minDate={minEndDate}
          maxDate={maxEndDate}
          placeholderText="End date"
          disabled={disabledEnd}
          isMonth={isMonth}
        />
      </Box>
    </Stack>
  );
};

CustomDatepicker.propTypes = {
  dateError: PropTypes.string,
  onChangeStart: PropTypes.func,
  onChangeEnd: PropTypes.func,
  minStartDate: PropTypes.shape(),
  maxStartDate: PropTypes.shape(),
  minEndDate: PropTypes.shape(),
  maxEndDate: PropTypes.shape(),
  selectedStart: PropTypes.shape(),
  selectedEnd: PropTypes.shape(),
  disabledStart: PropTypes.bool,
  disabledEnd: PropTypes.bool,
  openToDate: PropTypes.shape(),
  startDate: PropTypes.shape(),
  endDate: PropTypes.shape(),
  isMonth: PropTypes.bool
};

CustomDatepicker.defaultProps = {
  dateError: "",
  onChangeStart: () => {},
  onChangeEnd: () => {},
  minStartDate: {},
  maxStartDate: {},
  minEndDate: {},
  maxEndDate: {},
  selectedStart: {},
  selectedEnd: {},
  disabledStart: false,
  disabledEnd: false,
  openToDate: {},
  startDate: {},
  endDate: {},
  isMonth: false
};

export default CustomDatepicker;
