import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ReportStepper } from "components/CustomComponents";
import { fetchStaticData } from "actions";
import { HIERARCHY_LIST } from "constants";
import { getStoryConfig } from "config";
import { storyEnums } from "constants/enums";
import Header from "./Header";
import Product from "./Product";
import Period from "./Period";
import Hierarchy from "./Hierarchy";
import Review from "./Review";

const steps = [
  "Product & Filter",
  "Time Period",
  "Hierarchy & Metric",
  "Review and Run"
];

const story = storyEnums.PRR;
const { dataSet, type } = getStoryConfig(story);

const NewRangeReview = props => {
  const {
    isStaticData,
    getData,
    client,
    subsections,
    filteredSubsections,
    dataDate,
    isFilteringData,
    isFilteredData,
    temperature,
    consumptionType,
    category,
    sector,
    retailer,
    period,
    implementationDate,
    hier1,
    hier2,
    metric
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const params = useParams();
  const { step } = params;

  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  const categoryList = isFilteredData
    ? filteredSubsections.find(i => i.name === "category")
    : subsections.find(i => i.name === "category");
  const sectorList = isFilteredData
    ? filteredSubsections.find(i => i.name === "sector")
    : subsections.find(i => i.name === "sector");
  const retailerList = subsections.find(i => i.name === "prr_retailer");

  return (
    <Fragment>
      <ReportStepper activeStep={activeStep} steps={steps} />
      <Header
        story={story}
        isLastStep={activeStep === steps.length - 1}
        category={category}
        sector={sector}
        retailer={retailer}
      />
      {step === "product" && (
        <Product
          categoryOptions={
            categoryList?.keywords.map(i => (i.value ? i.value : i)) || []
          }
          sectorOptions={
            sectorList?.keywords.map(i => (i.value ? i.value : i)) || []
          }
          retailerOptions={retailerList?.keywords || []}
          story={story}
          client={client}
          isFilteringData={isFilteringData}
          isStaticData={isStaticData}
          category={category}
          sector={sector}
          retailer={retailer}
          setActiveStep={setActiveStep}
          reportType={type}
          temperature={temperature}
          consumptionType={consumptionType}
          hier1={hier1}
          hier2={hier2}
        />
      )}
      {step === "period" && (
        <Period
          dataDate={dataDate}
          period={period}
          implementationDate={implementationDate}
          story={story}
          setActiveStep={setActiveStep}
          reportType={type}
        />
      )}
      {step === "hierarchy" && (
        <Hierarchy
          hierarchyList={
            (sector &&
              HIERARCHY_LIST.filter(
                i => i.name !== "sector" && i.name !== "category"
              )) ||
            (category && HIERARCHY_LIST.filter(i => i.name !== "category")) ||
            HIERARCHY_LIST
          }
          hier1={hier1}
          hier2={hier2}
          metric={metric}
          setActiveStep={setActiveStep}
          story={story}
          isLoading={isFilteringData}
          reportType={type}
        />
      )}
      {step === "review" && (
        <Review
          temperature={temperature}
          consumptionType={consumptionType}
          category={category}
          sector={sector}
          retailer={retailer}
          period={period}
          implementationDate={implementationDate}
          hier1={hier1}
          hier2={hier2}
          metric={metric}
          setActiveStep={setActiveStep}
          story={story}
          isLoading={isFilteringData}
          reportType={type}
        />
      )}
    </Fragment>
  );
};

NewRangeReview.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  isStaticData: PropTypes.bool,
  getData: PropTypes.func,
  dataDate: PropTypes.string,
  isFilteringData: PropTypes.bool,
  isFilteredData: PropTypes.bool,
  client: PropTypes.string,
  temperature: PropTypes.string,
  consumptionType: PropTypes.string,
  category: PropTypes.string,
  sector: PropTypes.string,
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  implementationDate: PropTypes.string,
  hier1: PropTypes.shape(),
  hier2: PropTypes.shape(),
  metric: PropTypes.string
};

NewRangeReview.defaultProps = {
  subsections: [],
  filteredSubsections: [],
  isStaticData: false,
  getData: () => {},
  dataDate: "",
  isFilteringData: false,
  isFilteredData: false,
  client: "",
  temperature: null,
  consumptionType: null,
  category: null,
  sector: null,
  retailer: null,
  period: null,
  implementationDate: null,
  hier1: null,
  hier2: null,
  metric: null
};

const mapStateToProps = state => {
  const {
    search: {
      filteredSubsections = { story: [] },
      isFilteredData = { story: false },
      isFilteringData = false
    },
    data: { subsections = { dataSet: [] }, isStaticData = false },
    reportConstraints: {
      [story]: {
        temperature = null,
        consumptionType = null,
        category = null,
        sector = null,
        retailer = null,
        period = null,
        implementationDate = null,
        hier1 = null,
        hier2 = null,
        metric = null
      } = {}
    }
  } = state;
  return {
    dataDate: state.data.date[dataSet],
    subsections: subsections[dataSet],
    filteredSubsections: filteredSubsections[story],
    isStaticData,
    isFilteredData: isFilteredData[story],
    isFilteringData,
    client: state.user.user.client,
    temperature,
    consumptionType,
    category,
    sector,
    retailer,
    period,
    implementationDate,
    hier1,
    hier2,
    metric
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => dispatch(fetchStaticData(client))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRangeReview);
