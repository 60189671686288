import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { MinusIcon, ArrowUpIcon, ArrowDownIcon } from "components/Icons";
import { AUTO_RANGING_COLORS } from "constants/reportConstants";
import {
  convertToPercentage,
  formatAbsoluteCurrency,
  formatAbsolutePercentage,
  formatCurrency,
  formatValue
} from "utils";
import { Table } from "components/BaseComponents";

const styles = {
  sustainability: { width: 80, px: 0.25 },
  headerRow: { pt: 1.5, pb: 0.25, color: "grey.500", borderBottom: "none" },
  header: { pb: 1.5, pt: 0.25 },
  border: { borderRight: 1 },
  noBorder: { "&.MuiTableCell-root": { borderRight: "none" } }
};

const getBayPlanIcon = direction => {
  let icon = (
    <ArrowDownIcon sx={{ fontSize: 16, color: "negative.main", mr: 0.5 }} />
  );
  if (direction > 0) {
    icon = (
      <ArrowUpIcon sx={{ fontSize: 16, color: "positiveArray.700", mr: 0.5 }} />
    );
  }
  if (direction === 0) {
    icon = <MinusIcon sx={{ fontSize: 16, color: "neutralArray.600" }} />;
  }
  return icon;
};

const columns = [
  { label: "Rank", renderCell: item => item.rank + 1, rowSpan: 2 },
  {
    label: "SKU",
    renderCell: item => item.sku,
    styles: { textAlign: "left" },
    rowSpan: 2
  },
  {
    label: "Before",
    renderCell: item => formatCurrency(item.sales_before),
    styles: { ...styles.noBorder, ...styles.header }
  },
  {
    label: "After",
    renderCell: item => formatCurrency(item.sales_after),
    styles: { ...styles.noBorder, ...styles.header }
  },
  {
    label: "Change",
    renderCell: item => (
      <Stack direction="row" alignItems="center" justifyContent="center">
        {formatAbsoluteCurrency(item.sales_change)} (
        {formatAbsolutePercentage(item.sales_percentage_change)})
      </Stack>
    ),
    styles: { ...styles.border, ...styles.header }
  },
  {
    label: "Before",
    renderCell: item => formatValue(item.uros_before),
    styles: { ...styles.noBorder, ...styles.header }
  },
  {
    label: "After",
    renderCell: item => formatValue(item.uros_after),
    styles: { ...styles.border, ...styles.header }
  },
  {
    label: "Before",
    renderCell: item => convertToPercentage(item.ndist_before),
    styles: { ...styles.noBorder, ...styles.header }
  },
  {
    label: "After",
    renderCell: item => convertToPercentage(item.ndist_after),
    styles: { ...styles.border, ...styles.header }
  },
  {
    label: "Sales Flow",
    renderCell: item => formatCurrency(item.net_sales_flow),
    rowSpan: 2,
    styles: styles.border
  },
  {
    label: "Bay Plan Change",
    renderCell: item => (
      <Stack direction="row" alignItems="center" justifyContent="center">
        {getBayPlanIcon(item.bay_plan_changed)}
        {item.bay_plan_changed !== 0 &&
          `${item.bay_plan_changed} ${item.bay_name_after}`}
      </Stack>
    ),
    rowSpan: 2
  }
];

const header = [
  { label: "Rank", rowSpan: 2 },
  { label: "SKU", rowSpan: 2, styles: { textAlign: "left" } },
  { label: "Sales", colSpan: 3, styles: styles.headerRow },
  { label: "Rate of Sale", colSpan: 2, styles: styles.headerRow },
  { label: "Distribution", colSpan: 2, styles: styles.headerRow },
  { label: "Sales Flow", rowSpan: 2 },
  { label: "Bay Plan Change", rowSpan: 2 }
];

const CompareSales = props => {
  const { data } = props;
  return (
    <Table
      columns={columns}
      rows={data.map(i => ({
        ...i,
        styles: { bgcolor: `${AUTO_RANGING_COLORS[i.bay_id_after]}60` }
      }))}
      multipleHeader
      header={header}
      styles={{ head: { "& .MuiTableCell-root": { lineHeight: 1.25 } } }}
    />
  );
};

CompareSales.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape())
};

CompareSales.defaultProps = {
  data: []
};

export default CompareSales;
