import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid } from "@mui/material";
import {
  ProductIcon,
  PackagingIcon,
  PackageDetailIcon
} from "components/Icons";
import { SelectAutocomplete, Tabs } from "components/BaseComponents";
import { getSearchOptions } from "utils";
import { GROUPINGS } from "constants";
import Expand from "./Expand";

const getIcon = label => {
  switch (label) {
    case "product":
      return <ProductIcon fontSize="small" />;
    case "packaging":
      return <PackagingIcon fontSize="small" />;
    case "package detail":
      return <PackageDetailIcon fontSize="small" />;
    default:
      return <ProductIcon fontSize="small" />;
  }
};

const getTabs = subsections =>
  GROUPINGS.filter(i =>
    subsections
      .filter(j => j.keywords.length)
      .map(j => j.grouping)
      .includes(i)
  ).map(i => ({ label: _.startCase(i), value: i, icon: getIcon(i) }));

const getInputFields = (subsections, group, story) =>
  subsections
    .filter(i => i.grouping === group)
    .filter(i => i.keywords.length > 0)
    .map(i => ({
      subsection: i.name,
      options: getSearchOptions(
        i.keywords.sort().map(kw => ({ name: kw, subsection: i.name })),
        i.table,
        story
      )
    }));

const getSelectedValue = (selected, subsection, isMultiple, isSingle) => {
  if (isMultiple) {
    // multiple values within each subsection
    return selected.filter(i => i.subsection === subsection) || [];
  }
  if (isSingle) {
    // one value across all subsections
    return (selected && selected.subsection === subsection && selected) || null;
  } // multiple values across subsections, max one per subsection
  return selected.find(i => i.subsection === subsection) || null;
};

const AdvancedSearch = props => {
  const {
    styles,
    fields,
    isLoading,
    selected,
    onChange,
    multiple,
    single,
    story
  } = props;
  const [activeTab, setActiveTab] = useState("product");
  const [tabs, setTabs] = useState([]);
  const [inputFields, setInputFields] = useState([]);

  useEffect(() => {
    setTabs(getTabs(fields));
  }, [fields]);
  useEffect(() => {
    setInputFields(getInputFields(fields, activeTab, story));
  }, [fields, activeTab]);

  return (
    <Expand label="Advanced Search" styles={styles}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChange={(e, value) => setActiveTab(value)}
        isLoading={isLoading}
        styles={{ pb: 3, mt: -1 }}
      />
      <Grid container spacing={2}>
        {inputFields.map(i => (
          <Grid item xs={12} md={4} key={i.subsection}>
            <SelectAutocomplete
              label={_.startCase(i.subsection)}
              fullWidth
              options={i.options}
              value={getSelectedValue(selected, i.subsection, multiple, single)}
              getOptionLabel={option => option.value}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              onChange={(e, value) => {
                let result;
                if (multiple) {
                  // multiple values within each subsection
                  result = selected
                    .filter(j => j.subsection !== i.subsection)
                    .concat(value);
                } else if (single) {
                  // one value across all subsections
                  result = value;
                } else {
                  // multiple values across subsections, max one per subsection
                  result = value
                    ? selected.concat(value)
                    : selected.filter(j => j.subsection !== i.subsection);
                }
                onChange(result);
              }}
              isLoading={isLoading}
              multiple={multiple}
              filterSelectedOptions
            />
          </Grid>
        ))}
      </Grid>
    </Expand>
  );
};

AdvancedSearch.propTypes = {
  styles: PropTypes.shape(),
  fields: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape()
  ]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  single: PropTypes.bool,
  story: PropTypes.string
};

AdvancedSearch.defaultProps = {
  styles: {},
  fields: [],
  isLoading: false,
  selected: [],
  onChange: () => {},
  multiple: false,
  single: false,
  story: ""
};

export default AdvancedSearch;
