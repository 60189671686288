import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { SelectAutocomplete } from "components/BaseComponents";
import { NUMBER_SUGGESTIONS } from "constants";
import { getSearchOptions, initSearch } from "utils";

const keys = [
  "name",
  "name.value",
  "name.translation",
  { name: "subsection", weight: 0.05 }
];

const getOptions = (subsections, story) => {
  const options = subsections
    .filter(i => i.grouping)
    .reduce(
      (prev, subsection) =>
        prev.concat(
          subsection.keywords.map(kw => ({
            name: kw,
            subsection: subsection.name,
            table: subsection.table
          }))
        ),
      []
    );
  const table =
    _.uniq(subsections.filter(i => i.grouping).map(i => i.table))[0] || "what";
  return getSearchOptions(options, table, story);
};

const handleFilter = (options, state, searchEngine) => {
  const { inputValue } = state;
  const suggestions = searchEngine
    .search(inputValue)
    .slice(0, NUMBER_SUGGESTIONS)
    .map(i => ({ ...i.item, name: i.item.name }));
  const intersection = _.intersectionWith(suggestions, options, _.isEqual);
  return intersection;
};

const SearchBar = props => {
  const {
    label,
    fullWidth,
    options,
    styles,
    error,
    required,
    size,
    multiple,
    story,
    ...otherProps
  } = props;
  const [input, setInput] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchEngine, setSearchEngine] = useState(null);

  useEffect(() => {
    const searchItems = getOptions(options, story);
    setSearchOptions(searchItems);
    setSearchEngine(initSearch(searchItems, keys));
  }, [options]);

  return (
    <SelectAutocomplete
      label={label}
      required={required}
      fullWidth={fullWidth}
      styles={styles}
      error={error}
      size={size}
      options={searchOptions}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      filterOptions={(opts, state) => handleFilter(opts, state, searchEngine)}
      onInputChange={(e, val) => setInput(val)}
      noOptionsText={input ? "No options" : "Please enter 1 or more characters"}
      multiple={multiple}
      filterSelectedOptions
      {...otherProps}
    />
  );
};

SearchBar.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  styles: PropTypes.shape(),
  required: PropTypes.bool,
  error: PropTypes.string,
  size: PropTypes.string,
  multiple: PropTypes.bool,
  story: PropTypes.string
};

SearchBar.defaultProps = {
  label: "",
  fullWidth: true,
  options: [],
  styles: {},
  required: false,
  error: "",
  size: "medium",
  multiple: false,
  story: ""
};

export default SearchBar;
