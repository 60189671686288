import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import { AutoRangingIcon, SterlingIcon } from "components/Icons";
import { Paper } from "components/BaseComponents";
import {
  NEW_AUTO_RANGING_INITIAL_ROUTE,
  STERLING_INITIAL_ROUTE
} from "constants/viewRoutes";
import { permissionEnums } from "constants/enums";
import { clientConfig } from "config";
import { ReportCard } from "components/CustomComponents";
import { REPORT_COLORS } from "theme";

const CategoryOptimisation = props => {
  const { client } = props;
  const navigate = useNavigate();

  const { permissions, name: clientName = "" } = clientConfig[client] || {
    permissions: []
  };

  const roles =
    (localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).roles) ||
    [];

  const isAutoRanging = roles && roles.includes("auto_ranging");

  const reportTypes = [
    {
      title: "Auto-Ranging",
      subtitle:
        "The Auto-Ranging tool is to be used to understand performance within a retailer or environment and will provide recommended range changes for the category.",
      icon: <AutoRangingIcon sx={{ color: REPORT_COLORS.autoRanging }} />,
      buttons: [
        {
          label: "New Auto-Ranging Report",
          onClick: () => {
            navigate(NEW_AUTO_RANGING_INITIAL_ROUTE);
          },
          style: { "&, &:hover": { bgcolor: REPORT_COLORS.autoRanging } }
        }
      ],
      descriptions: [
        "Auto-Ranging is typically used when preparing for range reviews but can also be used to identify over- and under-performing products."
      ],
      isVisible:
        permissions.includes(permissionEnums.AUTO_RANGING) && isAutoRanging
    },
    {
      title: "Sterling Portfolio Prioritisation",
      subtitle: `The Sterling Portfolio Prioritisation tool creates a single master view of the ${clientName} priority range (both within brand/sector and across brands by environment) to ensure that:`,
      icon: <SterlingIcon sx={{ color: REPORT_COLORS.sterling }} />,
      buttons: [
        {
          label: "New Sterling Report",
          onClick: () => {
            navigate(STERLING_INITIAL_ROUTE);
          },
          style: { "&, &:hover": { bgcolor: REPORT_COLORS.sterling } }
        }
      ],
      descriptions: [
        "All parts of the business are focused on the core range (across system and across functions).",
        "We understand the 'must stock list' at the top, but also have a consistent view on what sits at the tail in order to support rationalisation choices."
      ],
      isVisible: permissions.includes(permissionEnums.STERLING)
    }
  ].filter(i => i.isVisible);

  return (
    <Paper>
      <Typography variant="h3" color="primary" sx={{ mb: 3 }}>
        Category Optimisation
      </Typography>
      <Grid container spacing={2}>
        {reportTypes.map(report => (
          <Grid key={report.title} item xs={12} md={6}>
            <ReportCard report={report} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

CategoryOptimisation.propTypes = {
  client: PropTypes.string
};

CategoryOptimisation.defaultProps = {
  client: ""
};

const mapStateToProps = state => ({
  client: state.user.user.client
});

export default connect(mapStateToProps)(CategoryOptimisation);
