import React from "react";
import PropTypes from "prop-types";
import { WarningIcon } from "components/Icons";
import { Dialog } from "components/BaseComponents";
import { dataSetConfig } from "config";

const DataRefreshDialog = props => {
  const { isOpen, onClose, dataSets } = props;
  const updatingDataSets = dataSets.map(i => dataSetConfig[i]).join(", ");
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Data Refresh in Progress"
      Icon={WarningIcon}
      color="neutralArray"
      confirmButton="Proceed"
      onConfirm={onClose}
      content={`We're currently updating the following data: ${updatingDataSets}. Some reports may be briefly affected, but you can still run non-dependent reports. Thank you for your patience!`}
    />
  );
};

DataRefreshDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  dataSets: PropTypes.arrayOf(PropTypes.string)
};

DataRefreshDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  dataSets: []
};

export default DataRefreshDialog;
