import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";
import { NavigateStepButtons } from "components/CustomComponents";
import Input from "./Input";
import Products from "./Products";
import Header from "../Header";

const SKUPrioritisation = props => {
  const { options, isLoading, date, isSterling, client } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [channel, setChannel] = useState("ih");
  const [environment, setEnvironment] = useState(null);
  const [environments, setEnvironments] = useState([]);
  const [number, setNumber] = useState(0);
  const [rankType, setRankType] = useState("sku");
  const [consumptionType, setconsumptionType] = useState("all");

  useEffect(() => {
    if (environment) {
      setEnvironments([environment]);
    } else {
      setEnvironments([]);
    }
  }, [environment]);

  const handleNext = () => setActiveStep(activeStep + 1);

  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <Fragment>
      <Paper>
        <Header isSterling={isSterling} />
        <Stack spacing={1} sx={{ mb: 2 }}>
          <Typography variant="h4" color="primary.main">
            SKU Prioritisation
          </Typography>
          <Typography variant="body1" color="grey.500">
            Please select a channel and then one or more environments within it.
          </Typography>
        </Stack>
        {activeStep === 0 && (
          <Input
            channel={channel}
            setChannel={setChannel}
            environment={environment}
            setEnvironment={setEnvironment}
            rankType={rankType}
            setRankType={setRankType}
            consumptionType={consumptionType}
            setConsumptionType={setconsumptionType}
            number={number}
            setNumber={setNumber}
            isLoading={isLoading}
          />
        )}
        {activeStep === 1 && (
          <Products
            channel={channel}
            environments={environments}
            handleBack={handleBack}
            options={options}
            number={number}
            isLoading={isLoading}
            rankType={rankType}
            consumptionType={consumptionType}
            date={date}
            client={client}
          />
        )}
      </Paper>
      {activeStep === 0 && (
        <NavigateStepButtons
          onBackClick={() => navigate(-1)}
          onNextClick={handleNext}
          nextDisabled={!environment || number === 0}
        />
      )}
    </Fragment>
  );
};

SKUPrioritisation.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  date: PropTypes.shape(),
  isSterling: PropTypes.bool,
  client: PropTypes.string
};

SKUPrioritisation.defaultProps = {
  options: [],
  isLoading: false,
  date: {},
  isSterling: false,
  client: ""
};

const mapStateToProps = state => ({
  options: state.sterling.products,
  isLoading: state.sterling.isLoading,
  date: state.data.date,
  client: state.user.user.client
});

export default connect(mapStateToProps, null)(SKUPrioritisation);
