import React from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  CircularProgress,
  Skeleton,
  TextField
} from "@mui/material";
import _ from "lodash";
import { CloseIcon, DownIcon } from "components/Icons";
import { FormControl } from "./Internal";

// any props from mui can also be added - https://mui.com/material-ui/api/autocomplete/
const SelectAutocomplete = props => {
  const {
    id,
    name,
    label,
    options,
    fullWidth,
    styles,
    infoText,
    value,
    error,
    onChange,
    required,
    size,
    isLoading,
    placeholder,
    isUpdating,
    ...otherProps
  } = props;
  return (
    <FormControl
      label={label}
      fullWidth={fullWidth}
      styles={styles}
      required={required}
      infoText={infoText}
      error={error}
    >
      {isLoading ? (
        <Skeleton sx={styles} variant="rounded" width="100%" height={40} />
      ) : (
        <Autocomplete
          loading={isLoading}
          id={id}
          options={options}
          value={value}
          onChange={onChange}
          renderInput={params => (
            <TextField
              name={name}
              placeholder={_.isEmpty(value) ? placeholder : ""}
              {...params}
            />
          )}
          size={size}
          popupIcon={
            isUpdating ? (
              <CircularProgress size={24} sx={{ color: "grey.500", p: 0.25 }} />
            ) : (
              <DownIcon sx={{ color: "grey.300", p: 0.25 }} />
            )
          }
          clearIcon={<CloseIcon fontSize="small" />}
          sx={{ "& .MuiChip-label": { fontWeight: 400 } }}
          {...otherProps}
        />
      )}
    </FormControl>
  );
};

SelectAutocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  fullWidth: PropTypes.bool,
  styles: PropTypes.shape(),
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  infoText: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  error: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string
};

SelectAutocomplete.defaultProps = {
  id: "",
  name: "",
  label: "",
  options: [],
  fullWidth: false,
  styles: {},
  required: false,
  isLoading: false,
  isUpdating: false,
  infoText: "",
  value: undefined,
  error: "",
  onChange: () => {},
  size: "small",
  placeholder: ""
};

export default SelectAutocomplete;
