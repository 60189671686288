import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import ReportTable from "../ReportTable";
import ReportChart from "../ReportChart";
import ReportTypography from "../ReportTypography";

const DoubleChartTable = props => {
  const { charts, table, config } = props;
  return (
    <Box sx={{ height: 1, width: 1, mx: 2 }}>
      <Grid container columnSpacing={2} alignItems="space-between">
        <Grid item xs={6}>
          <ReportTypography textAlign="center" fontSize={14} config={config}>
            {charts[0].title}
          </ReportTypography>
          <Box sx={{ position: "relative", width: 1, height: 250 }}>
            <ReportChart
              data={{
                x: charts[0].x,
                y: charts[0].y,
                type: charts[0].style.chart_style.type
              }}
              display={{ ...charts[0].style, isSmall: true }}
              layout={{ height: 100, width: 100, top: 0, left: 0 }}
              config={config}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <ReportTypography textAlign="center" fontSize={14} config={config}>
            {charts[1].title}
          </ReportTypography>
          <Box sx={{ position: "relative", width: 1, height: 250 }}>
            <ReportChart
              data={{
                x: charts[1].x,
                y: charts[1].y,
                type: charts[1].style.chart_style.type
              }}
              display={{ ...charts[1].style, isSmall: true }}
              layout={{ height: 100, width: 100, top: 0, left: 0 }}
              config={config}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ReportTable
            data={{
              columns: table.columns,
              index: table.index,
              values: table.data
            }}
            display={{
              gradient: table.gradient,
              highlight: table.tp_highlight,
              isSmall: true
            }}
            config={config}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

DoubleChartTable.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.shape()),
  table: PropTypes.shape(),
  config: PropTypes.shape()
};

DoubleChartTable.defaultProps = {
  charts: [],
  table: {},
  config: {}
};

export default DoubleChartTable;
