import React from "react";
import PropTypes from "prop-types";
import { Skeleton, TextField as MUITextField } from "@mui/material";
import { FormControl } from "./Internal";

// any props from mui can also be added - https://mui.com/material-ui/api/text-field/
const TextField = props => {
  const {
    label,
    fullWidth,
    value,
    onChange,
    styles,
    inputStyles,
    error,
    infoText,
    required,
    size,
    isLoading,
    ...otherProps
  } = props;
  return (
    <FormControl
      label={label}
      fullWidth={fullWidth}
      styles={styles}
      required={required}
      infoText={infoText}
      error={error}
    >
      {isLoading ? (
        <Skeleton variant="rounded" height={40} />
      ) : (
        <MUITextField
          value={value}
          onChange={onChange}
          sx={inputStyles}
          size={size}
          {...otherProps}
        />
      )}
    </FormControl>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  styles: PropTypes.shape(),
  fullWidth: PropTypes.bool,
  inputStyles: PropTypes.shape(),
  required: PropTypes.bool,
  error: PropTypes.string,
  infoText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  size: PropTypes.string,
  isLoading: PropTypes.bool
};

TextField.defaultProps = {
  label: "",
  styles: {},
  fullWidth: false,
  inputStyles: {},
  required: false,
  error: "",
  infoText: "",
  value: "",
  onChange: () => {},
  size: "small",
  isLoading: false
};

export default TextField;
