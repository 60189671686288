import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { uniqueId } from "lodash";
import ReportTypography from "../ReportTypography";

const List = props => {
  const { text, config } = props;
  const soundbites = text.find(i => i.key === "soundbites");
  return (
    <Box sx={{ height: 1, width: 1 }}>
      <Grid item xs={12} container spacing={1.5}>
        {soundbites?.text?.map(i => (
          <Grid item xs={6} key={uniqueId()}>
            <Box
              sx={{
                border: 1,
                borderColor: "grey.200",
                borderRadius: 1,
                p: 1.5,
                height: 1
              }}
            >
              <ReportTypography
                fontSize={14}
                config={config}
                textAlign="center"
              >
                {i}
              </ReportTypography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

List.propTypes = {
  text: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape()
};

List.defaultProps = {
  text: [],
  config: {}
};

export default List;
