import defaultState from "./defaultState";
import {
  GET_STREAMLIT_REQUEST,
  GET_STREAMLIT_SUCCESS,
  GET_STREAMLIT_ERROR,
  GET_PROTOTYPES_LIST_ERROR,
  GET_PROTOTYPES_LIST_SUCCESS,
  GET_PROTOTYPES_LIST_REQUEST
} from "../constants/actionConstants";

// eslint-disable-next-line default-param-last
const streamlit = (state = defaultState.streamlit, action) => {
  switch (action.type) {
    case GET_PROTOTYPES_LIST_REQUEST:
      return {
        ...state,
        prototypesLoading: true
      };
    case GET_PROTOTYPES_LIST_SUCCESS:
      return {
        ...state,
        prototypes: action.data,
        prototypesError: "",
        prototypesLoading: false
      };
    case GET_PROTOTYPES_LIST_ERROR:
      return {
        ...state,
        prototypesError: action.error,
        prototypesLoading: false
      };
    case GET_STREAMLIT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_STREAMLIT_SUCCESS:
      return {
        ...state,
        url: action.data.URL,
        error: "",
        isLoading: false
      };
    case GET_STREAMLIT_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    default:
      return state;
  }
};

export default streamlit;
