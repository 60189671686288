import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { RangeReviewIcon } from "components/Icons";
import { ReportHeader } from "components/CustomComponents";

const Highlight = ({ children }) => (
  <Typography variant="h4" component="span" color="primary">
    {children}
  </Typography>
);

Highlight.propTypes = { children: PropTypes.node };

Highlight.defaultProps = { children: null };

const Header = props => {
  const { isLastStep, category, sector, retailer, isEdit } = props;

  const getProductString = () => {
    if (!category) return "All categories";
    if (!sector) return `All sectors within ${category}`;
    return `${category} ${sector}`;
  };

  return (
    <ReportHeader
      icon={<RangeReviewIcon />}
      title="Range Review Impact Assessment"
      description="Conduct a range review to optimise the revenue and profit that you are generating from your space. You will spell out specific objectives for the range reviews."
      isLastStep={isLastStep}
      isEdit={isEdit}
      review={
        <Typography variant="h4">
          Preview: Post Range Review Analysis of{" "}
          <Highlight>{getProductString()}</Highlight> performance in{" "}
          <Highlight>{retailer}</Highlight> in{" "}
          <Highlight>Post Review Period Vs Comparison Period</Highlight>
        </Typography>
      }
    />
  );
};

Header.propTypes = {
  isLastStep: PropTypes.bool,
  category: PropTypes.string,
  sector: PropTypes.string,
  retailer: PropTypes.string,
  isEdit: PropTypes.bool
};

Header.defaultProps = {
  isLastStep: false,
  category: null,
  sector: null,
  retailer: null,
  isEdit: false
};

export default Header;
