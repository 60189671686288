import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Stack } from "@mui/material";
import { InHomeIcon, OutOfHomeIcon } from "components/Icons";
import { SelectAutocomplete, SelectGroup } from "components/BaseComponents";
import { getEnvironments } from "actions";
import { clientConfig } from "config";

const allChannelOptions = [
  { label: "In Home", id: "ih", icon: <InHomeIcon /> },
  { label: "Out Of Home", id: "ooh", icon: <OutOfHomeIcon /> }
];

const ChannelEnvironment = props => {
  const {
    channel,
    setChannel,
    environment,
    setEnvironment,
    listEnvironments,
    retailers,
    client,
    isLoading,
    additionalInput
  } = props;
  const [environmentOptions, setEnvironmentOptions] = useState([]);
  const { channels = [] } = clientConfig[client] || {};
  const channelOptions = allChannelOptions.filter(i => channels.includes(i.id));

  useEffect(() => {
    if (retailers.length === 0) {
      listEnvironments(client);
    }
  }, []);

  useEffect(() => {
    if (retailers.length === 0) return;
    const options = retailers
      .filter(i => i.channel === channel)
      .map(i => i.name);
    setEnvironmentOptions(options);
  }, [channel, retailers]);

  const handleChannel = item => {
    const { id } = item;
    setChannel(id);
    setEnvironment(null);
  };

  return (
    <Stack spacing={3}>
      <SelectGroup
        options={channelOptions}
        onClick={handleChannel}
        isLoading={isLoading}
        controlled
        selected={channel}
        label="Channel"
      />
      {additionalInput}
      <SelectAutocomplete
        label="Environment"
        placeholder="Environment"
        fullWidth
        styles={{ maxWidth: 400 }}
        options={environmentOptions}
        value={environment}
        onChange={(e, value) => setEnvironment(value)}
        isLoading={isLoading}
      />
    </Stack>
  );
};

ChannelEnvironment.propTypes = {
  channel: PropTypes.string,
  setChannel: PropTypes.func,
  environment: PropTypes.string,
  setEnvironment: PropTypes.func,
  listEnvironments: PropTypes.func,
  retailers: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string,
  isLoading: PropTypes.bool,
  additionalInput: PropTypes.node
};

ChannelEnvironment.defaultProps = {
  channel: "",
  setChannel: () => {},
  environment: null,
  setEnvironment: () => {},
  listEnvironments: () => {},
  retailers: [],
  client: "",
  isLoading: false,
  additionalInput: null
};

const mapStateToProps = state => ({
  retailers: state.sterling.retailers,
  client: state.user.user.client,
  isLoading: state.sterling.isLoading
});

const mapDispatchToProps = dispatch => ({
  listEnvironments: client => dispatch(getEnvironments(client))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelEnvironment);
