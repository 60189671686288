import React from "react";
import PropTypes from "prop-types";
import PlotlyChart from "components/PlotlyChart";
import theme from "theme";

const StackedBarChart = props => {
  const { data, tickprefix, ticksuffix, layout } = props;
  const length = Math.max(...data.map(i => i.x.length));
  return (
    <PlotlyChart
      data={data}
      layout={{
        barmode: "stack",
        xaxis: {
          tickangle: 90,
          ticklen: 2,
          tickcolor: "transparent",
          automargin: true,
          tickfont: {
            size: length > 30 ? 8 : 11,
            color: theme.palette.grey[500]
          }
        },
        yaxis: {
          tickprefix,
          ticksuffix,
          tickfont: { size: 11, color: theme.palette.grey[500] }
        },
        showlegend: false,
        hovermode: "x unified",
        hoverlabel: {
          bgcolor: theme.palette.grey[700],
          bordercolor: theme.palette.grey[600],
          font: { color: theme.palette.grey[300], size: 12 }
        },
        height: 750,
        ...layout
      }}
    />
  );
};

StackedBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  layout: PropTypes.shape(),
  tickprefix: PropTypes.string,
  ticksuffix: PropTypes.string
};

StackedBarChart.defaultProps = {
  data: [],
  layout: {},
  tickprefix: "",
  ticksuffix: ""
};

export default StackedBarChart;
