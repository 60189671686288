import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import ReportChart from "../ReportChart";

const SubCharts = props => {
  const { charts, config } = props;
  return (
    <Box sx={{ height: 1, width: 1, mx: 2, position: "relative" }}>
      <ReportChart
        data={{
          type: "subchart",
          data: charts.map(i => ({
            x: i.x,
            y: i.y,
            type: i.style.chart_style.type
          }))
        }}
        display={{
          display: charts.map(i => ({ ...i.style, title: i.title })),
          isLegend: false
        }}
        layout={{ height: 100, width: 100, top: 0, left: 0 }}
        config={config}
      />
    </Box>
  );
};

SubCharts.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape()
};

SubCharts.defaultProps = {
  charts: [],
  config: {}
};

export default SubCharts;
