import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Menu as MUIMenu,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Box,
  Typography
} from "@mui/material";

const getTitle = (title, bold) => {
  if (!bold) return title;
  const [before, after] = title.split("{bold}");
  return (
    <Typography variant="inherit" noWrap>
      {before}
      <Typography
        component="span"
        variant="inherit"
        fontWeight="medium"
        color="common.black"
      >
        {bold}
      </Typography>
      {after}
    </Typography>
  );
};

const Menu = props => {
  const {
    menuItems,
    handleClose,
    anchorEl,
    fullWidth,
    newTab,
    styles,
    disablePopover,
    id,
    ...otherProps
  } = props;
  const open = Boolean(anchorEl);
  const dual = menuItems.length > 10;
  const middle = Math.ceil(menuItems.length / 2);

  const renderMenuItems = () =>
    menuItems.map((i, k) => (
      <MenuItem
        onClick={() => {
          if (i.action) {
            i.action();
          }
          handleClose();
        }}
        key={i.path}
        component={Link}
        to={i.path}
        target={newTab ? "_blank" : "_self"}
        sx={theme => ({
          "&:hover": {
            bgcolor: "grey.100",
            borderTopRightRadius:
              dual && k < middle ? theme.shape.borderRadius : 0,
            borderBottomRightRadius:
              dual && k < middle ? theme.shape.borderRadius : 0,
            borderTopLeftRadius:
              dual && k >= middle ? theme.shape.borderRadius : 0,
            borderBottomLeftRadius:
              dual && k >= middle ? theme.shape.borderRadius : 0
          }
        })}
        disabled={i.disabled}
      >
        <ListItem disablePadding>
          <ListItemText
            sx={{ pr: 2, py: 0.25 }}
            primaryTypographyProps={{ noWrap: true }}
          >
            {getTitle(i.title, i.bold)}
          </ListItemText>
          <ListItemIcon sx={{ mr: -2 }}>{i.icon}</ListItemIcon>
        </ListItem>
      </MenuItem>
    ));

  if (disablePopover) {
    return (
      <Box
        id={id}
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, 0)",
          display: "none",
          "&:hover": { display: "block" },
          ...styles
        }}
      >
        <Paper
          variant="outlined"
          sx={theme => ({
            border: 1,
            borderColor: "grey.200",
            py: 0.5,
            boxShadow:
              "0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)",
            zIndex: theme.zIndex.modal,
            columnCount: dual ? 2 : 1
          })}
        >
          {renderMenuItems()}
        </Paper>
      </Box>
    );
  }
  return (
    <MUIMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{ mt: 0.5, ...styles }}
      MenuListProps={{
        sx: {
          py: 0,
          width: (fullWidth && anchorEl && anchorEl.offsetWidth) || 1
        }
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      {...otherProps}
    >
      {renderMenuItems()}
    </MUIMenu>
  );
};

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape()),
  anchorEl: PropTypes.shape(),
  handleClose: PropTypes.func,
  fullWidth: PropTypes.bool,
  newTab: PropTypes.bool,
  styles: PropTypes.shape(),
  disablePopover: PropTypes.bool,
  id: PropTypes.string
};

Menu.defaultProps = {
  menuItems: [],
  anchorEl: null,
  handleClose: () => {},
  fullWidth: false,
  newTab: false,
  styles: {},
  disablePopover: false,
  id: ""
};

export default Menu;
