import {
  CLEAR_ALL_CONSTRAINTS,
  REPORT_CONSTRAINTS,
  CLEAR_STORY_CONSTRAINTS
} from "constants/actionConstants";
import defaultState from "./defaultState";

// eslint-disable-next-line default-param-last
const reportConstraints = (state = defaultState.reportConstraints, action) => {
  switch (action.type) {
    case REPORT_CONSTRAINTS:
      return {
        ...state,
        [action.story]: {
          ...state[action.story],
          [action.constraintType]: action.constraints
        }
      };
    case CLEAR_STORY_CONSTRAINTS:
      return {
        ...state,
        [action.story]: defaultState.reportConstraints[action.story]
      };
    case CLEAR_ALL_CONSTRAINTS:
      return defaultState.reportConstraints;
    default:
      return state;
  }
};

export default reportConstraints;
