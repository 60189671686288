import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Stack } from "@mui/material";
import { SelectAutocomplete, SelectGroup } from "components/BaseComponents";
import { EditCard } from "components/CustomComponents";
import { filterStaticData } from "actions";
import { AmbientIcon, ChilledIcon, FCIcon, ICIcon } from "components/Icons";

const temperatureOptions = [
  { label: "Ambient", id: "AMBIENT", icon: <AmbientIcon /> },
  { label: "Chilled", id: "CHILLED", icon: <ChilledIcon /> },
  { label: "Ambient & Chilled", id: "all" }
];

const consumptionTypeOptions = [
  {
    label: "FC (Future Consumption)",
    id: "FUTURE CONSUMPTION",
    icon: <FCIcon />
  },
  {
    label: "IC (Immediate Consumption)",
    id: "IMMEDIATE CONSUMPTION",
    icon: <ICIcon />
  },
  { label: "FC & IC", id: "all" }
];

const getScope = (temperature, consumptionType) => {
  if (!temperature && !consumptionType) return [];
  if (!temperature)
    return [`Consumption Type: ${consumptionType.toUpperCase()}`];
  if (!consumptionType) return [`Temperature: ${temperature.toUpperCase()}`];
  return [
    `Temperature: ${temperature.toUpperCase()}`,
    `Consumption Type: ${consumptionType.toUpperCase()}`
  ];
};

const EditProduct = props => {
  const {
    story,
    category,
    sector,
    retailer,
    isFilteringData,
    isStaticData,
    temperature,
    consumptionType,
    categoryOptions,
    sectorOptions,
    retailerOptions,
    dispatchFilterStaticData,
    client,
    hier1,
    hier2,
    setTemperature,
    setConsumptionType,
    setCategory,
    setSector,
    setRetailer,
    setHier1,
    setHier2
  } = props;

  const handleTemperature = item => {
    if (category) setCategory(null);
    if (sector) setSector(null);
    const { id } = item;
    setTemperature(id);
    if (id !== "all") {
      const term = {
        name: id,
        subsection: "temperature",
        table: "what",
        story
      };
      dispatchFilterStaticData(term, [], story, client);
    } else {
      dispatchFilterStaticData(null, [], story, client);
    }
  };

  const handleConsumptionType = item => {
    const { id } = item;
    setConsumptionType(id);
  };

  const handleCategory = value => {
    if (sector) setSector(null);
    if (hier1) setHier1(null);
    if (hier2) setHier2(null);
    setCategory(value);
    const filterTerms =
      temperature && temperature !== "all"
        ? [
            {
              name: temperature,
              subsection: "temperature",
              table: "what",
              story
            }
          ]
        : [];
    if (value) {
      const term = {
        name: value,
        subsection: "category",
        table: "what",
        story
      };
      dispatchFilterStaticData(term, filterTerms, story, client);
    } else {
      dispatchFilterStaticData(null, filterTerms, story, client);
    }
  };

  const handleSector = value => {
    if (hier1) setHier1(null);
    if (hier2) setHier2(null);
    setSector(value);
  };

  const handleRetailer = value => setRetailer(value);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <EditCard
          label="Product Filter"
          selected={getScope(temperature, consumptionType)}
          isWarning
          warningText="If you change the temperature you must also change The Product."
        >
          <Stack spacing={4}>
            <SelectGroup
              options={temperatureOptions}
              onClick={handleTemperature}
              isLoading={isFilteringData}
              controlled
              selected={temperature}
              label="Temperature"
            />
            <SelectGroup
              options={consumptionTypeOptions}
              onClick={handleConsumptionType}
              controlled
              selected={consumptionType}
              label="Consumption Type"
            />
          </Stack>
        </EditCard>
      </Grid>
      <Grid item xs={12} md={12}>
        <EditCard
          label="The Product"
          selected={[
            category ? `Category: ${category}` : "All Categories",
            sector ? `Sector: ${sector}` : "All Sectors Within Category"
          ]}
          isWarning
          warningText="If you change The Product you must also change the Result Hierarchy."
        >
          <Stack spacing={2} sx={{ maxWidth: 400 }}>
            <SelectAutocomplete
              placeholder="All Categories"
              label="Category"
              fullWidth
              options={categoryOptions}
              value={category}
              onChange={(e, value) => handleCategory(value)}
              isUpdating={isFilteringData}
              disabled={!isStaticData || isFilteringData}
            />
            <SelectAutocomplete
              placeholder="All Sectors Within Category"
              label="Sector"
              fullWidth
              options={sectorOptions}
              value={sector}
              onChange={(e, value) => handleSector(value)}
              isUpdating={isFilteringData}
              disabled={!category || !isStaticData || isFilteringData}
            />
          </Stack>
        </EditCard>
      </Grid>
      <Grid item xs={12} md={12}>
        <EditCard label="Analysis Retailer" selected={[retailer]}>
          <SelectAutocomplete
            fullWidth
            styles={{ maxWidth: 400 }}
            options={retailerOptions}
            value={retailer}
            onChange={(e, value) => handleRetailer(value)}
            disabled={!isStaticData}
          />
        </EditCard>
      </Grid>
    </Grid>
  );
};

EditProduct.propTypes = {
  story: PropTypes.string,
  category: PropTypes.string,
  sector: PropTypes.string,
  retailer: PropTypes.string,
  isFilteringData: PropTypes.bool,
  isStaticData: PropTypes.bool,
  temperature: PropTypes.string,
  consumptionType: PropTypes.string,
  categoryOptions: PropTypes.arrayOf(PropTypes.string),
  sectorOptions: PropTypes.arrayOf(PropTypes.string),
  retailerOptions: PropTypes.arrayOf(PropTypes.string),
  dispatchFilterStaticData: PropTypes.func,
  client: PropTypes.string,
  hier1: PropTypes.shape(),
  hier2: PropTypes.shape(),
  setTemperature: PropTypes.func,
  setConsumptionType: PropTypes.func,
  setCategory: PropTypes.func,
  setSector: PropTypes.func,
  setRetailer: PropTypes.func,
  setHier1: PropTypes.func,
  setHier2: PropTypes.func
};

EditProduct.defaultProps = {
  story: "",
  category: null,
  sector: null,
  retailer: null,
  isFilteringData: false,
  isStaticData: false,
  temperature: null,
  consumptionType: null,
  categoryOptions: [],
  sectorOptions: [],
  retailerOptions: [],
  dispatchFilterStaticData: () => {},
  client: "",
  hier1: null,
  hier2: null,
  setTemperature: () => {},
  setConsumptionType: () => {},
  setCategory: () => {},
  setSector: () => {},
  setRetailer: () => {},
  setHier1: () => {},
  setHier2: () => {}
};

const mapDispatchToProps = dispatch => ({
  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client))
});

export default connect(null, mapDispatchToProps)(EditProduct);
