import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import ReportChart from "../ReportChart";

const SingleChart = props => {
  const { chart, config } = props;
  const { isMulti } = chart;
  return (
    <Box sx={{ height: 1, width: 1, mx: 2, position: "relative" }}>
      {isMulti ? (
        <ReportChart
          data={chart.data}
          display={chart.display}
          layout={{ height: 100, width: 100, top: 0, left: 0 }}
          config={config}
        />
      ) : (
        <ReportChart
          data={{ x: chart.x, y: chart.y, type: chart.style.chart_style.type }}
          display={{
            ...chart.style,
            title: chart.title,
            xAxisTitle: chart.x_axis_title,
            yAxisTitle: chart.y_axis_title
          }}
          layout={{ height: 100, width: 100, top: 0, left: 0 }}
          config={config}
        />
      )}
    </Box>
  );
};

SingleChart.propTypes = {
  chart: PropTypes.shape(),
  config: PropTypes.shape()
};

SingleChart.defaultProps = {
  chart: {},
  config: {}
};

export default SingleChart;
