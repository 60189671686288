import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Collapse } from "@mui/material";
import { Paper, SelectAutocomplete } from "components/BaseComponents";
import CustomDate from "components/CustomDate";

const EditMarket = props => {
  const {
    retailer,
    period,
    metric,
    isLoading,
    setRetailer,
    setPeriod,
    setMetric,
    subsections,
    story,
    dataDate
  } = props;
  const [isCustomDate, setIsCustomDate] = useState(false);

  useEffect(() => {
    const isOpen = period?.period === "custom";
    setIsCustomDate(isOpen);
  }, [period]);

  const marketOptions = subsections.find(
    item => item.name === "retailer"
  )?.keywords;

  const periodOptions = [
    ...(subsections.find(item => item.table === "when")?.keywords || []).map(
      i => ({
        date: i.date,
        label: `${i.period} ${i.date}`,
        period: i.period,
        value: i.period
      })
    ),
    { period: "custom", label: "Choose range on calendar" }
  ];

  const metricOptions = subsections.find(
    item => item.table === "measure"
  )?.keywords;

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={12} md={4}>
        <Paper>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Analysis Market
          </Typography>
          <SelectAutocomplete
            fullWidth
            options={marketOptions}
            value={retailer}
            onChange={(e, value) => setRetailer(value)}
            isLoading={isLoading}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Time Frame
          </Typography>
          <SelectAutocomplete
            fullWidth
            options={periodOptions}
            value={period}
            onChange={(e, value) => setPeriod(value)}
            isLoading={isLoading}
            isOptionEqualToValue={(option, value) =>
              option.period === value.period
            }
          />
          <Collapse in={isCustomDate} mountOnEnter unmountOnExit sx={{ mt: 3 }}>
            <CustomDate
              story={story}
              selectedPeriod={
                period && period.period === "custom" && period.name
                  ? period
                  : undefined
              }
              dataDate={dataDate}
              isMonth={story === "cga"}
              onChange={value =>
                setPeriod({
                  ...value,
                  period: "custom",
                  label: "Choose range on calendar"
                })
              }
            />
          </Collapse>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Analysis Metric
          </Typography>
          <SelectAutocomplete
            fullWidth
            options={metricOptions}
            value={metric}
            onChange={(e, value) => setMetric(value)}
            isLoading={isLoading}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

EditMarket.propTypes = {
  isLoading: PropTypes.bool,
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  metric: PropTypes.string,
  setRetailer: PropTypes.func,
  setPeriod: PropTypes.func,
  setMetric: PropTypes.func,
  story: PropTypes.string,
  dataDate: PropTypes.string
};

EditMarket.defaultProps = {
  isLoading: false,
  subsections: [],
  retailer: null,
  period: null,
  metric: null,
  setRetailer: () => {},
  setPeriod: () => {},
  setMetric: () => {},
  story: "",
  dataDate: ""
};

export default EditMarket;
