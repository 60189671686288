import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { BrandBattlesIcon } from "components/Icons";
import { ReportHeader } from "components/CustomComponents";
import { getDateLabel } from "utils";

const Highlight = ({ children }) => (
  <Typography variant="h4" component="span" color="primary">
    {children}
  </Typography>
);

Highlight.propTypes = { children: PropTypes.node };

Highlight.defaultProps = { children: null };

const Header = props => {
  const {
    isLastStep,
    story,
    productA,
    productB,
    context,
    retailer,
    period,
    metric,
    isEdit
  } = props;

  const getSelectedDateLabel = date => {
    if (!date) return "";
    if (date.period !== "custom") return date.label;
    if (date.name) return getDateLabel(date.name, story);
    return "";
  };

  return (
    <ReportHeader
      icon={<BrandBattlesIcon />}
      title="Brand Battles (Product Comparison)"
      description="Run a report to compare the performance of two products within a specific market or retailer. This report will allow you to identify what may have enabled one product to win in the chosen time frame and provide you with key insights into suggested actions."
      isLastStep={isLastStep}
      isEdit={isEdit}
      review={
        <Typography variant="h4">
          Preview: Analysis of <Highlight>{productA?.value}</Highlight> Vs.{" "}
          <Highlight>{productB?.value}</Highlight> within{" "}
          <Highlight>{context.map(i => i.value).join(" ")}</Highlight> in{" "}
          <Highlight>{retailer}</Highlight> for <Highlight>{metric}</Highlight>{" "}
          in <Highlight>{getSelectedDateLabel(period)}</Highlight>
        </Typography>
      }
    />
  );
};

Header.propTypes = {
  isLastStep: PropTypes.bool,
  story: PropTypes.string,
  productA: PropTypes.shape(),
  productB: PropTypes.shape(),
  context: PropTypes.arrayOf(PropTypes.shape()),
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  metric: PropTypes.string,
  isEdit: PropTypes.bool
};

Header.defaultProps = {
  isLastStep: false,
  story: "",
  productA: null,
  productB: null,
  context: [],
  retailer: null,
  period: null,
  metric: null,
  isEdit: false
};

export default Header;
