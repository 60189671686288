import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import {
  AppBar,
  Container,
  IconButton,
  List,
  ListItem,
  Drawer,
  Hidden,
  Box,
  Stack,
  Typography,
  Collapse
} from "@mui/material";
import {
  ABOUT_ROUTE,
  AUTO_RANGING_ROUTE,
  CATEGORY_OPTIMISATION_ROUTE,
  HOME_ROUTE,
  LOGOUT_ROUTE,
  LOOKOUT_BASE_ROUTE,
  LOOKOUT_IH_ROUTE,
  NEW_AUTO_RANGING_INITIAL_ROUTE,
  NEW_FOCUS_REPORT_ROUTE,
  NEW_IH_BRAND_BATTLES_REPORT_ROUTE,
  NEW_IH_PERFORMANCE_REPORT_ROUTE,
  NEW_IH_RANGE_REVIEW_REPORT_ROUTE,
  NEW_OOH_PERFORMANCE_REPORT_ROUTE,
  NEW_REPORT_ROUTE,
  PREVIOUS_REPORTS_PERFORMANCE_ROUTE,
  PREVIOUS_REPORTS_ROUTE,
  REPORTS_REVIEWS_ROUTE,
  SETTINGS_ROUTE,
  STERLING_INITIAL_ROUTE,
  STERLING_ROUTE,
  SUPPORT_ROUTE,
  TOOL_ROUTE,
  TOOL_SINGLE_ROUTE
} from "constants/viewRoutes";
import { PROTOTYPE_ICONS } from "constants/reportConstants";
import theme, { REPORT_COLORS } from "theme";
import { permissionEnums, storyEnums } from "constants/enums";
import { clientConfig } from "config";
import {
  signOut,
  clearStaticDataCache,
  clearAllSearchData,
  clearLookoutData,
  clearSterlingData,
  clearAllConstraints,
  clearAutoRangingData,
  getPrototypesList
} from "../actions";
import {
  SettingsIcon,
  LogoutIcon,
  AccountIcon,
  AutoRangingIcon,
  AddIcon,
  ArrowRightIcon,
  BrandBattlesIcon,
  PerformanceIcon,
  RangeReviewIcon,
  DownIcon,
  SterlingIcon,
  UpIcon,
  MenuIcon,
  ToolsIcon,
  FocusIcon
} from "./Icons";
import { logo } from "../images";
import NavLink from "./NavLink";
import { Menu } from "./BaseComponents";

const MenuBar = props => {
  const {
    onLogout,
    client,
    isDataRefresh,
    updatingStories,
    dispatchGetPrototypesList,
    prototypes
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [subMenuId, setSubMenuId] = useState("");
  const { permissions, stories } = clientConfig[client] || {
    permissions: [],
    stories: []
  };
  const roles =
    (localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).roles) ||
    [];
  const isAutoRanging = roles && roles.includes("auto_ranging");

  useEffect(() => {
    if (permissions.includes(permissionEnums.STREAMLIT)) {
      dispatchGetPrototypesList();
    }
  }, []);

  const openUserMenu = e => setAnchorEl(e.currentTarget);

  const closeUserMenu = () => setAnchorEl(null);

  const openSubMenu = id => {
    const subMenu = document.getElementById(id);
    if (subMenu) {
      subMenu.style.display = "block";
    }
  };

  const closeSubMenu = id => {
    const subMenu = document.getElementById(id);
    if (subMenu) {
      subMenu.style.display = "none";
    }
  };

  const categoryOptimisationItems = [
    {
      title: "Auto-Ranging",
      path: NEW_AUTO_RANGING_INITIAL_ROUTE,
      icon: (
        <AutoRangingIcon
          sx={{ color: REPORT_COLORS.autoRanging, fontSize: 18 }}
        />
      ),
      isVisible:
        permissions.includes(permissionEnums.AUTO_RANGING) && isAutoRanging
    },
    {
      title: "Sterling Portfolio Prioritisation",
      path: STERLING_INITIAL_ROUTE,
      icon: (
        <SterlingIcon sx={{ color: REPORT_COLORS.sterling, fontSize: 18 }} />
      ),
      isVisible: permissions.includes(permissionEnums.STERLING)
    }
  ].filter(i => i.isVisible);

  const prototypeItems = prototypes
    ?.map(i => {
      const Icon = PROTOTYPE_ICONS[i.service_name] || ToolsIcon;
      return {
        title: i.display_name,
        path: generatePath(TOOL_SINGLE_ROUTE, { type: i.service_name }),
        icon: <Icon sx={{ color: "primary.main", fontSize: 18 }} />,
        isVisible: permissions.includes(permissionEnums.STREAMLIT)
      };
    })
    .filter(i => i.isVisible);

  const reportsItems = [
    {
      title: "New Report",
      path: NEW_REPORT_ROUTE,
      icon: <AddIcon sx={{ color: "grey.500", fontSize: 18 }} />,
      isVisible: true
    },
    {
      title: "Previous Reports",
      path: PREVIOUS_REPORTS_PERFORMANCE_ROUTE,
      icon: <ArrowRightIcon sx={{ color: "grey.500", fontSize: 18 }} />,
      isVisible: true
    },
    {
      title: "In-Home Performance Report",
      path: NEW_IH_PERFORMANCE_REPORT_ROUTE,
      icon: (
        <PerformanceIcon
          sx={{
            color:
              isDataRefresh && updatingStories.includes(storyEnums.IDA)
                ? "grey.300"
                : REPORT_COLORS.performance,
            fontSize: 18
          }}
        />
      ),
      isVisible: stories.includes(storyEnums.IDA),
      disabled: isDataRefresh && updatingStories.includes(storyEnums.IDA)
    },
    {
      title: "Out-of-Home Performance Report",
      path: NEW_OOH_PERFORMANCE_REPORT_ROUTE,
      icon: (
        <PerformanceIcon
          sx={{
            color:
              isDataRefresh && updatingStories.includes(storyEnums.CGA)
                ? "grey.300"
                : REPORT_COLORS.performance,
            fontSize: 18
          }}
        />
      ),
      isVisible: stories.includes(storyEnums.CGA),
      disabled: isDataRefresh && updatingStories.includes(storyEnums.CGA)
    },
    {
      title: "Focused Reports",
      path: NEW_FOCUS_REPORT_ROUTE,
      icon: (
        <FocusIcon
          sx={{
            color: isDataRefresh ? "grey.300" : REPORT_COLORS.focus,
            fontSize: 18
          }}
        />
      ),
      isVisible:
        stories.includes(storyEnums.FP) &&
        (stories.includes(storyEnums.IDA) || stories.includes(storyEnums.CGA)),
      disabled:
        isDataRefresh &&
        (updatingStories.includes(storyEnums.CGA) ||
          updatingStories.includes(storyEnums.IDA))
    },
    {
      title: "Brands Battles",
      path: NEW_IH_BRAND_BATTLES_REPORT_ROUTE,
      icon: (
        <BrandBattlesIcon
          sx={{
            color:
              isDataRefresh && updatingStories.includes(storyEnums.BB)
                ? "grey.300"
                : REPORT_COLORS.brandBattles,
            fontSize: 18
          }}
        />
      ),
      isVisible: stories.includes(storyEnums.BB),
      disabled: isDataRefresh && updatingStories.includes(storyEnums.BB)
    },
    {
      title: "Range Review",
      path: NEW_IH_RANGE_REVIEW_REPORT_ROUTE,
      icon: (
        <RangeReviewIcon
          sx={{
            color:
              isDataRefresh && updatingStories.includes(storyEnums.PRR)
                ? "grey.300"
                : REPORT_COLORS.rangeReview,
            fontSize: 18
          }}
        />
      ),
      isVisible: stories.includes(storyEnums.PRR),
      disabled: isDataRefresh && updatingStories.includes(storyEnums.PRR)
    }
  ].filter(i => i.isVisible);

  const menuItems = [
    { title: "Home", path: HOME_ROUTE, isVisible: true },
    {
      title: "Reports",
      path: NEW_REPORT_ROUTE,
      isSubMenu: true,
      subMenu: { items: reportsItems },
      isActive: pathname =>
        pathname.includes(NEW_REPORT_ROUTE) ||
        pathname.includes(PREVIOUS_REPORTS_ROUTE) ||
        pathname.includes(REPORTS_REVIEWS_ROUTE),
      isVisible: true
    },
    {
      title: "Lookout",
      path: LOOKOUT_IH_ROUTE,
      isActive: pathname => pathname.includes(LOOKOUT_BASE_ROUTE),
      isVisible: permissions.includes(permissionEnums.LOOKOUT)
    },
    {
      title: "Category Optimisation",
      path: CATEGORY_OPTIMISATION_ROUTE,
      isSubMenu: true,
      subMenu: { items: categoryOptimisationItems },
      isActive: pathname =>
        pathname.includes(CATEGORY_OPTIMISATION_ROUTE) ||
        pathname.startsWith(AUTO_RANGING_ROUTE) ||
        pathname.includes(STERLING_ROUTE),
      isVisible:
        permissions.includes(permissionEnums.STERLING) ||
        (permissions.includes(permissionEnums.AUTO_RANGING) && isAutoRanging)
    },
    {
      title: "Tools (Beta)",
      path: "#",
      isSubMenu: true,
      subMenu: { items: prototypeItems },
      isActive: pathname => pathname.includes(TOOL_ROUTE),
      isVisible:
        permissions.includes(permissionEnums.STREAMLIT) &&
        prototypeItems.length > 0
    },
    { title: "About", path: ABOUT_ROUTE, isVisible: true },
    { title: "Support", path: SUPPORT_ROUTE, isVisible: true }
  ].filter(i => i.isVisible);

  const subMenuItems = [
    {
      title: "Settings",
      path: SETTINGS_ROUTE,
      isVisible: true,
      icon: <SettingsIcon sx={{ fontSize: 18, color: "primary.main" }} />
    },
    {
      title: "Logout",
      path: LOGOUT_ROUTE,
      isVisible: true,
      icon: <LogoutIcon sx={{ fontSize: 18, color: "primary.main" }} />,
      action: () => onLogout()
    }
  ].filter(i => i.isVisible);

  const renderMenuItems = items => (
    <Stack direction="row" spacing={3} alignItems="center">
      {items.map(item => {
        const {
          title,
          path,
          isSubMenu,
          subMenu,
          isActive = pathname => pathname === path
        } = item;
        const id = `${title}-sub-menu-box`;
        return (
          <Box
            key={title}
            sx={{ position: "relative" }}
            onMouseOver={() => isSubMenu && openSubMenu(id)}
            onMouseLeave={() => isSubMenu && closeSubMenu(id)}
          >
            <NavLink
              to={path}
              isActive={isActive}
              style={{ color: theme.palette.grey[300], fontSize: 14 }}
              activeStyle={{ color: "white", fontWeight: 700 }}
            >
              <Typography
                variant="inherit"
                sx={{
                  "&::before": {
                    display: "block",
                    content: `' + ${title} + '`,
                    fontWeight: "bold",
                    height: 0,
                    overflow: "hidden",
                    visibility: "hidden"
                  }
                }}
              >
                {title}
                {isSubMenu && (
                  <DownIcon
                    sx={{ verticalAlign: "middle", fontSize: 13, ml: 0.5 }}
                  />
                )}
              </Typography>
            </NavLink>
            {isSubMenu && (
              <Menu
                menuItems={subMenu.items}
                styles={{ pt: 3.5 }}
                disablePopover
                id={id}
                handleClose={() => closeSubMenu(id)}
              />
            )}
          </Box>
        );
      })}
    </Stack>
  );

  const renderMobileMenu = items => (
    <Drawer
      anchor="left"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <List sx={{ mt: 8 }}>
        {items.map(i => {
          const isOpen = subMenuId === i.title;
          return (
            <Fragment key={i.title}>
              <ListItem
                component={Link}
                to={i.path}
                onClick={() => {
                  if (i.action) {
                    i.action();
                  }
                  if (!i.isSubMenu) {
                    setIsDrawerOpen(false);
                  }
                }}
                sx={{ color: "primary.main" }}
              >
                {i.title}
                {i.isSubMenu &&
                  (isOpen ? (
                    <UpIcon
                      sx={{ fontSize: 16, ml: "auto" }}
                      onClick={() => setSubMenuId("")}
                    />
                  ) : (
                    <DownIcon
                      sx={{ fontSize: 16, ml: "auto" }}
                      onClick={() => setSubMenuId(i.title)}
                    />
                  ))}
              </ListItem>
              {i.isSubMenu && (
                <Collapse in={isOpen}>
                  <List disablePadding sx={{ ml: 2 }}>
                    {i.subMenu.items?.map(item => (
                      <ListItem
                        key={item.title}
                        component={Link}
                        to={item.path}
                        onClick={() => {
                          if (i.action) {
                            i.action();
                          }
                          setSubMenuId("");
                          setIsDrawerOpen(false);
                        }}
                        sx={{ color: "primary.main" }}
                      >
                        {item.title}
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </List>
    </Drawer>
  );

  return (
    <AppBar position="sticky" sx={{ height: 65, mb: 3 }}>
      <Container
        className="menu-container"
        sx={{ height: 1, display: "flex", alignItems: "center" }}
      >
        {/* mobile menu */}
        <Hidden mdUp>
          <IconButton
            edge="start"
            onClick={() => setIsDrawerOpen(true)}
            size="large"
          >
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
          {renderMobileMenu([...menuItems, ...subMenuItems])}
        </Hidden>
        <Link
          to={HOME_ROUTE}
          style={{ display: "flex", marginRight: "1.5rem" }}
        >
          <img src={logo} style={{ width: 114 }} alt="dijuno logo" />
        </Link>
        {/* main menu */}
        <Hidden mdDown>
          {renderMenuItems(menuItems)}
          <IconButton
            sx={{ ml: "auto", p: 0 }}
            size="large"
            onClick={openUserMenu}
          >
            <AccountIcon sx={{ color: "white", fontSize: 18 }} />
          </IconButton>
          <Menu
            menuItems={subMenuItems}
            handleClose={closeUserMenu}
            anchorEl={anchorEl}
            styles={{ mt: 3.5 }}
          />
        </Hidden>
      </Container>
    </AppBar>
  );
};

MenuBar.propTypes = {
  onLogout: PropTypes.func,
  client: PropTypes.string,
  isDataRefresh: PropTypes.bool,
  updatingStories: PropTypes.arrayOf(PropTypes.string),
  prototypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  dispatchGetPrototypesList: PropTypes.func
};

MenuBar.defaultProps = {
  onLogout: () => {},
  client: "",
  isDataRefresh: false,
  updatingStories: [],
  prototypes: [],
  dispatchGetPrototypesList: () => {}
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(signOut());
    // force data refresh on logout
    dispatch(clearAllConstraints());
    dispatch(clearStaticDataCache());
    dispatch(clearAllSearchData());
    dispatch(clearLookoutData());
    dispatch(clearSterlingData());
    dispatch(clearAutoRangingData());
  },
  dispatchGetPrototypesList: () => dispatch(getPrototypesList())
});

export default connect(null, mapDispatchToProps)(MenuBar);
