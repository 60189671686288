import React from "react";
import PropTypes from "prop-types";
import PlotlyChart from "components/PlotlyChart";
import { formatNumber } from "utils";

const ClusteredBar = props => {
  const { data, display } = props;
  const isValence = display.apply?.includes("valence");
  const numberFormat = display.labels_style.number_format;
  return (
    <PlotlyChart
      data={[
        {
          x: data.y,
          y: data.x,
          type: "bar",
          orientation: "h",
          text: data.y.map(i => formatNumber(i, numberFormat)),
          textposition: "outside",
          textfont: {
            color:
              isValence && data.y.map(i => (i > 0 ? "#01B050" : "#FF0000")),
            size: 14
          },
          insidetextfont: { color: "#FFFFFF" },
          cliponaxis: false,
          marker: {
            color: isValence && data.y.map(i => (i > 0 ? "#01B050" : "#FF0000"))
          }
        }
      ]}
      layout={{
        yaxis: {
          type: "category",
          tickfont: { size: 12 },
          ticklen: 2,
          tickcolor: "transparent",
          showticklabels: true,
          automargin: true,
          zeroline: true,
          fixedrange: true
        },
        xaxis: {
          showgrid: false,
          showticklabels: false,
          automargin: true,
          title: { text: display.xAxisTitle, font: { size: 12 } },
          fixedrange: true
        },
        margin: display.isSmall
          ? { t: 20, b: 40, r: 0, l: 0 }
          : { t: 40, b: 40, r: 20, l: 20 }
      }}
    />
  );
};

ClusteredBar.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape()
};

ClusteredBar.defaultProps = {
  data: {},
  display: {}
};

export default ClusteredBar;
