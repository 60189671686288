import React from "react";
import PropTypes from "prop-types";
import { useNavigate, generatePath } from "react-router-dom";
import { Stack, Typography, Button } from "@mui/material";
import { ArrowRightIcon } from "components/Icons";
import { lookoutLogo } from "images";
import UpdateDate from "components/Lookout/UpdateDate";
import { LOOKOUT_PREFERENCES_ROUTE } from "constants/viewRoutes";

const NoInsights = props => {
  const { dataDate, isPreference, channel, client } = props;
  const navigate = useNavigate();
  if (!isPreference)
    return (
      <Stack alignItems="center" sx={{ mx: "auto", pt: 2 }}>
        <img alt="lookout" src={lookoutLogo} style={{ width: 113 }} />
        <Typography variant="subtitle1" color="grey.500" sx={{ mt: 1, mb: 3 }}>
          What you need to know without even asking a question!
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          endIcon={<ArrowRightIcon />}
          sx={{ width: 250 }}
          onClick={() =>
            navigate(generatePath(LOOKOUT_PREFERENCES_ROUTE, { channel }))
          }
        >
          Get Started
        </Button>
      </Stack>
    );
  return (
    <Stack alignItems="center" sx={{ mx: "auto", pt: 2 }}>
      <img alt="lookout" src={lookoutLogo} style={{ width: 76 }} />
      <Typography variant="h3" fontWeight="normal" sx={{ my: 1 }}>
        You&apos;re all set
      </Typography>
      <Typography variant="subtitle1" color="grey.500" sx={{ mt: 1, mb: 3 }}>
        Preferences are set, please wait for the next data update.
      </Typography>
      <UpdateDate dataDate={dataDate} client={client} />
    </Stack>
  );
};

NoInsights.propTypes = {
  isPreference: PropTypes.bool,
  dataDate: PropTypes.shape(),
  channel: PropTypes.string,
  client: PropTypes.string
};

NoInsights.defaultProps = {
  isPreference: false,
  dataDate: {},
  channel: "",
  client: ""
};

export default NoInsights;
