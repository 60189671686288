import React from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import {
  Stack,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Box
} from "@mui/material";
import { AddIcon } from "components/Icons";
import { Paper } from "components/BaseComponents";

const ReportCard = props => {
  const { report } = props;
  const {
    title,
    subtitle,
    icon,
    buttons,
    descriptions = [],
    description
  } = report;
  return (
    <Paper styles={{ p: 3, height: 1 }}>
      <Stack alignItems="flex-end" sx={{ height: 1 }}>
        <Stack alignItems="center" spacing={2.5} sx={{ mb: 2.5, width: 1 }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 40,
              height: 40,
              border: 1,
              borderColor: "grey.200",
              borderRadius: 1,
              bgcolor: "grey.50"
            }}
          >
            {icon}
          </Stack>
          <Typography variant="h4" align="center">
            {title}
          </Typography>
          {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
          {description && (
            <Typography variant="subtitle1" color="grey.500" textAlign="center">
              {description}
            </Typography>
          )}
          {descriptions.length > 0 && (
            <List disablePadding sx={{ pl: 2, listStyleType: "disc" }}>
              {descriptions.map(i => (
                <ListItem
                  disablePadding
                  sx={{ display: "list-item", color: "grey.500" }}
                  key={i}
                >
                  <ListItemText
                    primary={i}
                    primaryTypographyProps={{ variant: "body1" }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          sx={{ alignSelf: "stretch", mt: "auto" }}
        >
          {buttons.map(
            ({
              label = "",
              onClick = () => {},
              style = {},
              disabled = false,
              disabledTooltip = "",
              ...otherProps
            }) =>
              disabled && disabledTooltip ? (
                <Tooltip key={uniqueId()} title={disabledTooltip}>
                  <Box sx={{ flex: 1 }}>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ lineHeight: 1.25, ...style }}
                      endIcon={<AddIcon />}
                      disabled={disabled}
                      fullWidth
                      {...otherProps}
                    >
                      {label}
                    </Button>
                  </Box>
                </Tooltip>
              ) : (
                <Button
                  key={uniqueId()}
                  size="large"
                  variant="contained"
                  sx={{ flex: 1, lineHeight: 1.25, ...style }}
                  endIcon={<AddIcon />}
                  onClick={onClick}
                  disabled={disabled}
                  {...otherProps}
                >
                  {label}
                </Button>
              )
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

ReportCard.propTypes = {
  report: PropTypes.shape()
};

ReportCard.defaultProps = {
  report: {}
};

export default ReportCard;
