import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Chip, Stack } from "@mui/material";
import { EditIcon } from "components/Icons";
import { DataGrid, Paper } from "components/BaseComponents";
import { WarningDialog } from "components/CustomComponents";
import ErrorBoundary from "components/ErrorBoundary";
import { getProducts } from "actions";
import { getChannelConfig } from "config";
import { NoRowsOverlay, Toolbar, getColumns } from "./Components";
import AddSKU from "./AddSKU";

const getValue = (value, max) => {
  if (value > max) return max;
  if (value < 0) return 0;
  return value;
};

const Details = props => {
  const {
    channel,
    environments,
    handleBack,
    getOptions,
    options,
    rows,
    setRows,
    isLoading
  } = props;
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const { name: channelName } = getChannelConfig(channel);
  const isEmpty = rows.length === 0;

  useEffect(() => {
    getOptions(channel, environments);
  }, []);

  return (
    <Fragment>
      <Paper styles={{ p: 1.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ columnGap: 1.5, rowGap: 1 }}
          >
            <Chip label={channelName} />
            {environments.map(item => (
              <Chip key={item} label={item} />
            ))}
          </Stack>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleBack}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Stack>
      </Paper>
      <ErrorBoundary>
        <DataGrid
          rows={rows}
          columns={getColumns(setIsDeleteOpen, setSelected)}
          isLoading={isLoading}
          styles={{
            mt: 2,
            "--DataGrid-overlayHeight": "380px",
            "& .MuiDataGrid-cell--textRight": { justifyContent: "left" }
          }}
          columnHeaderHeight={60}
          hideFooter
          slots={{
            noRowsOverlay: NoRowsOverlay,
            toolbar: isEmpty ? null : Toolbar
          }}
          slotProps={{
            noRowsOverlay: { handleClick: () => setIsEditOpen(true) },
            toolbar: { handleClick: () => setIsEditOpen(true) }
          }}
          localeText={{ toolbarFilters: "Add Filter" }}
          processRowUpdate={(newVal, oldVal) => {
            const { importance, sustainability } = newVal || {};
            const modifiedRow = {
              ...newVal,
              importance: getValue(importance, 3),
              sustainability: getValue(sustainability, 100)
            };
            setRows(prev =>
              prev.map(row => (row.id === oldVal.id ? modifiedRow : row))
            );
            return modifiedRow;
          }}
        />
      </ErrorBoundary>
      <WarningDialog
        isOpen={isDeleteOpen}
        title="Removing SKU"
        content="Please confirm you would like to remove the sku. Removing it will erase all inputs relating to this sku for the selected environments."
        onClose={() => setIsDeleteOpen(false)}
        onConfirm={() => {
          setRows(selected ? rows.filter(i => i.sku !== selected.row.sku) : []);
          setSelected(null);
          setIsDeleteOpen(false);
        }}
        confirmButton="Remove"
      />
      <AddSKU
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        options={options}
        rows={rows}
        setRows={setRows}
        isLoading={isLoading}
      />
    </Fragment>
  );
};

Details.propTypes = {
  channel: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.string),
  handleBack: PropTypes.func,
  getOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  setRows: PropTypes.func,
  isLoading: PropTypes.bool
};

Details.defaultProps = {
  channel: "",
  environments: [],
  handleBack: () => {},
  getOptions: () => {},
  options: [],
  rows: [],
  setRows: () => {},
  isLoading: false
};

const mapDispatchToProps = dispatch => ({
  getOptions: (channel, environments) =>
    dispatch(getProducts(channel, environments, "edit"))
});

export default connect(null, mapDispatchToProps)(Details);
