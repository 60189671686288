import defaultState from "./defaultState";
import {
  LOAD_REPORT_REQUEST,
  LOAD_REPORT_SUCCESS,
  LOAD_REPORT_ERROR,
  GET_REPORT_ID,
  GET_MY_RECENT_REPORTS_REQUEST,
  GET_MY_RECENT_REPORTS_SUCCESS,
  GET_MY_RECENT_REPORTS_ERROR,
  GET_MY_FAVOURITE_REPORTS_REQUEST,
  GET_MY_FAVOURITE_REPORTS_SUCCESS,
  GET_MY_FAVOURITE_REPORTS_ERROR,
  ADD_TO_FAVOURITES_REQUEST,
  ADD_TO_FAVOURITES_SUCCESS,
  ADD_TO_FAVOURITES_ERROR,
  // REMOVE_FROM_FAVOURITES_REQUEST,
  REMOVE_FROM_FAVOURITES_SUCCESS,
  GET_AUTO_RANGING_RECENT_REPORTS_REQUEST,
  GET_AUTO_RANGING_RECENT_REPORTS_SUCCESS,
  GET_AUTO_RANGING_RECENT_REPORTS_ERROR,
  GET_AUTO_RANGING_FAVORITE_REPORTS_REQUEST,
  GET_AUTO_RANGING_FAVORITE_REPORTS_SUCCESS,
  GET_AUTO_RANGING_FAVORITE_REPORTS_ERROR,
  AUTO_RANGING_CHANGE_FAVORITE_STATUS_REQUEST,
  AUTO_RANGING_CHANGE_FAVORITE_STATUS_SUCCESS,
  AUTO_RANGING_CHANGE_FAVORITE_STATUS_ERROR,
  // REMOVE_FROM_FAVOURITES_ERROR,
  FLUSH_CACHE_REQUEST,
  FLUSH_CACHE_SUCCESS,
  FLUSH_CACHE_ERROR
} from "../constants/actionConstants";

// eslint-disable-next-line default-param-last
const report = (state = defaultState.report, action) => {
  switch (action.type) {
    case LOAD_REPORT_REQUEST:
      return {
        ...state,
        reportQuery: action.report,
        isLoading: true
      };
    case LOAD_REPORT_SUCCESS:
      return {
        ...state,
        error: "",
        reportContent: action.report.data,
        reportTitle: action.report.title,
        reportClient: action.report.client,
        reportId: action.report.id,
        reportStory: action.report.story,
        reportNavigation: action.report.navigation,
        isLoading: false
      };
    case LOAD_REPORT_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case GET_REPORT_ID:
      return {
        ...state,
        reportId: action.id
      };
    case GET_MY_RECENT_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingMyReports: true,
        myRecentReportsError: ""
      };
    case GET_MY_RECENT_REPORTS_SUCCESS:
      return {
        ...state,
        myRecentReports: action.myRecentReports,
        isLoadingMyReports: false,
        myRecentReportsError: ""
      };
    case GET_MY_RECENT_REPORTS_ERROR:
      return {
        ...state,
        isLoadingMyReports: false,
        myRecentReportsError: action.error
      };
    case GET_MY_FAVOURITE_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingMyFavourites: true,
        myFavouriteReportsError: ""
      };
    case GET_MY_FAVOURITE_REPORTS_SUCCESS:
      return {
        ...state,
        myFavouriteReports: action.myFavouriteReports,
        isLoadingMyFavourites: false,
        myFavouriteReportsError: ""
      };
    case GET_MY_FAVOURITE_REPORTS_ERROR:
      return {
        ...state,
        isLoadingMyFavourites: false,
        myFavouriteReportsError: action.error
      };
    case ADD_TO_FAVOURITES_REQUEST:
      return {
        ...state,
        isLoadingAddToFavourite: true
      };
    case ADD_TO_FAVOURITES_SUCCESS:
      return {
        ...state,
        myFavouriteReports: [...state.myFavouriteReports, action.report],
        isLoadingAddToFavourite: false
      };
    case ADD_TO_FAVOURITES_ERROR:
      return {
        ...state,
        isLoadingAddToFavourite: false
      };
    case REMOVE_FROM_FAVOURITES_SUCCESS:
      return {
        ...state,
        myFavouriteReports: state.myFavouriteReports.filter(
          i => i.query !== action.query
        )
      };
    case GET_AUTO_RANGING_RECENT_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingMyARReports: true,
        myRecentARReportsError: ""
      };
    case GET_AUTO_RANGING_RECENT_REPORTS_SUCCESS:
      return {
        ...state,
        myRecentARReports: action.reports,
        isLoadingMyARReports: false,
        myRecentARReportsError: ""
      };
    case GET_AUTO_RANGING_RECENT_REPORTS_ERROR:
      return {
        ...state,
        isLoadingMyARReports: false,
        myRecentARReportsError: action.error
      };
    case GET_AUTO_RANGING_FAVORITE_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingMyARFavourites: true,
        myFavouriteARReportsError: ""
      };
    case GET_AUTO_RANGING_FAVORITE_REPORTS_SUCCESS:
      return {
        ...state,
        myFavouriteARReports: action.reports,
        isLoadingMyARFavourites: false,
        myFavouriteARReportsError: ""
      };
    case GET_AUTO_RANGING_FAVORITE_REPORTS_ERROR:
      return {
        ...state,
        isLoadingMyARFavourites: false,
        myFavouriteARReportsError: action.error
      };
    case AUTO_RANGING_CHANGE_FAVORITE_STATUS_REQUEST:
      return {
        ...state,
        isLoadingChangeARFavourite: true,
        changeARFavouriteError: ""
      };
    case AUTO_RANGING_CHANGE_FAVORITE_STATUS_SUCCESS: {
      let modifiedReports = state.myFavouriteARReports;
      if (action.operation === "add") {
        modifiedReports = [
          ...modifiedReports,
          { constraints_hash: action.constraintsHash }
        ];
      }
      if (action.operation === "remove") {
        modifiedReports = modifiedReports.filter(
          i => i.constraints_hash !== action.constraintsHash
        );
      }
      return {
        ...state,
        myFavouriteARReports: modifiedReports,
        isLoadingChangeARFavourite: false,
        changeARFavouriteError: ""
      };
    }
    case AUTO_RANGING_CHANGE_FAVORITE_STATUS_ERROR:
      return {
        ...state,
        isLoadingChangeARFavourite: false,
        changeARFavouriteError: action.error
      };
    case FLUSH_CACHE_REQUEST:
      return {
        ...state,
        isLoadingCache: true,
        cacheError: ""
      };
    case FLUSH_CACHE_SUCCESS:
      return {
        ...state,
        isLoadingCache: false,
        cacheError: ""
      };
    case FLUSH_CACHE_ERROR:
      return {
        ...state,
        isLoadingCache: false,
        cacheError: action.error
      };
    default:
      return state;
  }
};

export default report;
