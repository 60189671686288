// presentation
export const GOTO_SLIDE = "GOTO_SLIDE";
export const DOWNLOAD_REPORT_REQUEST = "DOWNLOAD_REPORT_REQUEST";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";
export const DOWNLOAD_REPORT_ERROR = "DOWNLOAD_REPORT_ERROR";

// report constraints
export const CLEAR_ALL_CONSTRAINTS = "CLEAR_ALL_CONSTRAINTS";
export const REPORT_CONSTRAINTS = "REPORT_CONSTRAINTS";
export const CLEAR_STORY_CONSTRAINTS = "CLEAR_STORY_CONSTRAINTS";

// report
export const LOAD_REPORT_REQUEST = "LOAD_REPORT_REQUEST";
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";
export const LOAD_REPORT_ERROR = "LOAD_REPORT_ERROR";
export const GET_REPORT_ID = "GET_REPORT_ID";
export const ADD_USER_TRACKING_REQUEST = "ADD_USER_TRACKING_REQUEST";
export const ADD_USER_TRACKING_SUCCESS = "ADD_USER_TRACKING_SUCCESS";
export const ADD_USER_TRACKING_ERROR = "ADD_USER_TRACKING_ERROR";
export const GET_MY_RECENT_REPORTS_REQUEST = "GET_MY_RECENT_REPORTS_REQUEST";
export const GET_MY_RECENT_REPORTS_SUCCESS = "GET_MY_RECENT_REPORTS_SUCCESS";
export const GET_MY_RECENT_REPORTS_ERROR = "GET_MY_RECENT_REPORTS_ERROR";
export const GET_AUTO_RANGING_RECENT_REPORTS_REQUEST =
  "GET_AUTO_RANGING_RECENT_REPORTS_REQUEST";
export const GET_AUTO_RANGING_RECENT_REPORTS_SUCCESS =
  "GET_AUTO_RANGING_RECENT_REPORTS_SUCCESS";
export const GET_AUTO_RANGING_RECENT_REPORTS_ERROR =
  "GET_AUTO_RANGING_RECENT_REPORTS_ERROR";
export const GET_AUTO_RANGING_FAVORITE_REPORTS_REQUEST =
  "GET_AUTO_RANGING_FAVORITE_REPORTS_REQUEST";
export const GET_AUTO_RANGING_FAVORITE_REPORTS_SUCCESS =
  "GET_AUTO_RANGING_FAVORITE_REPORTS_SUCCESS";
export const GET_AUTO_RANGING_FAVORITE_REPORTS_ERROR =
  "GET_AUTO_RANGING_FAVORITE_REPORTS_ERROR";
export const AUTO_RANGING_CHANGE_FAVORITE_STATUS_REQUEST =
  "AUTO_RANGING_CHANGE_FAVORITE_STATUS_REQUEST";
export const AUTO_RANGING_CHANGE_FAVORITE_STATUS_SUCCESS =
  "AUTO_RANGING_CHANGE_FAVORITE_STATUS_SUCCESS";
export const AUTO_RANGING_CHANGE_FAVORITE_STATUS_ERROR =
  "AUTO_RANGING_CHANGE_FAVORITE_STATUS_ERROR";
export const GET_MY_FAVOURITE_REPORTS_REQUEST =
  "GET_MY_FAVOURITE_REPORTS_REQUEST";
export const GET_MY_FAVOURITE_REPORTS_SUCCESS =
  "GET_MY_FAVOURITE_REPORTS_SUCCESS";
export const GET_MY_FAVOURITE_REPORTS_ERROR = "GET_MY_FAVOURITE_REPORTS_ERROR";
export const ADD_TO_FAVOURITES_REQUEST = "ADD_TO_FAVOURITES_REQUEST";
export const ADD_TO_FAVOURITES_SUCCESS = "ADD_TO_FAVOURITES_SUCCESS";
export const ADD_TO_FAVOURITES_ERROR = "ADD_TO_FAVOURITES_ERROR";
export const REMOVE_FROM_FAVOURITES_REQUEST = "REMOVE_FROM_FAVOURITES_REQUEST";
export const REMOVE_FROM_FAVOURITES_SUCCESS = "REMOVE_FROM_FAVOURITES_SUCCESS";
export const REMOVE_FROM_FAVOURITES_ERROR = "REMOVE_FROM_FAVOURITES_ERROR";

// search
export const FILTER_STATIC_DATA_REQUEST = "FILTER_STATIC_DATA_REQUEST";
export const FILTER_STATIC_DATA_SUCCESS = "FILTER_STATIC_DATA_SUCCESS";
export const FILTER_STATIC_DATA_ERROR = "FILTER_STATIC_DATA_ERROR";
export const REMOVE_STATIC_DATA_FILTER = "REMOVE_STATIC_DATA_FILTER";
export const CLEAR_ALL_SEARCH_DATA = "CLEAR_ALL_SEARCH_DATA";
export const CLEAR_STORY_SEARCH_DATA = "CLEAR_STORY_SEARCH_DATA";
export const GET_CONTEXT_REQUEST = "GET_CONTEXT_REQUEST";
export const GET_CONTEXT_SUCCESS = "GET_CONTEXT_SUCCESS";
export const GET_CONTEXT_ERROR = "GET_CONTEXT_ERROR";
export const REMOVE_CONTEXT = "REMOVE_CONTEXT";

// data
export const FETCH_STATIC_DATA_REQUEST = "FETCH_STATIC_DATA_REQUEST";
export const FETCH_STATIC_DATA_SUCCESS = "FETCH_STATIC_DATA_SUCCESS";
export const FETCH_STATIC_DATA_ERROR = "FETCH_STATIC_DATA_ERROR";
export const CLEAR_STATIC_DATA_CACHE = "CLEAR_STATIC_DATA_CACHE";
export const SET_DATA_REFRESH = "SET_DATA_REFRESH";
export const SET_ALL_DATA_REFRESH = "SET_ALL_DATA_REFRESH";
export const CLEAR_DATA_REFRESH = "CLEAR_DATA_REFRESH";
export const CLEAR_ALL_DATA_REFRESH = "CLEAR_ALL_DATA_REFRESH";

// user
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const UNBLOCK_USER_REQUEST = "UNBLOCK_USER_REQUEST";
export const UNBLOCK_USER_SUCCESS = "UNBLOCK_USER_SUCCESS";
export const UNBLOCK_USER_ERROR = "UNBLOCK_USER_ERROR";
export const DISABLE_USER_REQUEST = "DISABLE_USER_REQUEST";
export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";
export const DISABLE_USER_ERROR = "DISABLE_USER_ERROR";
export const ENABLE_USER_REQUEST = "ENABLE_USER_REQUEST";
export const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS";
export const ENABLE_USER_ERROR = "ENABLE_USER_ERROR";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const CLEAR_USER_MESSAGES = "CLEAR_USER_MESSAGES";
export const LOGOUT = "LOGOUT";
export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_ERROR = "GET_DEPARTMENTS_ERROR";
export const ADD_DEPARTMENT_REQUEST = "ADD_DEPARTMENT_REQUEST";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_ERROR = "ADD_DEPARTMENT_ERROR";
export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_ERROR = "GET_CLIENTS_ERROR";
export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";
export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

// notification
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// lookout
export const ADD_PREFERENCE_REQUEST = "ADD_PREFERENCE_REQUEST";
export const ADD_PREFERENCE_SUCCESS = "ADD_PREFERENCE_SUCCESS";
export const ADD_PREFERENCE_ERROR = "ADD_PREFERENCE_ERROR";
export const REMOVE_PREFERENCE_REQUEST = "REMOVE_PREFERENCE_REQUEST";
export const REMOVE_PREFERENCE_SUCCESS = "REMOVE_PREFERENCE_SUCCESS";
export const REMOVE_PREFERENCE_ERROR = "REMOVE_PREFERENCE_ERROR";
export const EDIT_PREFERENCE_REQUEST = "EDIT_PREFERENCE_REQUEST";
export const EDIT_PREFERENCE_SUCCESS = "EDIT_PREFERENCE_SUCCESS";
export const EDIT_PREFERENCE_ERROR = "EDIT_PREFERENCE_ERROR";
export const GET_PREFERENCES_REQUEST = "GET_PREFERENCES_REQUEST";
export const GET_PREFERENCES_SUCCESS = "GET_PREFERENCES_SUCCESS";
export const GET_PREFERENCES_ERROR = "GET_PREFERENCES_ERROR";
export const GET_LOOKOUT_REQUEST = "GET_LOOKOUT_REQUEST";
export const GET_LOOKOUT_SUCCESS = "GET_LOOKOUT_SUCCESS";
export const GET_LOOKOUT_ERROR = "GET_LOOKOUT_ERROR";
export const CLEAR_LOOKOUT_DATA = "CLEAR_LOOKOUT_DATA";

// subscription
export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_ERROR = "ADD_CONTACT_ERROR";
export const REMOVE_CONTACT_REQUEST = "REMOVE_CONTACT_REQUEST";
export const REMOVE_CONTACT_SUCCESS = "REMOVE_CONTACT_SUCCESS";
export const REMOVE_CONTACT_ERROR = "REMOVE_CONTACT_ERROR";
export const SUBSCRIBE_REQUEST = "SUBSCRIBE_REQUEST";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";
export const UNSUBSCRIBE_REQUEST = "UNSUBSCRIBE_REQUEST";
export const UNSUBSCRIBE_SUCCESS = "UNSUBSCRIBE_SUCCESS";
export const UNSUBSCRIBE_ERROR = "UNSUBSCRIBE_ERROR";
export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_ERROR = "GET_SUBSCRIPTION_ERROR";

// sterling
export const GET_ENVIRONMENTS_REQUEST = "GET_ENVIRONMENTS_REQUEST";
export const GET_ENVIRONMENTS_SUCCESS = "GET_ENVIRONMENTS_SUCCESS";
export const GET_ENVIRONMENTS_ERROR = "GET_ENVIRONMENTS_ERROR";
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const GET_RANKINGS_REQUEST = "GET_RANKINGS_REQUEST";
export const GET_RANKINGS_SUCCESS = "GET_RANKINGS_SUCCESS";
export const GET_RANKINGS_ERROR = "GET_RANKINGS_ERROR";
export const GET_WEIGHTINGS_REQUEST = "GET_WEIGHTINGS_REQUEST";
export const GET_WEIGHTINGS_SUCCESS = "GET_WEIGHTINGS_SUCCESS";
export const GET_WEIGHTINGS_ERROR = "GET_WEIGHTINGS_ERROR";
export const EDIT_STERLING_REQUEST = "EDIT_STERLING_REQUEST";
export const EDIT_STERLING_SUCCESS = "EDIT_STERLING_SUCCESS";
export const EDIT_STERLING_ERROR = "EDIT_STERLING_ERROR";
export const GET_SHEETS_REQUEST = "GET_SHEETS_REQUEST";
export const GET_SHEETS_SUCCESS = "GET_SHEETS_SUCCESS";
export const GET_SHEETS_ERROR = "GET_SHEETS_ERROR";
export const CLEAR_STERLING_DATA = "CLEAR_STERLING_DATA";

// pusher
export const CREATE_PUSHER_CONNECTION = "CREATE_PUSHER_CONNECTION";
export const REMOVE_PUSHER_CONNECTION = "REMOVE_PUSHER_CONNECTION";

// auto ranging
export const ADD_AUTO_RANGING_USER_TRACKING_REQUEST =
  "ADD_AUTO_RANGING_USER_TRACKING_REQUEST";
export const ADD_AUTO_RANGING_USER_TRACKING_SUCCESS =
  "ADD_AUTO_RANGING_USER_TRACKING_SUCCESS";
export const ADD_AUTO_RANGING_USER_TRACKING_ERROR =
  "ADD_AUTO_RANGING_USER_TRACKING_ERROR";
export const GET_AUTO_RANGING_STATIC_DATA_REQUEST =
  "GET_AUTO_RANGING_STATIC_DATA_REQUEST";
export const GET_AUTO_RANGING_STATIC_DATA_SUCCESS =
  "GET_AUTO_RANGING_STATIC_DATA_SUCCESS";
export const GET_AUTO_RANGING_STATIC_DATA_ERROR =
  "GET_AUTO_RANGING_STATIC_DATA_ERROR";
export const AUTO_RANGING_BASIC_CONSTRAINTS = "AUTO_RANGING_BASIC_CONSTRAINTS";
export const CLEAR_AUTO_RANGING_BASIC_CONSTRAINTS =
  "CLEAR_AUTO_RANGING_BASIC_CONSTRAINTS";
export const GET_BAY_PLANS_STATIC_DATA_REQUEST =
  "GET_BAY_PLANS_STATIC_DATA_REQUEST";
export const GET_BAY_PLANS_STATIC_DATA_SUCCESS =
  "GET_BAY_PLANS_STATIC_DATA_SUCCESS";
export const GET_BAY_PLANS_STATIC_DATA_ERROR =
  "GET_BAY_PLANS_STATIC_DATA_ERROR";
export const AUTO_RANGING_BAY_PLANS_CONSTRAINTS =
  "AUTO_RANGING_BAY_PLANS_CONSTRAINTS";
export const GET_CURRENT_RANGE_STATIC_DATA_REQUEST =
  "GET_CURRENT_RANGE_STATIC_DATA_REQUEST";
export const GET_CURRENT_RANGE_STATIC_DATA_SUCCESS =
  "GET_CURRENT_RANGE_STATIC_DATA_SUCCESS";
export const GET_CURRENT_RANGE_STATIC_DATA_ERROR =
  "GET_CURRENT_RANGE_STATIC_DATA_ERROR";
export const AUTO_RANGING_CURRENT_RANGE_CONSTRAINTS =
  "AUTO_RANGING_CURRENT_RANGE_CONSTRAINTS";
export const GENERATE_AUTO_RANGING_REPORT_REQUEST =
  "GENERATE_AUTO_RANGING_REPORT_REQUEST";
export const GENERATE_AUTO_RANGING_REPORT_SUCCESS =
  "GENERATE_AUTO_RANGING_REPORT_SUCCESS";
export const GENERATE_AUTO_RANGING_REPORT_ERROR =
  "GENERATE_AUTO_RANGING_REPORT_ERROR";
export const GET_AUTO_RANGING_REPORT_REQUEST =
  "GET_AUTO_RANGING_REPORT_REQUEST";
export const GET_AUTO_RANGING_REPORT_SUCCESS =
  "GET_AUTO_RANGING_REPORT_SUCCESS";
export const GET_AUTO_RANGING_REPORT_ERROR = "GET_AUTO_RANGING_REPORT_ERROR";
export const GET_AUTO_RANGING_REPORT_CONSTRAINTS_REQUEST =
  "GET_AUTO_RANGING_REPORT_CONSTRAINTS_REQUEST";
export const GET_AUTO_RANGING_REPORT_CONSTRAINTS_SUCCESS =
  "GET_AUTO_RANGING_REPORT_CONSTRAINTS_SUCCESS";
export const GET_AUTO_RANGING_REPORT_CONSTRAINTS_ERROR =
  "GET_AUTO_RANGING_REPORT_CONSTRAINTS_ERROR";
export const CLEAR_AUTO_RANGING_DATA = "CLEAR_AUTO_RANGING_DATA";
export const CLEAR_AUTO_RANGING_CONSTRAINTS = "CLEAR_AUTO_RANGING_CONSTRAINTS";
export const CLEAR_AUTO_RANGING_REPORTS = "CLEAR_AUTO_RANGING_REPORTS";

// chache
export const FLUSH_CACHE_REQUEST = "FLUSH_CACHE_REQUEST";
export const FLUSH_CACHE_SUCCESS = "FLUSH_CACHE_SUCCESS";
export const FLUSH_CACHE_ERROR = "FLUSH_CACHE_ERROR";

// streamlit
export const GET_STREAMLIT_REQUEST = "GET_STREAMLIT_REQUEST";
export const GET_STREAMLIT_SUCCESS = "GET_STREAMLIT_SUCCESS";
export const GET_STREAMLIT_ERROR = "GET_STREAMLIT_ERROR";
export const GET_PROTOTYPES_LIST_REQUEST = "GET_PROTOTYPES_LIST_REQUEST";
export const GET_PROTOTYPES_LIST_SUCCESS = "GET_PROTOTYPES_LIST_SUCCESS";
export const GET_PROTOTYPES_LIST_ERROR = "GET_PROTOTYPES_LIST_ERROR";
