import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Tabs as MUITabs, Tab, Stack, Skeleton } from "@mui/material";

const defaultStyles = {
  "& .MuiTabs-flexContainer": { borderBottom: 1, borderColor: "grey.200" },
  "& .MuiTab-root": { minHeight: 42 },
  "& .MuiTabs-indicator": { top: 42 }
};

const Tabs = props => {
  const { activeTab, tabs, onChange, isLoading, styles, ...otherProps } = props;
  if (isLoading) {
    return (
      <Stack spacing={2} direction="row" sx={styles}>
        {[...Array(3)].map(() => (
          <Skeleton
            key={_.uniqueId()}
            variant="rounded"
            width={100}
            height={50}
          />
        ))}
      </Stack>
    );
  }
  return (
    <MUITabs
      value={activeTab}
      onChange={onChange}
      textColor="secondary"
      indicatorColor="secondary"
      sx={{ ...defaultStyles, ...styles }}
    >
      {tabs.map(i => (
        <Tab
          key={i.value}
          value={i.value}
          label={i.label}
          icon={i.icon}
          iconPosition="start"
          sx={{ textTransform: "none" }}
          {...otherProps}
        />
      ))}
    </MUITabs>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape()),
  activeTab: PropTypes.string,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  styles: PropTypes.shape()
};

Tabs.defaultProps = {
  tabs: [],
  onChange: () => {},
  activeTab: "",
  isLoading: false,
  styles: {}
};

export default Tabs;
