import Pusher from "pusher-js";
import {
  CREATE_PUSHER_CONNECTION,
  REMOVE_PUSHER_CONNECTION
} from "../constants/actionConstants";
import { PUSHER_URL, PUSHER_KEY, PUSHER_CLUSTER } from "../constants";

// eslint-disable-next-line default-param-last
const pusher = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PUSHER_CONNECTION: {
      const token = localStorage.getItem("refreshToken");
      const pusherConnection = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
        channelAuthorization: {
          endpoint: PUSHER_URL,
          transport: "ajax",
          headers: { Authorization: `Bearer ${token}` },
          customHandler: null
        }
      });
      // Pusher.logToConsole = process.env.NODE_ENV === "development";
      // Pusher.logToConsole = process.env.REACT_APP_DEV_ENV === "dev";
      const userChannel = pusherConnection.subscribe(
        `private-${action.userId}`
      );
      const clientChannel = pusherConnection.subscribe(
        `public-${action.client}`
      );
      return {
        ...state,
        pusher: pusherConnection,
        userChannel,
        clientChannel
      };
    }
    case REMOVE_PUSHER_CONNECTION: {
      const pusherConnection = state.pusher?.disconnect();
      return {
        ...state,
        pusher: pusherConnection
      };
    }
    default:
      return state;
  }
};
export default pusher;
