import { STREAMLIT_API, ALL_PROTOTYPE_API } from "constants";
import fetchInstance from "utils/fetchInstance";
import {
  GET_STREAMLIT_REQUEST,
  GET_STREAMLIT_SUCCESS,
  GET_STREAMLIT_ERROR,
  GET_PROTOTYPES_LIST_ERROR,
  GET_PROTOTYPES_LIST_SUCCESS,
  GET_PROTOTYPES_LIST_REQUEST
} from "../constants/actionConstants";
import logger from "../logger";

function getStreamlitRequest(prototype) {
  return {
    type: GET_STREAMLIT_REQUEST,
    prototype
  };
}

function getStreamlitSuccess(data) {
  return {
    type: GET_STREAMLIT_SUCCESS,
    data
  };
}

function getStreamlitError(error) {
  return {
    type: GET_STREAMLIT_ERROR,
    error
  };
}

function getPrototypesListRequest() {
  return {
    type: GET_PROTOTYPES_LIST_REQUEST
  };
}

function getPrototypesListSuccess(data) {
  return {
    type: GET_PROTOTYPES_LIST_SUCCESS,
    data
  };
}

function getPrototypesListError(error) {
  return {
    type: GET_PROTOTYPES_LIST_ERROR,
    error
  };
}

export const getStreamlitUrl =
  (type, userId, client, isFromReport) => dispatch => {
    dispatch(getStreamlitRequest(type));
    try {
      return fetchInstance(`${STREAMLIT_API}/${type}`, {
        headers: { "Content-Type": "application/json" }
      })
        .then(async res => {
          if (!res.ok) {
            const data = await res.json();
            const err = data?.message || data;
            throw new Error(err);
          }
          return res.json();
        })
        .then(
          res => {
            dispatch(getStreamlitSuccess(res));
            logger({
              date: new Date().toISOString(),
              action: "NEW_PROTOTYPE",
              user_id: userId,
              prototype: type,
              client,
              source: isFromReport ? "report" : "menu"
            });
          },
          err => dispatch(getStreamlitError(err.message))
        );
    } catch (err) {
      return dispatch(getStreamlitError(err.message));
    }
  };

export const getPrototypesList = () => dispatch => {
  dispatch(getPrototypesListRequest());
  try {
    return fetchInstance(ALL_PROTOTYPE_API, {
      headers: { "Content-Type": "application/json" }
    })
      .then(async res => {
        if (!res.ok) {
          const data = await res.json();
          const err = data?.message || data;
          throw new Error(err);
        }
        return res.json();
      })
      .then(
        res => {
          dispatch(getPrototypesListSuccess(res.result));
        },
        err => dispatch(getPrototypesListError(err.message))
      );
  } catch (err) {
    return dispatch(getPrototypesListError(err.message));
  }
};

export default null;
