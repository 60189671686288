import React from "react";
import PropTypes from "prop-types";
import {
  ToggleButtonGroup as MUIToggleButtonGroup,
  ToggleButton
} from "@mui/material";

const ToggleButtonGroup = props => {
  const { value, onChange, values, labels, ...otherProps } = props;
  return (
    <MUIToggleButtonGroup
      color="neutral"
      value={value}
      onChange={onChange}
      exclusive
      sx={{
        height: 35,
        "& .MuiToggleButton-root:first-of-type": {
          borderTopLeftRadius: 100,
          borderBottomLeftRadius: 100
        },
        "& .MuiToggleButton-root:last-of-type": {
          borderTopRightRadius: 100,
          borderBottomRightRadius: 100
        }
      }}
      {...otherProps}
    >
      {values.map((val, index) => (
        <ToggleButton key={val} value={val}>
          {labels[index]}
        </ToggleButton>
      ))}
    </MUIToggleButtonGroup>
  );
};

ToggleButtonGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string)
};

ToggleButtonGroup.defaultProps = {
  value: "",
  onChange: () => {},
  values: [],
  labels: []
};

export default ToggleButtonGroup;
