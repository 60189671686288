import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, generatePath, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { InfoText, Paper } from "components/BaseComponents";
import {
  NavigateStepButtons,
  SectionTitle,
  SearchBar,
  AdvancedSearch,
  Suggestions
} from "components/CustomComponents";
import { getContext, filterStaticData, reportConstraints } from "actions";
import {
  NEW_REPORT_TYPE_ROUTE,
  NEW_REPORT_FOCUS_TYPE_ROUTE
} from "constants/viewRoutes";

const Product = props => {
  const {
    initialSubsections,
    filteredSubsections,
    isFilteredData,
    contextSubsections,
    dispatchGetContext,
    story,
    client,
    isFetchingData,
    isLoadingContext,
    suggestedContext,
    dispatchFilterStaticData,
    isFilteringData,
    error,
    dispatchReportConstraints,
    setActiveStep,
    product,
    context,
    reportType
  } = props;
  const [subsections, setSubsections] = useState(initialSubsections);
  const navigate = useNavigate();
  const params = useParams();
  const { channel, focusType } = params;

  useEffect(() => {
    setActiveStep(0);
  }, []);

  useEffect(() => {
    if (isFilteredData) {
      setSubsections(filteredSubsections);
    } else {
      setSubsections(initialSubsections);
    }
  }, [initialSubsections, filteredSubsections, isFilteredData]);

  useEffect(() => {
    const apiProduct = product.map(i => i.apiValue);
    dispatchFilterStaticData(null, apiProduct, story, client);
  }, [product]);

  useEffect(() => {
    if (product.length === 0) return;
    const apiProduct = product.map(i => i.apiValue);
    const apiContext = context.map(i => i.apiValue);
    dispatchGetContext(apiProduct, apiContext, story, client);
  }, [product, context]);

  const handleProduct = value => {
    dispatchReportConstraints("product", value, story);
    if (context.length > 0) dispatchReportConstraints("context", [], story);
  };

  const handleContext = value =>
    dispatchReportConstraints("context", value, story);

  const handleSuggestion = suggestion => {
    const allContext = context.concat(suggestion);
    dispatchReportConstraints("context", allContext, story);
  };

  const handleNextClick = () => {
    const path = focusType
      ? generatePath(NEW_REPORT_FOCUS_TYPE_ROUTE, {
          reportType,
          focusType,
          channel,
          step: "market"
        })
      : generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "market"
        });
    navigate(path);
  };

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="1"
          title="Target Product"
          subtitle="Select your target product, you can filter your desired product with following tabs."
          isTooltip
          tooltipTitle="What is target product?"
          tooltipContent="In most instances you should only choose a distributor or brand as your target product. Other attributes such as category, sector, pack type, or sugar content are likely part of the competitive set you are interested in, so we recommend adding them at the next step."
        />
        <SearchBar
          label="Target Product"
          value={product}
          onChange={(e, value) => handleProduct(value)}
          options={subsections}
          multiple
          disabled={isFilteringData}
          styles={{ mb: 2 }}
          isLoading={isFetchingData}
          isUpdating={isFilteringData}
          story={story}
        />
        <AdvancedSearch
          fields={subsections}
          isLoading={isFetchingData || isFilteringData}
          selected={product}
          onChange={value => handleProduct(value)}
          story={story}
        />
      </Paper>
      <Paper>
        {(product.length === 0 || context.length === 0) && (
          <InfoText
            level="error"
            text={
              product.length === 0
                ? "Please select your Target Product first."
                : "Please select a new Product Filter."
            }
            styles={{ mb: 2 }}
          />
        )}
        <Box sx={{ position: "relative" }}>
          {(product.length === 0 || isFilteringData || error) && (
            <Box
              sx={{
                bgcolor: "white",
                filter: "blur(0px)",
                opacity: 0.6,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100
              }}
            />
          )}
          <SectionTitle
            order="2"
            title="Product Filter"
            subtitle="Select your desired competitive set in which you want to get the analysis."
          />
          <SearchBar
            label="Product Filter"
            value={context}
            onChange={(e, value) => handleContext(value)}
            options={contextSubsections}
            multiple
            disabled={isLoadingContext}
            styles={{ mb: 2 }}
            story={story}
          />
          {suggestedContext.length > 0 && (
            <Fragment>
              <Typography variant="subtitle2">
                Suggested Product Filter (based on your target product)
              </Typography>
              <Suggestions
                suggestions={suggestedContext}
                isLoading={isLoadingContext}
                onClick={value => handleSuggestion(value)}
                styles={{ mb: 3 }}
                story={story}
              />
            </Fragment>
          )}
          <AdvancedSearch
            fields={contextSubsections}
            isLoading={isLoadingContext}
            selected={context}
            onChange={value => handleContext(value)}
            story={story}
          />
        </Box>
      </Paper>
      <NavigateStepButtons
        onBackClick={() => navigate(-1)}
        onNextClick={handleNextClick}
        nextDisabled={product.length === 0 || context.length === 0}
      />
    </Fragment>
  );
};

Product.propTypes = {
  initialSubsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  isFilteredData: PropTypes.bool,
  contextSubsections: PropTypes.arrayOf(PropTypes.shape()),
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  isFetchingData: PropTypes.bool,
  isLoadingContext: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  error: PropTypes.string,
  dispatchGetContext: PropTypes.func,
  dispatchFilterStaticData: PropTypes.func,
  client: PropTypes.string,
  story: PropTypes.string,
  dispatchReportConstraints: PropTypes.func,
  setActiveStep: PropTypes.func,
  product: PropTypes.arrayOf(PropTypes.shape()),
  context: PropTypes.arrayOf(PropTypes.shape()),
  reportType: PropTypes.string
};

Product.defaultProps = {
  initialSubsections: [],
  filteredSubsections: [],
  isFilteredData: false,
  contextSubsections: [],
  suggestedContext: [],
  isFetchingData: false,
  isLoadingContext: false,
  isFilteringData: false,
  error: "",
  dispatchGetContext: () => {},
  dispatchFilterStaticData: () => {},
  client: "",
  story: "",
  dispatchReportConstraints: () => {},
  setActiveStep: () => {},
  product: [],
  context: [],
  reportType: ""
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      error,
      isFilteringData,
      filteredSubsections = { story: [] },
      isFilteredData = { story: false },
      isLoadingContext = false,
      contextSubsections = { story: [] },
      suggestedContext = { story: [] }
    },
    data: { subsections = { dataSet: [] } }
  } = state;
  return {
    error,
    isFilteringData,
    client: state.user.user.client,
    isFetchingData: state.data.isFetchingData,
    isLoadingContext,
    contextSubsections: contextSubsections[story],
    suggestedContext: suggestedContext[story],
    initialSubsections: subsections[dataSet],
    filteredSubsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story]
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchGetContext: (products, contexts, story, client) =>
    dispatch(getContext(products, contexts, "product", story, client)),

  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client)),

  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story))
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
