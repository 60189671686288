import React from "react";
import ReportBuilder from "components/ReportBuilder";
import { getStoryConfig } from "config";
import { storyEnums } from "constants/enums";
import { TrendIcon } from "../Icons";

const NewTrendReport = () => {
  const story = storyEnums.TREND;
  const { dataSet, type } = getStoryConfig(story);
  return (
    <ReportBuilder
      title="Trend Reports"
      icon={<TrendIcon />}
      description="Be informed of the market trends that are affecting a category, manufacturer, brand, and more, within a given market and wider channel."
      story={story}
      dataSet={dataSet}
      reportType={type}
    />
  );
};

export default NewTrendReport;
