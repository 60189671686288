import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  FocusProductSalesIcon,
  MinusIcon,
  ShareIcon,
  SugarIcon,
  TotalSalesIcon,
  WeightOfPlasticIcon
} from "components/Icons";
import { Paper, Switch, Table } from "components/BaseComponents";
import {
  formatAbsolutePercentage,
  formatAbsoluteValue,
  formatCurrency,
  formatPercentage,
  formatValue,
  formatVolume
} from "utils";
import { PageBar } from "./Components";

const getColor = value => {
  if (value > 0) return "positive.main";
  if (value < 0) return "negative.main";
  return "";
};

const getBgColor = value => {
  if (value > 0) return "positiveArray.50";
  if (value < 0) return "negativeArray.50";
  return "";
};

const getArrow = (value, color) => {
  if (value > 0) return <ArrowUpIcon sx={{ fontSize: 16, color }} />;
  if (value < 0) return <ArrowDownIcon sx={{ fontSize: 16, color }} />;
  if (value === 0)
    return <MinusIcon sx={{ fontSize: 16, color: "grey.700" }} />;
  return null;
};

const getValue = (value, conversionFunction, unit = "") => {
  if (!value && value !== 0) return "--";
  return `${conversionFunction(value)} ${unit}`;
};

const getSummaryItems = (items, conversionFunction, sustainability, units) => {
  if (items.length === 0 || sustainability.length === 0) return [];
  return [
    {
      icon: <TotalSalesIcon />,
      iconColor: "jade.300",
      title: items[0].sales_overview,
      percentage: getValue(
        items[0].relative_percentage_change,
        formatAbsolutePercentage
      ),
      change: `${getValue(
        items[0].absolute_change,
        formatAbsoluteValue
      )} (to ${getValue(items[0].after, conversionFunction)})`,
      value: items[0].absolute_change,
      color: getColor(items[0].absolute_change)
    },
    {
      icon: <FocusProductSalesIcon />,
      iconColor: "secondaryArray.100",
      title: items[1].sales_overview,
      percentage: getValue(
        items[1].relative_percentage_change,
        formatAbsolutePercentage
      ),
      change: `${getValue(
        items[1].absolute_change,
        formatAbsoluteValue
      )} (to ${getValue(items[1].after, conversionFunction)})`,
      value: items[1].absolute_change,
      color: getColor(items[1].absolute_change)
    },
    {
      icon: <ShareIcon />,
      iconColor: "primaryArray.100",
      title: items[2].sales_overview,
      percentage: getValue(items[2].absolute_change, formatAbsolutePercentage),
      change: `(to ${getValue(items[2].after, formatPercentage)})`,
      value: items[2].absolute_change,
      color: getColor(items[2].absolute_change)
    },
    {
      icon: <SugarIcon />,
      iconColor: "positiveArray.200",
      title: sustainability[0].sustainability_overview,
      percentage: getValue(
        sustainability[0].change_percentage,
        formatAbsolutePercentage
      ),
      change: getValue(
        sustainability[0].change_percentage,
        formatAbsoluteValue,
        units[0].unit
      ),
      value: sustainability[0].change,
      color: getColor(-sustainability[0].change)
    },
    {
      icon: <WeightOfPlasticIcon />,
      iconColor: "positiveArray.200",
      title: sustainability[2].sustainability_overview,
      percentage: getValue(
        sustainability[2].change_percentage,
        formatAbsolutePercentage
      ),
      change: getValue(
        sustainability[2].change_percentage,
        formatAbsoluteValue,
        units[2].unit
      ),
      value: sustainability[2].change,
      color: getColor(-sustainability[2].change)
    }
  ];
};

const getSalesData = (items, conversionFunction) => {
  if (items.length === 0) return [];
  return [
    {
      title: items[0].sales_overview,
      before: getValue(items[0].before, conversionFunction),
      after: getValue(items[0].after, conversionFunction),
      absoluteChange: getValue(items[0].absolute_change, formatAbsoluteValue),
      relativeChange: getValue(
        items[0].relative_percentage_change,
        formatAbsolutePercentage
      ),
      value: items[0].absolute_change
    },
    {
      title: items[1].sales_overview,
      before: getValue(items[1].before, conversionFunction),
      after: getValue(items[1].after, conversionFunction),
      absoluteChange: getValue(items[1].absolute_change, formatAbsoluteValue),
      relativeChange: getValue(
        items[1].relative_percentage_change,
        formatAbsolutePercentage
      ),
      value: items[1].absolute_change
    },
    {
      title: items[2].sales_overview,
      before: getValue(items[2].before, formatPercentage),
      after: getValue(items[2].after, formatPercentage),
      absoluteChange: getValue(
        items[2].absolute_change,
        formatAbsolutePercentage
      ),
      value: items[2].absolute_change
    },
    {
      title: items[3].sales_overview,
      before: items[3].before,
      after: items[3].after,
      absoluteChange: "",
      value: ""
    }
  ];
};

const getSustainabilityData = (items, units) => {
  if (items.length === 0) return [];
  return [
    {
      listTitle: "Health:",
      list: ["Sugar", "Calories"],
      before: [
        getValue(items[0].before, formatValue, units[0].unit),
        getValue(items[1].before, formatValue, units[1].unit)
      ],
      after: [
        getValue(items[0].after, formatValue, units[0].unit),
        getValue(items[1].after, formatValue, units[1].unit)
      ],
      change: [
        getValue(items[0].change, formatValue, units[0].unit),
        getValue(items[1].change, formatValue, units[1].unit)
      ],
      color: [getColor(-items[0].change), getColor(-items[1].change)]
    },
    {
      listTitle: "Plastic:",
      list: ["Weight of Virgin Plastic", "% of rPET", "Pieces of Plastic"],
      before: [
        getValue(items[2].before, formatValue, units[2].unit),
        getValue(items[3].before, formatPercentage),
        getValue(items[4].before, formatValue, units[4].unit)
      ],
      after: [
        getValue(items[2].after, formatValue, units[2].unit),
        getValue(items[3].after, formatPercentage),
        getValue(items[4].after, formatValue, units[4].unit)
      ],
      change: [
        getValue(items[2].change, formatValue, units[2].unit),
        getValue(items[3].change, formatValue, units[3].unit),
        getValue(items[4].change, formatValue, units[4].unit)
      ],
      color: [
        getColor(-items[2].change),
        getColor(items[3].change),
        getColor(-items[4].change)
      ]
    },
    {
      title: "Recyclability",
      before: getValue(items[5].before, formatPercentage),
      after: getValue(items[5].after, formatPercentage),
      change: getValue(items[5].change, formatValue, units[5].unit),
      color: getColor(items[5].change)
    },
    {
      title: "Carbon Emissions",
      before: "",
      after: "Coming in phase 2",
      change: "",
      color: ""
    }
  ];
};

const Summary = props => {
  const { report } = props;
  const [metric, setMetric] = useState("value");
  const [salesOverview, setSalesOverview] = useState([]);
  const [summaryItems, setSummaryItems] = useState([]);
  const [sustainabilityOverview, setSustainabilityOverview] = useState([]);

  const {
    summary_data: summaryData = { sales_overview: {} },
    sustainability = {},
    sustainability_units_guide: sustainabilityUnits = {}
  } = report || { summary_data: {} };
  const { sales_overview: sales = { value_sales: [], volume_sales: [] } } =
    summaryData;
  const { value_sales: valueSales, volume_sales: volumeSales } = sales;

  useEffect(() => {
    if (metric === "value") {
      const summary = getSummaryItems(
        valueSales,
        formatCurrency,
        sustainability,
        sustainabilityUnits
      );
      setSummaryItems(summary);
      const salesItems = getSalesData(valueSales, formatCurrency);
      setSalesOverview(salesItems);
    } else {
      const summary = getSummaryItems(
        volumeSales,
        formatVolume,
        sustainability,
        sustainabilityUnits
      );
      setSummaryItems(summary);
      const salesItems = getSalesData(volumeSales, formatVolume);
      setSalesOverview(salesItems);
    }
  }, [metric]);

  useEffect(() => {
    const sustainabilityItems = getSustainabilityData(
      sustainability,
      sustainabilityUnits
    );
    setSustainabilityOverview(sustainabilityItems);
  }, [sustainabilityUnits, sustainability]);

  return (
    <Fragment>
      <PageBar
        leftChild={
          <Switch
            checked={metric === "value"}
            onChange={e => setMetric(e.target.checked ? "value" : "volume")}
            offLabel="Volume"
            onLabel="Value"
            styles={{ mb: 2 }}
          />
        }
      />
      <Paper styles={{ borderColor: "primaryArray.100", bgcolor: "grey.50" }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-evenly"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "primaryArray.100" }}
            />
          }
        >
          {summaryItems.map(i => (
            <Stack
              alignItems="flex-start"
              sx={{ width: 1, overflow: "hidden" }}
              key={i.title}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 48,
                  height: 48,
                  bgcolor: i.iconColor,
                  borderRadius: "50%",
                  mb: 2
                }}
              >
                {i.icon}
              </Stack>
              <Tooltip title={i.title}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="grey.500"
                  noWrap
                  sx={{ width: 1 }}
                >
                  {i.title}
                </Typography>
              </Tooltip>
              <Typography variant="h2" color={i.color || "grey.700"}>
                {i.percentage}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={0.25}>
                {getArrow(i.value, i.color)}
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color={i.color || "grey.700"}
                >
                  {i.change}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Paper>
      <Grid container spacing={1} sx={{ pt: 2 }}>
        <Grid item lg={6} xs={12}>
          <Typography variant="subtitle1" color="grey.500" gutterBottom>
            Sales Overview
          </Typography>
          <Table
            columns={[
              {
                label: "Sales Overview",
                styles: { textAlign: "left" },
                cellStyles: { bgcolor: "grey.100", py: 2.2, maxWidth: 350 },
                renderCell: item => (
                  <Typography variant="inherit" noWrap sx={{ width: 350 }}>
                    {item.title}
                  </Typography>
                )
              },
              { label: "Before", renderCell: item => item.before },
              { label: "After", renderCell: item => item.after },
              {
                label: "Change",
                cellStyles: { p: 0 },
                renderCell: item => (
                  <Typography
                    variant="inherit"
                    sx={{
                      color: getColor(item.value),
                      bgcolor: getBgColor(item.value),
                      px: 0.5,
                      py: 2.2
                    }}
                  >
                    {item.absoluteChange}
                    {item.relativeChange ? ` (${item.relativeChange})` : ""}
                  </Typography>
                )
              }
            ]}
            rows={salesOverview}
            styles={{
              head: { "& .MuiTableCell-root": { py: 1 } },
              row: { bgcolor: "white" },
              root: { "& .MuiTableCell-body": { borderTop: 1 } }
            }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography variant="subtitle1" color="grey.500" gutterBottom>
            Sustainability Overview
          </Typography>
          <Table
            columns={[
              {
                label: "Sustainability Overview",
                cellStyles: { bgcolor: "grey.100" },
                styles: { textAlign: "left" },
                renderCell: item => (
                  <Fragment>
                    {item.title}
                    <Typography variant="inherit" color="grey.500">
                      {item.listTitle}
                    </Typography>
                    {item.list && (
                      <List
                        disablePadding
                        sx={{ pl: 2, listStyleType: "disc" }}
                      >
                        {item.list.map(i => (
                          <ListItem
                            disablePadding
                            sx={{ display: "list-item" }}
                            key={i}
                          >
                            {i}
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Fragment>
                )
              },
              {
                label: "Before",
                renderCell: item =>
                  Array.isArray(item.before)
                    ? item.before.map((i, k) => (
                        <Typography variant="inherit" key={item.list[k]}>
                          {i}
                        </Typography>
                      ))
                    : item.before
              },
              {
                label: "After",
                renderCell: item =>
                  Array.isArray(item.after)
                    ? item.after.map((i, k) => (
                        <Typography variant="inherit" key={item.list[k]}>
                          {i}
                        </Typography>
                      ))
                    : item.after
              },
              {
                label: "Change",
                renderCell: item =>
                  Array.isArray(item.change) ? (
                    item.change.map((i, k) => (
                      <Typography
                        variant="inherit"
                        color={item.color[k]}
                        key={item.list[k]}
                      >
                        {i}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="inherit" color={item.color}>
                      {item.change}
                    </Typography>
                  )
              }
            ]}
            rows={sustainabilityOverview}
            styles={{
              head: { "& .MuiTableCell-root": { py: 1 } },
              row: { bgcolor: "white" },
              root: { "& .MuiTableCell-body": { py: 0.855, borderTop: 1 } }
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

Summary.propTypes = {
  report: PropTypes.shape()
};

Summary.defaultProps = {
  report: {}
};

export default Summary;
