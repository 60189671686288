export const HELP_DESK_ROUTE =
  "https://share.hsforms.com/1JBh490lnRS6bBFd0QnZ5AA2xb00";
export const HELP_DESK_MAIL_ROUTE = "mailto:helpdesk@dijuno.ai";
export const HOME_ROUTE = "/";
export const ABOUT_ROUTE = "/about";
export const SUPPORT_ROUTE = "/support";
export const SETTINGS_ROUTE = "/settings";

// streamlit tools
export const TOOL_ROUTE = "/tools";
export const TOOL_SINGLE_ROUTE = "/tools/:type";

// login
export const LOGIN_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const RESET_PASSWORD_ROUTE = "/reset";

// lookout
export const LOOKOUT_BASE_ROUTE = "/lookout";
export const LOOKOUT_ROUTE = "/lookout/:channel";
export const LOOKOUT_PREFERENCES_ROUTE = "/lookout-preferences/:channel";
export const LOOKOUT_IH_ROUTE = "/lookout/ih";

// reports
export const NEW_REPORT_ROUTE = "/new-report";
export const NEW_FOCUS_REPORT_ROUTE = "/new-report#focus-reports";
export const VIEW_REPORT_ROUTE = "/reports";
export const REPORTS_REVIEWS_ROUTE = "/reports-reviews";
export const REPORTS_REVIEWS_BASE_ROUTE = "/reports-reviews/*";
// new report route layout with params
export const NEW_REPORT_TYPE_ROUTE = `${REPORTS_REVIEWS_ROUTE}/:reportType/new/:channel/:step`;
export const NEW_REPORT_FOCUS_TYPE_ROUTE = `${REPORTS_REVIEWS_ROUTE}/:reportType/new/:focusType/:channel/:step`;
// relative routes for ReportsReviews
export const NEW_PERFORMANCE_REPORT_ROUTE = "performance/new/:channel/:step";
export const NEW_BRAND_BATTLES_REPORT_ROUTE =
  "brand-battles/new/:channel/:step";
export const NEW_RANGE_REVIEW_REPORT_ROUTE = "range-review/new/:channel/:step";
export const NEW_TREND_REPORT_ROUTE = "trend/new/:channel/:step";
export const NEW_FOCUSED_REPORT_ROUTE = "focus/new/:focusType/:channel/:step";

// used to navigate from e.g. menu bar
export const NEW_IH_PERFORMANCE_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/performance/new/ih/product`;
export const NEW_OOH_PERFORMANCE_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/performance/new/ooh/product`;
export const NEW_IH_BRAND_BATTLES_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/brand-battles/new/ih/product`;
export const NEW_IH_RANGE_REVIEW_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/range-review/new/ih/product`;
export const NEW_IH_TREND_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/trend/new/ih/product`;
export const NEW_IH_PERFORMANCE_FOCUSED_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/focus/new/performance/ih/product`;
export const NEW_OOH_SHARE_FOCUSED_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/focus/new/performance/ooh/product`;
export const NEW_IH_NPD_FOCUSED_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/focus/new/npd/ih/product`;
export const NEW_OOH_NPD_FOCUSED_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/focus/new/npd/ooh/product`;
export const NEW_IH_STARS_FOCUSED_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/focus/new/stars/ih/product`;
export const NEW_OOH_STARS_FOCUSED_REPORT_ROUTE = `${REPORTS_REVIEWS_ROUTE}/focus/new/stars/ooh/product`;

// edit report with params
export const EDIT_REPORT_TYPE_ROUTE = `${REPORTS_REVIEWS_ROUTE}/:reportType/edit`;
export const EDIT_REPORT_FOCUS_TYPE_ROUTE = `${REPORTS_REVIEWS_ROUTE}/focus/:focusType/edit`;
// relative routes for ReportsReviews
export const EDIT_PERFORMANCE_REPORT_ROUTE = "performance/edit";
export const EDIT_BRAND_BATTLES_REPORT_ROUTE = "brand-battles/edit";
export const EDIT_RANGE_REVIEW_REPORT_ROUTE = "range-review/edit";
export const EDIT_TREND_REPORT_ROUTE = "trend/edit";
export const EDIT_FOCUS_REPORT_ROUTE = "focus/:focusType/edit";

// previous reports
export const PREVIOUS_REPORTS_ROUTE = "/previous-reports";
export const PREVIOUS_REPORTS_TYPE_ROUTE = `${PREVIOUS_REPORTS_ROUTE}/:reportType`;
export const PREVIOUS_REPORTS_PERFORMANCE_ROUTE = `${PREVIOUS_REPORTS_ROUTE}/performance`;
export const PREVIOUS_REPORTS_BRAND_BATTLES_ROUTE = `${PREVIOUS_REPORTS_ROUTE}/brand-battles`;
export const PREVIOUS_REPORTS_TREND_ROUTE = `${PREVIOUS_REPORTS_ROUTE}/trend`;
export const PREVIOUS_REPORTS_RANGE_REVIEW_ROUTE = `${PREVIOUS_REPORTS_ROUTE}/range-review`;
export const PREVIOUS_REPORTS_AUTO_RANGING_ROUTE = `${PREVIOUS_REPORTS_ROUTE}/auto-ranging`;

// sterling
export const STERLING_ROUTE = "/sterling";
export const STERLING_OPTION_ROUTE = `${STERLING_ROUTE}/:option/:type?`;
export const STERLING_INITIAL_ROUTE = `${STERLING_ROUTE}/prioritisation`;
export const STERLING_RANGING_ROUTE = `${STERLING_ROUTE}/ranging/:type?`;
export const STERLING_WEIGHTINGS_ROUTE = `${STERLING_ROUTE}/weightings`;
export const STERLING_RANKING_ROUTE = `${STERLING_ROUTE}/ranking`;
export const STERLING_PRIORITISATION_ROUTE = `${STERLING_ROUTE}/prioritisation`;

// auto ranging
export const AUTO_RANGING_ROUTE = "/auto-ranging";
export const NEW_AUTO_RANGING_ROUTE = `${AUTO_RANGING_ROUTE}/:type/:id/:step`;
// for menu bar etc.
export const NEW_AUTO_RANGING_INITIAL_ROUTE = `${AUTO_RANGING_ROUTE}/new/1/basics`;
export const NEW_AUTO_RANGING_FINAL_ROUTE = `${AUTO_RANGING_ROUTE}/:type/:id/review`;
export const EDIT_AUTO_RANGING_INITIAL_ROUTE = `${AUTO_RANGING_ROUTE}/edit/:id/basics`;

// auto ranging report
export const AUTO_RANGING_REPORT_ROUTE = `${AUTO_RANGING_ROUTE}/report/:reportId/:story`;
export const AUTO_RANGING_REPORT_SECTION_ROUTE = `${AUTO_RANGING_ROUTE}/report/:reportId/:story/:section`;
export const AUTO_RANGING_REPORT_INITIAL_ROUTE = `${AUTO_RANGING_REPORT_ROUTE}/summary`;

// category optimisation
export const CATEGORY_OPTIMISATION_ROUTE = "/category-optimisation";
