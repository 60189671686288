import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Typography, Stack, Box } from "@mui/material";
import { Paper } from "components/BaseComponents";
import { NEW_REPORT_ROUTE } from "constants/viewRoutes";
import { reportError } from "images";

const PresentationError = props => {
  const { errorMessage, newReportLink } = props;
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "6d098052-78cd-4f9b-a165-f7f6f0a7e90f",
          target: "#hubspotErrorForm"
        });
      }
    });
  }, []);
  return (
    <Stack alignItems="stretch" sx={{ width: 1 }}>
      <Paper>
        <Stack alignItems="center" spacing={3} sx={{ py: 4 }}>
          <img alt="report error" src={reportError} style={{ width: 155 }} />
          <Stack alignItems="center" spacing={0.5}>
            <Typography variant="h4" color="primary.main">
              Report Failed!
            </Typography>
            <Typography
              variant="body1"
              color="grey.500"
              id="error" // for load-runner
            >
              {errorMessage}
            </Typography>
          </Stack>
          <Button
            component={Link}
            to={newReportLink}
            variant="contained"
            color="primary"
          >
            Generate a new report
          </Button>
        </Stack>
        <Box
          sx={{ px: 4, py: 2, bgcolor: "grey.50", borderRadius: 1 }}
          id="hubspotErrorForm"
        />
      </Paper>
    </Stack>
  );
};

PresentationError.propTypes = {
  errorMessage: PropTypes.string,
  newReportLink: PropTypes.string
};

PresentationError.defaultProps = {
  errorMessage: "An unknown error occured",
  newReportLink: NEW_REPORT_ROUTE
};

export default PresentationError;
