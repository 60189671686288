import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Stack } from "@mui/material";
import { ArrowRightIcon } from "components/Icons";
import ReportTypography from "../ReportTypography";

const Section = props => {
  const { text, config } = props;
  // performance
  const sectionValue = text.find(i => i.key === "section_value");
  const value = sectionValue?.text;
  const isPositiveValue = value && value.startsWith("+");
  // bb subset
  const subsetSummary = text.find(i => i.key === "subset_summary");
  const subsetDetails = text.find(i => i.key === "subset_details");
  const subsetDetailsList = subsetDetails && subsetDetails?.text?.split("\n");
  const subsetReportText = text.find(i => i.key === "subset_report_text");
  const subsetReportUrl = text.find(i => i.key === "subset_report_url");
  return (
    <Fragment>
      {value && (
        <ReportTypography
          fontSize={20}
          config={config}
          fontWeight={500}
          color="common.black"
          sx={{
            border: 1,
            borderRadius: 1,
            borderColor: isPositiveValue ? "positive.main" : "negative.main",
            bgcolor: isPositiveValue ? "positive.light" : "negative.light",
            px: 1.5,
            py: 1,
            my: 1
          }}
        >
          {value}
        </ReportTypography>
      )}
      {subsetSummary && (
        <ReportTypography fontSize={16} config={config} gutterBottom>
          {subsetSummary.text}
        </ReportTypography>
      )}
      <Stack spacing={1} sx={{ my: 1 }}>
        {subsetDetailsList &&
          subsetDetailsList.map(i => {
            const isPositive = i.includes("+");
            return (
              <ReportTypography
                fontSize={14}
                config={config}
                color="common.black"
                sx={{
                  border: 1,
                  borderRadius: 1,
                  borderColor: isPositive ? "positive.main" : "negative.main",
                  bgcolor: isPositive ? "positive.light" : "negative.light",
                  px: 1.5,
                  py: 1
                }}
                key={i}
              >
                {i}
              </ReportTypography>
            );
          })}
      </Stack>
      {subsetReportUrl && (
        <Button
          variant="contained"
          size="large"
          endIcon={<ArrowRightIcon />}
          sx={{
            my: 1,
            ...(config.alignment.section === "right"
              ? { whiteSpace: "normal", lineHeight: 1.4 }
              : {})
          }}
          href={subsetReportUrl.text}
          target="_blank"
          rel="noreferrer"
        >
          {subsetReportText?.text}
        </Button>
      )}
    </Fragment>
  );
};

Section.propTypes = {
  text: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape()
};

Section.defaultProps = {
  text: [],
  config: {}
};

export default Section;
