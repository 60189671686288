import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { ReportStepper } from "components/CustomComponents";
import { storyEnums } from "constants/enums";
import { fetchStaticData, filterStaticData, reportConstraints } from "actions";
import {
  getConstraintsFromQuery,
  getSearchOption,
  updateAPIValue
} from "utils";
import { getStoryConfig } from "config";
import { BB_COMPARISON_PRODUCTS } from "constants";
import Header from "./Header";
import Products from "./Products";
import Context from "./Context";
import Market from "./Market";
import Review from "./Review";

const steps = [
  "Target Products",
  "Product Filter",
  "Market, Time, Measure",
  "Review and Run"
];

const NewBrandBattlesReport = props => {
  const {
    isStaticData,
    client,
    getData,
    productA,
    productB,
    context,
    retailer,
    period,
    metric,
    dispatchReportConstraints,
    dispatchFilterStaticData,
    allSubsections
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const params = useParams();
  const story = storyEnums.BB;
  const { step } = params;
  const { dataSet, type } = getStoryConfig(story);

  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      const {
        market: marketValue,
        period: periodValue,
        metric: metricValue,
        product: productValue
      } = getConstraintsFromQuery(location.search);
      const subsections = allSubsections[dataSet];

      const updatedProduct = updateAPIValue(
        getSearchOption(productValue[0], "what", story),
        "product_a"
      );
      let productAValue = null;
      if (BB_COMPARISON_PRODUCTS[updatedProduct.subsection]) {
        productAValue = updatedProduct;
      }
      dispatchReportConstraints("productA", productAValue, story);

      const marketOptions = subsections.find(
        item => item.name === "retailer"
      )?.keywords;
      const retailerExists = marketOptions.includes(marketValue);
      if (retailerExists) {
        dispatchReportConstraints("retailer", marketValue, story);
      }

      const periodOptions = [
        ...(
          subsections.find(item => item.table === "when")?.keywords || []
        ).map(i => ({
          date: i.date,
          label: `${i.period} ${i.date}`,
          period: i.period,
          value: i.period
        })),
        { period: "custom", label: "Choose range on calendar" }
      ];
      const selectedPeriod = periodOptions.find(i => i.period === periodValue);
      dispatchReportConstraints("period", selectedPeriod, story);

      dispatchReportConstraints("metric", metricValue, story);

      // filter static data
      if (productAValue) {
        const filterTerms = [productAValue];
        const apiTerms = filterTerms.map(i => i.apiValue);
        dispatchFilterStaticData(null, apiTerms, story, client);
      }
    }
  }, []);

  return (
    <Fragment>
      <ReportStepper activeStep={activeStep} steps={steps} />
      <Header
        story={story}
        isLastStep={activeStep === steps.length - 1}
        productA={productA}
        productB={productB}
        context={context}
        retailer={retailer}
        period={period}
        metric={metric}
      />
      {step === "product" && (
        <Products
          story={story}
          setActiveStep={setActiveStep}
          dataSet={dataSet}
          productA={productA}
          productB={productB}
          context={context}
          reportType={type}
        />
      )}
      {step === "context" && (
        <Context
          story={story}
          setActiveStep={setActiveStep}
          dataSet={dataSet}
          productA={productA}
          productB={productB}
          context={context}
          reportType={type}
        />
      )}
      {step === "market" && (
        <Market
          story={story}
          setActiveStep={setActiveStep}
          dataSet={dataSet}
          productA={productA}
          productB={productB}
          context={context}
          retailer={retailer}
          period={period}
          metric={metric}
          reportType={type}
        />
      )}
      {step === "review" && (
        <Review
          story={story}
          setActiveStep={setActiveStep}
          productA={productA}
          productB={productB}
          context={context}
          retailer={retailer}
          period={period}
          metric={metric}
          reportType={type}
        />
      )}
    </Fragment>
  );
};

NewBrandBattlesReport.propTypes = {
  getData: PropTypes.func,
  isStaticData: PropTypes.bool,
  client: PropTypes.string,
  productA: PropTypes.shape(),
  productB: PropTypes.shape(),
  context: PropTypes.arrayOf(PropTypes.shape()),
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  metric: PropTypes.string,
  dispatchReportConstraints: PropTypes.func,
  dispatchFilterStaticData: PropTypes.func,
  allSubsections: PropTypes.shape()
};

NewBrandBattlesReport.defaultProps = {
  getData: () => {},
  isStaticData: false,
  client: "",
  productA: null,
  productB: null,
  context: [],
  retailer: null,
  period: null,
  metric: null,
  dispatchReportConstraints: () => {},
  dispatchFilterStaticData: () => {},
  allSubsections: {}
};

const mapStateToProps = state => {
  const story = storyEnums.BB;
  const {
    reportConstraints: {
      [story]: {
        productA = null,
        productB = null,
        context = [],
        retailer = null,
        period = null,
        metric = null
      } = {}
    },
    data: { subsections = { dataSet: [] } }
  } = state;
  return {
    isStaticData: state.data.isStaticData,
    client: state.user.user.client,
    productA,
    productB,
    context,
    retailer,
    period,
    metric,
    allSubsections: subsections
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => dispatch(fetchStaticData(client)),
  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story)),
  dispatchFilterStaticData: (term, filterTerms, filterStory, client) =>
    dispatch(filterStaticData(term, filterTerms, filterStory, client))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBrandBattlesReport);
