import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  Navigate,
  generatePath
} from "react-router-dom";
import _ from "lodash";
import { Grid } from "@mui/material";
import { NEW_REPORT_TYPE_ROUTE, VIEW_REPORT_ROUTE } from "constants/viewRoutes";
import {
  ExpectedOutput,
  NavigateStepButtons,
  ReviewCard
} from "components/CustomComponents";
import { getDateLabel, buildQueryString } from "utils";
import { clearStoryConstraints, clearStorySearchData } from "actions";

const Review = props => {
  const {
    story,
    setActiveStep,
    productA,
    productB,
    context,
    retailer,
    period,
    metric,
    dispachClearStory,
    reportType
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { channel } = params;

  useEffect(() => {
    setActiveStep(3);
  }, []);

  const goToProduct = () =>
    navigate(
      generatePath(NEW_REPORT_TYPE_ROUTE, {
        reportType,
        channel,
        step: "product"
      })
    );

  const goToContext = () =>
    navigate(
      generatePath(NEW_REPORT_TYPE_ROUTE, {
        reportType,
        channel,
        step: "context"
      })
    );

  const goToMarket = () =>
    navigate(
      generatePath(NEW_REPORT_TYPE_ROUTE, {
        reportType,
        channel,
        step: "market"
      })
    );

  const getSelectedLabel = items =>
    items.map(i => `${_.startCase(i.subsection)}: ${i.value}`);

  const getSelectedDateLabel = date => {
    if (date.period !== "custom") return [date.label];
    const dateString = getDateLabel(date.name, story).split("Vs");
    return [`${dateString[0]} Vs`, dateString[1]];
  };

  if (!productA || !productB) {
    return (
      <Navigate
        to={generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "product"
        })}
      />
    );
  }

  if (context.length === 0) {
    return (
      <Navigate
        to={generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "context"
        })}
      />
    );
  }

  if (!retailer || !period || !metric) {
    return (
      <Navigate
        to={generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "market"
        })}
      />
    );
  }

  const onNextClick = () => {
    const constraints = [
      {
        name: productA.value,
        subsection: productA.subsection,
        prefix: "product_a"
      },
      {
        name: productB.value,
        subsection: productB.subsection,
        prefix: "product_b"
      },
      ...context.map(i => ({ name: i.value, subsection: i.subsection })),
      { name: retailer, subsection: "retailer" },
      {
        name: period.period !== "custom" ? period.value : period.name,
        subsection: "period"
      },
      { name: metric, subsection: "metric_name" }
    ];
    dispachClearStory(story);
    navigate({
      pathname: VIEW_REPORT_ROUTE,
      search: `story=${story.toUpperCase()}&${buildQueryString(constraints)}`
    });
  };

  return (
    <Fragment>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        <Grid item xs={12} md={6}>
          <ReviewCard
            abel="Primary Product"
            selected={getSelectedLabel([productA])}
            onEdit={goToProduct}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReviewCard
            label="Comparison Product"
            selected={getSelectedLabel([productB])}
            onEdit={goToProduct}
          />
        </Grid>
        <Grid item xs={12}>
          <ReviewCard
            label="Product Filter"
            selected={getSelectedLabel(context)}
            onEdit={goToContext}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Analysis Market"
            selected={[retailer]}
            onEdit={goToMarket}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Time Frame"
            selected={getSelectedDateLabel(period)}
            onEdit={goToMarket}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewCard
            label="Analysis Metric"
            selected={[metric]}
            onEdit={goToMarket}
          />
        </Grid>
      </Grid>
      <ExpectedOutput story={story} />
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "market"
            })
          )
        }
        onNextClick={onNextClick}
        nextLabel="Run Report"
      />
    </Fragment>
  );
};

Review.propTypes = {
  story: PropTypes.string,
  productA: PropTypes.shape(),
  productB: PropTypes.shape(),
  context: PropTypes.arrayOf(PropTypes.shape()),
  setActiveStep: PropTypes.func,
  retailer: PropTypes.string,
  period: PropTypes.shape(),
  metric: PropTypes.string,
  dispachClearStory: PropTypes.func,
  reportType: PropTypes.string
};

Review.defaultProps = {
  story: "",
  productA: null,
  productB: null,
  context: [],
  setActiveStep: () => {},
  retailer: null,
  period: null,
  metric: null,
  dispachClearStory: () => {},
  reportType: ""
};

const mapDispatchToProps = dispatch => ({
  dispachClearStory: story => {
    dispatch(clearStoryConstraints(story));
    dispatch(clearStorySearchData(story));
  }
});

export default connect(null, mapDispatchToProps)(Review);
