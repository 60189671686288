import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Grid, Stack } from "@mui/material";
import { ALIGNMENT, CHART_COLORS, clientConfig } from "config";
import { getFocusFromQuery, getTextAlign } from "utils";
import {
  ChartTable,
  Dual,
  KantarMeasureTree,
  MultipleCharts,
  Summary,
  SubCharts,
  MultipleChartsCompare,
  SingleChart,
  Appendix,
  Contents,
  List,
  Section,
  DoubleChartTable,
  Stars,
  PrototypeContents,
  RelatedContents,
  Title
} from "./Layouts";
import ReportTypography from "./ReportTypography";
import ReportPrototype from "./ReportPrototype";

const ALIGNMENT_MAPPINGS = {
  right: "flex-end",
  center: "center",
  left: "flex-start"
};

const getFooter = (footer, config) => (
  <Grid item xs={12} sx={{ position: "absolute", top: "100%", width: 1 }}>
    <Stack
      direction="row"
      justifyContent={ALIGNMENT_MAPPINGS[config.alignment.footer]}
      sx={{ width: 1 }}
    >
      {footer?.map((i, k) => (
        <Fragment key={i}>
          <ReportTypography component="span" fontSize={10} config={config}>
            {i}
          </ReportTypography>
          {k !== footer.length - 1 && (
            <ReportTypography
              component="span"
              fontSize={10}
              color="grey.300"
              config={config}
              sx={{ px: 1.5 }}
            >
              |
            </ReportTypography>
          )}
        </Fragment>
      ))}
    </Stack>
  </Grid>
);

const showTitleSlide = (slideData, color, config, isFocus) => (
  <Grid
    container
    rowSpacing={2}
    sx={{
      position: "absolute",
      height: "calc(100% - 48px)",
      width: "calc(100% - 64px)",
      top: 24,
      left: 32,
      textAlign: getTextAlign(config.alignment.title)
    }}
  >
    <Grid
      item
      xs={12}
      container
      rowSpacing={3}
      sx={{ position: "absolute", top: isFocus ? "20%" : "25%" }}
      justifyContent={ALIGNMENT_MAPPINGS[config.alignment.title]}
    >
      <Grid item xs={6}>
        {isFocus && (
          <ReportTypography
            fontSize={28}
            config={config}
            fontWeight={600}
            color={color}
            gutterBottom
          >
            {slideData.title}
          </ReportTypography>
        )}
        <ReportTypography
          fontSize={28}
          config={config}
          fontWeight={500}
          color={color}
          gutterBottom
        >
          {slideData.heading}
        </ReportTypography>
        <Title text={slideData.content?.texts} config={config} color={color} />
      </Grid>
    </Grid>
    {getFooter(slideData.footer, config)}
  </Grid>
);

const showSectionSlide = (slideData, color, config) => (
  <Grid
    container
    rowSpacing={2}
    sx={{
      position: "absolute",
      height: "calc(100% - 48px)",
      width: "calc(100% - 64px)",
      top: 24,
      left: 32,
      textAlign: getTextAlign(config.alignment.section)
    }}
    justifyContent={ALIGNMENT_MAPPINGS[config.alignment.section]}
  >
    <Grid item xs={12 * config.alignment.width} sx={{ mt: 10 }}>
      <ReportTypography
        fontSize={28}
        fontWeight={500}
        color={color}
        config={config}
      >
        {slideData.heading}
      </ReportTypography>
      <ReportTypography fontSize={16} config={config} sx={{ my: 2 }}>
        {slideData.title}
      </ReportTypography>
      <Stack
        alignItems={
          config.alignment.section === "center" ? "center" : "flex-start"
        }
        sx={{ my: 2 }}
      >
        <Section text={slideData.content?.texts} config={config} />
      </Stack>
    </Grid>
    {slideData.prototype && (
      <ReportPrototype
        formatKey="section"
        prototype={slideData.prototype}
        alignment={config.alignment.section}
      />
    )}
    {getFooter(slideData.footer, config)}
  </Grid>
);

const showNoFormatKey = (formatKey, config) => (
  <ReportTypography fontSize={14} config={config}>
    No slide found with format: {formatKey}
  </ReportTypography>
);

const showSlide = (
  formatKey,
  slideData,
  config,
  story,
  client,
  contents,
  isFocus,
  prototypeArray,
  navigateToSlide
) => {
  switch (formatKey) {
    case "summary":
      return <Summary text={slideData.content?.texts} config={config} />;
    case "multiple_charts":
      return (
        <MultipleCharts config={config} charts={slideData.content?.charts} />
      );
    case "chart_table":
      return (
        <ChartTable
          chart={slideData.content?.charts[0]}
          table={slideData.content?.tables[0]}
          config={config}
          align={slideData.content?.charts[0]?.style?.chart_style?.is_aligned}
        />
      );
    case "dual_table":
      return (
        <Dual
          config={config}
          tables={slideData.content?.tables}
          contentKey="dual_table"
        />
      );
    case "dual_chart_table":
      return (
        <Dual
          config={config}
          tables={slideData.content?.tables}
          charts={slideData.content?.charts}
          contentKey="dual_chart_table"
        />
      );
    case "dual_table_chart":
      return (
        <Dual
          config={config}
          contentKey="dual_table_chart"
          table={slideData.content?.tables[0]}
          chart={slideData.content?.charts[0]}
        />
      );
    case "single_chart":
      if (slideData.content?.charts.length > 1) {
        const multiCharts = slideData.content?.charts;
        return (
          <SingleChart
            config={config}
            chart={{
              data: {
                type: "multi",
                ...multiCharts.map(i => ({
                  x: i.x,
                  y: i.y,
                  type: i.style.chart_style.type
                }))
              },
              display: {
                ...multiCharts.map(i => ({
                  ...i.style,
                  yAxisTitle: i.y_axis_title
                }))
              },
              isMulti: true
            }}
          />
        );
      }
      if (slideData.content?.charts.length === 0) return null;
      return (
        <SingleChart config={config} chart={slideData.content?.charts[0]} />
      );
    case "kantar_measure_tree":
      return (
        <KantarMeasureTree
          config={config}
          text={slideData.content?.texts}
          chart={slideData.content?.charts[0]}
        />
      );
    case "sub_charts":
      return <SubCharts config={config} charts={slideData.content?.charts} />;
    case "multiple_charts_compare":
      return (
        <MultipleChartsCompare
          config={config}
          charts={slideData.content?.charts}
        />
      );
    case "appendix":
      return <Appendix text={slideData.content?.texts} config={config} />;
    case "content":
      return (
        <Contents
          text={slideData.content?.texts}
          nav={contents}
          config={config}
          story={isFocus ? "focus" : story}
        />
      );
    case "list":
      return <List text={slideData.content?.texts} config={config} />;
    case "double_chart_table":
      return (
        <DoubleChartTable
          table={slideData.content?.tables[0]}
          charts={slideData.content?.charts}
          config={config}
        />
      );
    case "stars":
      return (
        <Stars
          texts={slideData.content?.texts}
          tables={slideData.content?.tables}
          charts={slideData.content?.charts}
          config={config}
        />
      );
    case "prototype_content":
      return <PrototypeContents prototypes={prototypeArray} config={config} />;
    case "prototype_slide":
      return null;
    case "related_content":
      return (
        <RelatedContents
          client={client}
          config={config}
          prototypeArray={prototypeArray}
          navigateToSlide={navigateToSlide}
        />
      );
    default:
      return showNoFormatKey(formatKey, config);
  }
};

const calculateOverflowWidth = isPrototype => {
  const defaultValue = isPrototype ? 0.65 : 1;
  const protoWidth = document.getElementById("report-prototype")?.offsetWidth;
  return protoWidth ? `calc(100% - ${protoWidth}px - 8px)` : defaultValue;
};

const Layout = props => {
  const {
    client,
    formatKey,
    slideData,
    contents,
    story,
    prototypeArray,
    navigateToSlide
  } = props;
  const {
    color = "primary",
    fontFamily = "Inter Variable",
    fontSize = 16,
    chartColors = CHART_COLORS,
    titleColor = "primary",
    sectionColor = "primary",
    alignment = ALIGNMENT
  } = clientConfig[client] || {};
  const config = { color, fontFamily, fontSize, chartColors, alignment };
  const location = useLocation();
  const { isFocus } = getFocusFromQuery(location.search);
  if (formatKey === "title") {
    return showTitleSlide(slideData, titleColor, config, isFocus);
  }
  if (formatKey === "section") {
    return showSectionSlide(slideData, sectionColor, config);
  }
  return (
    <Grid
      container
      rowSpacing={2}
      sx={{
        position: "absolute",
        height: "calc(100% - 48px)",
        width: "calc(100% - 64px)",
        top: 24,
        left: 32
      }}
    >
      <Grid
        item
        xs={12}
        container
        rowSpacing={0.5}
        sx={{ position: "absolute" }}
      >
        <Grid item xs={10.8}>
          <ReportTypography
            fontSize={20}
            fontWeight={500}
            color={color}
            config={config}
          >
            {slideData.heading}
          </ReportTypography>
        </Grid>
        <Grid item xs={1.2} />
        <Grid item xs={slideData.heading.length < 100 ? 11 : 12}>
          <ReportTypography fontSize={14} config={config}>
            {slideData.title}
          </ReportTypography>
        </Grid>
        {slideData.heading.length < 100 && <Grid item xs={1} />}
        {slideData.warning && (
          <Grid item xs={12} sx={{ mt: slideData.title ? -0.5 : 0 }}>
            <ReportTypography
              fontSize={14}
              fontWeight={700}
              color="negativeArray.400"
              config={config}
            >
              {slideData.warning}
            </ReportTypography>
          </Grid>
        )}
        {slideData.info_text && (
          <Grid item xs={12}>
            <Stack alignItems="flex-end">
              <ReportTypography
                fontSize={12}
                color="common.black"
                config={config}
                sx={{
                  px: 1.5,
                  py: 1,
                  bgcolor: "neutralArray.50",
                  border: 1,
                  borderColor: "neutral.main",
                  borderRadius: 1
                }}
              >
                {slideData.info_text}
              </ReportTypography>
            </Stack>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        container
        sx={{
          position: "absolute",
          top:
            (slideData.title && slideData.warning && "22%") ||
            ((slideData.title || slideData.info_text || slideData.warning) &&
              "20%") ||
            (slideData.heading.length > 200 && "15%") ||
            "12%",
          height: slideData.title && slideData.warning ? "70%" : "72%"
        }}
      >
        {showSlide(
          formatKey,
          slideData,
          config,
          story,
          client,
          contents,
          isFocus,
          prototypeArray,
          navigateToSlide
        )}
      </Grid>
      {slideData.overflow && (
        <Grid
          item
          xs={12}
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: calculateOverflowWidth(!!slideData.prototype)
          }}
        >
          <ReportTypography
            fontSize={12}
            color="common.black"
            align="center"
            config={config}
            sx={{
              p: 1,
              bgcolor: "primaryArray.50",
              border: 1,
              borderColor: "primary.main",
              borderRadius: 1
            }}
          >
            {slideData.overflow}
          </ReportTypography>
        </Grid>
      )}
      {slideData.extra_info && (
        <Grid
          item
          xs={12}
          sx={{
            position: "absolute",
            top: "90%",
            width: 1,
            right: slideData.prototype ? "20%" : 0
          }}
        >
          {Array.isArray(slideData.extra_info) ? (
            slideData.extra_info.map(i => (
              <ReportTypography
                key={i}
                fontSize={12}
                color="grey.700"
                align="center"
                config={config}
              >
                {i}
              </ReportTypography>
            ))
          ) : (
            <ReportTypography
              fontSize={14}
              color="grey.700"
              align="center"
              fontStyle="italic"
              config={config}
            >
              {slideData.extra_info}
            </ReportTypography>
          )}
        </Grid>
      )}
      {slideData.prototype && (
        <ReportPrototype
          prototype={slideData.prototype}
          formatKey={formatKey}
        />
      )}
      {getFooter(slideData.footer, config)}
    </Grid>
  );
};

Layout.propTypes = {
  client: PropTypes.string,
  formatKey: PropTypes.string,
  slideData: PropTypes.shape(),
  contents: PropTypes.arrayOf(PropTypes.shape()),
  prototypeArray: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  navigateToSlide: PropTypes.func
};

Layout.defaultProps = {
  client: "",
  formatKey: "",
  slideData: {},
  contents: [],
  prototypeArray: [],
  story: "",
  navigateToSlide: () => {}
};

export default Layout;
