import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import {
  parse,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  addDays,
  addWeeks,
  addMonths,
  isBefore,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths
} from "date-fns";
import { Collapse } from "@mui/material";
import {
  getWeekStart,
  getWeekEnd,
  getDatePeriod,
  getMonthStart,
  getMonthEnd,
  getCompareDates
} from "utils";
import { InfoText } from "components/BaseComponents";
import CustomDatepicker from "./CustomDatepicker";
import CompareOptions from "./CompareOptions";

const CustomDate = props => {
  const { onChange, dataDate, selectedPeriod, periodError, story, isMonth } =
    props;
  const [periodOption, setPeriodOption] = useState("yearAgo");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [customDateError, setCustomDateError] = useState(null);
  const lastValidDate = parse(dataDate, "dd/MM/yy", new Date());
  const lastValidSunday = subDays(lastValidDate, 6);
  const selectedDates = selectedPeriod
    ? selectedPeriod.name.split(",").map((i, k) => {
        if (isMonth) {
          return k % 2 === 0 ? getMonthStart(i) : getMonthEnd(i);
        }
        return k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i);
      })
    : null;

  useEffect(() => {
    if (selectedPeriod) {
      setStartDate(selectedDates[2]);
      setEndDate(selectedDates[3]);
      if (
        (isMonth &&
          differenceInMonths(selectedDates[2], selectedDates[0]) !== 12) ||
        (!isMonth &&
          differenceInWeeks(selectedDates[2], selectedDates[0]) !== 52)
      ) {
        if (
          story === "prr" &&
          differenceInDays(selectedDates[2], selectedDates[1]) === 0
        ) {
          setPeriodOption("justBefore");
        } else {
          setPeriodOption("custom");
          setCustomStartDate(selectedDates[0]);
          setCustomEndDate(selectedDates[1]);
        }
      }
    }
  }, [selectedPeriod]);

  const checkPeriodErrors = (start, end, customStart, customEnd) => {
    if (isMonth) {
      const duration = differenceInMonths(end, start) + 1;
      if (duration > 12) {
        setDateError("The period is longer than 12 months");
      } else if (duration < 1) {
        setDateError("The period is less than 1 month");
      } else if (duration > 0 && duration < 13) {
        setDateError(null);
      } else {
        setDateError("The selected period doesn't make sense");
      }
      if (periodOption === "custom") {
        const customDuration = differenceInMonths(customEnd, customStart) + 1;
        if (duration !== customDuration) {
          setCustomDateError("The selected periods are not of equal length");
        } else {
          setCustomDateError(null);
        }
      }
    } else {
      const duration = differenceInWeeks(end, start) + 1;
      if (duration > 52) {
        setDateError("The period is longer than 52 weeks");
      } else if (duration < 2) {
        setDateError("The period is less than 2 weeks");
      } else if (duration > 1 && duration < 53) {
        setDateError(null);
      } else {
        setDateError("The selected period doesn't make sense");
      }
      if (periodOption === "custom") {
        const customDuration = differenceInWeeks(customEnd, customStart) + 1;
        if (duration !== customDuration) {
          setCustomDateError("The selected periods are not of equal length");
        } else {
          setCustomDateError(null);
        }
      }
    }
  };

  const onSelect = (
    selectedStartDate,
    selectedEndDate,
    selectedPeriodOption,
    oldValue,
    selectedCustomStartDate,
    selectedCustomEndDate
  ) => {
    const diff = isMonth
      ? differenceInMonths(selectedEndDate, selectedStartDate) + 1
      : differenceInWeeks(selectedEndDate, selectedStartDate) + 1;
    if (
      (isMonth && !(diff > 0 && diff < 13)) ||
      (!isMonth && !(diff > 1 && diff < 53))
    ) {
      return;
    }
    if (
      selectedPeriodOption === "custom" &&
      selectedCustomStartDate &&
      selectedCustomEndDate
    ) {
      const compareDiff = isMonth
        ? differenceInMonths(selectedCustomEndDate, selectedCustomStartDate) + 1
        : differenceInWeeks(selectedCustomEndDate, selectedCustomStartDate) + 1;
      if (
        (isMonth && !(compareDiff > 0 && compareDiff < 13)) ||
        (!isMonth && !(compareDiff > 1 && compareDiff < 53))
      ) {
        return;
      }
      if (diff !== compareDiff) {
        return;
      }
    }
    if (oldValue !== undefined) {
      onChange(oldValue, story);
    }
    if (
      selectedPeriodOption === "custom" &&
      !selectedCustomStartDate &&
      !selectedCustomEndDate
    ) {
      setCustomStartDate(null);
      setCustomEndDate(null);
      return;
    }
    const [compareStartDate, compareEndDate] = getCompareDates(
      selectedPeriodOption,
      selectedStartDate,
      selectedEndDate,
      isMonth,
      selectedCustomStartDate,
      selectedCustomEndDate
    );
    onChange(
      {
        name: getDatePeriod(
          selectedStartDate,
          selectedEndDate,
          compareStartDate,
          compareEndDate,
          isMonth
        ),
        subsection: "period",
        table: "when",
        story
      },
      story
    );
  };

  return (
    <Fragment>
      <CustomDatepicker
        dateError={selectedPeriod ? dateError : periodError}
        onChangeStart={date => {
          setStartDate(date);
          if (endDate && periodOption !== "custom") {
            checkPeriodErrors(date, endDate);
            onSelect(date, endDate, periodOption, selectedPeriod);
          } else if (
            endDate &&
            customStartDate &&
            customEndDate &&
            periodOption === "custom"
          ) {
            checkPeriodErrors(date, endDate, customStartDate, customEndDate);
            if (selectedPeriod) {
              // changing period but not custom period -> remove period from search terms
              onSelect(date, endDate, periodOption, selectedPeriod);
            } else {
              onSelect(
                date,
                endDate,
                periodOption,
                selectedPeriod,
                customStartDate,
                customEndDate
              );
            }
          }
        }}
        onChangeEnd={date => {
          setEndDate(date);
          if (periodOption !== "custom") {
            checkPeriodErrors(startDate, date);
            onSelect(startDate, date, periodOption, selectedPeriod);
          } else if (
            customStartDate &&
            customEndDate &&
            periodOption === "custom"
          ) {
            checkPeriodErrors(startDate, date, customStartDate, customEndDate);
            if (selectedPeriod) {
              // changing period but not custom period -> remove period from search terms
              onSelect(startDate, date, periodOption, selectedPeriod);
            } else {
              onSelect(
                startDate,
                date,
                periodOption,
                selectedPeriod,
                customStartDate,
                customEndDate
              );
            }
          }
        }}
        minStartDate={
          isMonth ? subYears(lastValidDate, 3) : subYears(lastValidSunday, 3)
        }
        maxStartDate={
          isMonth ? subMonths(lastValidDate, 1) : subWeeks(lastValidSunday, 1)
        }
        minEndDate={isMonth ? addMonths(startDate, 1) : addWeeks(startDate, 1)}
        maxEndDate={
          (isMonth && isBefore(addMonths(startDate, 12), lastValidDate)
            ? addMonths(startDate, 12)
            : lastValidDate) ||
          (!isMonth && isBefore(addWeeks(startDate, 52), lastValidDate)
            ? addWeeks(startDate, 52)
            : lastValidDate)
        }
        selectedStart={selectedPeriod ? selectedDates[2] : startDate}
        selectedEnd={selectedPeriod ? selectedDates[3] : endDate}
        disabledStart={false}
        disabledEnd={!startDate}
        openToDate={
          !isMonth && startDate ? addDays(addWeeks(startDate, 1), 6) : null
        }
        startDate={startDate}
        endDate={endDate}
        isMonth={isMonth}
      />
      <InfoText
        level="warning"
        text={`The period cannot be less than ${
          isMonth ? "1 month" : "2 weeks"
        } or more than ${isMonth ? "12 months" : "52 weeks"}.`}
        styles={{ mt: 1 }}
      />
      <CompareOptions
        periodOption={periodOption}
        setPeriodOption={setPeriodOption}
        startDate={startDate}
        endDate={endDate}
        checkPeriodErrors={checkPeriodErrors}
        selectedPeriod={selectedPeriod}
        setCustomStartDate={setCustomStartDate}
        setCustomDateError={setCustomDateError}
        setCustomEndDate={setCustomEndDate}
        onSelect={onSelect}
        story={story}
      />
      <Collapse in={periodOption === "custom"} mountOnEnter unmountOnExit>
        <CustomDatepicker
          dateError={customDateError}
          onChangeStart={date => {
            setCustomStartDate(date);
            const customEnd = isMonth
              ? addMonths(date, differenceInMonths(endDate, startDate))
              : addDays(
                  addWeeks(date, differenceInWeeks(endDate, startDate)),
                  6
                );
            setCustomEndDate(customEnd);
            checkPeriodErrors(startDate, endDate, date, customEnd);
            onSelect(
              startDate,
              endDate,
              periodOption,
              selectedPeriod,
              date,
              customEnd
            );
          }}
          onChangeEnd={date => {
            setCustomEndDate(date);
          }}
          minStartDate={
            isMonth ? subYears(lastValidDate, 3) : subYears(lastValidSunday, 3)
          }
          maxStartDate={
            isMonth
              ? subMonths(startDate, differenceInMonths(endDate, startDate) + 1)
              : subWeeks(startDate, differenceInWeeks(endDate, startDate) + 1)
          }
          minEndDate={
            isMonth
              ? addMonths(
                  customStartDate,
                  differenceInMonths(endDate, startDate)
                )
              : addWeeks(customStartDate, differenceInWeeks(endDate, startDate))
          }
          maxEndDate={
            isMonth
              ? addMonths(
                  customStartDate,
                  differenceInMonths(endDate, startDate)
                )
              : addWeeks(customStartDate, differenceInWeeks(endDate, startDate))
          }
          selectedStart={
            selectedPeriod && customStartDate
              ? selectedDates[0]
              : customStartDate
          }
          selectedEnd={
            selectedPeriod && customEndDate ? selectedDates[1] : customEndDate
          }
          disabledStart={periodOption !== "custom"}
          disabledEnd
          openToDate={
            !isMonth && customStartDate
              ? addDays(
                  addWeeks(
                    customStartDate,
                    differenceInWeeks(endDate, startDate)
                  ),
                  6
                )
              : null
          }
          startDate={customStartDate}
          endDate={customEndDate}
          isMonth={isMonth}
        />
        <InfoText
          level="warning"
          text="The periods must have the same duration and cannot overlap."
          styles={{ mt: 1 }}
        />
      </Collapse>
    </Fragment>
  );
};

CustomDate.propTypes = {
  onChange: PropTypes.func,
  dataDate: PropTypes.string,
  selectedPeriod: PropTypes.objectOf(PropTypes.string),
  periodError: PropTypes.string,
  story: PropTypes.string,
  isMonth: PropTypes.bool
};

CustomDate.defaultProps = {
  onChange: () => {},
  dataDate: "",
  selectedPeriod: undefined,
  periodError: "",
  story: "",
  isMonth: false
};

export default CustomDate;
