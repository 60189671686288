import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  generatePath,
  Navigate
} from "react-router-dom";
import _ from "lodash";
import { Button, Typography, Collapse } from "@mui/material";
import { Paper, Table } from "components/BaseComponents";
import {
  SectionTitle,
  NavigateStepButtons,
  Legend
} from "components/CustomComponents";
import { ArrowRightIcon, ArrowDownIcon, RefreshIcon } from "components/Icons";
import { NEW_AUTO_RANGING_ROUTE } from "constants/viewRoutes";
import { AUTO_RANGING_COLORS } from "constants/reportConstants";
import { currentRangeConstraintsAction } from "actions";
import InRangeAction from "./InRangeAction";

const getBgcolor = (index, bayQuantities) => {
  switch (true) {
    case index < bayQuantities[0]:
      return AUTO_RANGING_COLORS[0];
    case index < bayQuantities[1]:
      return AUTO_RANGING_COLORS[1];
    case index < bayQuantities[2]:
      return AUTO_RANGING_COLORS[2];
    case index < bayQuantities[3]:
      return AUTO_RANGING_COLORS[3];
    case index < bayQuantities[4]:
      return AUTO_RANGING_COLORS[4];
    case index < bayQuantities[5]:
      return AUTO_RANGING_COLORS[5];
    default:
      return AUTO_RANGING_COLORS[5];
  }
};

const getBayQuantities = (bayIds, inRangeData) => {
  const eachBayQuantities = bayIds.map(
    id => inRangeData.filter(item => item.bay_id === id).length
  );
  const bayQuantities = eachBayQuantities.map((item, index) =>
    eachBayQuantities.slice(0, index + 1).reduce((a, b) => a + b, 0)
  );
  return bayQuantities;
};

const CurrentRange = props => {
  const {
    currentRangeData,
    dispatchCurrentRangeConstraints,
    currentRangeConstraints,
    bayPlanConstraints,
    editConstraints,
    setActiveStep
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { id: processId, type } = params;
  const [isExpanded, setIsExpanded] = useState(false);

  const { inRange, notInRange } = currentRangeConstraints;

  const {
    current_range: currentRange = [],
    skus_not_in_current_range: rawNotInRangeData = []
  } = currentRangeData;
  const { current_range_table: editCurrentRange = [] } = editConstraints;

  const rawInRangeData = _.isEmpty(currentRange)
    ? editCurrentRange
    : currentRange;

  const bayIds = _.uniq(rawInRangeData.map(({ bay_id: bayId }) => bayId));

  const bays = bayIds.map(id => {
    const bayName =
      rawInRangeData.find(({ bay_id: bayId }) => bayId === id)?.bay_name ?? "";
    return { bayId: id, bayName };
  });

  const bayQuantities = getBayQuantities(bayIds, rawInRangeData);

  const legends = bays.map(({ bayId, bayName }) => ({
    label: bayName,
    color: AUTO_RANGING_COLORS[bayId]
  }));

  useEffect(() => {
    setActiveStep(2);
  }, []);

  const initializeRanges = () => {
    if (_.isEmpty(rawInRangeData)) return;
    const modifiedInRange = rawInRangeData.map(i => ({
      id: _.uniqueId(),
      tag: i.tag,
      bayId: i.bay_id,
      bayName: i.bay_name,
      sku: i.sku,
      salesFormatted: i.sales_formatted,
      sales: i.sales,
      distribution: i.distribution,
      distributionFormatted: i.distribution_formatted
    }));
    const modifiedNotInRange = rawNotInRangeData.map(i => ({
      id: _.uniqueId(),
      tag: i.tag,
      sku: i.sku,
      salesFormatted: i.sales_formatted,
      sales: i.sales,
      distribution: i.distribution,
      distributionFormatted: i.distribution_formatted
    }));
    dispatchCurrentRangeConstraints({
      inRange: modifiedInRange,
      notInRange: modifiedNotInRange
    });
  };

  useEffect(() => {
    if (!_.isEmpty(inRange)) return;
    initializeRanges();
  }, [rawInRangeData, rawNotInRangeData]);

  const onRemove = removed => {
    const isMaxBayPlan = inRange.length > bayQuantities.slice(-1)[0];
    const firstNotInRangeItem = notInRange[0];
    const modifiedInRange = [
      ...inRange.filter(item => item.id !== removed.id),
      ...(firstNotInRangeItem && !isMaxBayPlan ? [firstNotInRangeItem] : [])
    ];
    const modifiedNotInRange = [...notInRange.slice(1, notInRange.length)];
    dispatchCurrentRangeConstraints({
      inRange: modifiedInRange,
      notInRange: modifiedNotInRange
    });
  };

  const onNextClicked = () => {
    const getValue = (item, index) => {
      switch (true) {
        case index < bayQuantities[0]:
          return { ...item, bayId: bays[0].bayId, bayName: bays[0].bayName };
        case index < bayQuantities[1]:
          return { ...item, bayId: bays[1].bayId, bayName: bays[1].bayName };
        case index < bayQuantities[2]:
          return { ...item, bayId: bays[2].bayId, bayName: bays[2].bayName };
        case index < bayQuantities[3]:
          return { ...item, bayId: bays[3].bayId, bayName: bays[3].bayName };
        case index < bayQuantities[4]:
          return { ...item, bayId: bays[4].bayId, bayName: bays[4].bayName };
        case index < bayQuantities[5]:
          return { ...item, bayId: bays[5].bayId, bayName: bays[5].bayName };
        default:
          return item;
      }
    };
    const modifiedInRange = inRange.map((item, index) => getValue(item, index));
    dispatchCurrentRangeConstraints({ inRange: modifiedInRange, notInRange });
    navigate(
      generatePath(NEW_AUTO_RANGING_ROUTE, {
        type,
        id: processId,
        step: "review"
      })
    );
  };

  if (_.isEmpty(bayPlanConstraints)) {
    return (
      <Navigate
        to={generatePath(NEW_AUTO_RANGING_ROUTE, {
          type,
          id: processId,
          step: "basics"
        })}
      />
    );
  }

  const isTotalQuantityValid = inRange.length === rawInRangeData.length;

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="6"
          title="Current Range"
          subtitle="Review the current range by Bay Plan. We rank skus based on their distribution. If you want to edit the number of skus in each bay plan, go back to the previous step. Note that only products shown in the current range can be included in the Auto-Range."
        />
        <Button
          variant="soft"
          color="secondary"
          size="small"
          sx={{ mt: -2, mb: 1 }}
          startIcon={<RefreshIcon />}
          onClick={initializeRanges}
        >
          Reset table data
        </Button>
        <Legend items={legends} styles={{ mb: 1.5 }} />
        <Table
          columns={[
            {
              label: "Sku Rank by Distribution",
              styles: { width: 160, px: 0 },
              renderCell: (item, index) => index + 1
            },
            {
              label: "SKU",
              styles: { textAlign: "left" },
              renderCell: item => item.sku
            },
            {
              label: "Sales",
              styles: { width: 150 },
              renderCell: item => item.salesFormatted
            },
            {
              label: "Distribution",
              styles: { width: 150 },
              renderCell: item => item.distributionFormatted
            },
            {
              label: "Actions",
              styles: { width: 120, py: 0 },
              renderCell: item => (
                <InRangeAction
                  item={item}
                  onRemove={onRemove}
                  disabled={notInRange.length === 0}
                />
              )
            }
          ]}
          rows={inRange.map((item, index) => ({
            ...item,
            styles: {
              bgcolor: `${getBgcolor(index, bayQuantities)}60`,
              "& .MuiTableCell-root": { py: 0.5 }
            }
          }))}
          styles={{ head: { "& .MuiTableCell-root": { py: 1 } } }}
        />
        <Button
          variant="outlined"
          size="large"
          color="disabled"
          endIcon={isExpanded ? <ArrowDownIcon /> : <ArrowRightIcon />}
          sx={{
            width: 1,
            mt: 1.5,
            mb: 1,
            "&, &:hover": { color: "common.black" },
            justifyContent: "space-between"
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          SKUs not in Current Range
        </Button>
        <Collapse in={isExpanded}>
          <Table
            size="small"
            rows={notInRange}
            columns={[
              {
                label: "Sku Rank by Distribution",
                styles: { width: 160, px: 0 },
                renderCell: (item, index) => index + 1
              },
              {
                label: "SKU",
                styles: { textAlign: "left" },
                renderCell: item => item.sku
              },
              {
                label: "Sales",
                styles: { width: 150 },
                renderCell: item => item.salesFormatted
              },
              {
                label: "Distribution",
                styles: { width: 150 },
                renderCell: item => item.distributionFormatted
              },
              { label: "", styles: { width: 120 }, renderCell: () => null }
            ]}
          />
        </Collapse>
        {!isTotalQuantityValid && (
          <Typography variant="body1" color="negative.main">
            * Total number of SKUs in current bay plans must match the inputs on
            the previous page. There are {inRange.length} SKUs listed here, but
            you input {rawInRangeData.length} on the Range Sizes section. Change
            the number included here, or go back and change the range sizes.
          </Typography>
        )}
      </Paper>
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_AUTO_RANGING_ROUTE, {
              type,
              id: processId,
              step: "range-sizes"
            })
          )
        }
        onNextClick={onNextClicked}
        nextDisabled={!isTotalQuantityValid}
      />
    </Fragment>
  );
};

CurrentRange.propTypes = {
  currentRangeData: PropTypes.shape(),
  dispatchCurrentRangeConstraints: PropTypes.func,
  currentRangeConstraints: PropTypes.shape(),
  bayPlanConstraints: PropTypes.arrayOf(PropTypes.shape()),
  editConstraints: PropTypes.shape(),
  setActiveStep: PropTypes.func
};

CurrentRange.defaultProps = {
  currentRangeData: {},
  dispatchCurrentRangeConstraints: () => {},
  currentRangeConstraints: {},
  bayPlanConstraints: [],
  editConstraints: {},
  setActiveStep: () => {}
};

const mapStateToProps = state => ({
  currentRangeData: state.autoRanging.currentRangeStaticData.data,
  currentRangeConstraints: state.autoRanging.currentRangeConstraints,
  bayPlanConstraints: state.autoRanging.bayPlanConstraints,
  editConstraints: state.autoRanging.editConstraints.data
});

const mapDispatchToProps = dispatch => ({
  dispatchCurrentRangeConstraints: constraints =>
    dispatch(currentRangeConstraintsAction(constraints))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentRange);
