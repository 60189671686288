import React from "react";
import PropTypes from "prop-types";
import { Button, Chip, Stack, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";
import { EditIcon } from "components/Icons";

const ReviewCard = props => {
  const { label, selected, onEdit } = props;
  return (
    <Paper styles={{ height: 1 }}>
      <Stack direction="row" justifyContent="space-between" sx={{ height: 1 }}>
        <Stack spacing={1}>
          <Typography variant="h6">{label}</Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ columnGap: 1.5, rowGap: 1 }}
          >
            {selected.map(product => (
              <Chip key={product} label={product} />
            ))}
          </Stack>
        </Stack>
        <Button
          variant="outlined"
          size="small"
          onClick={onEdit}
          endIcon={<EditIcon />}
          sx={{ alignSelf: "center" }}
        >
          Edit
        </Button>
      </Stack>
    </Paper>
  );
};

ReviewCard.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  onEdit: PropTypes.func
};

ReviewCard.defaultProps = {
  selected: [],
  label: "",
  onEdit: () => {}
};

export default ReviewCard;
