import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Collapse, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowRightIcon } from "components/Icons";
import {
  PasswordField,
  SelectAutocomplete,
  TextField
} from "components/BaseComponents";
import { FORGOT_PASSWORD_ROUTE } from "constants/viewRoutes";
import { alertLevels } from "constants/enums";
import {
  signIn,
  getClients,
  addNotification,
  clearSettingsMessages
} from "actions";
import Layout from "./Layout";

const Login = props => {
  const {
    isUser,
    error,
    dispatchSignIn,
    isLoading,
    clients,
    dispatchGetClients,
    dispatchAddNotification,
    dispatchClearErrors,
    isLoadingClients
  } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [client, setClient] = useState(null);
  const isDijunoUser =
    email.includes("@interrodata.com") || email.includes("@dijuno.ai");
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { from } = state || { from: { pathname: "/" } };

  useEffect(() => {
    if (isUser) {
      navigate(from);
    }
  }, [isUser]);

  useEffect(() => {
    if (isDijunoUser) {
      dispatchGetClients();
    }
  }, [isDijunoUser, dispatchGetClients]);

  useEffect(() => {
    if (error) dispatchAddNotification(error);
    return () => {
      dispatchClearErrors();
    };
  }, [error, dispatchAddNotification, dispatchClearErrors]);

  return (
    <Layout>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h2">Welcome back</Typography>
          <Typography variant="subtitle1" color="grey.500">
            Please enter your credentials.
          </Typography>
        </Stack>
        <TextField
          required
          placeholder="Enter your email"
          label="Email"
          type="email"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          fullWidth
          inputStyles={{ "& .MuiInputBase-input": { height: 28 } }}
        />
        <Stack sx={{ position: "relative" }}>
          <PasswordField
            required
            placeholder="Enter your password"
            name="password"
            label="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            fullWidth
            inputStyles={{ "& .MuiInputBase-input": { height: 28 } }}
          />
          <Button
            size="small"
            onClick={() => navigate(FORGOT_PASSWORD_ROUTE)}
            disableRipple
            disableFocusRipple
            sx={{
              fontSize: 12,
              p: 0,
              textDecoration: "underline",
              "&:hover": { bgcolor: "transparent" },
              position: "absolute",
              right: 0
            }}
          >
            Forgot your password?
          </Button>
        </Stack>
        <Collapse in={isDijunoUser}>
          <SelectAutocomplete
            id="client-select" // for load-runner
            required
            placeholder="Select your client"
            name="client"
            label="Client"
            value={client}
            onChange={(e, newValue) => setClient(newValue)}
            options={clients}
            fullWidth
            isUpdating={isLoadingClients}
            styles={{ "& .MuiInputBase-input": { height: 28 }, mb: 1 }}
          />
        </Collapse>
        <LoadingButton
          color="secondary"
          loadingPosition="end"
          fullWidth
          endIcon={<ArrowRightIcon />}
          variant="contained"
          size="large"
          loading={isLoading}
          disabled={!email || !password || (isDijunoUser && !client)}
          onClick={() => dispatchSignIn(email, password, client)}
        >
          Sign In
        </LoadingButton>
      </Stack>
    </Layout>
  );
};

Login.propTypes = {
  dispatchSignIn: PropTypes.func,
  isUser: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  dispatchGetClients: PropTypes.func,
  dispatchAddNotification: PropTypes.func,
  dispatchClearErrors: PropTypes.func,
  clients: PropTypes.arrayOf(PropTypes.string),
  isLoadingClients: PropTypes.bool
};

Login.defaultProps = {
  dispatchSignIn: () => {},
  isUser: false,
  error: "",
  isLoading: false,
  dispatchGetClients: () => {},
  dispatchAddNotification: () => {},
  dispatchClearErrors: () => {},
  clients: [],
  isLoadingClients: false
};

const mapStateToProps = state => ({
  isUser: state.user.isUser,
  error: state.user.error,
  isLoading: state.user.isLoading,
  clients: state.user.clients,
  isLoadingClients: state.user.isLoadingClients
});

const mapDispatchToProps = dispatch => ({
  dispatchSignIn: (email, password, client) => {
    dispatch(signIn(email, password, client));
  },
  dispatchGetClients: () => dispatch(getClients()),
  dispatchAddNotification: errorMessage =>
    dispatch(addNotification(errorMessage, alertLevels.ERROR)),
  dispatchClearErrors: () => dispatch(clearSettingsMessages())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
