import React from "react";
import PropTypes from "prop-types";
import { Slider as MUISlider } from "@mui/material";

const Slider = props => {
  const { styles, value, onChange, min, max, step, marks, valueLabelFormat } =
    props;
  return (
    <MUISlider
      value={value}
      onChange={onChange}
      valueLabelDisplay="auto"
      valueLabelFormat={valueLabelFormat}
      marks={marks}
      min={min}
      max={max}
      step={step}
      sx={{
        "& .MuiSlider-markLabel": { color: "grey.500" },
        ...styles
      }}
    />
  );
};

Slider.propTypes = {
  styles: PropTypes.shape(),
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  marks: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  valueLabelFormat: PropTypes.func
};

Slider.defaultProps = {
  styles: {},
  value: 0,
  onChange: () => {},
  min: 0,
  max: 100,
  step: 1,
  marks: false,
  valueLabelFormat: x => x
};

export default Slider;
