import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  useNavigate,
  generatePath,
  useParams,
  Navigate
} from "react-router-dom";
import { Paper } from "components/BaseComponents";
import {
  NavigateStepButtons,
  SectionTitle,
  SearchBar,
  AdvancedSearch
} from "components/CustomComponents";
import { filterStaticData, reportConstraints } from "actions";
import { NEW_REPORT_TYPE_ROUTE } from "constants/viewRoutes";
import { BB_PRODUCTS } from "constants";

const Context = props => {
  const {
    subsections,
    isFilteredData,
    story,
    client,
    dispatchFilterStaticData,
    isFilteringData,
    dispatchReportConstraints,
    setActiveStep,
    productA,
    productB,
    context,
    reportType
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { channel } = params;

  useEffect(() => {
    setActiveStep(1);
  }, []);

  useEffect(() => {
    // get initial filter terms
    if (!productA || !productB) return;
    const filterTerms = [productA, productB];
    const apiTerms = filterTerms.map(i => i.apiValue);
    dispatchFilterStaticData(null, apiTerms, story, client);
  }, []);

  const handleContext = value => {
    dispatchReportConstraints("context", value, story);
    const filterTerms = [...value, productA, productB];
    const apiTerms = filterTerms.map(i => i.apiValue);
    dispatchFilterStaticData(null, apiTerms, story, client);
  };

  if (!productA || !productB) {
    return (
      <Navigate
        to={generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType,
          channel,
          step: "product"
        })}
      />
    );
  }

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="3"
          title="Product Filter"
          subtitle="Enter the category/sector and any other attributes that you would like to compare these products within."
        />
        <SearchBar
          label="Product Filter"
          value={context}
          onChange={(e, value) => handleContext(value)}
          options={subsections.filter(i => !BB_PRODUCTS.includes(i.name))}
          multiple
          disabled={isFilteringData}
          styles={{ mb: 2 }}
          isLoading={!isFilteredData}
          isUpdating={isFilteringData}
          infoText="What you select here will become the scope of the report. You can enter multiple attributes."
          story={story}
        />
        <AdvancedSearch
          fields={subsections.filter(i => !BB_PRODUCTS.includes(i.name))}
          isLoading={isFilteringData}
          selected={context}
          onChange={(value, subsection) =>
            handleContext(value, true, subsection)
          }
          story={story}
        />
      </Paper>
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "product"
            })
          )
        }
        onNextClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "market"
            })
          )
        }
        nextDisabled={context.length === 0}
      />
    </Fragment>
  );
};

Context.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  isFilteredData: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  dispatchFilterStaticData: PropTypes.func,
  client: PropTypes.string,
  story: PropTypes.string,
  dispatchReportConstraints: PropTypes.func,
  setActiveStep: PropTypes.func,
  productA: PropTypes.shape(),
  productB: PropTypes.shape(),
  context: PropTypes.arrayOf(PropTypes.shape()),
  reportType: PropTypes.string
};

Context.defaultProps = {
  subsections: [],
  isFilteredData: false,
  isFilteringData: false,
  dispatchFilterStaticData: () => {},
  client: "",
  story: "",
  dispatchReportConstraints: () => {},
  setActiveStep: () => {},
  productA: null,
  productB: null,
  context: [],
  reportType: ""
};

const mapStateToProps = (state, ownProps) => {
  const { story } = ownProps;
  const {
    search: {
      filteredSubsections = { story: [] },
      isFilteredData = { story: false }
    }
  } = state;
  return {
    isFilteringData: state.search.isFilteringData,
    client: state.user.user.client,
    subsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story]
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client)),

  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story))
});

export default connect(mapStateToProps, mapDispatchToProps)(Context);
