import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton, Typography, Stack } from "@mui/material";
import { goToSlide } from "../../actions";
import { RightIcon } from "../Icons";

const PresentationNavRight = props => {
  const { activeSlideIndex, toSlide, report, setIsNavigation } = props;
  const slideCount = report.length;
  const isLastSlide = activeSlideIndex + 1 >= slideCount;
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      className="PresentationNavRight"
      sx={{ display: isLastSlide && "none" }}
      spacing={0.5}
    >
      <IconButton
        id="presentation-next"
        color="secondary"
        sx={{ bgcolor: "grey.200", "&:hover": { bgcolor: "secondary.main" } }}
        onClick={() => {
          toSlide(activeSlideIndex + 1, report, slideCount);
          setIsNavigation(true);
        }}
        disabled={
          isLastSlide ||
          (activeSlideIndex + 2 === slideCount &&
            report[activeSlideIndex + 1].hidden)
        }
      >
        <RightIcon fontSize="small" sx={{ color: "white" }} />
      </IconButton>
      <Typography variant="caption" color="grey.500">
        Next
      </Typography>
    </Stack>
  );
};

PresentationNavRight.propTypes = {
  activeSlideIndex: PropTypes.number,
  toSlide: PropTypes.func,
  report: PropTypes.arrayOf(PropTypes.shape()),
  setIsNavigation: PropTypes.func
};

PresentationNavRight.defaultProps = {
  activeSlideIndex: 0,
  toSlide: () => {},
  report: [],
  setIsNavigation: () => {}
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toSlide: (slideIndex, report, slideCount) => {
    const { reportId, userId, story, client } = ownProps;
    dispatch(
      goToSlide(
        slideIndex,
        report,
        "next",
        slideCount,
        reportId,
        userId,
        story,
        client
      )
    );
  }
});

export { PresentationNavRight as PresentationNavRightComponent };
export default connect(null, mapDispatchToProps)(PresentationNavRight);
