import React from "react";
import PropTypes from "prop-types";
import { DataGrid as MUIDataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { DownloadIcon, FilterIcon } from "components/Icons";
import { DataGridFooter } from "./Internal";

const FilterButtonIcon = () => <FilterIcon sx={{ fontSize: "inherit" }} />;

const DataGrid = props => {
  const { rows, columns, styles, isLoading, ...otherProps } = props;
  const { slots = {}, ...others } = otherProps;
  return (
    <MUIDataGrid
      sx={{
        border: "none",
        fontSize: 12,
        "& .MuiDataGrid-main": {
          borderRadius: 1,
          border: 1,
          borderColor: "grey.200"
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 400,
          whiteSpace: "normal",
          lineHeight: 1.2
        },
        "& .MuiDataGrid-columnHeader": {
          "&:not(:last-child)": { borderRight: 1, borderColor: "grey.200" },
          "&:focus": { outline: "none" }
        },
        "& .MuiDataGrid-columnSeparator": { display: "none" },
        "& .MuiDataGrid-row": { "&:nth-of-type(odd)": { bgcolor: "grey.100" } },
        "& .MuiDataGrid-cell": { borderBottom: 0 },
        // pagination
        "& .MuiTablePagination-toolbar": { padding: 0 },
        "& .MuiTablePagination-spacer": { display: "none" },
        ...styles
      }}
      rows={rows}
      columns={columns}
      loading={isLoading}
      autoHeight
      disableColumnMenu
      slots={{
        loadingOverlay: LinearProgress,
        footer: DataGridFooter,
        exportIcon: DownloadIcon,
        openFilterButtonIcon: FilterButtonIcon,
        ...slots
      }}
      {...others}
    />
  );
};

DataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape()),
  styles: PropTypes.shape(),
  isLoading: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center", "right"])
};

DataGrid.defaultProps = {
  rows: [],
  columns: [],
  styles: {},
  isLoading: false,
  align: "left"
};

export default DataGrid;
