import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import ReportTable from "../ReportTable";
import ReportChart from "../ReportChart";
import ReportTypography from "../ReportTypography";

const getChildren = (contentKey, content, config) => {
  let leftChild;
  let rightChild;
  if (contentKey === "dual_table") {
    leftChild = (
      <Box sx={{ height: 1, width: 1 }}>
        <ReportTypography
          textAlign="center"
          fontSize={14}
          config={config}
          sx={{ mb: 2 }}
        >
          {content.tables[0].title}
        </ReportTypography>
        <ReportTable
          data={{
            columns: content.tables[0].columns,
            index: content.tables[0].index,
            values: content.tables[0].data
          }}
          display={{
            gradient: content.tables[0].gradient,
            highlight: content.tables[0].tp_highlight,
            isSmall: true
          }}
          config={config}
        />
      </Box>
    );
    rightChild = (
      <Box sx={{ height: 1, width: 1 }}>
        <ReportTypography
          textAlign="center"
          fontSize={14}
          config={config}
          sx={{ mb: 2 }}
        >
          {content.tables[1].title}
        </ReportTypography>
        <ReportTable
          data={{
            columns: content.tables[1].columns,
            index: content.tables[1].index,
            values: content.tables[1].data
          }}
          display={{
            gradient: content.tables[1].gradient,
            highlight: content.tables[1].tp_highlight,
            isSmall: true
          }}
          config={config}
        />
      </Box>
    );
  } else if (contentKey === "dual_table_chart") {
    leftChild = (
      <Box sx={{ height: 1, width: 1 }}>
        <ReportTypography
          textAlign="center"
          fontSize={14}
          config={config}
          gutterBottom
        >
          {content.table.title}
        </ReportTypography>
        <ReportTable
          data={{
            columns: content.table.columns,
            index: content.table.index,
            values: content.table.data
          }}
          display={{
            gradient: content.table.gradient,
            highlight: content.table.tp_highlight,
            isSmall: true
          }}
          config={config}
        />
      </Box>
    );
    rightChild = (
      <Box sx={{ height: 1, width: 1 }}>
        <ReportTypography
          textAlign="center"
          fontSize={14}
          config={config}
          gutterBottom
        >
          {content.chart.title}
        </ReportTypography>
        <Box sx={{ position: "relative", width: 1, height: 350 }}>
          <ReportChart
            data={{
              x: content.chart.x,
              y: content.chart.y,
              type: content.chart.style.chart_style.type
            }}
            display={{
              ...content.chart.style,
              title: content.chart.title,
              isSmall: true
            }}
            layout={{ height: 100, width: 100, top: 0, left: 0 }}
            config={config}
          />
        </Box>
      </Box>
    );
  } else if (contentKey === "dual_chart_table") {
    leftChild = (
      <Box sx={{ height: 1, width: 1 }}>
        <Box sx={{ height: 50 }}>
          <ReportTypography
            textAlign="center"
            fontSize={14}
            config={config}
            gutterBottom
          >
            {content.charts[0].title}
          </ReportTypography>
        </Box>
        <Box sx={{ position: "relative", width: 1, height: 250 }}>
          <ReportChart
            data={{
              x: content.charts[0].x,
              y: content.charts[0].y,
              type: content.charts[0].style.chart_style.type
            }}
            display={{
              ...content.charts[0].style,
              title: content.charts[0].title,
              isSmall: true
            }}
            layout={{ height: 100, width: 100, top: 0, left: 0 }}
            config={config}
          />
        </Box>
        <ReportTable
          data={{
            columns: content.tables[0].columns,
            index: content.tables[0].index,
            values: content.tables[0].data
          }}
          display={{
            gradient: content.tables[0].gradient,
            highlight: content.tables[0].tp_highlight,
            isSmall: true
          }}
          config={config}
        />
      </Box>
    );
    const tableWidth = (content.tables[1]?.columns?.length || 0) + 1;
    const align = content.charts[1]?.style?.chart_style?.is_aligned;
    rightChild = (
      <Box sx={{ height: 1, width: 1 }}>
        <Box sx={{ height: 50 }}>
          <ReportTypography
            textAlign="center"
            fontSize={14}
            config={config}
            gutterBottom
          >
            {content.charts[1].title}
          </ReportTypography>
        </Box>
        <Box sx={{ position: "relative", width: 1, height: 250 }}>
          <ReportChart
            data={{
              x: content.charts[1].x,
              y: content.charts[1].y,
              type: content.charts[1].style.chart_style.type
            }}
            display={{
              ...content.charts[1].style,
              title: content.charts[1].title,
              isSmall: true,
              align
            }}
            layout={{
              height: 100,
              width: align ? 100 - 100 / tableWidth : 100,
              top: 0,
              left: align ? 100 / tableWidth : 0
            }}
            config={config}
          />
        </Box>
        <ReportTable
          data={{
            columns: content.tables[1].columns,
            index: content.tables[1].index,
            values: content.tables[1].data
          }}
          display={{
            gradient: content.tables[1].gradient,
            highlight: content.tables[1].tp_highlight,
            isSmall: true
          }}
          config={config}
        />
      </Box>
    );
  }
  return { leftChild, rightChild };
};

const Dual = props => {
  const { chart, table, config, contentKey, tables, charts } = props;
  const content = { chart, table, tables, charts };
  const { leftChild, rightChild } = getChildren(contentKey, content, config);
  return (
    <Box sx={{ height: 1, width: 1, mx: 2 }}>
      <Grid container columnSpacing={3} alignItems="space-between">
        <Grid item xs={6}>
          {leftChild}
        </Grid>
        <Grid item xs={6}>
          {rightChild}
        </Grid>
      </Grid>
    </Box>
  );
};

Dual.propTypes = {
  chart: PropTypes.shape(),
  table: PropTypes.shape(),
  config: PropTypes.shape(),
  contentKey: PropTypes.string,
  tables: PropTypes.arrayOf(PropTypes.shape()),
  charts: PropTypes.arrayOf(PropTypes.shape())
};

Dual.defaultProps = {
  chart: {},
  table: {},
  config: {},
  contentKey: "",
  tables: [],
  charts: []
};

export default Dual;
