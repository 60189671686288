import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Box, Stack, Typography, Button, Skeleton, Link } from "@mui/material";
import { getLookout, getPreferences } from "actions";
import { getLookoutDate } from "utils";
import { clientConfig, getChannelConfig } from "config";
import { LOOKOUT_ROUTE, LOOKOUT_PREFERENCES_ROUTE } from "constants/viewRoutes";
import { lookout, lookoutLogo } from "images";
import { EditIcon, InHomeIcon, OutOfHomeIcon } from "components/Icons";
import { Paper, Tabs } from "components/BaseComponents";
import UpdateDate from "./UpdateDate";
import Insights from "./Insights";

const tabItems = [
  { label: "In Home", value: "ih", icon: <InHomeIcon /> },
  { label: "Out Of Home", value: "ooh", icon: <OutOfHomeIcon /> }
];

const Lookout = props => {
  const {
    preferences,
    dataDate,
    lookoutData,
    isLookoutData,
    getLookoutData,
    getPreferenceList,
    client
  } = props;
  const { channel } = useParams();
  const navigate = useNavigate();
  const { dataSet, isMonth } = getChannelConfig(channel);
  const { channels = [], name = "" } = clientConfig[client] || {};
  const homeTabs = tabItems.filter(i => channels.includes(i.value));
  const isPreference = preferences[channel] && preferences[channel].length > 0;

  useEffect(() => {
    if (!isPreference) {
      getPreferenceList();
    }
    if (!isLookoutData) {
      getLookoutData(dataDate, client);
    }
  }, []);

  useEffect(() => {
    if (!isLookoutData) {
      getLookoutData(dataDate, client);
    }
  }, [dataDate]);

  const renderLookoutDate = () =>
    !isLookoutData ? (
      <Skeleton variant="rounded" width="150px" height="30px" />
    ) : (
      <Typography variant="body2" color="grey.700">
        {channel === "ih" ? "L4W" : "L1M"} (
        {getLookoutDate(dataDate[dataSet], isMonth)})
      </Typography>
    );

  const insights = lookoutData[channel];
  const userInsights = insights?.user?.insight || [];
  const clientInsights = insights?.totalClient?.insight || [];

  return (
    <Paper>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <img
            alt="lookout"
            src={lookout}
            style={{ height: 40, marginBottom: 8 }}
          />
          <Typography variant="body1" color="grey.500">
            What you need to know without even asking a question!
          </Typography>
        </Box>
        <Stack alignItems="flex-end" spacing={1}>
          <UpdateDate dataDate={dataDate} client={client} />
          <Button
            variant="outlined"
            onClick={() =>
              navigate(generatePath(LOOKOUT_PREFERENCES_ROUTE, { channel }))
            }
          >
            Your Preferences
          </Button>
        </Stack>
      </Stack>
      <Tabs
        activeTab={channel}
        onChange={(e, value) =>
          navigate(generatePath(LOOKOUT_ROUTE, { channel: value }))
        }
        tabs={homeTabs}
        styles={{ mb: 2 }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ mb: 1.5 }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <img alt="lookout" src={lookoutLogo} style={{ width: 40 }} />
          <Typography variant="subtitle2" color="grey.800">
            Your Top Insights
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ mt: -0.5 }}
        >
          {renderLookoutDate()}
          <Button
            size="small"
            variant="soft"
            endIcon={<EditIcon />}
            onClick={() =>
              navigate(generatePath(LOOKOUT_PREFERENCES_ROUTE, { channel }))
            }
          >
            Edit Preferences
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={!isLookoutData || clientInsights.length === 0}
            component={Link}
            href="#client-insights"
          >
            Top {name} Insights
          </Button>
        </Stack>
      </Stack>
      <Insights
        userInsights={userInsights}
        clientInsights={clientInsights}
        channel={channel}
        isPreference={preferences?.[channel]?.length > 0}
        client={client}
        isLoading={!isLookoutData}
        dataDate={dataDate}
        clientName={name}
      />
    </Paper>
  );
};

Lookout.propTypes = {
  preferences: PropTypes.shape(),
  dataDate: PropTypes.shape(),
  lookoutData: PropTypes.shape(),
  isLookoutData: PropTypes.bool,
  getLookoutData: PropTypes.func,
  getPreferenceList: PropTypes.func,
  client: PropTypes.string
};

Lookout.defaultProps = {
  preferences: {},
  dataDate: {},
  lookoutData: {},
  isLookoutData: false,
  getLookoutData: () => {},
  getPreferenceList: () => {},
  client: ""
};

const mapStateToProps = state => ({
  preferences: state.lookout.preferences,
  dataDate: state.data.date,
  lookoutData: state.lookout.lookout,
  isLookoutData: state.lookout.isLookout,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  getLookoutData: (dataDate, client) => dispatch(getLookout(dataDate, client)),
  getPreferenceList: () => dispatch(getPreferences())
});

export default connect(mapStateToProps, mapDispatchToProps)(Lookout);
