import React, { useState } from "react";
import PropTypes from "prop-types";
import { SelectGroup } from "components/BaseComponents";

const compareItems = story =>
  [
    {
      label: "Same period a year ago",
      id: "yearAgo",
      isVisible: true,
      default: true
    },
    {
      label: "Period immediately before the Post Range Review period",
      id: "justBefore",
      isVisible: story === "prr"
    },
    { label: "Custom date", id: "custom", isVisible: true }
  ].filter(i => i.isVisible);

const CompareOptions = props => {
  const {
    periodOption,
    setPeriodOption,
    startDate,
    endDate,
    checkPeriodErrors,
    selectedPeriod,
    story,
    setCustomStartDate,
    setCustomDateError,
    setCustomEndDate,
    onSelect
  } = props;
  const [compareOptions] = useState(compareItems(story));

  const handleChange = item => {
    const { id } = item;
    if (periodOption === "custom") {
      setCustomStartDate(null);
      setCustomEndDate(null);
      setCustomDateError(null);
    } else {
      checkPeriodErrors(startDate, endDate);
    }
    setPeriodOption(id);
    onSelect(startDate, endDate, id, selectedPeriod);
  };

  return (
    <SelectGroup
      options={compareOptions}
      onClick={handleChange}
      disabled={!startDate || !endDate}
      isIcon
      controlled
      selected={periodOption}
      label="Please choose a reference period to compare against"
      styles={{ py: 3 }}
    />
  );
};

CompareOptions.propTypes = {
  periodOption: PropTypes.string,
  setPeriodOption: PropTypes.func,
  startDate: PropTypes.shape(),
  endDate: PropTypes.shape(),
  checkPeriodErrors: PropTypes.func,
  selectedPeriod: PropTypes.objectOf(PropTypes.string),
  setCustomStartDate: PropTypes.func,
  setCustomDateError: PropTypes.func,
  setCustomEndDate: PropTypes.func,
  onSelect: PropTypes.func,
  story: PropTypes.string
};

CompareOptions.defaultProps = {
  periodOption: "",
  setPeriodOption: () => {},
  startDate: {},
  endDate: {},
  checkPeriodErrors: () => {},
  selectedPeriod: undefined,
  setCustomStartDate: () => {},
  setCustomDateError: () => {},
  setCustomEndDate: () => {},
  onSelect: () => {},
  story: ""
};

export default CompareOptions;
