import _ from "lodash";
import defaultState from "./defaultState";
import {
  FETCH_STATIC_DATA_REQUEST,
  FETCH_STATIC_DATA_SUCCESS,
  FETCH_STATIC_DATA_ERROR,
  CLEAR_STATIC_DATA_CACHE,
  SET_DATA_REFRESH,
  SET_ALL_DATA_REFRESH,
  CLEAR_DATA_REFRESH,
  CLEAR_ALL_DATA_REFRESH
} from "../constants/actionConstants";
import { dataSetStoryMapping } from "../config";

// eslint-disable-next-line default-param-last
export default (state = defaultState.data, action) => {
  switch (action.type) {
    case FETCH_STATIC_DATA_REQUEST: {
      return {
        ...state,
        isFetchingData: true,
        isStaticData: false
      };
    }

    case FETCH_STATIC_DATA_SUCCESS: {
      return {
        ...state,
        isFetchingData: false,
        subsections: action.subsections,
        isStaticData: true,
        date: action.date,
        placeholder: action.placeholder
      };
    }

    case FETCH_STATIC_DATA_ERROR: {
      return {
        ...state,
        isFetchingData: false,
        isStaticData: false
      };
    }

    case CLEAR_STATIC_DATA_CACHE: {
      return {
        ...state,
        isFetchingData: false,
        isStaticData: false,
        subsections: {},
        placeholder: {},
        date: {}
      };
    }

    case SET_DATA_REFRESH: {
      const updatingDataSets = _.uniq([
        ...state.updatingDataSets,
        action.dataSet
      ]);
      let updatingStories = [];
      for (let i = 0; i < updatingDataSets.length; i += 1) {
        updatingStories = updatingStories.concat(
          dataSetStoryMapping[updatingDataSets[i]]
        );
      }
      return {
        ...state,
        updatingDataSets,
        updatingStories: _.uniq(updatingStories),
        isUpdatingData: true
      };
    }

    case SET_ALL_DATA_REFRESH: {
      const updatingDataSets = _.uniq([
        ...state.updatingDataSets,
        ...action.dataSets
      ]);
      let updatingStories = [];
      for (let i = 0; i < updatingDataSets.length; i += 1) {
        updatingStories = updatingStories.concat(
          dataSetStoryMapping[updatingDataSets[i]]
        );
      }
      return {
        ...state,
        updatingDataSets,
        updatingStories: _.uniq(updatingStories),
        isUpdatingData: true
      };
    }

    case CLEAR_DATA_REFRESH: {
      const updatingDataSets = state.updatingDataSets.filter(
        i => !_.isEqual(i, action.dataSet)
      );
      let updatingStories = [];
      for (let i = 0; i < updatingDataSets.length; i += 1) {
        updatingStories = updatingStories.concat(
          dataSetStoryMapping[updatingDataSets[i]]
        );
      }
      return {
        ...state,
        updatingDataSets,
        updatingStories: _.uniq(updatingStories),
        isUpdatingData: updatingDataSets.length > 0
      };
    }

    case CLEAR_ALL_DATA_REFRESH: {
      return {
        ...state,
        updatingDataSets: [],
        updatingStories: [],
        isUpdatingData: false
      };
    }

    default: {
      return state;
    }
  }
};
