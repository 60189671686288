import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { TextField } from "components/BaseComponents";
import ChannelEnvironment from "../ChannelEnvironment";

const Environment = props => {
  const { name, setName, channel, setChannel, environment, setEnvironment } =
    props;

  return (
    <Stack spacing={2}>
      <TextField
        label="Sheet Name"
        placeholder="Sheet Name"
        value={name}
        onChange={e => setName(e.target.value)}
        fullWidth
        styles={{ maxWidth: 400 }}
      />
      <ChannelEnvironment
        channel={channel}
        setChannel={setChannel}
        environment={environment}
        setEnvironment={setEnvironment}
      />
    </Stack>
  );
};

Environment.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  channel: PropTypes.string,
  setChannel: PropTypes.func,
  environment: PropTypes.string,
  setEnvironment: PropTypes.func
};

Environment.defaultProps = {
  name: "",
  setName: () => {},
  channel: "",
  setChannel: () => {},
  environment: null,
  setEnvironment: () => {}
};

export default Environment;
