import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Box, Stack, Collapse } from "@mui/material";
import { DownIcon, UpIcon } from "components/Icons";

const Expand = props => {
  const { styles, label, children } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Stack spacing={1} sx={styles}>
      <Box
        sx={{
          bgcolor: "grey.100",
          borderRadius: 1,
          px: 2,
          py: 1.5,
          cursor: "pointer"
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" fontWeight="medium" color="grey.500">
            {label}
          </Typography>
          {isOpen ? (
            <UpIcon sx={{ fontSize: 16, color: "grey.400" }} />
          ) : (
            <DownIcon sx={{ fontSize: 16, color: "grey.400" }} />
          )}
        </Stack>
      </Box>
      <Collapse in={isOpen}>
        <Box sx={{ bgcolor: "grey.50", p: 2, borderRadius: 1 }}>{children}</Box>
      </Collapse>
    </Stack>
  );
};

Expand.propTypes = {
  styles: PropTypes.shape(),
  label: PropTypes.string,
  children: PropTypes.node.isRequired
};

Expand.defaultProps = {
  styles: {},
  label: ""
};

export default Expand;
