import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { CHART_COLORS } from "config";
import ErrorBoundary from "components/ErrorBoundary";
import {
  ClusteredColumn,
  ClusteredBar,
  StackedBar,
  StackedColumn,
  Multi,
  PieGraph,
  SubChart,
  BubbleChart
} from "./Charts";
import ReportTypography from "./ReportTypography";

const FallbackComponent = (
  <Box sx={{ textAlign: "center", color: "negative.main", py: 1 }}>
    <Typography variant="body1" gutterBottom>
      Oops, something went wrong :(
    </Typography>
    <Typography variant="body1">Error displaying chart</Typography>
  </Box>
);

const ReportChart = props => {
  const { data, display, layout, config } = props;
  const { chartColors = CHART_COLORS } = config;
  return (
    <div className="Chart ReportChart">
      <div
        className={data.type}
        style={{
          position: "absolute",
          top: `${layout.top}%`,
          left: `${layout.left}%`,
          height: `${layout.height}%`,
          width: `${layout.width}%`
        }}
      >
        {(data.type === "clustered_column" && (
          <ErrorBoundary fallback={FallbackComponent}>
            <ClusteredColumn
              data={data}
              display={display}
              colors={chartColors}
            />
          </ErrorBoundary>
        )) ||
          (data.type === "clustered_bar" && (
            <ErrorBoundary fallback={FallbackComponent}>
              <ClusteredBar data={data} display={display} />
            </ErrorBoundary>
          )) ||
          (data.type === "stacked_column" && (
            <ErrorBoundary fallback={FallbackComponent}>
              <StackedColumn
                data={data}
                display={display}
                colors={chartColors}
              />
            </ErrorBoundary>
          )) ||
          (data.type === "stacked_bar" && (
            <ErrorBoundary fallback={FallbackComponent}>
              <StackedBar data={data} display={display} colors={chartColors} />
            </ErrorBoundary>
          )) ||
          (data.type === "pie" && (
            <ErrorBoundary fallback={FallbackComponent}>
              <PieGraph display={display} data={data} colors={chartColors} />
            </ErrorBoundary>
          )) ||
          (data.type === "multi" && (
            <ErrorBoundary fallback={FallbackComponent}>
              <Multi data={data} display={display} colors={chartColors} />
            </ErrorBoundary>
          )) ||
          (data.type === "subchart" && (
            <ErrorBoundary fallback={FallbackComponent}>
              <SubChart data={data} display={display} colors={chartColors} />
            </ErrorBoundary>
          )) ||
          (data.type === "bubble" && (
            <ErrorBoundary fallback={FallbackComponent}>
              <BubbleChart data={data} display={display} colors={chartColors} />
            </ErrorBoundary>
          )) || (
            <ReportTypography fontSize={14} config={config}>
              No chart found with type: {data.type}
            </ReportTypography>
          )}
      </div>
    </div>
  );
};

ReportChart.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  layout: PropTypes.shape(),
  config: PropTypes.shape()
};

ReportChart.defaultProps = {
  data: {},
  display: {},
  layout: {},
  config: {}
};

export default ReportChart;
