import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import {
  Paper,
  SelectAutocomplete,
  SelectGroup
} from "components/BaseComponents";
import { NavigateStepButtons, SectionTitle } from "components/CustomComponents";
import { filterStaticData, reportConstraints } from "actions";
import { NEW_REPORT_TYPE_ROUTE } from "constants/viewRoutes";
import { AmbientIcon, ChilledIcon, FCIcon, ICIcon } from "components/Icons";

const temperatureOptions = [
  { label: "Ambient", id: "AMBIENT", icon: <AmbientIcon /> },
  { label: "Chilled", id: "CHILLED", icon: <ChilledIcon /> },
  { label: "Ambient & Chilled", id: "all" }
];

const consumptionTypeOptions = [
  {
    label: "FC (Future Consumption)",
    id: "FUTURE CONSUMPTION",
    icon: <FCIcon />
  },
  {
    label: "IC (Immediate Consumption)",
    id: "IMMEDIATE CONSUMPTION",
    icon: <ICIcon />
  },
  { label: "FC & IC", id: "all" }
];

const Product = props => {
  const {
    story,
    setActiveStep,
    dispatchReportConstraints,
    category,
    sector,
    retailer,
    isFilteringData,
    reportType,
    isStaticData,
    temperature,
    consumptionType,
    categoryOptions,
    sectorOptions,
    retailerOptions,
    dispatchFilterStaticData,
    client,
    hier1,
    hier2
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { channel } = params;

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const handleTemperature = item => {
    if (category) dispatchReportConstraints("category", null, story);
    if (sector) dispatchReportConstraints("sector", null, story);
    const { id } = item;
    dispatchReportConstraints("temperature", id, story);
    if (id !== "all") {
      const term = {
        name: id,
        subsection: "temperature",
        table: "what",
        story
      };
      dispatchFilterStaticData(term, [], story, client);
    } else {
      dispatchFilterStaticData(null, [], story, client);
    }
  };

  const handleConsumptionType = item => {
    const { id } = item;
    dispatchReportConstraints("consumptionType", id, story);
  };

  const handleCategory = value => {
    if (sector) dispatchReportConstraints("sector", null, story);
    if (hier1) dispatchReportConstraints("hier1", null, story);
    if (hier2) dispatchReportConstraints("hier2", null, story);
    dispatchReportConstraints("category", value, story);
    const filterTerms =
      temperature && temperature !== "all"
        ? [
            {
              name: temperature,
              subsection: "temperature",
              table: "what",
              story
            }
          ]
        : [];
    if (value) {
      const term = {
        name: value,
        subsection: "category",
        table: "what",
        story
      };
      dispatchFilterStaticData(term, filterTerms, story, client);
    } else {
      dispatchFilterStaticData(null, filterTerms, story, client);
    }
  };

  const handleSector = value => {
    if (hier1) dispatchReportConstraints("hier1", null, story);
    if (hier2) dispatchReportConstraints("hier2", null, story);
    dispatchReportConstraints("sector", value, story);
  };

  const handleRetailer = value =>
    dispatchReportConstraints("retailer", value, story);

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="1"
          title="Product Filter"
          subtitle="Select whether you want to filter by Temperature and/or Consumption Type."
        />
        <Stack spacing={4}>
          <SelectGroup
            options={temperatureOptions}
            onClick={handleTemperature}
            isLoading={isFilteringData}
            controlled
            selected={temperature}
            label="Temperature"
            required
          />
          <SelectGroup
            options={consumptionTypeOptions}
            onClick={handleConsumptionType}
            controlled
            selected={consumptionType}
            label="Consumption Type"
            required
          />
        </Stack>
      </Paper>
      <Paper>
        <SectionTitle
          order="2"
          title="The Product"
          subtitle="Select which category / sector to run the report on."
        />
        <Stack spacing={2} sx={{ maxWidth: 400 }}>
          <SelectAutocomplete
            label="Category"
            placeholder="All Categories"
            fullWidth
            options={categoryOptions}
            value={category}
            onChange={(e, value) => handleCategory(value)}
            isUpdating={isFilteringData}
            disabled={!isStaticData || isFilteringData}
          />
          <SelectAutocomplete
            label="Sector"
            placeholder="All Sectors Within Category"
            fullWidth
            options={sectorOptions}
            value={sector}
            onChange={(e, value) => handleSector(value)}
            isUpdating={isFilteringData}
            disabled={!category || !isStaticData || isFilteringData}
          />
        </Stack>
      </Paper>
      <Paper>
        <SectionTitle
          order="3"
          title="Analysis Retailer"
          subtitle="Select your retailer."
        />
        <SelectAutocomplete
          label="Retailer"
          placeholder="Retailer"
          fullWidth
          styles={{ maxWidth: 400 }}
          options={retailerOptions}
          value={retailer}
          onChange={(e, value) => handleRetailer(value)}
          required
          disabled={!isStaticData}
        />
      </Paper>
      <NavigateStepButtons
        onBackClick={() => navigate(-1)}
        onNextClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType,
              channel,
              step: "period"
            })
          )
        }
        nextDisabled={!temperature || !consumptionType || !retailer}
      />
    </Fragment>
  );
};

Product.propTypes = {
  story: PropTypes.string,
  setActiveStep: PropTypes.func,
  dispatchReportConstraints: PropTypes.func,
  category: PropTypes.string,
  sector: PropTypes.string,
  retailer: PropTypes.string,
  isFilteringData: PropTypes.bool,
  reportType: PropTypes.string,
  isStaticData: PropTypes.bool,
  temperature: PropTypes.string,
  consumptionType: PropTypes.string,
  categoryOptions: PropTypes.arrayOf(PropTypes.string),
  sectorOptions: PropTypes.arrayOf(PropTypes.string),
  retailerOptions: PropTypes.arrayOf(PropTypes.string),
  dispatchFilterStaticData: PropTypes.func,
  client: PropTypes.string,
  hier1: PropTypes.shape(),
  hier2: PropTypes.shape()
};

Product.defaultProps = {
  story: "",
  setActiveStep: () => {},
  dispatchReportConstraints: () => {},
  category: null,
  sector: null,
  retailer: null,
  isFilteringData: false,
  reportType: "",
  isStaticData: false,
  temperature: null,
  consumptionType: null,
  categoryOptions: [],
  sectorOptions: [],
  retailerOptions: [],
  dispatchFilterStaticData: () => {},
  client: "",
  hier1: null,
  hier2: null
};

const mapDispatchToProps = dispatch => ({
  dispatchReportConstraints: (type, constraint, story) =>
    dispatch(reportConstraints(type, constraint, story)),
  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client))
});

export default connect(null, mapDispatchToProps)(Product);
