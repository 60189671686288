import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, generatePath, useParams } from "react-router-dom";
import _ from "lodash";
import { Box, Stack } from "@mui/material";
import {
  Paper,
  SelectGroup,
  SelectAutocomplete,
  InfoText
} from "components/BaseComponents";
import {
  NavigateStepButtons,
  SectionTitle,
  SearchBar,
  AdvancedSearch
} from "components/CustomComponents";
import { FCIcon, ICIcon, InHomeIcon } from "components/Icons";
import { NEW_AUTO_RANGING_ROUTE } from "constants/viewRoutes";
import {
  addBasicConstraints,
  clearBasicConstraints,
  getAutoRangingReportConstraints,
  getBayPlansStaticData,
  filterStaticData,
  removeFilterTerm
} from "actions";
import { DEFAULT_DIST_THRESHOLD, DEFAULT_NUM_BAYS } from "constants";
import { getSearchOptions } from "utils";

const channelOptions = [
  {
    label: "In Home",
    id: "ih",
    story: "nielsen_auto_ranging",
    default: true,
    icon: <InHomeIcon />
  }
];

const consumptionTypeItems = [
  {
    label: "All",
    id: "all",
    value: "",
    val: ["IMMEDIATE CONSUMPTION", "FUTURE CONSUMPTION"],
    default: true
  },
  {
    label: "IC",
    id: "ic",
    value: "IMMEDIATE CONSUMPTION",
    val: ["IMMEDIATE CONSUMPTION"],
    icon: <ICIcon />
  },
  {
    label: "FC",
    id: "fc",
    value: "FUTURE CONSUMPTION",
    val: ["FUTURE CONSUMPTION"],
    icon: <FCIcon />
  }
];

const Basics = props => {
  const {
    client,
    subsections,
    dispatchFilterStaticData,
    dispatchRemoveFilterTerm,
    isFilteringData,
    error,
    dispatchBasicConstraints,
    basicConstraints,
    editConstraints,
    dispatchClearBasicConstraints,
    bayPlanConstraints,
    dispatchGetReportConstraints,
    isLoadingBayPlan,
    dispatchGetBayPlansStaticData,
    setActiveStep,
    isLoading,
    isLoadingEdit,
    filterTerms,
    isEdit
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { id: processId, type } = params;
  const [categoryValues, setCategoryValues] = useState([]);
  const [consumptionTypeOptions, setConsumptionTypeOptions] =
    useState(consumptionTypeItems);
  const [consumptionType, setConsumptionType] = useState(
    consumptionTypeOptions.find(i => i.default).val
  );
  const [periodOption, setPeriodOption] = useState(null);

  const {
    story,
    retailer = null,
    comparisonRetailer = null,
    period = null,
    categories = {},
    nDist = DEFAULT_DIST_THRESHOLD
  } = basicConstraints;

  const retailerOptions = subsections.find(
    item => item.name === "retailer"
  )?.keywords;

  const comparisonRetailerOptions = subsections.find(
    group => group.name === "comparison_retailer"
  )?.keywords;

  const periodOptions = (
    subsections.find(item => item.table === "when")?.keywords || []
  ).map(i => ({
    date: i.date,
    label: `${i.period} ${i.date}`,
    period: i.period,
    value: i.period
  }));

  useEffect(() => {
    setActiveStep(0);
  }, []);

  useEffect(() => {
    if (isEdit && _.isEmpty(editConstraints)) {
      dispatchGetReportConstraints(processId);
    }
  }, []);

  useEffect(() => {
    if (
      !!retailer ||
      !isEdit ||
      _.isEmpty(editConstraints) ||
      _.isEmpty(subsections)
    )
      return;
    const {
      story: editStory,
      retailer: editRetailer,
      comparison_retailer: editComparisonRetailer,
      period: editPeriod,
      scope,
      ndist_threshold: editNDist = DEFAULT_DIST_THRESHOLD
    } = editConstraints;
    const { consumption_type: editConsumptionType, ...editCategories } = scope;

    setConsumptionType(editConsumptionType);
    setConsumptionTypeOptions(
      consumptionTypeItems.map(i =>
        _.isEqual(i.val, editConsumptionType) ? { ...i, default: true } : i
      )
    );

    const selectedPeriod = periodOptions.find(i => i.period === editPeriod);
    setPeriodOption(selectedPeriod);

    dispatchBasicConstraints({
      story: editStory,
      retailer: editRetailer,
      comparisonRetailer: editComparisonRetailer,
      period: editPeriod,
      consumptionType: editConsumptionType,
      nDist: editNDist,
      categories: editCategories
    });
  }, [isEdit, editConstraints, subsections]);

  useEffect(() => {
    if (!isEdit) {
      // ensure default value added if not changed
      dispatchBasicConstraints({ consumptionType });
    }
  }, []);

  useEffect(() => {
    const selectedPeriod = periodOptions.find(i => i.period === period) || null;
    setPeriodOption(selectedPeriod);
  }, []);

  useEffect(() => {
    const result = [];
    _.entries(categories).forEach(([key, value]) =>
      result.push(
        ...getSearchOptions(
          value.map(i => ({ name: i, subsection: key })),
          "what",
          story
        )
      )
    );
    setCategoryValues(result);
  }, [categories]);

  const handleChannel = () => dispatchClearBasicConstraints();

  const handleConsumptionType = item => {
    const { val, value } = item;
    const term = {
      name: value,
      story,
      subsection: "consumption_type",
      table: "what"
    };
    setConsumptionType(val);
    dispatchBasicConstraints({ consumptionType: val, categories: {} });
    if (value) {
      dispatchFilterStaticData(term, [], story, client);
    } else {
      dispatchRemoveFilterTerm(null, [], story, client);
    }
  };

  const onNextClicked = () => {
    if (!_.isEmpty(bayPlanConstraints)) {
      navigate(
        generatePath(NEW_AUTO_RANGING_ROUTE, {
          type,
          id: processId,
          step: "range-sizes"
        })
      );
      return;
    }
    dispatchGetBayPlansStaticData({
      retailer,
      period,
      scope: { consumption_type: consumptionType, ...categories },
      story,
      client,
      nDist,
      numBays: DEFAULT_NUM_BAYS,
      onSuccess: () =>
        navigate(
          generatePath(NEW_AUTO_RANGING_ROUTE, {
            type,
            id: processId,
            step: "range-sizes"
          })
        )
    });
  };

  const handleRetailer = (name, value, subsection) => {
    dispatchBasicConstraints({ categories: {} });
    let updatedFilterTerms = filterTerms;
    if (name === "retailer") {
      // remove comparison retailer
      dispatchBasicConstraints({ comparisonRetailer: null });
      updatedFilterTerms = updatedFilterTerms.filter(
        i => i.subsection !== "comparison_retailer"
      );
    }
    dispatchBasicConstraints({ [name]: value });
    // filter static data
    if (value) {
      const term = { name: value, subsection, table: "where", story };
      updatedFilterTerms = updatedFilterTerms.filter(
        i => i.subsection !== subsection
      );
      dispatchFilterStaticData(term, updatedFilterTerms, story, client);
    } else {
      const term = filterTerms.find(i => i.subsection === subsection);
      dispatchRemoveFilterTerm(term, updatedFilterTerms, story, client);
    }
  };

  const handlePeriod = value => {
    setPeriodOption(value);
    if (value) dispatchBasicConstraints({ period: value.period });
    else dispatchBasicConstraints({ period: null });
  };

  const handleCategory = content => {
    const result = {};
    _.uniq(content.map(i => i.subsection)).forEach(i => {
      result[i] = [];
    });
    content.forEach(i => result[i.subsection].push(i.value));
    dispatchBasicConstraints({ categories: result });
  };

  return (
    <Fragment>
      <Paper>
        <SectionTitle
          order="1"
          title="Basic Settings"
          subtitle="Select the Channel and Retailer / Environment to Auto-Range. You can also select the comparison Retailer / Environment to be used for Gap Analysis."
        />
        <SelectGroup
          label="Channel"
          options={channelOptions}
          onClick={handleChannel}
          isLoading={isLoading || isLoadingEdit}
          styles={{ mb: 3 }}
        />
        <Stack spacing={3} sx={{ maxWidth: 400 }}>
          <SelectAutocomplete
            label="Retailer / Environment"
            placeholder="Retailer / Environment"
            fullWidth
            value={retailer}
            onChange={(e, value) =>
              handleRetailer("retailer", value, "retailer")
            }
            options={retailerOptions}
            isUpdating={!!retailer && !comparisonRetailer && isFilteringData}
            disabled={isFilteringData || isLoadingEdit}
            isLoading={isLoading}
          />
          <SelectAutocomplete
            label="Comparison Retailer / Environment for Gap Analysis"
            placeholder="Retailer / Environment for Gap Analysis"
            fullWidth
            value={comparisonRetailer}
            onChange={(e, value) =>
              handleRetailer("comparisonRetailer", value, "comparison_retailer")
            }
            options={comparisonRetailerOptions}
            isUpdating={!!retailer && !comparisonRetailer && isFilteringData}
            disabled={!retailer || isFilteringData || isLoading}
            isLoading={isLoading}
            error={!retailer ? "Please select retailer first." : ""}
          />
        </Stack>
      </Paper>
      <Paper>
        <SectionTitle
          order="2"
          title="Time Period"
          subtitle="Select your desired time frame."
        />
        <SelectAutocomplete
          label="Time Frame"
          placeholder="Time Frame"
          styles={{ maxWidth: 400 }}
          fullWidth
          value={periodOption}
          onChange={(e, value) => handlePeriod(value)}
          options={periodOptions}
          isOptionEqualToValue={(option, value) =>
            option.period === value.period
          }
          disabled={isLoadingEdit}
          isLoading={isLoading}
        />
      </Paper>
      <Paper>
        {(!retailer || !comparisonRetailer) && (
          <InfoText
            level="error"
            text="Please select retailer and comparison retailer first."
            styles={{ mb: 2 }}
          />
        )}
        <Box sx={{ position: "relative" }}>
          {(!retailer || !comparisonRetailer) && (
            <Box
              sx={{
                bgcolor: "white",
                filter: "blur(0px)",
                opacity: 0.6,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100
              }}
            />
          )}
          <SectionTitle
            order="3"
            title="Category / Sector to Auto-Range"
            subtitle="Select your desired Optimisation Scope in which you want to get the Auto-Ranging."
          />
          <SelectGroup
            label="Products filtered by"
            options={consumptionTypeOptions}
            onClick={handleConsumptionType}
            isLoading={isFilteringData || isLoadingEdit}
            skeletonWidth={64}
            styles={{ mb: 3 }}
          />
          <SearchBar
            label="Category / Sector"
            value={categoryValues}
            onChange={(e, value) => handleCategory(value)}
            options={subsections}
            multiple
            disabled={isFilteringData}
            styles={{ mb: 2 }}
            isLoading={isLoading}
            isUpdating={!!retailer && !!comparisonRetailer && isFilteringData}
            story={story}
          />
          <AdvancedSearch
            fields={subsections}
            isLoading={isLoading || isFilteringData}
            selected={categoryValues}
            onChange={value => handleCategory(value)}
            multiple
            story={story}
          />
        </Box>
      </Paper>
      <NavigateStepButtons
        onBackClick={() => navigate(-1)}
        onNextClick={onNextClicked}
        nextDisabled={!!error || !period || _.isEmpty(categories)}
        nextLoading={isLoadingBayPlan}
      />
    </Fragment>
  );
};

Basics.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  isFilteringData: PropTypes.bool,
  error: PropTypes.string,
  dispatchFilterStaticData: PropTypes.func,
  dispatchRemoveFilterTerm: PropTypes.func,
  dispatchBasicConstraints: PropTypes.func,
  dispatchClearBasicConstraints: PropTypes.func,
  dispatchGetReportConstraints: PropTypes.func,
  client: PropTypes.string,
  basicConstraints: PropTypes.shape(),
  editConstraints: PropTypes.shape(),
  bayPlanConstraints: PropTypes.arrayOf(PropTypes.shape()),
  dispatchGetBayPlansStaticData: PropTypes.func,
  isLoadingBayPlan: PropTypes.bool,
  setActiveStep: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingEdit: PropTypes.bool,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  isEdit: PropTypes.bool
};

Basics.defaultProps = {
  subsections: [],
  isFilteringData: false,
  error: "",
  dispatchFilterStaticData: () => {},
  dispatchRemoveFilterTerm: () => {},
  dispatchBasicConstraints: () => {},
  dispatchClearBasicConstraints: () => {},
  dispatchGetReportConstraints: () => {},
  client: "",
  basicConstraints: {},
  editConstraints: {},
  bayPlanConstraints: [],
  dispatchGetBayPlansStaticData: () => {},
  isLoadingBayPlan: false,
  setActiveStep: () => {},
  isLoading: false,
  isLoadingEdit: false,
  filterTerms: [],
  isEdit: false
};

const mapStateToProps = (state, ownProps) => {
  const { story } = ownProps;
  const {
    search: { error, isFilteringData, filterTerms = { story: [] } },
    autoRanging: {
      editConstraints: { data: editData = {}, isLoading: isLoadingEdit } = {},
      staticData: { isLoading } = {},
      bayPlanStaticData: { isLoading: isLoadingBayPlan } = {},
      basicConstraints = {},
      bayPlanConstraints = []
    }
  } = state;
  return {
    isLoading,
    isLoadingEdit,
    error,
    isFilteringData,
    filterTerms: filterTerms[story],
    client: state.user.user.client,
    basicConstraints,
    editConstraints: editData,
    bayPlanConstraints,
    isLoadingBayPlan
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client)),
  dispatchRemoveFilterTerm: (term, filterTerms, story, client) =>
    dispatch(removeFilterTerm(term, filterTerms, story, client)),
  dispatchBasicConstraints: constraints =>
    dispatch(addBasicConstraints(constraints)),
  dispatchClearBasicConstraints: () => dispatch(clearBasicConstraints()),
  dispatchGetReportConstraints: reportId =>
    dispatch(getAutoRangingReportConstraints(reportId)),
  dispatchGetBayPlansStaticData: ({
    retailer,
    period,
    scope,
    story,
    client,
    numBays,
    nDist,
    onSuccess
  }) =>
    dispatch(
      getBayPlansStaticData({
        retailer,
        period,
        scope,
        story,
        client,
        numBays,
        nDist,
        onSuccess
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Basics);
