import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";
import { NavigateStepButtons } from "components/CustomComponents";
import { editSterling } from "actions";
import { STERLING_RANGING_ROUTE } from "constants/viewRoutes";
import { clientConfig } from "config";
import Environment from "./Environment";
import Details from "./Details";
import Header from "../Header";

const NewPrioritisation = props => {
  const { options, isLoading, edit, isSterling, client } = props;
  const { type } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState(
    (location.state && location.state.name) || ""
  );
  const [channel, setChannel] = useState(
    (location.state && location.state.channel) || "ih"
  );
  const [environment, setEnvironment] = useState(
    (location.state && location.state.environment) || null
  );
  const [skus, setSkus] = useState(
    (location.state && location.state.skus) || []
  );
  const [environments, setEnvironments] = useState(
    environment ? [environment] : []
  );
  const [rows, setRows] = useState([]);
  const { name: clientName = "" } = clientConfig[client] || {};

  useEffect(() => {
    if (environment) {
      setEnvironments([environment]);
    } else {
      setEnvironments([]);
    }
  }, [environment]);

  useEffect(() => {
    if (location.state && options.length > 0) {
      setRows(options.filter(i => location.state.skus.includes(i.sku)));
    }
  }, [options]);

  useEffect(() => {
    setSkus(rows.map(i => i.sku));
  }, [rows]);

  const handleNext = () =>
    navigate(generatePath(STERLING_RANGING_ROUTE, { type: "result" }), {
      state: { channel, environment, name, skus }
    });

  const handleBack = () =>
    navigate(generatePath(STERLING_RANGING_ROUTE, { type: "input" }));

  return (
    <Fragment>
      <Paper>
        <Header isSterling={isSterling} />
        <Stack spacing={1} sx={{ mb: 2 }}>
          <Typography variant="h4" color="primary.main">
            Shopper-Based Ranging
          </Typography>
          <Typography variant="body1" color="grey.500">
            Here you can make changes to the importance of SKUs by environment.
            This is here to combine your expertise with the data to produce the
            best output possible, with this output to be used throughout{" "}
            {clientName}.
          </Typography>
        </Stack>
        {type === "input" && (
          <Environment
            name={name}
            setName={setName}
            channel={channel}
            setChannel={setChannel}
            environment={environment}
            setEnvironment={setEnvironment}
          />
        )}
        {type === "result" && (
          <Details
            channel={channel}
            environments={environments}
            handleBack={handleBack}
            options={options}
            rows={rows}
            setRows={setRows}
            isLoading={isLoading}
          />
        )}
      </Paper>
      {type === "input" && (
        <NavigateStepButtons
          onBackClick={() => navigate(generatePath(STERLING_RANGING_ROUTE))}
          onNextClick={handleNext}
          nextDisabled={!environment || !name}
        />
      )}
      {type === "result" && (
        <NavigateStepButtons
          onBackClick={handleBack}
          onNextClick={() =>
            edit(
              _.difference(rows, options),
              channel,
              environments,
              handleBack,
              name,
              rows.map(i => i.sku)
            )
          }
          nextDisabled={isLoading || options.length === 0 || rows.length === 0}
          nextLabel="Apply Changes" // TODO: Create Sheet if not edit
        />
      )}
    </Fragment>
  );
};

NewPrioritisation.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  edit: PropTypes.func,
  isSterling: PropTypes.bool,
  client: PropTypes.string
};

NewPrioritisation.defaultProps = {
  options: [],
  isLoading: false,
  edit: () => {},
  isSterling: false,
  client: ""
};

const mapStateToProps = state => ({
  options: state.sterling.products,
  isLoading: state.sterling.isLoading,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  edit: (data, channel, environments, onSuccess, name, skus) =>
    dispatch(
      editSterling(
        "sku",
        data,
        [],
        channel,
        environments,
        onSuccess,
        name,
        skus
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPrioritisation);
