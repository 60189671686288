import React from "react";
import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";
import { Table } from "components/BaseComponents";
import { MinusIcon, ArrowUpIcon, ArrowDownIcon } from "components/Icons";
import { AUTO_RANGING_COLORS } from "constants/reportConstants";
import { formatCurrency } from "utils";

const styles = {
  sustainability: { width: 80, px: 0.25 },
  headerRow: { pt: 1.5, pb: 0.25, color: "grey.500", borderBottom: "none" },
  header: { pb: 1.5, pt: 0.25 },
  border: { borderRight: 1 },
  noBorder: { "&.MuiTableCell-root": { borderRight: "none" } },
  bandingCell: { position: "relative", p: 0, borderTop: 1 }
};

const getBayPlanIcon = direction => {
  let icon = (
    <ArrowDownIcon sx={{ fontSize: 16, color: "negative.main", mr: 0.5 }} />
  );
  if (direction > 0) {
    icon = (
      <ArrowUpIcon sx={{ fontSize: 16, color: "positiveArray.700", mr: 0.5 }} />
    );
  }
  if (direction === 0) {
    icon = <MinusIcon sx={{ fontSize: 16, color: "neutralArray.600" }} />;
  }
  return icon;
};

const getColor = value => {
  switch (value) {
    case 1:
      return "positiveArray.600";
    case 2:
      return "positiveArray.300";
    case 3:
      return "negativeArray.200";
    case 4:
      return "negativeArray.400";
    default:
      return "grey.300";
  }
};

const renderBanding = banding => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      bgcolor: getColor(banding),
      width: 1,
      height: 1
    }}
  />
);

const columns = [
  { label: "Rank", renderCell: item => item.rank + 1, rowSpan: 2 },
  {
    label: "SKU",
    renderCell: item => item.sku,
    styles: { textAlign: "left", ...styles.border },
    rowSpan: 2
  },
  {
    label: "Value Sales",
    renderCell: item => formatCurrency(item.sales_after),
    styles: styles.border,
    rowSpan: 2
  },
  {
    label: "Bay Plan Change",
    renderCell: item => (
      <Stack direction="row" alignItems="center" justifyContent="center">
        {getBayPlanIcon(item.bay_plan_changed)}
        {item.bay_plan_changed !== 0 &&
          `${item.bay_plan_changed} ${item.bay_name_after}`}
      </Stack>
    ),
    styles: styles.border,
    rowSpan: 2
  },
  {
    label: "Sugar",
    renderCell: item => renderBanding(item.sugar_gr_100ml_banding),
    cellStyles: styles.bandingCell,
    styles: { ...styles.noBorder, ...styles.header, ...styles.sustainability }
  },
  {
    label: "Calories",
    renderCell: () => renderBanding(""),
    cellStyles: styles.bandingCell,
    styles: { ...styles.border, ...styles.header, ...styles.sustainability }
  },
  {
    label: "Weight of Virgin Plastic",
    renderCell: item =>
      renderBanding(item.virgin_plastic_weight_gr_per_ml_banding),
    cellStyles: styles.bandingCell,
    styles: { ...styles.noBorder, ...styles.header, ...styles.sustainability }
  },
  {
    label: "% of rPET",
    renderCell: item => renderBanding(item.rPET_percentage_banding),
    cellStyles: styles.bandingCell,
    styles: { ...styles.noBorder, ...styles.header, ...styles.sustainability }
  },
  {
    label: "Pieces of Plastic",
    renderCell: item => renderBanding(item.number_of_pieces_of_plastic_banding),
    cellStyles: styles.bandingCell,
    styles: { ...styles.border, ...styles.header, ...styles.sustainability }
  },
  {
    label: "Recyclability",
    renderCell: item => renderBanding(item.recyclability_banding),
    cellStyles: styles.bandingCell,
    rowSpan: 2,
    styles: { ...styles.border, ...styles.sustainability }
  },
  {
    label: "Carbon Emissions",
    renderCell: () => renderBanding(""),
    cellStyles: styles.bandingCell,
    rowSpan: 2,
    styles: styles.sustainability
  }
];

const header = [
  { label: "Rank", rowSpan: 2 },
  { label: "SKU", rowSpan: 2, styles: { textAlign: "left" } },
  { label: "Value Sales", rowSpan: 2 },
  { label: "Bay Plan Change", rowSpan: 2 },
  { label: "Health", colSpan: 2, styles: styles.headerRow },
  { label: "Plastic", colSpan: 3, styles: styles.headerRow },
  { label: "Recyclability", rowSpan: 2 },
  { label: "Carbon Emissions", rowSpan: 2 }
];

const CompareSustainability = props => {
  const { data } = props;
  return (
    <Table
      columns={columns}
      rows={data.map(i => ({
        ...i,
        styles: { bgcolor: `${AUTO_RANGING_COLORS[i.bay_id_after]}60` }
      }))}
      multipleHeader
      header={header}
      styles={{ head: { "& .MuiTableCell-root": { lineHeight: 1.25 } } }}
    />
  );
};

CompareSustainability.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape())
};

CompareSustainability.defaultProps = {
  data: []
};

export default CompareSustainability;
