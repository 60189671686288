import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Chip, Skeleton, Stack } from "@mui/material";
import { getSearchOptions } from "utils";

const Suggestions = props => {
  const { isLoading, suggestions, onClick, styles, story } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const opts = getSearchOptions(suggestions, "context", story);
    setOptions(opts);
  }, [suggestions]);

  return (
    <Stack
      direction="row"
      sx={{ flexWrap: "wrap", columnGap: 1.5, rowGap: 1, my: 1.5, ...styles }}
    >
      {options.map(i =>
        isLoading ? (
          <Skeleton
            key={_.uniqueId()}
            sx={{ borderRadius: 50 }}
            variant="rounded"
            width={150}
            height={32}
          />
        ) : (
          <Chip
            key={i.label}
            label={`${_.startCase(i.subsection)}: ${i.value}`}
            onClick={() => onClick(i)}
          />
        )
      )}
    </Stack>
  );
};

Suggestions.propTypes = {
  isLoading: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func,
  styles: PropTypes.shape(),
  story: PropTypes.string
};

Suggestions.defaultProps = {
  isLoading: false,
  suggestions: [],
  onClick: () => {},
  styles: {},
  story: ""
};

export default Suggestions;
