import React from "react";
import PropTypes from "prop-types";
import { Box, List, ListItem } from "@mui/material";
import { uniqueId } from "lodash";

const Appendix = props => {
  const { text, config } = props;
  const { fontFamily, fontSize } = config;
  const appendix = text.find(i => i.key === "apndx");
  const appendixText = appendix && appendix.text.split("\n");
  return (
    <Box sx={{ height: 1, width: 1, mt: -3 }}>
      {appendix && (
        <List sx={{ pl: 2 }}>
          {appendixText.map(i => {
            const isBullet = i.startsWith("•");
            const item = isBullet ? i.replace("• ", "").replace("•", "") : i;
            return (
              <ListItem
                disablePadding
                sx={{
                  display: "list-item",
                  fontSize: 0.875 * fontSize,
                  color: "grey.500",
                  fontFamily,
                  listStyleType: isBullet && "disc",
                  pb: 0.5,
                  ml: !isBullet && -2
                }}
                key={uniqueId()}
              >
                {item}
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

Appendix.propTypes = {
  text: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape()
};

Appendix.defaultProps = {
  text: [],
  config: {}
};

export default Appendix;
