import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, Stack, Typography } from "@mui/material";
import { InHomeIcon, OutOfHomeIcon, TickCircleIcon } from "components/Icons";
import { Paper, Switch } from "components/BaseComponents";
import { getSubscription, subscribe, unsubscribe } from "actions";
import { clientConfig } from "config";

const SubscriptionSettings = props => {
  const {
    isLoading,
    getSubscribed,
    subscriptions,
    subscribeTopic,
    unsubscribeTopic,
    client
  } = props;
  const [settings, setSettings] = useState(false);

  useEffect(() => {
    if (_.isEmpty(subscriptions)) {
      getSubscribed();
    } else {
      setSettings(subscriptions);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(subscriptions)) {
      setSettings(subscriptions);
    }
  }, [subscriptions]);

  const subscriptionOptions = [
    {
      title: "Lookout (In-Home)",
      description:
        "Subscribe to IH Lookout emails if you are interested in In-Home updates. Please note that Impulse is included in the At Home updates as we take this from the Nielsen data.",
      type: "lookout-ih",
      isVisible: clientConfig[client].channels.includes("ih"),
      Icon: InHomeIcon
    },
    {
      title: "Lookout (Out-of-Home)",
      description:
        "Subscribe to OOH Lookout emails if you are interested in Out-of-Home updates. This covers all insights covered by CGA data.",
      type: "lookout-ooh",
      isVisible: clientConfig[client].channels.includes("ooh"),
      Icon: OutOfHomeIcon
    }
  ].filter(i => i.isVisible);

  return (
    <Fragment>
      <Stack spacing={1.5}>
        {subscriptionOptions.map(i => (
          <Paper key={i.title}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <i.Icon color="secondary" fontSize="small" />
                <Typography variant="subtitle1" fontWeight="medium">
                  {i.title}
                </Typography>
              </Stack>
              <Switch
                color="secondary"
                checked={settings[i.type] ? settings[i.type] : false}
                onChange={e =>
                  setSettings({ ...settings, [i.type]: e.target.checked })
                }
                disabled={isLoading || !settings}
              />
            </Stack>
            <Typography variant="body1" color="grey.500">
              {i.description}
            </Typography>
          </Paper>
        ))}
      </Stack>
      <Button
        variant="contained"
        endIcon={<TickCircleIcon />}
        onClick={() => {
          const changes = _.reduce(
            settings,
            (result, value, key) =>
              _.isEqual(value, subscriptions[key])
                ? result
                : result.concat(key),
            []
          );
          // subscribe or unsubscribe from changed topics as required
          changes.map(i =>
            settings[i] ? subscribeTopic(i) : unsubscribeTopic(i)
          );
        }}
        disabled={isLoading || !settings}
        sx={{ mt: 3 }}
      >
        Save
      </Button>
    </Fragment>
  );
};

SubscriptionSettings.propTypes = {
  getSubscribed: PropTypes.func,
  isLoading: PropTypes.bool,
  subscriptions: PropTypes.shape(),
  subscribeTopic: PropTypes.func,
  unsubscribeTopic: PropTypes.func,
  client: PropTypes.string
};

SubscriptionSettings.defaultProps = {
  getSubscribed: () => {},
  isLoading: false,
  subscriptions: {},
  subscribeTopic: () => {},
  unsubscribeTopic: () => {},
  client: ""
};

const mapDispatchToProps = dispatch => ({
  getSubscribed: () => dispatch(getSubscription()),
  subscribeTopic: topic => dispatch(subscribe(topic)),
  unsubscribeTopic: topic => dispatch(unsubscribe(topic))
});

export default connect(null, mapDispatchToProps)(SubscriptionSettings);
