import React from "react";
import PropTypes from "prop-types";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
  GridEditInputCell,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import { DeleteIcon } from "components/Icons";
import { report } from "images";

export const getColumns = (setIsDeleteOpen, setSelected) => [
  {
    field: "sku",
    headerName: "SKU",
    width: 100,
    sortable: false,
    renderCell: item => item.value
  },
  {
    field: "name",
    headerName: "Product Name",
    flex: 1,
    minWidth: 280,
    sortable: false,
    renderCell: params => {
      const { value } = params;
      return (
        <Tooltip title={value}>
          <Typography variant="inherit" noWrap>
            {value}
          </Typography>
        </Tooltip>
      );
    }
  },
  {
    field: "importance",
    headerName: "Strategic / Customer Importance",
    editable: true,
    width: 150,
    type: "number",
    sortable: false,
    renderEditCell: params => (
      <GridEditInputCell {...params} inputProps={{ min: 0, max: 3 }} />
    )
  },
  {
    field: "sustainability",
    headerName: "Sustainable Score",
    editable: true,
    width: 130,
    type: "number",
    sortable: false,
    renderEditCell: params => (
      <GridEditInputCell {...params} inputProps={{ min: 0, max: 100 }} />
    )
  },
  {
    field: "sector_projection",
    headerName: "Sector Performance / Projection",
    width: 150,
    type: "number",
    sortable: false
  },
  {
    field: "ic_fc_projection",
    headerName: "IC/FC Performance / Projection",
    width: 140,
    type: "number",
    sortable: false
  },
  {
    field: "sugar_projection",
    headerName: "Sugar Content Performance / Projection",
    width: 170,
    type: "number",
    sortable: false
  },
  {
    field: "action",
    headerName: "Action",
    headerAlign: "center",
    align: "center",
    width: 80,
    filterable: false,
    sortable: false,
    renderCell: params => (
      <IconButton
        onClick={() => {
          setIsDeleteOpen(true);
          setSelected(params);
        }}
      >
        <DeleteIcon sx={{ fontSize: 16 }} />
      </IconButton>
    )
  }
];

export const NoRowsOverlay = props => {
  const { handleClick } = props;
  return (
    <GridOverlay>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ height: 1 }}
      >
        <img
          alt="report"
          src={report}
          style={{ width: 275, marginBottom: 8 }}
        />
        <Typography variant="h4" color="primary">
          You have not added any SKUs yet!
        </Typography>
        <Button variant="contained" onClick={handleClick}>
          Add SKU
        </Button>
      </Stack>
    </GridOverlay>
  );
};

export const Toolbar = props => {
  const { handleClick } = props;
  return (
    <GridToolbarContainer sx={{ mb: 1, px: 0 }}>
      <Stack direction="row" justifyContent="space-between" sx={{ width: 1 }}>
        <GridToolbarFilterButton
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ "& .MuiBadge-badge": { bgcolor: "secondary.main" } }}
        />
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleClick}
        >
          Add SKU
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
};

NoRowsOverlay.propTypes = {
  handleClick: PropTypes.func
};

NoRowsOverlay.defaultProps = {
  handleClick: () => {}
};

Toolbar.propTypes = {
  handleClick: PropTypes.func
};

Toolbar.defaultProps = {
  handleClick: () => {}
};
