import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Collapse, createFilterOptions, Stack } from "@mui/material";
import { TickCircleIcon } from "components/Icons";
import {
  PasswordField,
  SelectAutocomplete,
  TextField
} from "components/BaseComponents";
import { createUser, getDepartments, addDepartment } from "actions";

const filter = createFilterOptions();

const CreateAccount = props => {
  const {
    submitCreateUser,
    isLoading,
    client,
    departments,
    getDepts,
    addDept
  } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [department, setDepartment] = useState("");

  const isDijuno =
    email.includes("@interrodata.com") || email.includes("@dijuno.ai");

  useEffect(() => {
    getDepts(client);
  }, []);

  const reset = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setDepartment("");
  };

  return (
    <Fragment>
      <Stack spacing={2} sx={{ maxWidth: 400 }}>
        <TextField
          label="Name"
          placeholder="Name"
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={isLoading}
          fullWidth
        />
        <TextField
          label="Email"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          disabled={isLoading}
          type="email"
          fullWidth
        />
        <PasswordField
          label="Password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          disabled={isLoading}
          fullWidth
        />
        <PasswordField
          label="Confirm Password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          disabled={isLoading}
          fullWidth
        />
        <Collapse in={!isDijuno}>
          <SelectAutocomplete
            label="Department"
            placeholder="Department"
            value={department}
            freeSolo
            fullWidth
            onChange={(e, newValue) => {
              if (typeof newValue === "string") {
                setDepartment(newValue);
              } else if (newValue && newValue.newOption) {
                // Create a new value from the user input
                setDepartment(newValue.newOption);
                addDept(newValue.newOption, client);
              } else {
                setDepartment(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  newOption: params.inputValue,
                  label: `Add "${params.inputValue}"`
                });
              }
              return filtered;
            }}
            getOptionLabel={option => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.newOption) {
                return option.label;
              }
              // Regular option
              return option;
            }}
            options={departments}
            disabled={isLoading}
          />
        </Collapse>
      </Stack>
      <Button
        variant="contained"
        endIcon={<TickCircleIcon />}
        disabled={
          !name ||
          !email ||
          !password ||
          !confirmPassword ||
          (!isDijuno && !department)
        }
        onClick={() =>
          submitCreateUser(
            name,
            email,
            password,
            confirmPassword,
            department,
            client,
            reset
          )
        }
        sx={{ mt: 3 }}
      >
        Create Account
      </Button>
    </Fragment>
  );
};

CreateAccount.propTypes = {
  submitCreateUser: PropTypes.func,
  isLoading: PropTypes.bool,
  client: PropTypes.string,
  departments: PropTypes.arrayOf(PropTypes.string),
  getDepts: PropTypes.func,
  addDept: PropTypes.func
};

CreateAccount.defaultProps = {
  submitCreateUser: () => {},
  isLoading: false,
  client: "",
  departments: [],
  getDepts: () => {},
  addDept: () => {}
};

const mapStateToProps = state => ({
  departments: state.user.departments
});

const mapDispatchToProps = dispatch => ({
  submitCreateUser: (
    name,
    email,
    password,
    confirmPassword,
    department,
    client,
    onSuccess
  ) =>
    dispatch(
      createUser(
        name,
        email,
        password,
        confirmPassword,
        department,
        client,
        onSuccess
      )
    ),
  getDepts: client => dispatch(getDepartments(client)),
  addDept: (dept, client) => dispatch(addDepartment(dept, client))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
