import React from "react";
import PropTypes from "prop-types";
import { getYear } from "date-fns";
import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import { logoFull } from "images";
import { ContactSupportIcon } from "components/Icons";
import { HELP_DESK_ROUTE } from "constants/viewRoutes";

const Layout = props => {
  const { children } = props;
  return (
    <Grid container rowSpacing={2} sx={{ minHeight: "100vh", py: 3 }}>
      <Grid
        item
        xs={12}
        md={8}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{ width: 1, maxWidth: 400 }}>{children}</Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack
          alignItems="center"
          spacing={1}
          sx={{ height: 1, bgcolor: "grey.100", p: 4, borderRadius: 2 }}
        >
          <Stack justifyContent="center" sx={{ height: 1 }}>
            <img src={logoFull} alt="dijuno logo" style={{ width: 200 }} />
          </Stack>
          <Button
            variant="outlined"
            endIcon={<ContactSupportIcon />}
            component={Link}
            href={HELP_DESK_ROUTE}
            target="_blank"
            rel="noreferrer"
          >
            Contact Support
          </Button>
          <Typography variant="body2">
            &copy; {getYear(new Date())} dijuno
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
