import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Stack, Typography, Button } from "@mui/material";
import {
  AutoRangingIcon,
  BrandBattlesIcon,
  PerformanceIcon,
  RangeReviewIcon,
  TrendIcon,
  InHomeIcon,
  OutOfHomeIcon,
  AddIcon
} from "components/Icons";
import { Menu, Checkbox } from "components/BaseComponents";
import {
  PREVIOUS_REPORTS_AUTO_RANGING_ROUTE,
  NEW_IH_PERFORMANCE_REPORT_ROUTE,
  NEW_OOH_PERFORMANCE_REPORT_ROUTE,
  NEW_IH_BRAND_BATTLES_REPORT_ROUTE,
  NEW_IH_TREND_REPORT_ROUTE,
  NEW_IH_RANGE_REVIEW_REPORT_ROUTE,
  NEW_AUTO_RANGING_INITIAL_ROUTE
} from "constants/viewRoutes";
import { clientConfig } from "config";
import { storyEnums } from "constants/enums";

const ReportInfo = props => {
  const { client, isFavourites, setIsFavourites, reportType } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const { stories } = clientConfig[client] || { stories: [] };

  const headerOptions = {
    performance: {
      title: "Performance Reports",
      icon: <PerformanceIcon fontSize="small" />,
      button: (
        <Fragment>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={e => setAnchorEl(e.currentTarget)}
          >
            New Performance Report
          </Button>
          <Menu
            menuItems={[
              {
                title: "In-Home Performance Report",
                icon: <InHomeIcon sx={{ color: "primary.main" }} />,
                path: NEW_IH_PERFORMANCE_REPORT_ROUTE,
                isVisible: stories.includes(storyEnums.IDA)
              },
              {
                title: "Out-of-Home Performance Report",
                icon: <OutOfHomeIcon sx={{ color: "primary.main" }} />,
                path: NEW_OOH_PERFORMANCE_REPORT_ROUTE,
                isVisible: stories.includes(storyEnums.CGA)
              }
            ].filter(i => i.isVisible)}
            anchorEl={anchorEl}
            handleClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          />
        </Fragment>
      )
    },
    "brand-battles": {
      title: "Brand Battles (Product Comparison",
      icon: <BrandBattlesIcon fontSize="small" />,
      button: (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => navigate(NEW_IH_BRAND_BATTLES_REPORT_ROUTE)}
        >
          New Brand Battles Report
        </Button>
      )
    },
    trend: {
      title: "Trend Reports",
      icon: <TrendIcon fontSize="small" />,
      button: (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => navigate(NEW_IH_TREND_REPORT_ROUTE)}
        >
          New Trend Report
        </Button>
      )
    },
    "range-review": {
      title: "Range Review Impact Assessments",
      icon: <RangeReviewIcon fontSize="small" />,
      button: (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => navigate(NEW_IH_RANGE_REVIEW_REPORT_ROUTE)}
        >
          New Range Review Report
        </Button>
      )
    },
    "auto-ranging": {
      title: "Auto-Ranging Reports",
      icon: <AutoRangingIcon fontSize="small" />,
      button: (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() =>
            navigate(NEW_AUTO_RANGING_INITIAL_ROUTE, {
              state: { from: PREVIOUS_REPORTS_AUTO_RANGING_ROUTE }
            })
          }
        >
          New Auto-Ranging Report
        </Button>
      )
    }
  };

  const { icon, title, button } = headerOptions[reportType] || {};

  return (
    <Fragment>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {icon}
          <Typography variant="h4" color="grey.900">
            {title}
          </Typography>
        </Stack>
        {button}
      </Stack>
      <Checkbox
        label="Only favourites"
        checked={isFavourites}
        onChange={e => setIsFavourites(e.target.checked)}
      />
    </Fragment>
  );
};

ReportInfo.propTypes = {
  client: PropTypes.string,
  isFavourites: PropTypes.bool,
  setIsFavourites: PropTypes.func,
  reportType: PropTypes.string
};

ReportInfo.defaultProps = {
  client: "",
  isFavourites: false,
  setIsFavourites: () => {},
  reportType: ""
};

export default ReportInfo;
