import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { Button, Link, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowRightIcon, EmailIcon } from "components/Icons";
import { TextField } from "components/BaseComponents";
import { InfoDialog } from "components/CustomComponents";
import {
  HELP_DESK_MAIL_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE
} from "constants/viewRoutes";
import { forgotPassword } from "actions";
import Layout from "./Layout";

const ForgotPassword = props => {
  const { isUser, isLoading, dispatchForgotPassword } = props;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (isUser) {
    return <Navigate to={HOME_ROUTE} />;
  }
  return (
    <Layout>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h2">Forgot your password?</Typography>
          <Typography variant="subtitle1" color="grey.500">
            Please enter your email, password reset link will be sent to your
            email.
          </Typography>
        </Stack>
        <TextField
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
          label="Email"
          type="email"
          placeholder="Enter your Email"
          inputStyles={{ "& .MuiInputBase-input": { height: 28 } }}
        />
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="large"
            sx={{ width: 130 }}
            onClick={() => navigate(LOGIN_ROUTE)}
          >
            Cancel
          </Button>
          <LoadingButton
            color="secondary"
            loadingPosition="end"
            endIcon={<ArrowRightIcon />}
            variant="contained"
            size="large"
            loading={isLoading}
            sx={{ width: 1 }}
            disabled={!email}
            onClick={() =>
              dispatchForgotPassword(email, () => setIsDialogOpen(true))
            }
          >
            Send Reset Link
          </LoadingButton>
        </Stack>
      </Stack>
      <InfoDialog
        isOpen={isDialogOpen}
        title="Please Check Your Email"
        content={
          <Fragment>
            <Typography variant="inherit" gutterBottom>
              If the email address is in our database, we will send you an email
              to reset your password. Please check your spam if it hasn&apos;t
              been delivered in the next 5 minutes. If you haven&apos;t received
              it after 5 minutes, please try submitting the form again.
            </Typography>
            <Typography variant="inherit">
              You can also always contact us at{" "}
              <Link href={HELP_DESK_MAIL_ROUTE}>helpdesk@dijuno.ai</Link> for
              any further issues!
            </Typography>
          </Fragment>
        }
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => {
          setIsDialogOpen(false);
          navigate(LOGIN_ROUTE);
        }}
        Icon={EmailIcon}
      />
    </Layout>
  );
};

ForgotPassword.propTypes = {
  isUser: PropTypes.bool,
  isLoading: PropTypes.bool,
  dispatchForgotPassword: PropTypes.func
};

ForgotPassword.defaultProps = {
  isUser: false,
  isLoading: false,
  dispatchForgotPassword: () => {}
};

const mapStateToProps = state => ({
  isUser: state.user.isUser,
  isLoading: state.user.isLoading,
  clients: state.user.clients
});

const mapDispatchToProps = dispatch => ({
  dispatchForgotPassword: (email, onComplete) =>
    dispatch(forgotPassword(email, onComplete))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
