import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, generatePath, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { AddIcon } from "components/Icons";
import { NEW_REPORT_TYPE_ROUTE, VIEW_REPORT_ROUTE } from "constants/viewRoutes";
import { NavigateStepButtons } from "components/CustomComponents";
import {
  buildQueryString,
  getConstraintsFromQuery,
  getSearchOption,
  getSearchOptions,
  updateAPIValue
} from "utils";
import { getStoryConfig } from "config";
import {
  fetchStaticData,
  filterStaticData,
  clearStoryConstraints,
  clearStorySearchData
} from "actions";
import { storyEnums } from "constants/enums";
import EditMarket from "components/ReportBuilder/EditMarket";
import Header from "./Header";
import EditContext from "./EditContext";
import EditProducts from "./EditProducts";

const story = storyEnums.BB;
const { dataSet, type, channel } = getStoryConfig(story);

const EditBrandBattlesReport = props => {
  const {
    subsections,
    isLoading,
    getData,
    client,
    isStaticData,
    dataDate,
    dispatchFilterStaticData,
    dispachClearStory
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [productA, setProductA] = useState(null);
  const [productB, setProductB] = useState(null);
  const [context, setContext] = useState([]);
  const [retailer, setRetailer] = useState(null);
  const [period, setPeriod] = useState(null);
  const [metric, setMetric] = useState(null);

  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  useEffect(() => {
    if (!isStaticData) return;
    if (subsections.length === 0) return;

    const {
      market: editRetailer,
      period: editPeriod,
      metric: editMetric,
      productA: editProductA,
      productB: editProductB,
      context: editContext
    } = getConstraintsFromQuery(location.search);

    if (
      !editRetailer ||
      !editPeriod ||
      !editMetric ||
      !editProductA ||
      !editProductB ||
      editContext.length === 0
    ) {
      dispachClearStory();
      navigate(
        generatePath(NEW_REPORT_TYPE_ROUTE, {
          reportType: type,
          channel,
          step: "product"
        })
      );
      return;
    }

    const updatedProductA = updateAPIValue(
      getSearchOption(editProductA, "what", story),
      "product_a"
    );
    const updatedProductB = updateAPIValue(
      getSearchOption(editProductB, "what", story),
      "product_b"
    );
    setProductA(updatedProductA);
    setProductB(updatedProductB);
    setRetailer(editRetailer);
    setMetric(editMetric);

    const periodOptions = [
      ...(subsections.find(item => item.table === "when")?.keywords || []).map(
        i => ({
          date: i.date,
          label: `${i.period} ${i.date}`,
          period: i.period,
          value: i.period
        })
      ),
      { period: "custom", label: "Choose range on calendar" }
    ];

    const selectedPeriod = periodOptions.find(i => i.period === editPeriod);

    if (selectedPeriod) {
      setPeriod(selectedPeriod);
    } else if (editPeriod.split(",").length === 4) {
      const customPeriod = {
        label: "Choose range on calendar",
        name: editPeriod,
        period: "custom",
        story,
        subsections: "period",
        table: "when"
      };
      setPeriod(customPeriod);
    }

    const updatedContext = getSearchOptions(editContext, "what", story);

    setContext(updatedContext);

    // filter static data
    const filterTerms = [...updatedContext, updatedProductA, updatedProductB];
    const apiTerms = filterTerms.map(i => i.apiValue);
    dispatchFilterStaticData(null, apiTerms, story, client);
  }, [isStaticData]);

  const onNextClick = () => {
    const constraints = [
      {
        name: productA.value,
        subsection: productA.subsection,
        prefix: "product_a"
      },
      {
        name: productB.value,
        subsection: productB.subsection,
        prefix: "product_b"
      },
      ...context.map(i => ({ name: i.value, subsection: i.subsection })),
      { name: retailer, subsection: "retailer" },
      {
        name: period.period !== "custom" ? period.value : period.name,
        subsection: "period"
      },
      { name: metric, subsection: "metric_name" }
    ];
    navigate({
      pathname: VIEW_REPORT_ROUTE,
      search: `story=${story.toUpperCase()}&${buildQueryString(constraints)}`
    });
  };

  return (
    <Fragment>
      <Header
        story={story}
        isLastStep
        productA={productA}
        productB={productB}
        context={context}
        retailer={retailer}
        period={period}
        metric={metric}
        isEdit
      />
      <Grid container rowSpacing={1} alignItems="stretch">
        <Grid item xs={12}>
          <EditProducts
            context={context}
            story={story}
            productA={productA}
            productB={productB}
            setProductA={setProductA}
            setProductB={setProductB}
            setContext={setContext}
            dataSet={dataSet}
          />
        </Grid>
        <Grid item xs={12}>
          <EditContext
            context={context}
            story={story}
            productA={productA}
            productB={productB}
            setContext={setContext}
          />
        </Grid>
        <Grid item xs={12}>
          <EditMarket
            isLoading={isLoading}
            subsections={subsections}
            retailer={retailer}
            period={period}
            metric={metric}
            setRetailer={setRetailer}
            setPeriod={setPeriod}
            setMetric={setMetric}
            dataDate={dataDate}
            story={story}
          />
        </Grid>
      </Grid>
      <NavigateStepButtons
        onBackClick={() =>
          navigate(
            generatePath(NEW_REPORT_TYPE_ROUTE, {
              reportType: type,
              channel,
              step: "product"
            })
          )
        }
        backLabel="New Brand Battles Report"
        backIcon={<AddIcon />}
        onNextClick={onNextClick}
        nextLabel="Run Report"
        nextDisabled={
          !productA ||
          !productB ||
          context.length === 0 ||
          !retailer ||
          !period ||
          !metric
        }
      />
    </Fragment>
  );
};

EditBrandBattlesReport.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  getData: PropTypes.func,
  client: PropTypes.string,
  isStaticData: PropTypes.bool,
  dataDate: PropTypes.string,
  dispatchFilterStaticData: PropTypes.func,
  dispachClearStory: PropTypes.func
};

EditBrandBattlesReport.defaultProps = {
  subsections: [],
  isLoading: false,
  getData: () => {},
  client: "",
  isStaticData: false,
  dataDate: "",
  dispatchFilterStaticData: () => {},
  dispachClearStory: () => {}
};

const mapStateToProps = state => {
  const {
    data: { subsections = { dataSet: [] }, date = { dataSet: "" } }
  } = state;
  return {
    subsections: subsections[dataSet],
    isLoading: state.data.isFetchingData,
    isStaticData: state.data.isStaticData,
    client: state.user.user.client,
    dataDate: date[dataSet]
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => dispatch(fetchStaticData(client)),
  dispatchFilterStaticData: (term, filterTerms, filterStory, client) =>
    dispatch(filterStaticData(term, filterTerms, filterStory, client)),
  dispachClearStory: () => {
    dispatch(clearStoryConstraints(story));
    dispatch(clearStorySearchData(story));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBrandBattlesReport);
