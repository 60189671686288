import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton, Typography, Stack } from "@mui/material";
import { goToSlide } from "../../actions";
import { LeftIcon } from "../Icons";

const PresentationNavLeft = props => {
  const { activeSlideIndex, toSlide, report, setIsNavigation } = props;
  const isFirstSlide = activeSlideIndex <= 0;
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      className="PresentationNavLeft"
      sx={{ display: isFirstSlide && "none" }}
      spacing={0.5}
    >
      <IconButton
        id="presentation-prev"
        color="secondary"
        sx={{ bgcolor: "grey.200", "&:hover": { bgcolor: "secondary.main" } }}
        onClick={() => {
          toSlide(activeSlideIndex - 1, report, report.length);
          setIsNavigation(true);
        }}
        disabled={isFirstSlide}
      >
        <LeftIcon fontSize="small" sx={{ color: "white" }} />
      </IconButton>
      <Typography variant="caption" color="grey.500">
        Previous
      </Typography>
    </Stack>
  );
};

PresentationNavLeft.propTypes = {
  activeSlideIndex: PropTypes.number,
  toSlide: PropTypes.func,
  report: PropTypes.arrayOf(PropTypes.shape()),
  setIsNavigation: PropTypes.func
};

PresentationNavLeft.defaultProps = {
  activeSlideIndex: 0,
  toSlide: () => {},
  report: [],
  setIsNavigation: () => {}
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toSlide: (slideIndex, report, slideCount) => {
    const { reportId, userId, story, client } = ownProps;
    dispatch(
      goToSlide(
        slideIndex,
        report,
        "prev",
        slideCount,
        reportId,
        userId,
        story,
        client
      )
    );
  }
});

export { PresentationNavLeft as PresentationNavLeftComponent };
export default connect(null, mapDispatchToProps)(PresentationNavLeft);
