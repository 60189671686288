import {
  track,
  init,
  reset,
  Identify,
  identify,
  flush,
  setUserId
} from "@amplitude/analytics-browser";
import { AMPLITUDE_API_KEY, USER_FEEDBACK_URL } from "constants";
import fetchInstance from "utils/fetchInstance";

const logger = message => {
  const { action, ...eventProperties } = message;
  track(action, eventProperties);
};

export const initLogger = (email, client) => {
  init(AMPLITUDE_API_KEY, email, { defaultTracking: true });
  setUserId(email);
  const identifyEvent = new Identify();
  const iClient =
    email.includes("@interrodata.com") || email.includes("@dijuno.ai")
      ? "dijuno"
      : client;
  identifyEvent.set("client", iClient);
  identify(identifyEvent);
  flush();
};

export const resetLogger = () => reset();

export const sendFeedback = (
  component,
  url,
  isPositive,
  isNegative,
  comment
) => {
  logger({
    date: new Date().toISOString(),
    action: "USER_FEEDBACK",
    component,
    url,
    thumbs_up: isPositive,
    thumbs_down: isNegative,
    comment
  });
  try {
    fetchInstance(USER_FEEDBACK_URL, {
      method: "POST",
      body: JSON.stringify({
        date: new Date().toISOString(),
        component,
        url,
        thumbs_up: isPositive,
        thumbs_down: isNegative,
        comment
      }),
      headers: { "Content-Type": "application/json" }
    });
  } catch (err) {
    // TODO
  }
};

export default logger;
