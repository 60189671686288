import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Grid, Stack } from "@mui/material";
import { SelectAutocomplete } from "components/BaseComponents";
import { SearchBar, Expand, EditCard } from "components/CustomComponents";
import { filterStaticData } from "actions";
import { BB_PRODUCTS, BB_COMPARISON_PRODUCTS } from "constants";
import { getSearchOptions, updateAPIValue } from "utils";

const getSelectedLabel = items =>
  items.map(i => `${_.startCase(i.subsection)}: ${i.value}`);

const EditProducts = props => {
  const {
    subsections,
    filteredSubsections,
    story,
    client,
    isFetchingData,
    dispatchFilterStaticData,
    isFilteringData,
    productA,
    productB,
    context,
    setProductA,
    setProductB,
    setContext
  } = props;
  const [isProductBOpen, setIsProductBOpen] = useState(false);
  const [subsectionsA, setSubsectionsA] = useState([]);
  const [subsectionsB, setSubsectionsB] = useState([]);
  const [comparisonOptions, setComparisonOptions] = useState([]);

  useEffect(() => {
    if (productA) {
      setComparisonOptions(BB_COMPARISON_PRODUCTS[productA.subsection]);
    } else {
      setComparisonOptions([]);
    }
  }, [productA]);

  useEffect(() => {
    const options = subsections.filter(i => BB_PRODUCTS.includes(i.name));
    setSubsectionsA(options);
  }, [subsections]);

  useEffect(() => {
    if (comparisonOptions.length === 0) return;
    const options = filteredSubsections.filter(i =>
      comparisonOptions.includes(i.name)
    );
    setSubsectionsB(options);
  }, [filteredSubsections, comparisonOptions]);

  const handleProductAChange = value => {
    const updatedValue = updateAPIValue(value, "product_a");
    setProductA(updatedValue);
    // remove productB
    if (productB) {
      setProductB(null);
      setIsProductBOpen(true);
    }
    // remove context
    if (context.length > 0) {
      setContext([]);
    }
    // filter static data
    const apiProduct = updatedValue ? updatedValue.apiValue : null;
    dispatchFilterStaticData(apiProduct, [], story, client);
  };

  const handleProductBChange = value => {
    const updatedValue = updateAPIValue(value, "product_b");
    setProductB(updatedValue);
    // remove context
    if (context.length > 0) {
      setContext([]);
    }
    // filter static data
    const apiProduct = updatedValue ? updatedValue.apiValue : null;
    const filterTerms = [productA.apiValue];
    dispatchFilterStaticData(apiProduct, filterTerms, story, client);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <EditCard
          label="Primary Product"
          selected={getSelectedLabel(productA ? [productA] : [])}
          isWarning={!!productA}
          warningText="If you change the Primary Product, you must also change the Comparison Product and Product Filter."
        >
          <SearchBar
            value={productA}
            onChange={(e, value) => handleProductAChange(value)}
            options={subsectionsA}
            disabled={isFilteringData}
            styles={{ mb: 2 }}
            isLoading={isFetchingData}
            infoText="You can select a Distributor, a Major Brand or a Sub Brand."
            story={story}
          />
          <Expand label="Advanced Search">
            <Stack spacing={2} sx={{ py: 1 }}>
              {subsectionsA.map(i => (
                <SelectAutocomplete
                  key={i.name}
                  label={_.startCase(i.name)}
                  fullWidth
                  styles={{ maxWidth: 400 }}
                  options={getSearchOptions(
                    i.keywords
                      .sort()
                      .map(kw => ({ name: kw, subsection: i.name })),
                    i.table,
                    story
                  )}
                  value={productA?.subsection === i.name ? productA : null}
                  onChange={(e, value) => handleProductAChange(value)}
                  getOptionLabel={option => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  disabled={isFilteringData}
                />
              ))}
            </Stack>
          </Expand>
        </EditCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <EditCard
          label="Comparison Product"
          selected={getSelectedLabel(productB ? [productB] : [])}
          isWarning={!!productB}
          warningText="If you change the Comparison Product, you must also change the Product Filter."
          controlled
          isOpen={isProductBOpen}
          disabled={!productA}
        >
          <SearchBar
            value={productB}
            onChange={(e, value) => handleProductBChange(value)}
            options={subsectionsB}
            disabled={isFilteringData || !productA}
            styles={{ mb: 2 }}
            infoText={
              productA &&
              `You have chosen a ${_.startCase(productA.subsection).replace(
                " Id",
                ""
              )} as the Primary Product. Please select a different ${comparisonOptions
                .join(" or a ")
                .replaceAll("_", " ")
                .replace(" id", "")} as your Comparison Product.`
            }
            story={story}
          />
          <Expand label="Advanced Search">
            <Stack spacing={2} sx={{ py: 1 }}>
              {subsectionsB.map(i => (
                <SelectAutocomplete
                  key={i.name}
                  label={_.startCase(i.name)}
                  fullWidth
                  styles={{ maxWidth: 400 }}
                  options={getSearchOptions(
                    i.keywords
                      .sort()
                      .map(kw => ({ name: kw, subsection: i.name })),
                    i.table,
                    story
                  )}
                  value={productB?.subsection === i.name ? productB : null}
                  onChange={(e, value) => handleProductBChange(value)}
                  getOptionLabel={option => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  disabled={isFilteringData}
                />
              ))}
            </Stack>
          </Expand>
        </EditCard>
      </Grid>
    </Grid>
  );
};

EditProducts.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  isFetchingData: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  dispatchFilterStaticData: PropTypes.func,
  client: PropTypes.string,
  story: PropTypes.string,
  productA: PropTypes.shape(),
  productB: PropTypes.shape(),
  context: PropTypes.arrayOf(PropTypes.shape()),
  setProductA: PropTypes.func,
  setProductB: PropTypes.func,
  setContext: PropTypes.func
};

EditProducts.defaultProps = {
  subsections: [],
  filteredSubsections: [],
  isFetchingData: false,
  isFilteringData: false,
  dispatchFilterStaticData: () => {},
  client: "",
  story: "",
  productA: null,
  productB: null,
  context: [],
  setProductA: () => {},
  setProductB: () => {},
  setContext: () => {}
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: { isFilteringData, filteredSubsections = { story: [] } },
    data: { subsections = { dataSet: [] } }
  } = state;
  return {
    isFilteringData,
    client: state.user.user.client,
    isFetchingData: state.data.isFetchingData,
    subsections: subsections[dataSet],
    filteredSubsections: filteredSubsections[story]
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFilterStaticData: (term, filterTerms, story, client) =>
    dispatch(filterStaticData(term, filterTerms, story, client))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProducts);
