import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Stack } from "@mui/material";
import { SelectAutocomplete, SelectGroup } from "components/BaseComponents";
import { EditCard } from "components/CustomComponents";

const metricOptions = [
  { label: "Unit Sales", id: "Unit Sales" },
  { label: "Value Sales", id: "Value Sales" }
];

const filterHierarchy = (hierarchyList, selected) => {
  const option = hierarchyList.find(i => i.name === selected);
  return option.isFilter
    ? hierarchyList
        .filter(i => i.name !== option.name)
        .filter(i => i.ranking <= option.ranking)
    : hierarchyList.filter(i => i.name !== option.name);
};

let isFirstRender = true;

const EditHierarchy = props => {
  const {
    setHier1,
    setHier2,
    setMetric,
    hier1,
    hier2,
    hierarchyList,
    isLoading,
    metric
  } = props;
  const [hierarchyTwoList, setHierarchyTwoList] = useState([]);
  const [isHierarchyOpen, setIsHierarchyOpen] = useState(false);

  useEffect(() => {
    if (!hier1) return;
    const filteredList = filterHierarchy(hierarchyList, hier1.name);
    setHierarchyTwoList(filteredList);
  }, [hier1]);

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      return;
    }
    if (!hier1) setIsHierarchyOpen(true);
  }, [hier1]);

  const handleHier1 = value => {
    if (hier2) setHier2(null);
    setHier1(value);
  };

  const handleMetric = item => {
    const { id } = item;
    setMetric(id);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <EditCard
          label="Result Hierarchy"
          selected={
            (hier1 &&
              hier2 && [
                `First Split: ${hier1.label}`,
                `Second Split: ${hier2.label}`
              ]) ||
            (hier1 && [`First Split: ${hier1.label}`]) ||
            []
          }
          controlled
          isOpen={isHierarchyOpen}
        >
          <Stack spacing={2} sx={{ maxWidth: 400 }}>
            <SelectAutocomplete
              label="First split by"
              fullWidth
              options={hierarchyList}
              value={hier1}
              onChange={(e, value) => handleHier1(value)}
              isLoading={isLoading}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
            />
            <SelectAutocomplete
              label="Then split by"
              fullWidth
              options={hierarchyTwoList}
              value={hier2}
              onChange={(e, value) => setHier2(value)}
              isLoading={isLoading}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              disabled={!hier1}
            />
          </Stack>
        </EditCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <EditCard label="Analysis Metric" selected={[metric]}>
          <SelectGroup
            options={metricOptions}
            selected={metric}
            onClick={handleMetric}
            controlled
            isIcon
          />
        </EditCard>
      </Grid>
    </Grid>
  );
};

EditHierarchy.propTypes = {
  setHier1: PropTypes.func,
  setHier2: PropTypes.func,
  setMetric: PropTypes.func,
  hier1: PropTypes.shape(),
  hier2: PropTypes.shape(),
  hierarchyList: PropTypes.arrayOf(PropTypes.shape()),
  metric: PropTypes.string,
  isLoading: PropTypes.bool
};

EditHierarchy.defaultProps = {
  setHier1: () => {},
  setHier2: () => {},
  setMetric: () => {},
  hier1: null,
  hier2: null,
  hierarchyList: [],
  metric: null,
  isLoading: false
};

export default EditHierarchy;
