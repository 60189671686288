import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  IconButton,
  Typography,
  Collapse,
  Link,
  Stack,
  Button,
  Box
} from "@mui/material";
import { Paper } from "components/BaseComponents";
import { CloseIcon, InfoIcon } from "components/Icons";
import { SUPPORT_ROUTE } from "constants/viewRoutes";

const showExample = (number, request, product, scope, market) => (
  <Stack spacing={1} sx={{ mb: 3 }}>
    <Typography variant="subtitle2" fontWeight="bold">
      Example {number}
    </Typography>
    <Typography variant="body1" color="grey.700">
      {request}
    </Typography>
    <Typography variant="body1" color="grey.700">
      1) {product}
    </Typography>
    <Typography variant="body1" color="grey.700">
      2) {scope}
    </Typography>
    <Typography variant="body1" color="grey.700">
      3) {market}
    </Typography>
  </Stack>
);

const ExamplesBox = props => {
  const { examples } = props;
  const [isHidden, setIsHidden] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Collapse in={!isHidden}>
      <Paper styles={{ borderColor: "jade.main", bgcolor: "jade.50" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack direction="row" spacing={2}>
            <InfoIcon color="jade" fontSize="small" />
            <Box>
              {showExample(
                1,
                `I would like to see the insights for ${examples[0].request} in ${examples[0].market}. What should I enter?`,
                `Enter ${examples[0].product} into the Target Product box.`,
                `Enter ${examples[0].scope} into the Product Filter box.`,
                `Enter ${examples[0].market} into the Market box.`
              )}
              {!isExpanded && (
                <Button
                  variant="outlined"
                  size="small"
                  color="jade"
                  onClick={() => setIsExpanded(true)}
                >
                  Show more examples
                </Button>
              )}
              <Collapse in={isExpanded}>
                {showExample(
                  2,
                  `I want to understand how ${examples[0].request} in ${examples[0].market}. How do I do this?`,
                  `Enter ${examples[1].product} as your Target Product.`,
                  `Enter ${examples[1].scope} into the Product Filter box.`,
                  `Enter ${examples[1].market} into the Market box.`
                )}
                {showExample(
                  3,
                  `I want to receive insights about how ${examples[0].request} in ${examples[0].market}. How do I do this?`,
                  `Enter ${examples[0].product} as your Target Product.`,
                  `Enter ${examples[0].scope} into the Product Filter box.`,
                  `Enter ${examples[0].market} into the Market box.`
                )}
                <Typography variant="body2" color="grey.700" sx={{ pb: 3 }}>
                  Please{" "}
                  <Link
                    component={RouterLink}
                    to={SUPPORT_ROUTE}
                    color="primary"
                  >
                    contact us
                  </Link>{" "}
                  to let us know of any other examples you would like to be
                  added!
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  color="jade"
                  onClick={() => setIsExpanded(false)}
                >
                  Show fewer examples
                </Button>
              </Collapse>
            </Box>
          </Stack>
          <IconButton
            color="disabled"
            onClick={() => {
              setIsHidden(true);
              setIsExpanded(false);
            }}
            size="large"
            sx={{ color: "grey.500" }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Paper>
    </Collapse>
  );
};

ExamplesBox.propTypes = {
  examples: PropTypes.arrayOf(PropTypes.shape())
};

ExamplesBox.defaultProps = {
  examples: []
};

export default ExamplesBox;
