import React from "react";
import PropTypes from "prop-types";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowLeftIcon, ArrowRightIcon } from "components/Icons";
import { Paper } from "components/BaseComponents";

const NavigateStepButtons = props => {
  const {
    backLabel,
    nextLabel,
    onBackClick,
    onNextClick,
    nextDisabled,
    nextLoading,
    backIcon,
    nextIcon
  } = props;

  return (
    <Paper>
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          startIcon={backIcon || <ArrowLeftIcon />}
          onClick={onBackClick}
        >
          {backLabel}
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          endIcon={nextIcon || <ArrowRightIcon />}
          onClick={onNextClick}
          disabled={nextDisabled}
          loading={nextLoading}
          loadingPosition="end"
        >
          {nextLabel}
        </LoadingButton>
      </Stack>
    </Paper>
  );
};

NavigateStepButtons.propTypes = {
  backLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  nextDisabled: PropTypes.bool,
  nextLoading: PropTypes.bool,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func,
  backIcon: PropTypes.node,
  nextIcon: PropTypes.node
};

NavigateStepButtons.defaultProps = {
  backLabel: "Go Back",
  nextLabel: "Next Step",
  nextDisabled: false,
  nextLoading: false,
  onBackClick: () => {},
  onNextClick: () => {},
  backIcon: null,
  nextIcon: null
};

export default NavigateStepButtons;
