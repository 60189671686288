import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import search from "./search";
import presentation from "./presentation";
import report from "./report";
import reportConstraints from "./reportConstraints";
import user from "./user";
import data from "./data";
import notification from "./notification";
import lookout from "./lookout";
import sterling from "./sterling";
import pusher from "./pusher";
import autoRanging from "./autoRanging";
import streamlit from "./streamlit";

const autoRangingPersistConfig = {
  key: "autoRanging",
  storage: sessionStorage
};

const rootReducer = combineReducers({
  search,
  presentation,
  report,
  reportConstraints,
  user,
  data,
  notification,
  lookout,
  sterling,
  pusher,
  autoRanging: persistReducer(autoRangingPersistConfig, autoRanging),
  streamlit
});

export default rootReducer;
