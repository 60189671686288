import { STATIC_DATA_URL, CHECK_DATA_REFRESH_URL } from "../constants";
import { clientConfig } from "../config";
import {
  FETCH_STATIC_DATA_REQUEST,
  FETCH_STATIC_DATA_SUCCESS,
  FETCH_STATIC_DATA_ERROR,
  CLEAR_STATIC_DATA_CACHE,
  SET_DATA_REFRESH,
  SET_ALL_DATA_REFRESH,
  CLEAR_DATA_REFRESH,
  CLEAR_ALL_DATA_REFRESH
} from "../constants/actionConstants";
import fetchInstance from "../utils/fetchInstance";

/**
 * Action for when the autocomplete data is being fetched from the API
 * @return {Object} Object to be used by the dispatch function
 */
const fetchStaticDataRequest = () => ({
  type: FETCH_STATIC_DATA_REQUEST
});

/**
 * Action for when the static data fetch failed.
 * @param  {String} [error=""] The error message to show
 * @return {Object}            Object to be used by the dispatch function
 */
const fetchStaticDataError = (error = "") => ({
  type: FETCH_STATIC_DATA_ERROR,
  message: error
});

/**
 * Action for when the static data was fetched successfully.
 * @param  {Object} data the data received from the API server
 * @return {Object}      Object to be used by the dispatch function
 */
const fetchStaticDataSuccess = data => ({
  type: FETCH_STATIC_DATA_SUCCESS,
  ...data
});

/**
 * Action for fetching static data from the API server
 * @return {Promise} Promise that is used by the thunk middleware
 */
export const fetchStaticData = client => async dispatch => {
  dispatch(fetchStaticDataRequest());
  try {
    const allSubsections = {};
    const placeholders = {};
    let dates = {};
    const dataSets = clientConfig[client].staticDataSets;
    await Promise.all(
      dataSets.map(async e => {
        await fetchInstance(
          `${STATIC_DATA_URL}?data_set=${e}&client=${client}`,
          {
            headers: { "Content-Type": "application/json" }
          }
        )
          .then(async res => {
            if (!res.ok) {
              const data = await res.json();
              const err = data?.message || data;
              throw new Error(err);
            }
            return res.json();
          })
          .then(
            res => {
              if (String(res).startsWith("ERROR")) {
                throw new Error("ERROR: static data cache corrupted");
              } else {
                const { subsections, date, placeholder } = res;
                allSubsections[e] = subsections;
                placeholders[e] = placeholder;
                dates = { ...dates, ...date };
              }
            },
            err => {
              dispatch(fetchStaticDataError(err.message));
            }
          );
      })
    );
    dispatch(
      fetchStaticDataSuccess({
        subsections: allSubsections,
        date: dates,
        placeholder: placeholders,
        dataSets
      })
    );
  } catch (err) {
    dispatch(fetchStaticDataError(err.message));
  }
};

/**
 * Action for when the static data cache is cleared so it can be refreshed.
 * @return {Object} Object to be used by the dispatch function
 */
export const clearStaticDataCache = () => ({
  type: CLEAR_STATIC_DATA_CACHE
});

export const setDataRefresh = dataSet => ({
  type: SET_DATA_REFRESH,
  dataSet
});

const setAllDataRefresh = dataSets => ({
  type: SET_ALL_DATA_REFRESH,
  dataSets
});

export const clearDataRefresh = dataSet => ({
  type: CLEAR_DATA_REFRESH,
  dataSet
});

const clearAllDataRefresh = () => ({
  type: CLEAR_ALL_DATA_REFRESH
});

export const checkDataRefresh = () => async dispatch =>
  fetchInstance(CHECK_DATA_REFRESH_URL, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(data => {
      const { datasets, update_in_progress: isUpdate } = data;
      if (isUpdate) {
        return dispatch(setAllDataRefresh(datasets));
      }
      return dispatch(clearAllDataRefresh());
    });

export const checkDataSetDataRefresh = dataSet => async dispatch =>
  fetchInstance(`${CHECK_DATA_REFRESH_URL}?dataset=${dataSet}`, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async res => {
      if (!res.ok) {
        const data = await res.json();
        const err = data?.message || data;
        throw new Error(err);
      }
      return res.json();
    })
    .then(data => {
      const { datasets, update_in_progress: isUpdate } = data;
      if (!isUpdate || datasets.length === 0) {
        dispatch(clearDataRefresh(dataSet));
      }
    });
