import React from "react";
import PropTypes from "prop-types";
import {
  InputAdornment,
  Stack,
  Tooltip as MUITooltip,
  Typography
} from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { SearchIcon } from "components/Icons";
import { Switch, TextField, Tooltip } from "components/BaseComponents";

const styles = {
  icon: val => ({
    color: val > 0 ? "positive.main" : "negative.main",
    bgcolor: "white",
    border: 1,
    borderColor: val > 0 ? "positive.main" : "negative.main",
    borderRadius: "50%",
    minWidth: 24,
    width: 24,
    height: 24
  })
};

const highlightCell = (isHighlight, value, rank) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{ width: 1 }}
  >
    <Typography variant="inherit">{value}</Typography>
    {isHighlight && rank && (
      <Stack alignItems="center" justifyContent="center" sx={styles.icon(rank)}>
        <Typography variant="body2" fontWeight="medium" component="span">
          {rank}
        </Typography>
      </Stack>
    )}
  </Stack>
);

const renderHeader = params => {
  const { colDef } = params;
  const { headerName, description } = colDef;
  return (
    <Tooltip title={headerName} content={description}>
      <Typography
        variant="inherit"
        sx={{ whiteSpace: "normal", lineHeight: 1.2 }}
      >
        {headerName}
      </Typography>
    </Tooltip>
  );
};

export const getColumns = (isHighlight, clientName) => [
  {
    field: "rank",
    headerName: "Rank",
    type: "number",
    sortable: false,
    headerAlign: "center",
    align: "center",
    width: 50
  },
  {
    field: "product_name",
    headerName: "Product Name",
    sortable: false,
    flex: 1,
    minWidth: 300,
    renderCell: params => {
      const { value } = params;
      return (
        <MUITooltip title={value}>
          <Typography variant="inherit" noWrap>
            {value}
          </Typography>
        </MUITooltip>
      );
    }
  },
  {
    field: "score",
    headerName: "Total Score",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description:
      "We calculate a score based on all of the metrics you can see here."
  },
  { field: "category", headerName: "Category", sortable: false },
  { field: "sector", headerName: "Sector", sortable: false },
  { field: "brand", headerName: "Brand", sortable: false },
  { field: "pack_type", headerName: "Pack Type", sortable: false },
  { field: "offer_type", headerName: "PMP?", sortable: false },
  {
    field: "consumption_type",
    headerName: "Consumption Type",
    sortable: false
  },
  {
    field: "units_per_package",
    headerName: "Units per Package",
    sortable: false
  },
  {
    field: "container_material",
    headerName: "Container Material",
    sortable: false
  },
  { field: "pack_size_litres", headerName: "Volume per Unit", sortable: false },
  {
    field: "value_sales",
    headerName: "Value Sales (£)",
    sortable: false,
    disableExport: true,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.value_sales_rank);
    },
    renderHeader: params => renderHeader(params),
    description:
      "The average weekly value sales of the product over the past 12 weeks."
  },
  {
    field: "value_sales_export",
    headerName: "Value sales (£)",
    sortable: false
  },
  {
    field: "sku_growth",
    headerName: "SKU Growth",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.sku_growth_rank);
    },
    renderHeader: params => renderHeader(params),
    description:
      "The % change of value sales of the product in the last 12 weeks compared to the same 12 weeks last year."
  },
  {
    field: "numeric_distribution",
    headerName: "Numeric Distribution",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.numeric_distribution_rank);
    },
    renderHeader: params => renderHeader(params),
    description: "The percentage of stores that the product is sold in."
  },
  {
    field: "number_shop",
    headerName: "Number of Stores",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description: "The total number of stores in the selected environment."
  },
  {
    field: "ros",
    headerName: "Unit Rate of Sale",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.ros_rank);
    },
    renderHeader: params => renderHeader(params),
    description: "Units sold per store per week."
  },
  {
    field: "financial_score",
    headerName: "Profitibility Decile",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.financial_score_rank);
    },
    renderHeader: params => renderHeader(params),
    description: `The relative profitability of a sku compared to other ${clientName} skus, based on profitability per unit case. 10 is most profitable, 0 is least profitable.`
  },
  {
    field: "retained_value",
    headerName: `Value Retained within ${clientName}`,
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.retained_value_rank);
    },
    renderHeader: params => renderHeader(params),
    description: `A dijuno model (Range Finder) that calculates the substitutability and incrementality of ${clientName} products. Within the ranging tool, it is predominantly used to identify the percentage of sales that would remain within ${clientName} if a product was removed from sale.`
  },
  {
    field: "sector_projection",
    headerName: "Sector Projection",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description:
      "The change in value sales of the category / sector that a product sits within between last 52 weeks compared to previous 52 weeks."
  },
  {
    field: "ic_fc_projection",
    headerName: "IC/FC Projection",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description:
      "The change in value sales of the consumption type (IC vs FC) that a product is between last 52 weeks compared to previous 52 weeks."
  },
  {
    field: "sugar_projection",
    headerName: "Sugar Content Projection",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description:
      "The change in value sales of the sugar content (Low Calorie vs Regular) that a product is between last 52 weeks compared to previous 52 weeks."
  },
  {
    field: "category_ranking",
    headerName: "Category Importance",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description:
      "User-input relative prioritisation of categories and sectors. Increased prioritisation of these will boost all products which have that respective attribute."
  },
  {
    field: "brand_ranking",
    headerName: "Brand Importance",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description:
      "User-input relative prioritisation of brands. Increased prioritisation of these will boost all products that sit within the given brand."
  },
  {
    field: "sub_brand_ranking",
    headerName: "Sub Brand Importance",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description:
      "User-input relative prioritisation of sub-brands/flavour. Increased prioritisation of these will boost all products that sit within the given sub-brand or flavour."
  },
  {
    field: "importance",
    headerName: "Strategic Importance",
    sortable: false,
    renderHeader: params => renderHeader(params),
    description: `A user-input value between 0-3 giving a boost to individual skus that have strategic importance for ${clientName}.`
  }
];

export const Toolbar = props => {
  const {
    search,
    setSearch,
    rankType,
    environments,
    consumptionType,
    isHighlight,
    setHighlight,
    dataDate
  } = props;
  return (
    <GridToolbarContainer sx={{ mb: 1, px: 0 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: 1 }}
      >
        <Typography variant="subtitle1" fontWeight="medium">
          Products&nbsp;
          <Typography variant="body1" component="span" color="grey.500">
            (Latest 12 weeks: w/e {dataDate})
          </Typography>
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={3}>
          <TextField
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search"
            styles={{ width: 250 }}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Switch
            color="secondary"
            checked={isHighlight}
            onChange={e => setHighlight(e.target.checked)}
            onLabel="Highlight top / bottom products"
          />
          <GridToolbarExport
            variant="outlined"
            color="secondary"
            size="small"
            csvOptions={{
              allColumns: true,
              fileName: `${consumptionType.toUpperCase()} ${rankType} ranking in ${environments.join(
                " "
              )} - ${
                search.length > 0 ? `filtered by ${search} - ` : ""
              }powered by dijuno (Latest 12 weeks w/e ${dataDate.replaceAll(
                "/",
                "-"
              )})`
            }}
            printOptions={{ hideToolbar: true, hideFooter: true }}
          />
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
};

Toolbar.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  rankType: PropTypes.string,
  consumptionType: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.string),
  isHighlight: PropTypes.bool,
  setHighlight: PropTypes.func,
  dataDate: PropTypes.string
};

Toolbar.defaultProps = {
  search: "",
  setSearch: () => {},
  rankType: "",
  consumptionType: "",
  environments: [],
  isHighlight: false,
  setHighlight: () => {},
  dataDate: ""
};
