import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { Paper } from "components/BaseComponents";
import { getWeightings, editSterling } from "actions";
import Header from "../Header";

const levelOptions = [
  { label: "Not important at all", value: 0 },
  { label: "Not very important", value: 20 },
  { label: "No change", value: 40 },
  { label: "Important", value: 60 },
  { label: "Very important", value: 80 },
  { label: "Most important", value: 100 }
];

const renderLoading = () => (
  <Stack direction="row" alignItems="stretch" spacing={0.5} sx={{ width: 1 }}>
    <Skeleton variant="rounded" width={350} height={128} />
    <Stack spacing={0.5} sx={{ width: 1 }}>
      {[...Array(3)].map(() => (
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ width: 1 }}
          key={_.uniqueId()}
        >
          <Skeleton variant="rounded" width={280} height={40} />
          <Skeleton variant="rounded" width={160} height={40} />
          <Skeleton variant="rounded" width={160} height={40} />
          <Skeleton variant="rounded" width={160} height={40} />
          <Skeleton variant="rounded" width={160} height={40} />
          <Skeleton variant="rounded" width={160} height={40} />
        </Stack>
      ))}
    </Stack>
  </Stack>
);

const Weightings = props => {
  const { options, getOptions, edit, isLoading, isSterling } = props;
  const [weightings, setWeightings] = useState([]);

  useEffect(() => {
    if (_.isEmpty(options)) {
      getOptions(options);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(options)) {
      setWeightings(options.details);
    }
  }, [options]);

  return (
    <Paper>
      <Header isSterling={isSterling} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <Stack spacing={1} sx={{ maxWidth: 0.85 }}>
          <Typography variant="h4" color="primary.main">
            Weightings
          </Typography>
          <Typography variant="body1" color="grey.500">
            This sheet allows you to edit the weightings given to each input of
            the model. You should increase or decrease importance of an input if
            you think it should impact the output of the model more or less than
            other inputs. By default all options have been assigned
            &lsquo;Standard&rsquo; weighting.
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            edit(_.differenceBy(weightings, options.details), {
              ...options,
              details: weightings
            })
          }
          disabled={
            isLoading || options.length === 0 || weightings.length === 0
          }
        >
          Apply Changes
        </Button>
      </Stack>
      <Stack spacing={0.5}>
        {_.isEmpty(options) || isLoading
          ? renderLoading()
          : options.categories.map(i => {
              const details = weightings.filter(j => j.parent === i);
              return (
                <Stack direction="row" spacing={0.5} key={i}>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      bgcolor: "grey.50",
                      borderRadius: 1,
                      minWidth: 150,
                      width: 1,
                      p: 1
                    }}
                  >
                    <Typography variant="subtitle2" color="grey.500">
                      {i}
                    </Typography>
                  </Stack>
                  <Stack spacing={0.5} sx={{ width: 1 }}>
                    {details.map(j => (
                      <Stack direction="row" spacing={0.5} key={j.name}>
                        <Tooltip title={j.description || ""}>
                          <Typography
                            variant="subtitle2"
                            color="grey.500"
                            sx={{
                              bgcolor: "grey.50",
                              borderRadius: 1,
                              minWidth: 250,
                              p: 1.25
                            }}
                          >
                            {j.name}
                          </Typography>
                        </Tooltip>
                        {levelOptions.map(o => (
                          <Button
                            key={o.label}
                            variant={
                              j.weighting === o.value ? "contained" : "soft"
                            }
                            onClick={() =>
                              setWeightings(
                                weightings.map(k =>
                                  k.id === j.id
                                    ? { ...k, weighting: o.value }
                                    : k
                                )
                              )
                            }
                          >
                            {o.label}
                          </Button>
                        ))}
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              );
            })}
      </Stack>
    </Paper>
  );
};

Weightings.propTypes = {
  options: PropTypes.shape(),
  getOptions: PropTypes.func,
  edit: PropTypes.func,
  isLoading: PropTypes.bool,
  isSterling: PropTypes.bool
};

Weightings.defaultProps = {
  options: {},
  getOptions: () => {},
  edit: () => {},
  isLoading: false,
  isSterling: false
};

const mapStateToProps = state => ({
  options: state.sterling.weightings,
  isLoading: state.sterling.isLoading
});

const mapDispatchToProps = dispatch => ({
  getOptions: () => dispatch(getWeightings()),
  edit: (data, updatedData) =>
    dispatch(editSterling("weightings", data, updatedData))
});

export default connect(mapStateToProps, mapDispatchToProps)(Weightings);
