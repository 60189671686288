import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography, Grid } from "@mui/material";
import { clearAllConstraints } from "actions";
import {
  BrandBattlesIcon,
  FocusIcon,
  PerformanceIcon,
  RangeReviewIcon,
  TrendIcon
} from "components/Icons";
import {
  NEW_IH_PERFORMANCE_REPORT_ROUTE,
  NEW_OOH_PERFORMANCE_REPORT_ROUTE,
  NEW_IH_BRAND_BATTLES_REPORT_ROUTE,
  NEW_IH_RANGE_REVIEW_REPORT_ROUTE,
  NEW_IH_TREND_REPORT_ROUTE,
  NEW_IH_PERFORMANCE_FOCUSED_REPORT_ROUTE,
  NEW_OOH_SHARE_FOCUSED_REPORT_ROUTE,
  NEW_IH_NPD_FOCUSED_REPORT_ROUTE,
  NEW_OOH_NPD_FOCUSED_REPORT_ROUTE,
  NEW_IH_STARS_FOCUSED_REPORT_ROUTE,
  NEW_OOH_STARS_FOCUSED_REPORT_ROUTE
} from "constants/viewRoutes";
import { Paper } from "components/BaseComponents";
import { ReportCard } from "components/CustomComponents";
import { clientConfig } from "config";
import { storyEnums } from "constants/enums";
import { REPORT_COLORS } from "theme";

const disabledTooltip =
  "This report is temporarily disabled during data refresh. Please try again shortly.";

const NewReport = props => {
  const {
    dispatchClearAllConstraints,
    client,
    isUpdatingData,
    updatingStories
  } = props;
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { stories } = clientConfig[client] || { stories: [] };

  useEffect(() => {
    dispatchClearAllConstraints();
  }, []);

  useEffect(() => {
    if (hash) {
      const hashValue = hash.slice(1);
      const element = document.getElementById(hashValue);
      if (element) {
        element.style.scrollMargin = "65px";
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  const reportTypes = [
    {
      title: "Performance Reports",
      description:
        "Understand how a category or brand is performing over time, in a specific market, channel or retailer. A performance overview that also drills down to sku level.",
      icon: <PerformanceIcon sx={{ color: REPORT_COLORS.performance }} />,
      buttons: [
        {
          label: (
            <Stack>
              <Typography variant="inherit">New In-Home Report</Typography>
              <Typography variant="inherit" fontSize={12}>
                (Including Impulse)
              </Typography>
            </Stack>
          ),
          onClick: () => navigate(NEW_IH_PERFORMANCE_REPORT_ROUTE),
          style: {
            "&, &:hover": { bgcolor: REPORT_COLORS.performance },
            display: !stories.includes(storyEnums.IDA) && "none"
          },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.IDA),
          disabledTooltip
        },
        {
          label: (
            <Stack>
              <Typography variant="inherit">New Out-of-Home Report</Typography>
              <Typography variant="inherit" fontSize={12}>
                (Excluding Impulse)
              </Typography>
            </Stack>
          ),
          onClick: () => navigate(NEW_OOH_PERFORMANCE_REPORT_ROUTE),
          style: {
            "&, &:hover": { bgcolor: REPORT_COLORS.performance },
            display: !stories.includes(storyEnums.CGA) && "none"
          },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.CGA),
          disabledTooltip
        }
      ],
      isVisible:
        stories.includes(storyEnums.IDA) || stories.includes(storyEnums.CGA)
    },
    {
      title: "Trend Reports",
      description:
        "Be informed of the market trends that are affecting a category, manufacturer, brand, and more, within a given market and wider channel.",
      icon: <TrendIcon sx={{ color: REPORT_COLORS.trend }} />,
      buttons: [
        {
          label: "New Trend Report",
          onClick: () => navigate(NEW_IH_TREND_REPORT_ROUTE),
          style: { "&, &:hover": { bgcolor: REPORT_COLORS.trend } },
          disabled:
            isUpdatingData && updatingStories.includes(storyEnums.TREND),
          disabledTooltip
        }
      ],
      isVisible: stories.includes(storyEnums.TREND)
    },
    {
      title: "Brand Battles (Product Comparison)",
      description:
        "Compare the performance of two products within a specific market or retailer; understand the key drivers behind the difference of performance.",
      icon: <BrandBattlesIcon sx={{ color: REPORT_COLORS.brandBattles }} />,
      buttons: [
        {
          label: "New Brand Battles Report",
          onClick: () => navigate(NEW_IH_BRAND_BATTLES_REPORT_ROUTE),
          style: { "&, &:hover": { bgcolor: REPORT_COLORS.brandBattles } },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.BB),
          disabledTooltip
        }
      ],
      isVisible: stories.includes(storyEnums.BB)
    },
    {
      title: "Range Review Impact Assessment",
      description:
        "Explore the context and the impact of a range review, as well as the key drivers of performance change. This report is typically utilised by the Category Team.",
      icon: <RangeReviewIcon sx={{ color: REPORT_COLORS.rangeReview }} />,
      buttons: [
        {
          label: "New Range Review Report",
          onClick: () => navigate(NEW_IH_RANGE_REVIEW_REPORT_ROUTE),
          style: { "&, &:hover": { bgcolor: REPORT_COLORS.rangeReview } },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.PRR),
          disabledTooltip
        }
      ],
      isVisible: stories.includes(storyEnums.PRR)
    },
    {
      title: "Focus On: Share Performance",
      description:
        "Insights for performance relative to the category and share movement amongst products.",
      icon: <FocusIcon sx={{ color: REPORT_COLORS.focus }} />,
      buttons: [
        {
          label: (
            <Stack>
              <Typography variant="inherit">New In-Home Report</Typography>
              <Typography variant="inherit" fontSize={12}>
                (Share Performance)
              </Typography>
            </Stack>
          ),
          onClick: () => navigate(NEW_IH_PERFORMANCE_FOCUSED_REPORT_ROUTE),
          style: {
            "&, &:hover": { bgcolor: REPORT_COLORS.focus },
            display: !stories.includes(storyEnums.IDA) && "none"
          },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.IDA),
          disabledTooltip
        },
        {
          label: (
            <Stack>
              <Typography variant="inherit">New Out-of-Home Report</Typography>
              <Typography variant="inherit" fontSize={12}>
                (Share Performance)
              </Typography>
            </Stack>
          ),
          onClick: () => navigate(NEW_OOH_SHARE_FOCUSED_REPORT_ROUTE),
          style: {
            "&, &:hover": { bgcolor: REPORT_COLORS.focus },
            display: !stories.includes(storyEnums.CGA) && "none"
          },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.CGA),
          disabledTooltip
        }
      ],
      isFocus: true,
      isVisible: stories.includes(storyEnums.FP)
    },
    {
      title: "Focus On: NPD",
      description: "Identification and performance of new product development.",
      icon: <FocusIcon sx={{ color: REPORT_COLORS.focus }} />,
      buttons: [
        {
          label: (
            <Stack>
              <Typography variant="inherit">New In-Home Report</Typography>
              <Typography variant="inherit" fontSize={12}>
                (NPD)
              </Typography>
            </Stack>
          ),
          onClick: () => navigate(NEW_IH_NPD_FOCUSED_REPORT_ROUTE),
          style: {
            "&, &:hover": { bgcolor: REPORT_COLORS.focus },
            display: !stories.includes(storyEnums.IDA) && "none"
          },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.IDA),
          disabledTooltip
        },
        {
          label: (
            <Stack>
              <Typography variant="inherit">New Out-of-Home Report</Typography>
              <Typography variant="inherit" fontSize={12}>
                (NPD)
              </Typography>
            </Stack>
          ),
          onClick: () => navigate(NEW_OOH_NPD_FOCUSED_REPORT_ROUTE),
          style: {
            "&, &:hover": { bgcolor: REPORT_COLORS.focus },
            display: !stories.includes(storyEnums.CGA) && "none"
          },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.CGA),
          disabledTooltip
        }
      ],
      isFocus: true,
      isVisible: stories.includes(storyEnums.FP)
    },
    {
      title: "Focus On: Star- & Under-Performers",
      description:
        "Highlighting products and that have over or under-performed and the drivers behind it.",
      icon: <FocusIcon sx={{ color: REPORT_COLORS.focus }} />,
      buttons: [
        {
          label: (
            <Stack>
              <Typography variant="inherit">New In-Home Report</Typography>
              <Typography variant="inherit" fontSize={12}>
                (Star- & Under-Performers )
              </Typography>
            </Stack>
          ),
          onClick: () => navigate(NEW_IH_STARS_FOCUSED_REPORT_ROUTE),
          style: {
            "&, &:hover": { bgcolor: REPORT_COLORS.focus },
            display: !stories.includes(storyEnums.IDA) && "none"
          },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.IDA),
          disabledTooltip
        },
        {
          label: (
            <Stack>
              <Typography variant="inherit">New Out-of-Home Report</Typography>
              <Typography variant="inherit" fontSize={12}>
                (Star- & Under-Performers)
              </Typography>
            </Stack>
          ),
          onClick: () => navigate(NEW_OOH_STARS_FOCUSED_REPORT_ROUTE),
          style: {
            "&, &:hover": { bgcolor: REPORT_COLORS.focus },
            display: !stories.includes(storyEnums.CGA) && "none"
          },
          disabled: isUpdatingData && updatingStories.includes(storyEnums.CGA),
          disabledTooltip
        }
      ],
      isFocus: true,
      isVisible: stories.includes(storyEnums.FP)
    }
  ].filter(i => i.isVisible);

  const reports = reportTypes.filter(i => !i.isFocus);
  const focusReports = reportTypes.filter(i => i.isFocus);
  return (
    <Paper>
      {/* <Stack direction="row" justifyContent="space-between"> */}
      <Typography variant="h3" color="primary">
        Generate a New Report
      </Typography>
      <Typography variant="subtitle1" color="grey.500" sx={{ mb: 3 }}>
        We have a range of reports that you can run, each answering slightly
        different business questions. Choose one of the below:
      </Typography>
      <Grid container rowSpacing={2}>
        <Grid item md={12} container spacing={2}>
          {reports.map(report => (
            <Grid key={report.title} item xs={12} md={4}>
              <ReportCard report={report} />
            </Grid>
          ))}
        </Grid>
        <Grid item md={12} container spacing={2} id="focus-reports">
          {focusReports.map(report => (
            <Grid key={report.title} item xs={12} md={4}>
              <ReportCard report={report} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

NewReport.propTypes = {
  dispatchClearAllConstraints: PropTypes.func,
  client: PropTypes.string,
  isUpdatingData: PropTypes.bool,
  updatingStories: PropTypes.arrayOf(PropTypes.string)
};

NewReport.defaultProps = {
  dispatchClearAllConstraints: () => {},
  client: "",
  isUpdatingData: false,
  updatingStories: []
};

const mapStateToProps = state => ({
  client: state.user.user.client,
  isUpdatingData: state.data.isUpdatingData,
  updatingStories: state.data.updatingStories
});

const mapDispatchToProps = dispatch => ({
  dispatchClearAllConstraints: () => dispatch(clearAllConstraints())
});

export default connect(mapStateToProps, mapDispatchToProps)(NewReport);
